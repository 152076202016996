import React, { useState } from "react"
import InitialRegistration from './components/InitialRegistration'
import ForgotPasswordVerifyAndSet from './components/ForgotPasswordVerifyAndSet'
import { post } from '../lib/call-api'
import Alert from '../components/Alert'

const Register = () => {
  
  const [userData, setUserData] = useState('')
  const [step, setStep] = useState(1)

  const nextStep = () => {
    setStep(step + 1)
  }

  const values = { "email": userData.email }
  switch (step) {
      
    case 2: 
        return(
          <CheckYourEmail 
            userData={userData} 
            nextStep={nextStep} />
        )
    case 3:
      return(
          <ForgotPasswordVerifyAndSet 
              nextStep={nextStep}
              values={values} /> 
      )
    case 4:
      return (
        <section className="page page-slim">
              <h1>You've successfully registered.</h1>
              
              <p className="intro"><a href="/login">Login now</a></p>
              
          </section>
      )
    default :
      return (
        <InitialRegistration nextStep={nextStep} setDataInParent={setUserData}/>
      )  
  }
}

const CheckYourEmail = ({userData,nextStep}) => {

  const [resendResult, setResendResult] = useState()
  const resendCode = () => {
    const onCodeResend = () => {
      setResendResult({
        "type": "success",
        "message": "Code resent. Please check your email and return to this page."
      })
    }
    const onCodeResendError = (err) => {
      setResendResult({
        "type": "error",
        "message": "Unable to resend your code. Please try again later."
      })
    }
    const postBody = {
      "email": userData.email
    }
    post('/register/resend',onCodeResend,onCodeResendError,() => {return true},postBody)
  }

  return (
    <section className="page page-slim">
      <h1>Check your email</h1>
      <p>If you have an account, you should have received an email at <b><em>{userData.email}</em></b> with a verification code.</p>
      
      <div className="buttonContainer">
        <button onClick={nextStep} className="btn btn-primary">
        Enter the code</button>
      </div>

      <div>
        <h5>Didn't get an email?</h5>
        <ul>
            <li>You may have mistyped your email address. Double check the address above</li>
            <li>It may be taking longer than expected to receive the email.</li>
            <li>Check your spam filter for an email from ministrysquads.com</li>
        </ul>

        {
          resendResult && 
          <Alert type={resendResult.type} message={resendResult.message} context="inline" />
        }

        <button onClick={() => resendCode() } className="btn btn-link btn-slim">Try sending the code again</button>
      </div>

    </section>
  )
}

export default Register