import React from 'react'
import TypeAheadSearch from './TypeAheadSearch'
import { useTypeAheadState } from '../contexts/TypeAhead.Context'
import './css/TypeAhead.scss'

const TypeAhead = ({htmlId,selectedItemText,resultItemText = selectedItemText,addItemFunction}) => {
  const [typeAheadState, typeAheadDispatch] = useTypeAheadState()

  const remove = (browserEvent,toRemove) => {
    browserEvent.preventDefault()
    typeAheadDispatch({"type":"removeItem","payload": toRemove})
  }
 
  return (
    <section id={htmlId}>
       <div className="type-ahead-container">
        {
          typeAheadState.selectedItems.length > 0 && 
          typeAheadState.selectedItems.map((item,itemCtr) => 
              <div className="selected-item" id="inLoop" key={`attendee${itemCtr}${item[typeAheadState.itemKey]}`}>{ selectedItemText(item) } <button className="btn btn-icon btn-close btn-slim" onClick={(e) => remove(e,item)}>&nbsp;</button></div>
            )
        }
        <TypeAheadSearch
          htmlId={`${htmlId}Search`}
          selectedItemText = {selectedItemText}
          resultItemText = {resultItemText}
          addItemFunction={addItemFunction}
        />
       
      </div>
    </section>
  )
}

export default TypeAhead