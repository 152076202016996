import React, { useState,useEffect,Fragment } from 'react'
import { Link } from 'react-router-dom'
import sessionManager from '../../lib/session'
import {get} from '../../lib/call-api'
import dateManager from '@ministry-squads-common/date-manager'
import EventSelector from './EventSelector'

const SelectFromRecentWeeks = ({handleChange, weeksInPast = 1, weeksInFuture = 1, unique, attribute = 'agenda'}) => {
  const [allEvents,setAllEvents] = useState()
  const session = sessionManager.getSession()

  useEffect(() => {
    const startDate = dateManager.subtract(session.currentWeek.startDate,weeksInPast * 7,'DAYS') 
    const endDate = dateManager.add(session.currentWeek.endDate,weeksInFuture * 7,'DAYS')

    const onGet = (response) => {
      setAllEvents(response)
    }
    
    const getApiPath = (attribNm) => {
      if(attribNm) {
        return `/api/calendar/attribute/${attribNm}`
      } else {
        return `/api/calendar/${session.orgData.orgId}`
      }
    }

    get(
      `${getApiPath(attribute)}?startDate=${dateManager.singleDate(startDate,'URL_PARAMETER')}&endDate=${dateManager.singleDate(endDate,'URL_PARAMETER')}`,
      onGet,null,null, 'get event list')

  },[session.currentWeek.endDate, session.currentWeek.startDate, session.orgData.orgId,weeksInPast,weeksInFuture])

  return (
    allEvents && 
    <EventSelector allEvents={allEvents} unique={unique} handleChange={handleChange} />
  )
}

export default SelectFromRecentWeeks