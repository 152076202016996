import React, { useState, useEffect } from 'react'
import SubmitButton from '../../components/SubmitButton'
import Alert from '../../components/Alert'
import ManageSongAssignment from './ManageSongAssignment'
import SelectFromRecentWeeks from '../../events/components/SelectFromRecentWeeks'
import {post} from '../../lib/call-api'
import {outputSeperated,intersection} from '../../lib/index'
import {emptyAssignmentObject} from '../common'
import useIsMountedRef from '../../hooks/useIsMountedRef'

const AssignSongToEvent = ({songList, onSave, unique}) => {
  const isMounted = useIsMountedRef()

  const [songs,setSongs] = useState((Array.isArray(songList)) ? songList : [songList])
  const [selectedEvents,setSelectedEvents] = useState([])
  const [errorMessage, setErrorMessage] = useState()
  const [successMessage,setSuccessMessage] = useState()

  const [,setSongsWithOutErrors] = useState(null)

  const [songsWithErrors,setSongsWithErrors] = useState(null)

  // initialize song assignments to have an object for each song. so, if the user is assigning multiple songs
  // and they only provide selections for a sub-set, we will catch the fact that the remaining songs dont have a lead
  // not key selected. otherwise songs without a lead and key will not be in the song assignment array at all
  const [songsAssigned,setSongsAssigned] = useState(songs.map(song => emptyAssignmentObject(song.songId))) 

  //
  const onPost = (response) => {
    resetForm()
    processResponse(response)
  }
  
  useEffect(() => {
    if(isMounted.current === true) {
      setSongs(songList)
      setSongsAssigned(() => songList.map(song => emptyAssignmentObject(song.songId)))
      setSongsWithOutErrors(null)
      setSongsWithErrors(null)
    }
    return () => isMounted.current = false

  }, [unique])

  const processResponse = (response) => {
    
    const songIdsWithoutErrors = response.successful

    const localSongsWithOutErrors = songs.filter(e => {
      return songIdsWithoutErrors.some(songWithoutError => songWithoutError.songId === e.songId); 
    })
    
    // if all songs were saved successfully
    // call the "onSave" function that was passed in. 
    // on SongList for example, it closes the panel and shows the success message at the top of the page
    if(songIdsWithoutErrors.length > 0 && songIdsWithoutErrors.length === songs.length && onSave) {
      onSave({
        "songs": localSongsWithOutErrors,
        "events": response.additionalData.events
      },`Successfully added ${outputSeperated(localSongsWithOutErrors.map(song => song.songTitle)) } to events`)
    } else if(songIdsWithoutErrors.length > 0) {

      setSongsWithOutErrors(localSongsWithOutErrors)

      // if we land here, we had a mixed result. some song assignments were saved, some weren't
      setSuccessMessage(`Successfully saved ${outputSeperated(localSongsWithOutErrors.map(song => song.songTitle)) }`)
    } 

    const localSongIdsWithErrors = response.unsuccessful
    if(localSongIdsWithErrors.length > 0) {
      const localSongsWithErrors = songs.filter(e => {
        return localSongIdsWithErrors.some(songWithError => songWithError.songId === e.songId); 
      })

      const errors = localSongIdsWithErrors.map(songWithError => {
        const songData = songs.filter(song => song.songId === songWithError.songId)[0]
        return `Could not save ${songData.songTitle}: ${songWithError.error.userMessage}`
      })

      setSongsWithErrors(response.unsuccessful)

      // remove any songs that did not have errors, so the user can select a different
      // event for the songs we couldn't save 
      setSongs(localSongsWithErrors)

      // update songsAssigned, so we only submit the songs that failed last time
      setSongsAssigned(intersection(songsAssigned, localSongsWithErrors, "songId", "songId"))

      setErrorMessage(errors)

    } 
  }

  const validateForm = () => {
    let message = ''
    
    if(selectedEvents.length === 0) {
      message = 'Please select at least one event.'
    }
    
    setErrorMessage(message)
    return message === ''
  }

  const resetForm = () => {
    setErrorMessage()
    setSuccessMessage()
    setSongsWithOutErrors(null)
    setSongsWithErrors(null)
  }

  const onPostError = (error) => {
    if(Array.isArray(error) === true) {
      processResponse(error)
    } else {
      
     
      setSongsWithErrors(error.unsuccessful)

      if(error.message) setErrorMessage(error.message)
    }
    
  }
  
  const handleSubmit = async event => {
    event.preventDefault()
    const postBody = {
      "eventData": selectedEvents.map(selected => {
        return {
          "scheduledEventId": selected.scheduledEventId,
          "isScheduled": selected.isScheduled
        }
      }),
      "songAssignments": songsAssigned      
    }
    post("/api/song/bulk-assign-member-date",onPost,onPostError,validateForm,postBody)
    
  }
  
  const findSong = (existingAssignments,songId) => {
    const matches = existingAssignments.filter(assignment => assignment.songId === songId)
    if(matches.length === 1) {
      return matches[0]
    } else {
      return null
    }
  }

 // could be dealing with an array of assignments in the parent component (AssignSongToEvent)
  // or just a single assignment (ManageSongsByEvent)
  const addAssignment = (current,dataToAdd) => {
    if(Array.isArray(current)) {
      return [
        ...current,
        dataToAdd
      ]
    } else {
      return dataToAdd
    }
  }

  const updateAssignment = (current,updatedData,songId) => {
    if(Array.isArray(current)) {
      return current.map(currentSong => {
        if(currentSong.songId === songId) {
          return updatedData
        } else { 
          return currentSong
        }
      })
    } else {
      return updatedData
    }
  }

  const changeSongAssignment = (updatedData) => {
    const currentSongData = findSong(songsAssigned,updatedData.songId)
    
    if(currentSongData === null) {
      // current song is not in the assignments array passed in to the component, add it
      setSongsAssigned((current) => addAssignment(current,updatedData))
    } else {
      setSongsAssigned((current) => updateAssignment(current,updatedData,updatedData.songId))
    }
  }

  const getSongAssignment = (assignmentsData,songId) => {
    const assignment = assignmentsData.filter(songAssigned => songAssigned.songId === songId)
    if(assignment.length > 0) {
      return assignment[0]
    } else {
      return null
    }
  }


  return (
    <form onSubmit={handleSubmit}>
      {
        errorMessage && errorMessage !== '' && (!successMessage || ( successMessage && successMessage === '')) && 
        <Alert type='error' message={errorMessage} />
      }
      
      {
        errorMessage && errorMessage !== '' && successMessage && successMessage !== '' && 
        
        <Alert type='warning' message='Mixed results'>
          <Alert context='inline' type='success' message={successMessage} />
          <Alert context='inline' type='error' message={errorMessage} />
        </Alert>
      }

      <section className="form-group">
        <p>Select an event to assign the songs below to.</p>
        <SelectFromRecentWeeks handleChange={setSelectedEvents} unique={unique} weeksInFuture={3} attribute="songs"/>
      </section>
      
      <section className="form-group">
        <div className="group-container with-title" id="songAssignments">
          <header className="grouped-row">
              <div>Song</div>
              <div>Lead by</div>
              <div>Key</div>            
          </header>
          <>
          {
            songs.map(song =>
              <ManageSongAssignment 
                key={`manage-song-assignment-${song.songId}`} 
                songData={song} 
                assignmentData={getSongAssignment(songsAssigned,song.songId)} 
                onDataChange={changeSongAssignment} 
                songErrorMessages={(songsWithErrors !== null) ? songsWithErrors.filter(songWithError => songWithError.songId === song.songId) : []}
              />
             
            )
          } 
          </>
        </div>
      </section>
      <section className="button-container"><SubmitButton /></section>
    </form>

  )

}

export default AssignSongToEvent