import React, { useState } from "react"
import FormField from "../../components/formFields/FormField"
import UrlField from '../../components/formFields/UrlField'
import Alert from '../../components/Alert'

import { save,remove } from '../../services/url.service.js'

import OrderableSection from '../../components/OrderableSection'
import { useOrderableSectionState, OrderableSectionProvider  } from '../../contexts/OrderableSectionContext'

const SaveUrls = (props) => {
  return (
   <OrderableSectionProvider> 
     <DisplayPage {...props} />
  </OrderableSectionProvider >
 )
}

const DisplayPage = ({ urlType, existingUrls = [], relatedToId, relatedToType, onSuccess, introCopy, noItemsText }) => {

  const [orderableSectionState, ] = useOrderableSectionState()

  const removeItem = (idToRemove,toId,toType) => {
    
    remove(idToRemove,toId,toType).then(() => {
      onSuccess()
    }).catch(e => {
      console.error('error removing item', e)
    })
  }


  return (
    <div className="group with-title" id="urlManager"> 
      {
        introCopy && introCopy.length > 0 && 
        introCopy.map((copy,copyCtr) => 
          <p key={`introcopy-${copyCtr}`} className="after-heading">{ copy }</p>
        )
        
      }
      
      {
        existingUrls && existingUrls.length === 0 && noItemsText &&
        <p className="no-results">{noItemsText}</p>
      }

      {
        existingUrls && existingUrls.length > 0 &&
        <header className="grouped-row">
          <div>URL details</div>
          <div>Actions</div>
        </header> 
      }

      <OrderableSection 
        editMode="popup"
        keyName='urlId'
        sectionList={existingUrls}
        emptySectionData={{
          "urlId": null,
          "urlPath": null,
          "urlLabel": null,
          "urlType": urlType,
          "isNew": true,
          "relatedToId": relatedToId,
          "relatedToType": relatedToType
        }}
        renderSection={(url,urlCtr) => <DisplayUrlDetail url={url} />}
        editSection={() => <EditUrlDetail onSuccess={onSuccess} />}
        removeFunction={(idToRemove) => removeItem(idToRemove,relatedToId,relatedToType)}
        addSectionText="Add a link" 
        popupHeadingText={(orderableSectionState.mode === "EDIT") ? 'Edit a link ' : 'Add a link'}
      />  
    </div>
  )
}

const DisplayUrlDetail = ({url}) => {
  return (
    <>
      <div>
        <a href={url.urlPath} target="_new">
          {url.urlTitle}
          <i className="fas fa-external-link-alt"></i>
        </a>
      </div>
    </>
  )
}

const EditUrlDetail = ({onSuccess}) => {
  const [ state,dispatch ] = useOrderableSectionState()

  const onSectionCancel = () => {
    dispatch({'type': 'cancelUpdate', 'payload': null})
  }

  const urlData = state.sectionBeingEdited
  const [saveErrorMessage, setSaveErrorMessage] = useState()

  const [newUrlData,setNewUrlData] = useState(urlData)

  const changeUrlPath = (newTitle) => {
    setNewUrlData((previous) =>  {
      return {
        ...previous,
        "urlPath": newTitle
      }
    })
  }

  const changeUrlTitle = (newTitle) => {
    setNewUrlData((previous) =>  {
      return {
        ...previous,
        "urlTitle": newTitle
      }
    })
  }

  const onSectionSave = (data) => {

    save(data,'song').then(saveResult => {
      dispatch({"type": "setReadMode", "payload": null})
      dispatch({"type": "updateSection", "payload": data})
      dispatch({"type": "clearSectionError", "payload": data.urlId})
      onSuccess()
    }).catch(e => {
      setSaveErrorMessage(e)
    })
  }

  return (
    <>
      <Alert type="error" message={saveErrorMessage} />
      <div>
        <div className="form-control-group">
          <label>URL:</label>
          <UrlField 
            id={`urlPath${urlData.urlId}`}
            passedValue={urlData.urlPath}
            placeholder="URL path"
            setValue={(newUrlPath) => { changeUrlPath(newUrlPath)}}
            isRequired={true}
          />
        </div>

        <div className="form-control-group">
          <label>Label:</label>
          <FormField 
            id={`urlLabel${urlData.urlId}`}
            value={urlData.urlTitle}
            placeholder=""
            handleChange={(newUrlTitle) => { changeUrlTitle(newUrlTitle)}}
            isRequired={true}
          />
        </div>
      </div>
      <div className="button-container">
        <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); onSectionSave(newUrlData)}}>Save URL</button>
        <button className="btn btn-link" onClick={(e) => {e.preventDefault(); onSectionCancel()}}>Cancel</button>
      </div>
    </>
  )

}


export default SaveUrls