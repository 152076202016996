import React, { useState, useEffect } from "react"
import FormField from './FormField'

const UrlField = ({passedValue,setValue,labelText,isRequired}) => {
  const DEFAULT_VALUE = ''
  const [url, setUrl] = useState(passedValue || DEFAULT_VALUE)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
      setUrl((passedValue) ? passedValue : DEFAULT_VALUE)        
  }, [passedValue])

  const urlIsValid = () => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i') // fragment locator

    const isValid = urlPattern.test(url) === true
    

    if(url === '' && isRequired === true) {
          setErrorMessage('Please provide a valid url.')
          setValue(null)
          return false
      } else if(url !== '' && isValid === false) {
          setErrorMessage('The url you entered is not formatted correctly.')
          setValue(null)
          return false
      }
      setErrorMessage('')
      setValue(url)
      return true
  }

  return (
    <div className="form-group">
      <label htmlFor="example-url">{labelText}</label>
      <FormField
        type="text"
        id="example-url"
        errorMessage={errorMessage}
        value={url}
        handleChange={setUrl}
        validateFunction={urlIsValid}
      />
    </div>
  )
}

export default UrlField