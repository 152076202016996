import React, { useState } from "react"
import FormField from "../../components/formFields/FormField"
import SongKeys from "./SongKeys"
import Alert from '../../components/Alert'
import sessionManager from '../../lib/session'
import SubmitButton from '../../components/SubmitButton'
import {post} from '../../lib/call-api'


const SaveSong = ({ onSuccess, songData, exampleUrl }) => {
  const [errorMessage, setErrorMessage] = useState("")
  const songId = (songData) ? songData.songId : null
  const [songTitle, setSongTitle] = useState((songData) ? songData.songTitle : '')
  const [ccliNumber, setCcliNumber] = useState((songData) ? songData.ccliNumber : '')
  const [recordingArtist, setRecordingArtist] = useState((songData) ? songData.recordingArtist : '')
  const [recordedKey, setRecordedKey] = useState((songData) ? songData.recordedKey : '')
  const [isLoading, setIsLoading] = useState(false)

  const [songTitleErrorMessage,setSongTitleErrorMessage] = useState('')
  const [ccliNumberErrorMessage,setCcliNumberErrorMessage] = useState('')

  const session = sessionManager.getSession()
 
  const onPost = (response) => {
    setSongTitle('')
    setCcliNumber('')
    setRecordedKey('')
    setRecordingArtist('')
    onSuccess(
      response.song,
      `'${response.song.songTitle}' saved.`
    )
    setIsLoading(false)

  }

  const validateForm = () => {
    // I have to call these here to have all the error messages show up at once
    songTitleIsValid() 
    return (songTitleIsValid() === true)

  }

  const handleSubmit = async event => {
    event.preventDefault()
    const postBody = {
      "orgId": session.orgData.orgId,
      "song": {
        "songId": songId,
        "songTitle": songTitle,
        "ccliNumber": ccliNumber,
        "recordingArtist": recordingArtist,
        "recordedKey": recordedKey
      }
    }
    post("/api/song/save",onPost,setErrorMessage,validateForm,postBody)
  }

  const songTitleIsValid = () => {
    const isValid = (songTitle !== '')
    setSongTitleErrorMessage((isValid === false) ? 'Please enter a song title.' : '')
    return isValid
  }

  const ccliNumberIsValid = () => {
    
    if(ccliNumber !== '') {
      const numberPattern = /^\d+$/
      const isValid = numberPattern.test(ccliNumber)
      setCcliNumberErrorMessage((isValid === false) ? 'Please enter a numeric CCLI number.' : '')
      return isValid
    } else {
      return true
    }
    

  }

  return (
      <form onSubmit={handleSubmit}>
        <Alert type='error' message={errorMessage} />
        <div className="form-group">
          <label htmlFor="song-title">Song title</label>
          <FormField
            type="text"
            id="song-title"
            errorMessage={songTitleErrorMessage}
            value={songTitle}
            handleChange={setSongTitle}
            validateFunction={songTitleIsValid}
          />
        </div>
        <div className="form-group">
          <label htmlFor="ccli-number">CCLI Number</label>
          <FormField
            type="text"
            id="ccli-number"
            errorMessage={ccliNumberErrorMessage}
            value={ccliNumber}
            handleChange={setCcliNumber}
            validateFunction={ccliNumberIsValid}
          />
        </div>
        <div className="form-group">
          <label htmlFor="recording-artist">Recording artist</label>
          <FormField
            type="text"
            id="recording-artist"
            value={recordingArtist}
            handleChange={setRecordingArtist}
          />
        </div>
        <div className="form-group">
          <label htmlFor="recorded-key">Recorded key</label>
          <SongKeys
            selectedSongKey={recordedKey}
            setFunction={setRecordedKey}
          />
        </div>
        
        <div className="buttonContainer">
          <SubmitButton isLoading={isLoading} />  
        </div>
      </form>

  )
}

export default SaveSong