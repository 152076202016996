import React, {useState} from 'react'
import { save } from '../../services/member.service.js'
import Alert from '../../components/Alert'
import SetEmail from '../../components/formFields/SetEmail'
import SetName from '../../components/formFields/SetName'

const AddPerson = ({searchText,onSave,onCancel,saveButtonText="Save"}) => {
  const [firstName,setFirstName] = useState(null)
  const [lastName,setLastName] = useState(null)
  const [emailAddress,setEmailAddress] = useState(null)
  const [errorText,setErrorText] = useState('')
  const [successText,setSuccessText] = useState('')

  const handleAddPerson = (browserEvent) => {

    browserEvent.preventDefault()

    const memberData = {
      "firstName": firstName,
      "lastName": lastName,
      "email": emailAddress
    }
    save(memberData).then(savedData => {

      if(onSave !== null) {
        onSave(savedData)
      } else {
        setSuccessText('Saved person.')
      }
    }).catch(e => {
      setErrorText(e.message)
    })    
  }

  const cancel = (browserEvent) => {
    browserEvent.preventDefault()
    onCancel()
  }

  return (
    <section>
      <Alert type="error" message={errorText} />
      <Alert type="success" message={successText} />
      <section>
        <SetName values={[firstName,lastName]} setValue={[setFirstName,setLastName]} />
        <SetEmail passedValue={emailAddress} setValue={setEmailAddress} />
      </section>
      
      <div className="button-container">
        <div className="row">
          <div className="col-md-3 offset-md-3 col-xs-12">
            <button className="btn btn-primary" onClick={(e) => handleAddPerson(e)}>{saveButtonText}</button>
          </div>
          <div className="col-md-3 col-xs-12">
            <button className="btn btn-link" onClick={(e) => cancel(e)}>Cancel</button>
            </div>
          </div>
      </div>
    </section>
  )
}

export default AddPerson