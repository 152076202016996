import React, { useState } from "react"
import ForgotPasswordGetCode from './components/ForgotPasswordGetCode'
import ForgotPasswordVerifyAndSet from './components/ForgotPasswordVerifyAndSet'


const ForgotPassword = () => {
  
  const [email, setEmail] = useState('')
  const [step, setStep] = useState(1)
  
  const setEmailInParent = (passedEmail) => {
     setEmail(passedEmail)
  }
  const nextStep = () => {
    setStep(step + 1)
  }

  const previousStep = () => {
    setStep(step - 1)
  }
  
  const values = { email }

    switch (step) {
        
        case 2: 
            return(
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <h1>Check your email</h1>
                        <p className="intro">If you have an account, you should have received an email at <b><em>{email}</em></b> with a verification code.</p>
                        
                        <div className="buttonContainer">
                          <button onClick={nextStep} className="btn btn-primary">
                          Enter the code</button>
                      </div>

                       

                        <div className="alert alert-warning mt-5" role="alert">
                          <h5>Didn't get an email?</h5>
                          <ul>
                              <li>You may have used a different email address when registering.</li>
                              <li>You may have mistyped your email address. Double check the address above</li>
                              <li>It may be taking longer than expected to receive the email.</li>
                          </ul>
                          <button onClick={previousStep} className="btn btn-link">Try sending the code again</button>
                      </div>
                    </div>
                </div>
            )
        case 3:
            return(
                <ForgotPasswordVerifyAndSet 
                    nextStep={nextStep}
                    values={values}
                    context="PASSWORD_RESET" />
            )
        case 4:
            return (
                <div className="row aft3r-">
                    <div className="col-md-6 offset-md-3">
                    <h1>You've successfully reset your password.</h1>
                    
                    <p className="intro"><a href="/login">Login now</a></p>
                    </div>
                </div>
            )
          default :
            return (
                <ForgotPasswordGetCode 
                  nextStep={nextStep}
                  setEmailInParent={setEmailInParent}
                  values={values} />
            )  
    }
}

export default ForgotPassword