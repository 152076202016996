import React, {useState } from "react"
import sessionManager from '../../lib/session'
import Alert from '../../components/Alert'
import FormField from '../../components/formFields/FormField'
import SubmitButton from '../../components/SubmitButton'
import {post,put} from '../../lib/call-api'

const SaveTeam = ({onSuccess, data}) => {
  const session = sessionManager.getSession()
  const [errorMessage, setErrorMessage] = useState('')

  const originalTeamName = (data) ? data.teamName : ''
  const [teamName, setTeamName] = useState(originalTeamName)
  const [isLoading, setIsLoading] = useState(false)
 
  const validateForm = () => {
    if(teamName === '') {
      setErrorMessage('Please provide a team name.')
      return false
    }
    return true
  }

  const reset = () => {
    setTeamName('')
    setErrorMessage()
    setIsLoading(false) 
  }

  const onSave = (resp) => {
    if (typeof onSuccess !== "undefined") {
      onSuccess(
        resp,
        `${resp.teamName} has been saved.`
      )
    }
    reset()
    return resp
  }
  
  const handleSubmit = async event => {
    event.preventDefault()
    const postBody = {
      "parentEntityId": session.orgData.orgId,
      "entityName": teamName,
      "entityId": (data) ? data.teamId : null
    }
     // if we have team already, we are updating an existing team, so we should use an http put
     if(data) {
      put("/api/team",onSave,setErrorMessage,validateForm,postBody)
    } else {
      post("/api/team",onSave,setErrorMessage,validateForm,postBody)
    }

    
  }

  return (
    <form onSubmit={handleSubmit}>
        {
        !data && 
          <h2>Add a Team</h2>
        }
      <Alert type='error' message={errorMessage} />

      <section>
        <FormField 
        placeholder="Enter team name"
        value={teamName} handleChange={setTeamName} />
      </section>

      <section className="button-container">
        <SubmitButton isLoading={isLoading} />  
      </section>
    </form>
  )
}


export default SaveTeam