import dateManager from '@ministry-squads-common/date-manager'

/** sermonDates
* @param {string}  startDaterepresents the date of the first event that the first sermon in the list of sermons passedin
* @param {array}  sermons a list of sermon objects
* @returns {array} a list of dates that each sermon should be associated to
*/
  
export const sermonDates = (startDate,sermons) => {

  const asDate = new Date(startDate)
 
  if(sermons && startDate && dateManager.isDate(asDate) === true) {
    
    const numberOfWeeks = sermons.length
    const lclEventDates = []
    for(let i=0;i<numberOfWeeks;i++) {
      lclEventDates.push(dateManager.add(asDate,7*i,"DAYS"))
    }
    return lclEventDates
    
  } else {
    return []
  }

}