import React, { useState, useRef, useEffect } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import '../css/DateField.css'
import '../css/Icons.scss'

const DateField = ({currentValue,setValue,label,errorTextLabel,minDate,maxDate,errorMessage = null, inline = false}) => {
    const [localDate, setLocalDate] = useState(currentValue)
    const [dateChanged, setDateChanged] = useState(false)
    //const [errorMessage, setErrorMessage] = useState(forceErrorMessage)
    const fieldReferenceText = errorTextLabel ? errorTextLabel : label

    const handleChange = (val) => {
        setDateChanged(true)
        setLocalDate(val)  
        setValue(val)
    }

    /*
    const dateIsValid = () => {

        const minAsDate = dateManager.asDate(minDate)
        const maxAsDate = dateManager.asDate(maxDate)
        const localAsDate = dateManager.asDate(localDate)

        if(!isDefined(localDate)) {
            setErrorMessage(`${fieldReferenceText} is required`)
            setValue('')
            return false    
        } else if(dateManager.isDate(localAsDate) === false) {
            setErrorMessage(`${fieldReferenceText} is not a valid date`)
            setValue('')
            return false    
        } else if(isDefined(minDate) && dateManager.diff(minAsDate,localAsDate,'DAYS') > 0) {
            setErrorMessage(`${fieldReferenceText} must be after ${dateManager.singleDate(minAsDate)}`) 
            setValue('')
            return false    
        } else if(isDefined(maxDate) && dateManager.diff(maxAsDate,localAsDate,'DAYS') < 0) {
            setErrorMessage(`${fieldReferenceText} must be before ${dateManager.singleDate(maxAsDate)}`) 
            setValue('')
            return false    
        }
        setValue(localDate)
        setErrorMessage('')
        return true
    }
*/
    useEffect(() => {
        setDateChanged(false)
        //setErrorMessage('')
        setLocalDate(currentValue)
    }, [currentValue])
    
    /*
     useEffect(() => {
        if(dateChanged === true) {
            dateIsValid()
        }
    }, [localDate,errorMessage,dateChanged])
*/

    const isDefined = (checkVal) => {
        return typeof checkVal !== "undefined" && checkVal !== null && checkVal !== ''
    }
    
    const idBasedOnLabel = (label) => {
        if(typeof(label) !== 'undefined' && label !== '' ) {
            return label.replace(/[\W_]+/g,"-")
        } else {
            return 'datefield'
        }
        
    }

    let _dateCalendar = useRef(null)
    const openDatepicker = () => _dateCalendar.setOpen(true);

    const containerCssClass = () => {
        return (inline === true) ? 'inline' : ''
    }

    const fieldCssClass = () => {
        return (haveErrorMessage() === true) ? `consolidated-field field-with-error` : `consolidated-field`
    }

    const haveErrorMessage = () => {
        return (errorMessage !== null && errorMessage !== ''  && typeof(errorMessage) !== 'undefined')
    }
    return (

        <div className={`dateInput ${containerCssClass()}`}>
            {
                typeof(label) !== 'undefined' && label !== '' && 
                <label htmlFor={idBasedOnLabel(label)}>{label}</label>
            }   

            <div className={fieldCssClass()}>
            <DatePicker
                selected={localDate}
                onChange={handleChange}
                selectsStart
                dateFormat='MM/dd/yyyy'
                ref={(c) => _dateCalendar = c}
                minDate={minDate}
                id={idBasedOnLabel(label)}
                className= 'form-control'  
            />
                <i className="fa fa-calendar icon-clickable" onClick={openDatepicker} />
            </div>
            {
                haveErrorMessage() === true && 
                <div className="field-error">
                    <p>{errorMessage}</p>
                </div>
            }
        </div>
    )
    
}

export default DateField