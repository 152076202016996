import React, {useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import DropDownField from '../components/formFields/DropDownField'
import { get} from '../lib/call-api'
import GetAssignmentData from './components/GetAssignmentData'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import SecondaryContent from '../components/SecondaryContent'
import ManageAssignments from './components/ManageAssignments'
import {useAssignmentsState,AssignmentsProvider } from './context'
import DataRange from '../components/formFields/DateRange'
import sessionManager from '../lib/session'
import dateManager from '@ministry-squads-common/date-manager'

import './styles.scss'


const Manage = (props) => { 
  return (
    <>
      <AssignmentsProvider initialMode="edit">
        <RenderWithinProvider props={props} />
      </AssignmentsProvider>
    </>
  )
}

const RenderWithinProvider = ({props}) => {
  const [teamId,setTeamId] = useState(props.match.params.teamId)
  const [state, dispatch] = useAssignmentsState()

  const [showSettings,setShowSettings] = useState(false)
  const [, modalDispatch] = useSecondaryContentState()

  const toggleSlider = () => {
    modalDispatch({ type: 'toggleIsOpen', payload: null })
  }

  const setRange = (range,team) => {
    setShowAssignments(true)
    dispatch({ "type": 'setDateRange', payload: range })  
    //dispatch({ "type": 'setTeam', payload: team })  
    setTeamId(team.teamId) // trigger GetAssignmentData?
    //getSchedule(team.teamId)
  }
  const checkShowAssignments = (selectedTeamId) => {
    setShowAssignments(state.team.teamId && selectedTeamId === state.team.teamId)
  }

  const [showAssignments,setShowAssignments] = useState(false)

  return (
    <section className="page">
     
      <header>
        <div className="breadcrumbs">
          <Link to="/schedule">Schedule</Link>
        </div>
        <h1>Manage Assignments</h1>
        <p className="intro">Add or remove team members from events for a range of events below.</p>
        {
        state.uiConfig.mode === "edit" && 
        <div className="feature-manager">
              <button className="btn btn-icon btn-settings" onClick={toggleSlider}>
                Settings
              </button>
          </div>  
          }
          
      </header>
      {
        state.uiConfig.mode === "edit" && 
      <div className="card">
          <DatesAndTeam onApply={(range,teamId) => setRange(range,teamId)} teamId={teamId} check={(selectedTeamId) => checkShowAssignments(selectedTeamId)} />
      </div>
      }

      <GetAssignmentData initialTeam={{"teamId": teamId}} />
      {
        showAssignments === true &&
        <ManageAssignments displayMode="as-table" initialMode="edit" />
      }

      {
        showAssignments === false && teamId === null && 
        <p className="no-results">Select a team and date range, then click Apply</p>
      }

      <SecondaryContent onClose={setShowSettings} show={showSettings} heading="Settings">
        <SettingsPanel handleSave={() => toggleSlider()}/>
      </SecondaryContent>
      
    </section>
  )
}

const DatesAndTeam = ({onApply,teamId,check}) => {

  const [lclStartDate,setLclDate] = useState()
  const [lclEndDate,setLclEndDate] = useState()
  const [state, ] = useAssignmentsState()
  const [lclTeamId,setLclTeamId] = useState(teamId)
  const sessionData = sessionManager.getSession()
  const isValid = () => (lclStartDate  && lclEndDate) ? true : false

  const saveChanges = () => {
    const teamSelected = sessionData.userData.teams.filter(team => team.teamId === lclTeamId)
    if(isValid() === true && teamSelected.length === 1) {
      onApply({
        "startDate": lclStartDate,
        "endDate": lclEndDate
      },teamSelected[0])
    } 
  }

  return (state.uiConfig.mode === "edit" &&
    <div className="filters" id="assignmentFilters">
          <div>
          <div className="form-group">
            <label for="teamDropDown">Team</label>
            <DropDownField 
              id="teamDropDown"
              showDefaultOption={false}
              selectedValue={lclTeamId}
              handleChange={(val) => {setLclTeamId(val);check(val)}}
              values={sessionData.userData.teams}
              idField="teamId"
              labelField="teamName"
            />
            </div>
          </div>
        <div>
          <div className="form-group">
          <DataRange
            startDate={lclStartDate}
            setStartDate={setLclDate}
            lclEndDate={lclEndDate}
            setEndDate={setLclEndDate}
            allowPast={true}
          />
        </div>
        </div>
        <div className="vertical-center">
          <button className="btn btn-primary" onClick={() => saveChanges()}>Apply</button>
        </div>
    </div>
  )
}

const SettingsPanel = ({handleSave}) => {
  const [state, dispatch] = useAssignmentsState()
  const [localValue,setLocalValue] = useState()

  const setValue = (e) => {
    
    setLocalValue(e.target.value === 'true')
  }
  const save = (e) => {
    e.preventDefault()
    console.log(typeof localValue)
    dispatch({"type": "setGroupEvents", "payload": localValue})
    handleSave(e)
  } 

  useEffect(() => {
    if(state.uiConfig.mode === "edit" && state.uiConfig.groupEvents !== null && typeof(state.uiConfig.groupEvents) !== "undefined") setLocalValue(state.uiConfig.groupEvents)
  },[state.uiConfig])


  return (
  
    <div className="settings-panel">
        <h3>How do you want to make assignments?</h3>      
        <div className="form-inline form-group">
          <input type="radio" name="groupEventsBy" id="groupEventsByWeek" value="true" onChange={setValue} checked={localValue===true}/> 
          <label className="label-right" htmlFor='groupEventsByWeek'>
            <strong>By week</strong> - Same assignments for each type of event in a given week
          </label>
        </div>

        <div className="form-inline form-group">
          <input type="radio" name="groupEventsBy" id="groupEventsByEvent" value="false" onChange={setValue} checked={localValue===false}/> 
          <label className="label-right" htmlFor='groupEventsByEvent'>
            <strong>By individual event</strong> - Have unique assignments for each individual event
          </label>
        </div>

        <div className="button-container">
          <button className="btn btn-primary" onClick={(e) => { save(e) }}>Apply Settings</button>
        </div>
        
      
    </div>
  ) 
}

export default Manage