import React from 'react'
import { Link } from 'react-router-dom'
import { getDisplayDate,getEventDisplayName,getEventDisplayTimes } from '../common.js'

const ShowHeader = ({eventData, onEventClick}) => {
  const displayDate = getDisplayDate(eventData)
  const eventName = getEventDisplayName(eventData[0])
  const eventTimes = getEventDisplayTimes(eventData)
  return (
      eventData && eventData.length > 0 &&
      <>
      {
          onEventClick && 
          <header>
              <Link to={`/schedule/event/detail/${eventData[0].scheduledEventId}`}>
                  <h1>{displayDate}</h1>
                  <p className="small">{eventTimes}</p>
                    <p className="small">{eventName}</p>
              </Link>
          </header>
      }
       {
          !onEventClick && 
          <header>
              <h1>{displayDate}</h1>
              <p className="small">{eventTimes}</p>
                <p className="small">{eventName}</p>
          </header>
      }
      </>
      
  )
}

export default ShowHeader