
import React, { useState } from 'react'

import dateManager from '@ministry-squads-common/date-manager'
import { Link } from 'react-router-dom'

import { getInitialObject } from '../../services/event.service'
import { save } from '../../services/eventSeries.service'

import Alert from '../../components/Alert'
import SubmitButton from '../../components/SubmitButton'
import EventBasics from './EventBasics'
import EventRepeatFields from './EventRepeatFields'
import EventRepeatSummary from './EventRepeatSummary'
import EventSeriesSummary from './EventSeriesSummary'
import EventTeamsAttendees from './EventTeamsAttendees'

import { useManageEventState } from '../../contexts/ManageEvent.Context'

import '../events.scss'

const AddEvent = ({ onSave = () => true }) => {

 const [eventState, eventDispatch] = useManageEventState()
 
 const ADD_TXT = 'add'
 const UPDATE_TXT = 'update'

 const mode = eventState.eventSeriesId === null ? ADD_TXT : UPDATE_TXT

  const [errorMessages, setErrorMessages] = useState(getInitialObject())
  const [saveResult, setSaveResult] = useState()
  const [saveResultHeading, setSaveResultHeading] = useState()
  const [saveResultMessage, setSaveResultMessage] = useState()
  const [saveResultType, setSaveResultType] = useState()

  
  const handleSubmit = (browserEvent) => {
    browserEvent.preventDefault()

    // the API was originally built using the ToggleList component, which creates an object that
    // keeps track of items that the user selected (assignToIds), and de-selected (removeFromIds)
    // right now we only support the add scenario, so just hard coding below. we'll need to refctor
    // one we allow users to edit the teams assigned to an existing event.
    const selectedTeamActions = {
      "assignToIds": (eventState.selectedTeams) ? eventState.selectedTeams.map(team => team.teamId) : [],
      "removeFromIds": []
    }


    const getDataToSubmit = () => {
        // if the event repeats every week, automatically set the
        // day of week based on the start date
        const dayOfWeek = (eventState.repeatEvery === "week") ? dateManager.asDate(eventState.rangeStartDate).getDay() : null
        return {
          "eventSeriesId": eventState.eventSeriesId,
          "eventSeriesName": eventState.eventName,
          "eventTypeId": eventState.eventTypeId,
          "startTime": eventState.startTime,
          "endTime": eventState.endTime,
          "repeatEvery": eventState.repeatEvery,
          "rangeStartDate": eventState.rangeStartDate,
          "rangeEndDate": eventState.rangeEndDate,
          "dayOfWeek": dayOfWeek,
          "teamsToAssignActions": selectedTeamActions,
          "attendees": eventState.attendees,
          "rangeEndTime": eventState.rangeEndDate
        }
    }

    const dataToSubmit = getDataToSubmit()

    save(dataToSubmit).then(result => {
      setSaveResult(() => result)

        setSaveResultType('success')

        eventDispatch({"type": "reset"})


      onSave({
        "resultHeading": 'All set!',
        "resultMessage": 'Your recurring event has been saved',
        "resultType": 'success',
        "savedOutput": () => { return <EventSeriesSummary saveResult={result} linkToEvents={(mode === UPDATE_TXT) ? false : true } />}
      })

    }).catch(customErrorObject => {
      setErrorMessages(() => {
        return {
          ...customErrorObject,
          "generalPageError": "Please correct the error(s) below.",
        }
      })
      console.error('Error saving event', customErrorObject)
    })

  }

  return (
    <>
   
      {
        !saveResult && eventState &&
        <form onSubmit={(e) => { handleSubmit(e) }}>
          {
            errorMessages && errorMessages.generalPageError &&
            <Alert type="error" message={errorMessages.generalPageError} />
          }

          <div className="row">
            <div className="col">
              
              <div className="card">
              <section className="dbl-bottom">
                <EventBasics eventName={eventState.eventName} eventTypeId={eventState.eventTypeId} errorMessages={errorMessages} />
              </section>
             
              <section className="dbl-bottom">
                <h3>Date &amp; time</h3>
                <EventRepeatFields
                  repeatEveryValue={eventState.repeatEvery}
                  rangeEndDateValue={eventState.rangeEndDate}
                  minDate={eventState.endDate}
                  errorMessages={errorMessages}
                  allowRepeatEveryChange={mode === ADD_TXT}
                />
      
                <EventRepeatSummary 
                  eventData={eventState} 
                />
              </section>
              </div>


                <EventTeamsAttendees />

            </div>
          </div>
          <div className="button-container">
            <SubmitButton />
          </div>
        </form>
      }
    </>
  )
}

export default AddEvent