import React, { useEffect, useState } from 'react'
import dateManager from '@ministry-squads-common/date-manager'
import {getDateOnly} from '../common'

const EventsGrid = (props) => {
    const [uniqueDays,setUniqueDays] = useState()
    const [templateColumns,setTemplateColumns] = useState()
    const getUniqueDays = (listOfEvents) => {
        return [...new Set(listOfEvents.map(event => dateManager.singleDate(event.startDate,'TEXT_FULL')))]
    }
    useEffect(() => {
        const lclUniqueDays =getUniqueDays(props.events)
        setUniqueDays(lclUniqueDays)
        const allEvenColumns = {"gridTemplateColumns": `repeat(${lclUniqueDays.length}, 1fr)`}
        const subColumnPerEvent = lclUniqueDays.map(day => {
            return props.events.filter(event => getDateOnly(event.startDate) === getDateOnly(day)).length + "fr"
        })
        setTemplateColumns((typeof props.multi !== "undefined" && props.multi === true) ? {"gridTemplateColumns": subColumnPerEvent.join(' ')} : allEvenColumns)
    },[props.events,props.multi])
    
    return (
        <>
        {
            uniqueDays && templateColumns &&
            <div className="calendar" id="calendar-container">
                <div className="calendar-row header-row" id="header" style={templateColumns}>
                    {
                        uniqueDays.map((day,dayCtr) => 
                            <div key={`day-heading-${dayCtr}`}>{day}</div>
                        )
                    }
                </div>
            
                <div className="grid-lines" id="grid-lines" style={templateColumns}>
                    {
                        uniqueDays.map((event,eventCtr) => 
                            <div key={`gl-${eventCtr}`}></div>        
                        )
                    }
                </div>
                
                {
                    props.children
                }
               
            </div>
        }
        </>
    )
}

export default EventsGrid