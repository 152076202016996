import React, { useState, useEffect  } from 'react'
import FormField from './FormField'

const SetName = ({values,setValue}) => {
    const DEFAULT_VALUE = ''
    const [firstName, setFirstName] = useState(values[0] || DEFAULT_VALUE)
    const [lastName, setLastName] = useState(values[1] || DEFAULT_VALUE)

    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('')
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState('')

    const setParentFirstName = setValue[0]
    const setParentLastName = setValue[1]
    
    useEffect(() => {
        setFirstName(values[0])
        setLastName(values[1])
    }, [values])

    const firstNameIsValid = () => {
        if(firstName === '')  {
            setFirstNameErrorMessage('First name is required')
            setParentFirstName(DEFAULT_VALUE)
            return false
        } 
        setFirstNameErrorMessage('')
        setParentFirstName(firstName)
        return true
    }

    const lastNameIsValid = () => {
        if(lastName === '')  {
            setLastNameErrorMessage('Last name is required')
            setParentLastName(DEFAULT_VALUE)
            return false
        } 
        setLastNameErrorMessage('')
        setParentLastName(lastName)
        return true
    }

    return (
        <div className="form-group" id="name-fields">
            <label htmlFor="firstName">Name</label>
            <div className="input-group">
                <FormField type="text" id="firstName" autofocus="false" value={firstName} errorMessage={firstNameErrorMessage} handleChange={setFirstName} validateFunction={firstNameIsValid} />
            </div>
            <div className="input-group">
                <FormField type="text" id="lastName" autofocus="false" value={lastName} errorMessage={lastNameErrorMessage} handleChange={setLastName} validateFunction={lastNameIsValid} />
            </div>
        </div>
    )
}
export default SetName


