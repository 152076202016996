import React, { useEffect} from 'react'
import { useOrderableSectionState  } from '../contexts/OrderableSectionContext'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'

import AddSection from './AddSection'
import SectionAction from './SectionActions'
import SecondaryContent from '../components/SecondaryContent'
import { generateTempId } from '../lib/index'
import './css/OrderableSection.scss'
import Alert from './Alert'

const OrderableSection = ({sectionList, emptySectionData,renderSection,editSection,keyName, addSectionText, editMode = "inline",popupHeadingText,removeFunction = null}) => {
  
  const [ orderableSectionState,orderableSectionDispatch ] = useOrderableSectionState()
  const [flyoutState, flyoutDispatch] = useSecondaryContentState()

  const togglePopup = () => {
    flyoutDispatch({ type: 'togglePopup', payload: null })
  }

  const updateLocalSectionList = (updatedSectionListData) => {
    // this function is called by SectionAction when the user re-orders or deletes a section
    // right now the removeItem and moveItem functions in that component return a function
    // that returns the updated list. this is because in the other instances of the SectionAction
    // component, a setter function from useState is passed in.
    // once we convert the agenda and song arrangement features over to the OrderableSection component,
    // we should be able to update the dispatch below to just use updatedSectionListData instead of newList

    const newList = updatedSectionListData
    orderableSectionDispatch({'type': 'updateSectionList', 'payload': newList()})
  }

  const setSectionBeingEdited = (sectionData) => {
    orderableSectionDispatch({'type': 'setEditMode', 'payload': null})
    orderableSectionDispatch({'type': 'updateSectionBeingEdited', 'payload': sectionData})
    if(editMode === "popup") {
      flyoutDispatch({ type: 'togglePopup', payload: null })
    }
    
  }

  useEffect(() => {
    orderableSectionDispatch({'type': 'updateSectionList', 'payload': sectionList})
    orderableSectionDispatch({'type': 'updateKeyName', 'payload': keyName}) 
  },[sectionList])

useEffect(() => {
  // this should trigger when the user closes a popup, clicks a save button in the popup, or clicks cancel
  if(orderableSectionState.mode === "READ" && editMode === "popup" && flyoutState.popupIsOpen === true) {
    flyoutDispatch({ type: 'togglePopup', payload: null })
  }
},[orderableSectionState.mode,editMode,flyoutState.popupIsOpen,flyoutDispatch])


  const addNewSection = () => {
    const newId = generateTempId()
    
    // create an empty section
    let newEmptySection = emptySectionData
    newEmptySection[keyName] = newId

    orderableSectionDispatch({'type': 'setAddMode', 'payload': null})
    orderableSectionDispatch({'type': 'addNewSection', 'payload': newEmptySection})

    if(editMode === "popup") {
      flyoutDispatch({ type: 'togglePopup', payload: null })
    }
    
  }

  const handleItemCancel = () => {
    console.log('item cancelled')
    orderableSectionDispatch({'type': 'cancelUpdate', 'payload': null})
    //setSectionBeingEdited(null)
  }

  const isBeingEdited = (sectionData, key, beingEdited) => {
    return beingEdited !== null && beingEdited[key] === sectionData[key]
  }

  const getActiveCssClass = (sectionData, key, beingEdited) => {
    return (isBeingEdited(sectionData, key, beingEdited) === true )? 'editing' : ''
  }

  const getSectionError = (allErrors,key, keyToFind) => {
    const sectionError = allErrors.filter(sectionError => sectionError[key] === keyToFind)
    if(sectionError.length === 0) {
      return null 
    } else if(sectionError[0].errorMessage) {
      return sectionError[0].errorMessage
    } else {
      return "Unknown error trying to save."
    }
  }


return (
  <>
  
    {
      orderableSectionState.sectionBeingEdited && editMode === "popup" &&
      <SecondaryContent
        heading={popupHeadingText}
        onClose={handleItemCancel} 
        type='popup'>
           
              {
                editSection(orderableSectionState.sectionBeingEdited,(sectionData) => sectionData,() => handleItemCancel()) 
              }
          </SecondaryContent>
    }

    {
      orderableSectionState.sections && orderableSectionState.sections.length > 0 && 
      orderableSectionState.sections.map((section,sectionCtr) => 
      <div className="group with-title" key={`orderableSection${sectionCtr}`}>
        <div className={`grouped-row orderable-section ${getActiveCssClass(section,keyName,orderableSectionState.sectionBeingEdited)}`}>
            {
              (isBeingEdited(section,keyName,orderableSectionState.sectionBeingEdited) === true) && editMode === "inline" &&
                editSection(section,(sectionData) => sectionData,() => handleItemCancel()) 
            }

            {
              (isBeingEdited(section,keyName,orderableSectionState.sectionBeingEdited) === false) && 
              <>
                {
                  renderSection(section, sectionCtr)
                }

                <SectionAction 
                  currentIndex={sectionCtr} 
                  list={orderableSectionState.sections} 
                  keyName={keyName}
                  valueToFind={section[keyName]}
                  setFunction={updateLocalSectionList}
                  onEditClick={(e) => {e.preventDefault(); setSectionBeingEdited(section)}}
                  compact={true}
                  onRemoveClick={(() => (removeFunction !== null) ? removeFunction(section[keyName]) : true)}
                />
              </>
            }
        
      </div>
      {
          getSectionError(orderableSectionState.sectionErrors,keyName,section[keyName]) &&     
          <div className="span-all"><Alert type="error" message={getSectionError(orderableSectionState.sectionErrors,keyName,section[keyName])} context="inline" /></div>
        }
      </div>
      )
    }

    {
      orderableSectionState.mode === "READ" && 
      <AddSection 
        actionText={ addSectionText }
        clickHandler={(e) => { e.preventDefault(); addNewSection()}} 
      />
    }
  </>
)

}
export default OrderableSection