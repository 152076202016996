import sessionManager from '../lib/session'
import dateManager from '@ministry-squads-common/date-manager'
import {get,post,put,httpDelete} from '../lib/http.js'
import {sortEventsByDate} from '../lib/index'
import {getEventDisplayName} from '../events/common'

const add = (eventData) => {
  return save(eventData,post)
}

const update = (eventData) => {
  return save(eventData,put)
}

const save = (eventData,saveFunction) => {
  return new Promise((resolve,reject) => {
    validate(eventData).then(result => {
      if(result === true) {
        return
      } else {
        reject(result)
      }
    }).then(() => {
      const timezone = dateManager.currentTimeZone()

      const {scheduledEventId,eventName,eventTypeId,startTime,endTime,startDate,endDate,attendees,teamsToAssignActions,eventSeriesId} = eventData

        const postBody = {
          "eventData": {
            "scheduledEventId": scheduledEventId,
            "eventName": eventName,
            "eventTypeId": eventTypeId,
            "startDate": dateManager.formatDate(startDate,'URL_PARAMETER'),
            "endDate": dateManager.formatDate(endDate,'URL_PARAMETER'),
            "startTime": startTime,  
            "endTime": endTime,  
            "timeZone": timezone,
            "attendeeIds": (attendees && attendees.length > 0) ? attendees.map(attendee => attendee.userId) : null,
            "assignableTeamsActions": teamsToAssignActions,
            "eventSeriesId": eventSeriesId
          }
        }

        return saveFunction('/api/event',postBody).then((resp) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })

    }).catch(customErrorObj => {
      reject(customErrorObj)
    })
  })
}




  
  const validate = (eventData) => {
    const {eventName, startTime,endTime,startDate,endDate,eventTypeId} = eventData

    return new Promise((resolve,reject) => {
      const newErrorObj = getInitialObject()
      let haveError = false

      if(!eventName || eventName === '') {
        haveError = true
        newErrorObj.eventName = "Please provide a name for your event"
      } 

      if(!eventTypeId || parseInt(eventTypeId) === -1) {
        haveError = true
        newErrorObj.eventType = "Please select a type of event"
      } 

      if(!startDate || startDate === '') {
        haveError = true
        newErrorObj.startDate = "Please provide a start date"
      } 
      
      if(!endDate || endDate === '') {
        haveError = true
        newErrorObj.endDate = "Please provide an end date"
      } else if(startDate && startDate > endDate) {
        haveError = true
        newErrorObj.endDate = `${endDate} Must be after the start date`
      }
        
      if(!startTime || startTime === '')  {
        haveError = true
        newErrorObj.startTime = 'Please provide a start time'
      }

      if(!endTime || endTime === '')  {
        haveError = true
        newErrorObj.endTime = 'Please provide an end time'
      } else if(startTime && startTime >= endTime) {
        haveError = true
        newErrorObj.endTime = 'Must be after the start time.'
      }

      if(haveError === true) {
        reject(newErrorObj)
      } else {
        resolve(true)
      }
    })
  }

  
  const getInitialObject = () => {
    return {
      "eventName": null,
      "eventType": null,
      "startDate": null,
      "endDate": null,
      "startTime": null,
      "endTime": null,
      "attendees":null,
      "generalPageError": null,
      
    }
  }

  const cancelEvent = (eventData) => {
    return new Promise((resolve,reject) => {
      const body = {
        "eventData": eventData
      }
      httpDelete('/api/event',body).then(() => resolve())
      .catch(e => {
        console.error(e)
        reject(e)
      })
    })
    
  }

  const listByAttribute = (attributeType,startDate,endDate) => {
    return new Promise((resolve,reject) => {
      if(!attributeType || attributeType !== 'sermon') {
        reject("Invalid attribute passed in for event list.")
      } else if(!startDate || !endDate) { 
        reject("Please provide a start and end date")
      } else {
        get(`/api/calendar/attribute/${attributeType}?startDate=${startDate}&endDate=${endDate}`).then((response) => resolve(response))
        .catch(e => {
          console.error(e)
          reject(e)
        })
      }
      
    })
    
  }

  const detail = (eventId) => {

    // Ideally this would come back from the database.
    const WORSHIP_EVENT_TYPE_ID = 1

    const getDisplayName = (resp) => {
      if(resp.events && resp.events.length > 0) {
         return getEventDisplayName(resp.events[0])
    } else if(resp.events && resp.events.length === 0) {
         return getEventDisplayName(resp.cancelledEvents[0])
    } else {
       return ('Could not get event details')
    }

    }

    const getAttendeesUserData = (attendees,members) => {
      return attendees
    }

    const getIsWorshipEvent = (events) => {
      return events.filter(event => event.eventTypeId === WORSHIP_EVENT_TYPE_ID).length > 0
    }

    return new Promise((resolve,reject) => {
      get(`/api/calendar/detail/${eventId}`).then(resp => {
        console.log(resp)
        resolve({
          "scheduledEventId": eventId,
          "activeEvents": resp.events,
          "cancelledEvents": resp.cancelledEvents,
          "allEvents": sortEventsByDate(resp.events.concat(resp.cancelledEvents)),
          "songs": resp.songs,
          "assignments": resp.assignments,
          "assignmentsByTeam": resp.assignmentsByTeam,
          "attendees": getAttendeesUserData(resp.attendees,resp.members),
          "members": resp.members,
          "roles": resp.roles,
          "teams": resp.teams,
          "notes": resp.notes,
          "sermons": resp.sermons,
          "isWorshipEvent": getIsWorshipEvent(resp.events),
          "isSeries": (resp.events[0].eventSeriesId !== null && typeof resp.events[0].eventSeriesId !== "undefined"),
          "isGroup": (resp.events[0].eventGroupId !== null&& typeof resp.events[0].eventGroupId !== "undefined"),
          "displayName": getDisplayName(resp)
        })

      }).catch(e => {
        reject(e)
      })
    })
    

  }


export {add,update,getInitialObject,cancelEvent,listByAttribute, detail}