
const {API_PATH,authHeader} = require('./common')
const { handleErrors } = require('../lib/call-api')

export const save = (urlData) => {
  return new Promise((resolve,reject) => {
    const addToPath = (urlData.isNew === true && urlData.relatedToType === 'song') ? `/${urlData.relatedToType}` : ''
     
      validate(urlData).then((validatedData) => {
        const verb = (validatedData.isNew === true) ? "post" : "put"
        fetch(`${API_PATH}/api/url${addToPath}`, {
          "method": verb,
          "headers": {
            "Accept": "application/json",
            "Content-Type": "application/json",
            ...authHeader()
          },
          "body": JSON.stringify(validatedData)
        }).then(response => 
          handleErrors(response)
        ).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      }).catch((e) => {
        reject(e)
      })
    
    

  })
}

export const remove = (itemId,relatedToId,relatedToType) => {
  console.log(itemId,relatedToId,relatedToType)
  return new Promise((resolve,reject) => {
    fetch(`${API_PATH}/api/url/${relatedToType}`, {
      "method": "DELETE",
      "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json",
        ...authHeader()
      },
      "body": JSON.stringify({
        "itemId": itemId,
        "relatedToId": relatedToId,
        "relatedToType": relatedToType
      })
    }).then(response => 
      handleErrors(response)
    ).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

const validate = (data) => {
  return new Promise((resolve,reject) => {
    if(data.urlPath === '' || data.urlTitle === '' || data.urlType === '') {
      reject('Please provide a valid URL.')
    } else {
      resolve(data)
    }
  })
}
