import React from 'react'
import { withRouter } from "react-router-dom"
import Routes from './routes/index'
import { SecondaryContentProvider  } from './contexts/SecondaryContent.Context'
import './App.scss';
import sessionManager from './lib/session'

function App() {

  return (
    <>
    <div className="App">
      <SecondaryContentProvider> 
        <Routes />
        <div id="secondary-content-container" className="secondary-content-container"></div>
        
        <div id="popup-content-container" className="secondary-content-container"></div>

      </SecondaryContentProvider> 
      <div id="timeout-warning-container" className="secondary-content-container">
        <TimeoutWarning />
      </div>
    </div>
    
  </>
  );
}

const TimeoutWarning = () => {
  const keepAlive = () => {
    sessionManager.refreshSession(true)

    const timeoutWarning = document.getElementById("timeout-warning-container")
    timeoutWarning.classList.remove('active')  
  }
  
  return (
    <div id="timeout-warning" className="secondary-content-popup">
      <h2>Are you still working?</h2>
      <p>Sorry for the interruption, but you will be automatically logged out in <span id="time-out-counter"></span></p>

      <div className="button-container">
        <div className="row">
          <div className="col-md-3 offset-md-3 col-xs-12">
            <input type="button" className="btn btn-primary" onClick={() => {keepAlive()}} value="Yes, keep me logged in" />
          </div>
          <div className="col-md-3 col-xs-12">
            <input type="button"  className="btn btn-link" onClick={() => {sessionManager.clearSession()}} value="No, log me out" />
          </div>
        </div>
        
      
        
      </div>
    </div>

  )
}

export default withRouter(App);
