import React from "react"
import { Route,Switch,Redirect } from "react-router-dom"

import UnauthenticatedRoute from "./components/UnauthenticatedRoute"
import AuthenticatedRoute from "./components/AuthenticatedRoute"

import Home from '../pages/home'
import Login from '../login/login'
import ForgotPassword from '../login/forgotPassword'
import Register from '../login/register'
import ChangePassword from '../profile/changePassword'

import Profile from '../profile/index'
import ProfileAvailability from '../profile/availability'
import Event from '../events/event-detail'
import CreateEvent from '../events/create'
import EditEvent from '../events/edit.js'

import ManageAssignments from '../assignments/manage'
import Schedule from '../assignments/schedule'
import MemberSchedule from '../assignments/memberSchedule.js'


import AgendaDetail from '../events/agenda'
import PrintAgenda from '../events/printableAgenda'

import SongList from '../songs/song-list'
import SongDetail from '../songs/song-detail'

import TeamList from '../teams/team-list'
import TeamDetail from '../teams/team-detail'

import Members from '../members/member-list'
import MemberDetail from '../members/member-detail'

import NotFound from '../pages/notFound'

import AboutUs from '../content/aboutUs'
import TermsOfUse from '../content/termsOfUse'
import Privacy from '../content/privacy'

import SermonSeriesList from '../sermonSeries/list'
import SermonSeriesDetail from '../sermonSeries/detail'


import Calendar from '../events/calendar'




const RootRoute = () => {
  return (<Redirect to={`/home`} />)
}

/*
  NOTE 
  /schedule/:startDate?/:endDate? needs to be after all routes that start with /schedule.
  otherwise, since the startDate and endDate parameters are optional, it will always match that URL first.
  
*/
const routes = ({ childProps }) =>
  <Switch>
    <Route path="/" exact component={RootRoute} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/register" exact component={Register} props={childProps} />
    <UnauthenticatedRoute path="/forgot-password" exact component={ForgotPassword} props={childProps} />
    
    <UnauthenticatedRoute path="/about-us" exact component={AboutUs} props={childProps} />
    <UnauthenticatedRoute path="/terms-of-use" exact component={TermsOfUse} props={childProps} />
    <UnauthenticatedRoute path="/privacy" exact component={Privacy} props={childProps} />

    <AuthenticatedRoute path="/calendar/:month?/:year?" exact component={Calendar} props={childProps} accessLevel='active' />


    <AuthenticatedRoute path="/home" exact component={Home} props={childProps} />
    <AuthenticatedRoute path="/profile" exact component={Profile} props={childProps} accessLevel='all' />
    <AuthenticatedRoute path="/profile/availability" exact component={ProfileAvailability} props={childProps} accessLevel='all' />
    <AuthenticatedRoute path="/profile/change-password" exact component={ChangePassword} props={childProps} accessLevel='all' />
    <AuthenticatedRoute path="/member/list" exact component={Members} props={childProps} accessLevel='active' />
    <AuthenticatedRoute path="/member/detail/:userId" exact component={MemberDetail} props={childProps} accessLevel='active'/>


    <AuthenticatedRoute path="/event/detail/:scheduledEventId" component={Event} props={childProps} accessLevel='active'/>
    <AuthenticatedRoute path="/event/edit/:eventSeriesId/:eventInstanceId" component={EditEvent} props={childProps} accessLevel='active' />
    <AuthenticatedRoute path="/calendar/event/detail/:scheduledEventId" component={Event} props={childProps} accessLevel='active'/>
    <AuthenticatedRoute path="/schedule/event/detail/:scheduledEventId" component={Event} props={childProps} accessLevel='active'/>

    <AuthenticatedRoute path="/event/create" component={CreateEvent} props={childProps} accessLevel='active'/>
    <AuthenticatedRoute path="/event/agenda/:scheduledEventId" exact component={AgendaDetail} props={childProps} accessLevel='active'/>
    <AuthenticatedRoute path="/event/agenda/print/:scheduledEventId" exact component={PrintAgenda} props={childProps} accessLevel='active' layout='popup' />

    <AuthenticatedRoute path="/schedule/member/:memberId" exact component={MemberSchedule} props={childProps} accessLevel='active'/>
    <AuthenticatedRoute path="/schedule/:startDate?/:endDate?" exact component={Schedule} props={childProps} accessLevel='active'/>

    <AuthenticatedRoute path="/make-assignments/:teamId?" exact component={ManageAssignments} props={childProps} accessLevel='active'/>

    <AuthenticatedRoute path="/sermon-series/list" exact component={SermonSeriesList} props={childProps} accessLevel='active' />
    <AuthenticatedRoute path="/sermon-series/detail/:sermonSeriesId" exact component={SermonSeriesDetail} props={childProps} accessLevel='active' />
    
    <AuthenticatedRoute path="/song/list" exact component={SongList} props={childProps} accessLevel='active'/>
    <AuthenticatedRoute path="/song/detail/:songId" exact component={SongDetail} props={childProps} accessLevel='active'/>
    <AuthenticatedRoute path="/team/list" exact component={TeamList} props={childProps} accessLevel='active' />
    <AuthenticatedRoute path="/team/detail/:teamId" exact component={TeamDetail} props={childProps} accessLevel='active' />

    <Route component={NotFound} />
  </Switch>

export default routes