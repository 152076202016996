import dateManager from '@ministry-squads-common/date-manager'

export const ITEM_TYPE_WORSHIP_SERVICE = 'Worship Service'
export const ITEM_TYPE_BLOCKED_DATE = 'Blocked date'

export const getDaysInRange = (startDate,endDate) => {

  const diff = dateManager.diff(endDate,startDate,'DAYS')
  const returnObj = []
  // populate with the requested month
  for(let i=0;i<=diff;i++) {
    returnObj.push({
      "isCurrentMonth": true,
      "dateObj": dateManager.add(startDate,i,"DAYS")
    })
  }
  return returnObj
}

export const getCurrentMonth = (dt) => {
  return {
    "month":dt.getMonth(),
    "year": dt.getFullYear()
  } 
}

export const getPreviousMonth = (dt) => {
  const current = getCurrentMonth(dt)

  const previousMonth = (current.month === 0) ? 11 : current.month - 1
  const previousYear = (current.month === 0) ? current.year-1 : current.year

  return {
    "month": previousMonth,
    "year": previousYear
  } 
}

export const getNextMonth = (dt) => {
  const current = getCurrentMonth(dt)
  const nextMonth = (current.month === 11) ? 0   : current.month + 1
  const nextYear = (current.month === 11) ? current.year+1 : current.year

  return {
    "month": nextMonth,
    "year": nextYear
  } 
  
}
export const onDay = (dateToCheck,dateOfItem) => {
  return new Date(dateToCheck).getFullYear() === new Date(dateOfItem).getFullYear() &&
    new Date(dateToCheck).getMonth() === new Date(dateOfItem).getMonth() &&
    new Date(dateToCheck).getDate() === new Date(dateOfItem).getDate()
}

export const getMonthData = (passedDate) => {
  
  const dateInMonth = (!passedDate) ? new Date() : passedDate;
  const current = getCurrentMonth(dateInMonth)
  const currentYear = current.year;
  const currentMonth = current.month; 
  const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay()
  const lastDayOfMonth = new Date(currentYear, currentMonth, daysInCurrentMonth).getDay()
  
  const returnObj = []

  // If the current month doesn't start on a sunday, then display the end of the previous month
  if(firstDayOfMonth > 0) {
    const previous = getPreviousMonth(passedDate)
    const previousMonth = previous.month
    const previousYear = previous.year
    const totalDaysInPreviousMonth = getDaysInMonth(previousYear, previousMonth);

    for(let z=totalDaysInPreviousMonth-firstDayOfMonth+1;z<=totalDaysInPreviousMonth;z++) {
      returnObj.push({
        "isCurrentMonth": false,
        "dateObj": new Date(previousYear, previousMonth, z)
      })
    }
  }

  // populate with the requested month
  for(let i=1;i<=daysInCurrentMonth;i++) {
      returnObj.push({
        "isCurrentMonth": true,
        "dateObj": new Date(currentYear, currentMonth, i)
    })
  }


  // If the current month doesn't end on a saturday, then display the beginning of the next month
  if(lastDayOfMonth < 6) {
    const next = getNextMonth(dateInMonth)
    const nextMonth = next.month
    const nextYear = next.year

    const nextMonthDaysToInclude = 7 - lastDayOfMonth 

    for(let p=1;p<nextMonthDaysToInclude;p++) {
      returnObj.push({
        "isCurrentMonth": false,
        "dateObj": new Date(nextYear, nextMonth, p)
    })
    }
  }

  return returnObj
  
}

const getDaysInMonth = (year, monthIndex) => {
  // passing 0 into new Date will return the last day of the month
  // BUT for some reason, when you do this, the month is no longer
  // interpreted as indexed.

  // for example, this will return May 31st: new Date(2022,4,31)
  // but this will not: new Date(2022,5,0)

  // so we're assuming there is a month's index passed in, and we need
  // to compensate for this Javascript date nonsense.
  return new Date(year, monthIndex+1, 0).getDate();
}