import sessionManager from './session'
const apiPath = process.env.REACT_APP_API_URI || ""




export const handleErrors = async (response) => {
  const responseBody = await response.json()
  
  if(response.status === 401) {
    throw new Error(401)
  } else if(response.status === 403) {
    sessionManager.clearSession()
  } else if(response.ok === false) {
    // "Bulk responses" are when we are updating more than one item in a 
    // single API call. An example is assigning songs to an event on song list.
    // For Bulk responses, there is additional data passed along that describes the
    // error for each item, so just passing a single message isn't good enough 
    const isBulkResponse = typeof(responseBody.unsuccessful) !== "undefined"
    if(isBulkResponse === true) {
      throw responseBody
    } else{
      throw responseBody.message
    }
    
  } else {
    return responseBody
  }
}

const authHeader = () => {
  const accessToken = sessionManager.getToken()
  return (accessToken) ? { 'authorization': `BEARER ${accessToken}` } : {}
}

export const get = (path,onSuccess,onError) => {
  fetch(`${apiPath}${path}`, {
    "method": 'get',
    "headers": authHeader()
  })
  .then(response => handleErrors(response))
  .then(resp => { 
    onSuccess(resp)
  })
  .catch(error => {
    if(onError) onError(error)
  })
}

export const post = (path,onSuccess,onError,validateFunction,body) => {
  return putPost('post',path,onSuccess,onError,validateFunction,body)
}

export const put = (path,onSuccess,onError,validateFunction,body) => {
  return putPost('put',path,onSuccess,onError,validateFunction,body)
}

const putPost = (verb,path,onSuccess,onError,validateFunction,body) => {
  if(verb !== 'put' && verb !== 'post') throw new Error('Invalid verb')
  if ((validateFunction === null || typeof(validateFunction) === "undefined") || validateFunction(body) === true) {
    const localAuthHeader = authHeader()
    fetch(`${apiPath}${path}`, {
      "method": verb,
      "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json",
        ...localAuthHeader
      },
      "body": JSON.stringify(body)
    }).then(response => 
      handleErrors(response)
    ).then(response => {
      onSuccess(response)
    }).catch(error => {
      onError(error)
    })
  } else {
    // do we need to do anything here?
  }
}

export const httpDelete = (path,onSuccess,onError,setIsLoading,pageDescription,body) => {
  
  const localAuthHeader = authHeader()
  fetch(`${apiPath}${path}`, {
    method: 'delete',
    "headers": {
      "Accept": "application/json",
      "Content-Type": "application/json",
      ...localAuthHeader
    },
    "body": (body) ? JSON.stringify(body) : null
  }).then(response => 
    handleErrors(response)
  ).then(response => {
    onSuccess(response)
    if(setIsLoading) setIsLoading(false)
  })
    .catch(error => {
      onError(error)
      if(setIsLoading) setIsLoading(false)
    })
  
}