import React  from 'react'
import AddTeam from './AddTeam'
import PersonSearch from '../../members/components/PersonSearch'
import '../events.scss'
import { useManageEventState } from '../../contexts/ManageEvent.Context'

const EventBasics = () => {
  const [eventState, eventDispatch] = useManageEventState()

  const setSelectedTeams = (val) => {
    eventDispatch({"type": "selectedTeams", "payload": val})
  }
  
  const setAttendees = (val) => {
    eventDispatch({"type": "attendees", "payload": val})
  }

  return (<>
    <div className="card">
      <section className="dbl-bottom">
        <div className="form-group">
          <h3>Assign Teams</h3>
          <AddTeam selectedList={eventState.selectedTeams} onListChange={(newList) => setSelectedTeams(newList)} />
        </div>
      </section>
    </div>
    <div className="card">
      <section className="dbl-bottom">
        <div className="form-group">
          <h3>Attendees</h3>
          <PersonSearch selectedList={eventState.attendees} onListChange={(newList) => setAttendees(newList)} />
        </div>
      </section>
    </div>
   </>
  )
}

export default EventBasics