import React, { useState, useEffect } from "react"

import Alert from '../Alert'

const TextArea = ({maxLength=255,errorMessage,errorType,handleChange}) => {
  const [note,setNote] = useState('')
  const [noteLength,setNoteLength] = useState(0)
 
  const lclChange = (txt) => {
    setNote(txt)
    handleChange(txt)
  }

  useEffect(() => {
    if(note && note !=="" ) setNoteLength(note.length)
  },[note,setNoteLength])

  return (
  <>
    <textarea className="form-control" maxLength={maxLength} onChange={(e) => lclChange(() => e.target.value)} value={note} />
    {
      errorMessage && errorType &&
      <Alert type={errorType} message={errorMessage} context="inline" />
    }

    <div>{maxLength - noteLength} characters left.</div>
  </>
  
  )

    
}

export default TextArea