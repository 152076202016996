import React, { useState, useEffect  } from 'react'
import FormField from './FormField'
const SetEmail = ({passedValue,setValue,emailIsRequired=true}) => {
    const [email, setEmail] = useState(passedValue || '')
    const [errorMessage, setErrorMessage] = useState('')
    useEffect(() => {
        setEmail(passedValue)        
    }, [passedValue])

    useEffect(() => {
        if(emailIsRequired === false) setErrorMessage('')
    },[emailIsRequired])

    const emailIsValid = () => {
        if(email === '' && emailIsRequired === true) {
            setErrorMessage('Please provide an email address.')
            setValue(null)
            return false
        } else if(emailIsRequired === true && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) === false) {
            setErrorMessage('The email address you entered is not formatted correctly.')
            setValue(null)
            return false
        } else {
            setErrorMessage('')
            setValue(email)
            return true
        }
        
    }

    return (
        <div className="form-group">
            <label htmlFor="email">Email address</label>
            <FormField type="text" id="email" value={email} errorMessage={errorMessage} handleChange={setEmail} validateFunction={emailIsValid}/>
        </div>

    )
}
export default SetEmail


