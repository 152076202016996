import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import SaveSermonSeries from './components/SaveSermonSeries'
import Alert from '../components/Alert'
import {get} from '../lib/call-api'
import AdminOnly from '../routes/components/AdminOnly'
import BiblePassageLink from '../components/BiblePassageLink'
import DisplayEvent from './components/DisplayEvent'
import SecondaryContent from '../components/SecondaryContent'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import useIsMountedRef from '../hooks/useIsMountedRef'
import dateManager from '@ministry-squads-common/date-manager'
import {sermonDates} from './common'

const SermonSeriesDetail = (props) => {
  
  const [sermonSeriesData, setSermonSeriesData] = useState(null)
  const [sermonEndDate, setSermonEndDate] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const {sermonSeriesId} = props.match.params
  const [successMessage, setSuccessMessage] = useState('')
  const [modalState, modalDispatch] = useSecondaryContentState()
  const [eventDates,setEventDates] = useState([]) 

  const [secondaryContentAction,setSecondaryContentAction] = useState()
  const [secondaryContentHeader,setSecondaryContentHeader] = useState('Edit a sermon series')
  const isMounted = useIsMountedRef()

  const toggleSlider = (action) => {
    setSecondaryContentAction(action)
  }

  useEffect(() => {
    if(sermonSeriesData !== null && typeof sermonSeriesData !== "undefined") {
      const lclEventDates = sermonDates(sermonSeriesData.startDate,sermonSeriesData.sermons)
      setEventDates(() => [...lclEventDates])
      setSermonEndDate(lclEventDates[lclEventDates.length-1])
    }
  },[sermonSeriesData])

  
  useEffect(() => {
    if(secondaryContentAction) modalDispatch({ type: 'toggleIsOpen', payload: null })
  },[secondaryContentAction,modalDispatch])

 
    
  useEffect(() => {
    const onGet = (resp) => {
      if(isMounted.current === true) {
        setSermonSeriesData(resp)
        setIsLoading(false)
      }
    }

    const onGetError = (resp) => {
      setIsLoading(false)
      setErrorMessage(resp)
    }
    get(`/api/sermon-series/detail/${sermonSeriesId}`,onGet,onGetError)

    return () => isMounted.current = false
  }, [sermonSeriesId, successMessage, isMounted])



  const updateOnAdd = (data, message) => {
    setSermonSeriesData(data)
    setSuccessMessage(message)
    closeSecondaryContent()
  }

  const closeSecondaryContent = () => {
    modalDispatch({ type: 'close', payload: null })
    setSecondaryContentHeader()
    setSecondaryContentAction()
  }

  return (
    <section className="page">
        {
          isLoading === false && 
          <>
          <header>
          <div className="breadcrumbs">
              <Link to="/sermon-series/list">Sermon Series</Link>
            </div>
            {
            sermonSeriesData && 
            <>
              <h1>
                {sermonSeriesData.sermonSeriesTitle}
              </h1>
              <AdminOnly>
                {
                  
                  <div className="feature-manager">
                    <button className="btn btn-icon btn-edit" onClick={() => toggleSlider('editSeries')}>Edit this sermon series</button>
                  </div>  
                     
                }
              </AdminOnly>

              <Alert type="success" message={successMessage} />
                
              <Alert type="error" message={errorMessage} />

              <div className="after-heading">
                <ul>
                  <li>
                    <label>Start Date: </label>
                    {
                      sermonSeriesData.startDate && sermonSeriesData.startDate !== '' &&
                      <span>{dateManager.formatDate(sermonSeriesData.startDate)}</span>
                    }
                    { (!sermonSeriesData.startDate || sermonSeriesData.startDate === '') &&
                      <span className="no-results">No start date</span>
                    }
                  </li>
                  <li>
                    <label>End Date: </label>
                    {
                      sermonEndDate && sermonEndDate !== '' &&
                      <span>{dateManager.formatDate(sermonEndDate)}</span>
                    }
                    { (!sermonEndDate || sermonEndDate === '') &&
                      <span className="no-results">No end date</span>
                    }
                  </li>
                </ul>
              </div>

              </>
            }
            
          </header>
          {
            sermonSeriesData && eventDates && 
            <div className="card">
              <h2>Sermons</h2>
              { 
                (sermonSeriesData.sermons.length === 0) &&
                <span className="no-results">No sermons yet. Edit this sermon series to add sermons.</span>
              }

              { 
                (sermonSeriesData.sermons.length > 0) &&
                
              
              <table>
                <thead>
                  <tr>
                    <th>Service/Event</th>
                    <th>Bible Passage(s)</th>
                    <th>Sermon Title</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    sermonSeriesData.sermons.map((sermon,sermonCtr) => 
                      <tr key={`sermon${sermonCtr}`}>
                        <td>
                          {
                            sermon.events && sermon.events.length > 0 &&
                            <DisplayEvent eventList={sermon.events} ctr={0} />
                          }
                          {
                            sermon.events && sermon.events.length === 0 &&
                            <span className="no-results">No events assigned</span>
                          }
                        </td>
                        <td>
                          {
                            sermon.biblePassages.map((verse,sermonVerseCtr) => 
                              <BiblePassageLink key={`sermonVerse${sermonCtr}-${sermonVerseCtr}`} unStructuredReference={verse} />
                            )
                          }
                        </td>
                        <td>{sermon.sermonTitle || <span className="no-results">No title yet</span>}</td>
                      </tr>
                    )
                  }
                  
                </tbody>

              </table>
              }
            </div>
          }
         
          </>

        }

  <SecondaryContent 
    heading={secondaryContentHeader}
    onClose={closeSecondaryContent} 

    size="larger"
   >

    {
      secondaryContentAction === 'editSeries' &&
      <SaveSermonSeries 
        onSuccess={updateOnAdd} 
        sermonSeriesData={sermonSeriesData} 
        onCancel={closeSecondaryContent}
      />
    }
  
  </SecondaryContent>

    </section>
  )
}


export default SermonSeriesDetail