import {put,post} from '../lib/http.js'

export const save = (sermonSeriesData) => {
  return new Promise((resolve,reject) => {
    const func = (sermonSeriesData.sermonSeriesId) ? put : post
    func(`/api/sermon-series/save`, sermonSeriesData).then(response => {
      resolve(response)
    
    }).catch((e) => {
      reject(e)
    })
  })

}
