import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DatesBlocked from './components/DatesBlocked'
import AddBlockedDate from './components/AddBlockedDate'
import SecondaryContent from '../components/SecondaryContent'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import Alert from '../components/Alert'
import dateManager from '@ministry-squads-common/date-manager'

const Availability = () => {
  const [datesJustBlocked, setDatesJustBlocked] = useState()
  const [datesNotBlocked, setDatesNotBlocked] = useState()

  const [showHiddenContent, setShowHiddenContent] = useState(false)
  const [modalState, modalDispatch] = useSecondaryContentState()
  

  const [successMessage, setSuccessMessage] = useState()
  const [resultType,setResultType] = useState()



  const toggleModal = () => {
    modalDispatch({ type: 'toggleIsOpen', payload: null })
  }

  const onAdd = (bulkResponse) => {
    
    const justBlocked = bulkResponse.successful
    const notJustBlocked = bulkResponse.unsuccessful

    if(justBlocked && notJustBlocked.length > 0) {
      setSuccessMessage(`We successfully blocked the highlighted dates below, but we couldn't block the following dates:`)
      setResultType("mixed")
    } else {
      setSuccessMessage(`We successfully blocked the highlighted dates below.`)
      setResultType("success")
    }
   
    setShowHiddenContent(false)
    toggleModal()

    setDatesJustBlocked(justBlocked)  
    setDatesNotBlocked(notJustBlocked)  
  
  }

  const onDelete = () => {
    // when a user unblocks a date, remove any previous messaging
    setSuccessMessage()
    setResultType()
    setDatesJustBlocked()
  }

  return (
    <section className="page page-slim">
     
      <header>
        <div className="breadcrumbs">
          <Link to="/profile">Your Profile</Link>
        </div>
        
        <h1>Your Availability</h1>
        <div className="feature-manager">
          <button className="btn btn-link" onClick={toggleModal}>
            Block a date
          </button>
        </div>  
      </header>

      <SecondaryContent onClose={setShowHiddenContent} show={showHiddenContent} heading="Block a date">
        <AddBlockedDate handleUpdate={onAdd} unique={modalState.counterKey} />
      </SecondaryContent>

      <div className="card">
     
        <DatesBlocked justBlocked={datesJustBlocked} onDelete={onDelete} readOnly={false}>
        {
          successMessage && resultType === 'success' &&
          <Alert type='success' message={successMessage} />
        }
        {
            successMessage && resultType === 'mixed' && datesNotBlocked && 
            <Alert type='warning' message={successMessage}>
                <ul>
                    {
                        datesNotBlocked.map(dateError => 
                            <li key={`dateErrro${dateError.date}`}>
                                {dateManager.formatDate(dateError.date,'TEXT_FULL')}:&nbsp;
                                {dateError.error.userMessage}
                            </li>
                        )
                    }
                </ul>
            </Alert>
        }
        </DatesBlocked>
      </div>
    </section>
  )
}

export default Availability