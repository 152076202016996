import React, {useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import sessionManager from '../../lib/session'
import CalendarEvents from './CalendarEvents'
import {get} from '../../lib/call-api'
import {getDaysInRange,ITEM_TYPE_WORSHIP_SERVICE} from '../../lib/calendar'
import '../../components/css/WeeklySchedule.scss'

const CurrentWeekEvents = () => {
    
    const session = sessionManager.getSession()

    const [eventData,setEventData] = useState()
    const [dateRange,setDateRange] = useState()
    
    const orgName = session.orgData.orgName

    useEffect(() => {
 
        const startDate = session.currentWeek.startDate
        const endDate = session.currentWeek.endDate
        
        const orgId = session.orgData.orgId
        
        setDateRange(getDaysInRange(startDate,endDate))

        // this code is duplicated in events/calendar.js
        const onScheduleGet = (resp) => {
            //if(isMounted.current === true) {
            const genericEventsObject = resp.map(evt => {
                return {
                "itemId": evt.scheduledEventId,
                "itemType": ITEM_TYPE_WORSHIP_SERVICE,
                "cssClass": "worship-service",
                "itemDate": evt.startDateTime,
                "itemLabel": evt.eventName,
                "backgroundColor": evt.eventTypeColor,
              "eventTypeName": evt.eventTypeName,
              "eventTypeId": evt.eventTypeId,
                }
            })
            setEventData(genericEventsObject)
    
            //}
        }
    
        const apiUrl = `/api/calendar/${orgId}?startDate=${startDate}&endDate=${endDate}`
        get(apiUrl,onScheduleGet,null)
       
        
    },[session.currentWeek.endDate, session.currentWeek.startDate, session.orgData.orgId])
    

    return ( 
        <section id="weekly-schedule-container">
            <h2>This week at {orgName} <small className='inline'><Link to='/calendar'>View the full calendar</Link></small></h2>
            {
                dateRange && eventData && 
                <CalendarEvents dateRangeData={ dateRange } eventData={eventData} />
            }
    
        </section>
    )
}


export default CurrentWeekEvents