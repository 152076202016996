import React, {Fragment} from 'react'

const RoleIcon = ({roleData}) => {
  return (
      <Fragment>
      {
          roleData.roleIcon !== null && roleData.roleIconType === 'CSS_CLASS' &&
          <i className={`role-icon ${roleData.roleIcon}`} title={roleData.roleName}></i>
      }

      </Fragment>
  )
}
export default RoleIcon