import React from 'react'
import classnames from 'classnames'

const ToggleButton = (props) => {

  const title = (props.isEnabled === false) ? props.ifDisabledMessage : ''

  const checkClick = () => {
    if(props.isEnabled === true) props.handleClick()
  }

  const extraClass = () => props.extraClass ? props.extraClass : ""
  return (
    <div 
      className={
          classnames(
              `toggle-selector ${ extraClass() }`,
              { 'selected': props.isSelected === true },
              { 'disabled': props.isEnabled === false },
              { 'small': props.isSmall === true}
          )}
      key={props.uniqueId}
      onClick={() => checkClick()}
      title={title}
      >
        {props.children}
      
    </div>
  )
  
}

export default ToggleButton