import React, {useState,useEffect} from 'react'
import SongKeys from './SongKeys'
import SelectMember from '../../members/components/SelectMembers'
import PreviouslyPlayed from './PreviouslyPlayed'
import Alert from '../../components/Alert'

import {emptyAssignmentObject} from '../common'
import SessionManager from '../../lib/session'

const ManageSongAssignment = ({songData,assignmentData,onDataChange,songErrorMessages}) => {
  const sessionData = SessionManager.getSession()
  const SINGER_ATTRIBUTE_TEXT = 'Lead singer' // not great. tied to changeable text in the database
  const ENCODED_SINGER_ATTRIBUTE_ID = sessionData.availableAttributes.filter(attrib => attrib.attributeName === SINGER_ATTRIBUTE_TEXT)[0].attributeId

  const [localAssignmentData,setLocalAssignmentData]  = useState()
  const [leadUserId,setLeadUserId] = useState()
  const [leadSongKey,setLeadSongKey] = useState()

  useEffect(() => {
    const initialObject = (assignmentData) ? assignmentData : emptyAssignmentObject(songData.songId)
    setLocalAssignmentData(initialObject)
  
    setLeadUserId(initialObject.ledBy.userId)
    setLeadSongKey(initialObject.ledBy.songKey)

  },[assignmentData,songData.songId])

  const updateParent = (newAssignment) => {
    onDataChange(newAssignment)
  }

  const updateLead = (userId) => {

    let updatedAssignment = localAssignmentData
    // if the user has lead this song before, don't just change the singer's ID
    // also pre-select the key the user sang the song in
    const mostRecentKey = getMostRecentKey(userId,songData)
    // update our local state with the new userId
    setLeadUserId(userId)

    updatedAssignment.ledBy.userId = userId

    if(mostRecentKey) {
      // this is going to cause the useEffect below to also be called, 
      // which we don't really need
      updateKey(mostRecentKey, updatedAssignment)
    } else {
      setLeadSongKey(null)

      // if we aren't also going to be making a change to the song key
      // 1. update our local copy of the assignment data
      setLocalAssignmentData(updatedAssignment)
      

      // 2. update the assignment in the parent.
      updateParent(updatedAssignment)
    }
  }

  const updateKey = (songKey, updatedWithNewLead = null) => {
    // if updating the lead triggered updating the key, use the data we got from updateLead
    let updatedAssignment = (updatedWithNewLead) ? updatedWithNewLead : localAssignmentData

    // update the local songKey value, and the object we will send to the parent
    setLeadSongKey(songKey)
    updatedAssignment.ledBy.songKey = songKey

    // update state with the new assignment data
    setLocalAssignmentData(updatedAssignment)

    // always update the parent when the song key is updated
    // this useEffect could be called if the user:
    // 1. selects a lead that has led the song before, the song key will be auto selected
    // 2. selects a new key directly
    updateParent(updatedAssignment)
  }

  // this function will set the key for a given song to the key that a singer most recently sang the song
  // if no key is found, it will do nothing
  const getMostRecentKey = (userId,song) => {
    
    if(typeof song.eventsPlayed === "undefined" || song.eventsPlayed === null || song.eventsPlayed.length === 0) {
      return null
    } else {
      const lastTimeSung = song.eventsPlayed.filter(eventPlayed => eventPlayed.ledBy.userId === userId && eventPlayed.ledBy.songKey)

      if(lastTimeSung.length > 0) {
        return lastTimeSung[0].ledBy.songKey
      } else {
        return null
      }
    }
  }
 
  const getAdditionalClass = (song) => {
    return (song.songTitle) ? "with-title" : "without-title"
  }
  
  return (
    <div className={`group ${getAdditionalClass(songData)}`} key={`song-rows-${songData.songId}`}>
      <div className="grouped-row">
        {
          songData.songTitle && 
          <div>{songData.songTitle}</div>
        }
        <div><SelectMember 
            id={`select-singer-${songData.songId}`}
            selectedMember={{"userId":leadUserId}}
            setFunction={(data) => {updateLead(data)}}
            attributeId={ENCODED_SINGER_ATTRIBUTE_ID}
          /> </div>
        <div> <SongKeys
            songId={songData.songId}
            selectedSongKey={leadSongKey}
            setFunction={(data) => {updateKey(data)}}
          /></div>
      </div>
      {
        typeof(songErrorMessages) !== 'undefined' && songErrorMessages.length > 0 && 
        <Alert type="error" context="inline" message={songErrorMessages[0].error.userMessage} />
      }
      <div className="grouped-row span-all">
        <div>
          <PreviouslyPlayed eventsPlayed={songData.eventsPlayed}/>
        </div>
      </div>
    </div>
  )
}

export default ManageSongAssignment