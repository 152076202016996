import React from 'react'
import ViewAgenda from './components/ViewAgenda'
import '../print.scss'

const PrintAgenda = (props) => {
  const {scheduledEventId} = props.match.params
  return (
    scheduledEventId && 
      <ViewAgenda scheduledEventId={scheduledEventId} mode='print' />
  )
  
}

export default PrintAgenda