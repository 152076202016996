import React, { Fragment, useState } from 'react'
import {Link} from 'react-router-dom'
import dateManager from '@ministry-squads-common/date-manager'
import Alert from '../components/Alert'
import EventsGrid from './components/EventsGrid'
import useIsMountedRef from '../hooks/useIsMountedRef.js'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import { ManageEventProvider } from '../contexts/ManageEvent.Context'

import SecondaryContent from '../components/SecondaryContent'
import AdminOnly from '../routes/components/AdminOnly'
import ManageSongsByEvent from '../songs/components/ManageSongsByEvent'
import ManageNotes from '../notes/components/ManageNotes'
import DisplayDaysAndTimes from './components/DisplayDaysAndTimes'

import ManageAssignments from '../assignments/components/ManageAssignments'
import GetAssignmentData from '../assignments/components/GetAssignmentData'
import SubscriptionEmail from '../subscriptions/SubscriptionEmail'

import EditOneTimeEvent from './components/EditOneTimeEvent.js'

import DynamicBreadcrumb from './components/DynamicBreadcrumb.js'
import AttendeesForEvent from './components/AttendeesForEvent.js'
import ManageAttendees from './components/ManageAttendees.js'

import AgendaForEvent from './components/AgendaForEvent.js'
import NotesForEvent from './components/NotesForEvent.js'
import SongsForEvent from './components/SongsForEvent.js'
import SermonsForEvent from './components/SermonsForEvent.js'
import Assignments from './components/AssignmentsForEvent.js'

import { AssignmentsProvider } from '../assignments/context'
import { EventProvider,useEventState,getEventDetails } from '../contexts/Event.Context'

const EventDetail = (props) => {
    const {scheduledEventId} = props.match.params

    return (
        <>
            <EventProvider>
                <OutputInProvider scheduledEventId={scheduledEventId} />
            </EventProvider>
        </>
    )
}

const OutputInProvider = ({scheduledEventId}) => {
    
    const [eventState, eventDispatch] = useEventState()
    const agenda = eventState.agenda
    const assignments = eventState.assignments
    const assignmentsByTeam = eventState.assignmentsByTeam
    const attendees = eventState.attendees
    const songs = eventState.songs
    const activeEvents = eventState.activeEvents
    const cancelledEvents = eventState.cancelledEvents
    const allEvents = eventState.allEvents
    const members = eventState.members
    const notes = eventState.notes
    const sermons = eventState.sermons
    const teams = eventState.teams
    const isWorshipEvent = eventState.isWorshipEvent
    const isSeries = eventState.isSeries
    const isGroup = eventState.isGroup
    const displayName = eventState.displayName    
   
    const [globalErrorMessage,setGlobalErrorMessage] = useState('')
    const [isEventDetailLoading, setIsEventDetailLoading] = useState(false)
    const [isAgendaLoading, setIsAgendaLoading] = useState(true)

    //const [agenda,setAgenda] = useState()
    const isMounted = useIsMountedRef()

    const [selectedTeam,setSelectedTeam] = useState()

    const MANAGE_SONGS_TITLE = 'Manage songs'
    const MANAGE_ASSIGNMENTS_TITLE = 'Manage assignments'
    const NOTIFICATIONS_TITLE = 'Send notifications'
    const MANAGE_NOTES_TITLE = 'Manage event notes'
    const MANAGE_ATTENDEES_TITLE = 'Manage attendees'
    const MANAGE_EVENT_TITLE = 'Manage event'

    const [secondaryContentTitle,setSecondaryContentTitle] = useState()
    const [flyoutState, flyoutDispatch] = useSecondaryContentState()

    const [savedOneTimeData,setSavedOneTimeData] = useState(null)

    const onOneTimeSave = (data) => {
        setSavedOneTimeData(data)
        toggleManageEventSlider()
        onSliderClose()
    }

    const toggleSlider = () => {
        flyoutDispatch({ type: 'toggleIsOpen', payload: null })
    }
    const toggleSongSlider = () => {
        setSecondaryContentTitle(MANAGE_SONGS_TITLE)
        toggleSlider()
    }

    const toggleAssignmentsSlider = (team) => {
        if(!team || !team.teamId) throw new Error()
        setSelectedTeam(team)
        setSecondaryContentTitle(MANAGE_ASSIGNMENTS_TITLE)
        toggleSlider()
    }

    const toggleNotificationSlider = () => {
        setSecondaryContentTitle(NOTIFICATIONS_TITLE)
        toggleSlider()
    }

    const toggleNotesSlider = () => {
        setSecondaryContentTitle(MANAGE_NOTES_TITLE)
        toggleSlider()
    }

    const toggleAttendeesSlider = () => {
        setSecondaryContentTitle(MANAGE_ATTENDEES_TITLE)
        toggleSlider()
    }

    const toggleManageEventSlider = () => {
        setSecondaryContentTitle(MANAGE_EVENT_TITLE)
        toggleSlider()
    }

    const onRefresh = () => {
        getEventDetails(scheduledEventId,eventDispatch)
    }
    
    const onSliderClose = () => {
        setIsAgendaLoading(true)
        setSavedOneTimeData(null)
        onRefresh(true)

    }

    const getOneTimeEventData = (passedData) => {
        return {
            ...passedData,
            "startTime": dateManager.formatDate(passedData.startDateTime,"TIMESTAMP_TIME_ONLY"),
            "endTime": dateManager.formatDate(passedData.endDateTime,"TIMESTAMP_TIME_ONLY"),
        }
    }

    return (
        <section className="page">
            <header>
                
                <DynamicBreadcrumb />
               
                {
                isEventDetailLoading === false && 
                <>
                 <h1>{displayName}</h1>
                {
                activeEvents && activeEvents.length > 0 &&
                <Fragment>
                    
                    <AdminOnly> 
                        <div className="feature-manager">
                            {
                                teams && teams.length > 0 && 
                                    <button className="btn btn-icon btn-add" onClick={() => {toggleNotificationSlider()}}>
                                        Send notifications
                                    </button>
                            }

                            {
                                isGroup === false && 
                                <button className="btn btn-icon btn-calendar" onClick={() => {toggleManageEventSlider()}}>
                                    Manage event
                                </button>
                            }
                            
                            {
                                isSeries === true && isGroup === false &&
                                <button className="btn btn-icon btn-edit" onClick={() => window.location.href = `/event/edit/${eventState.activeEvents[0].eventSeriesData.eventSeriesId}/${eventState.activeEvents[0].scheduledEventId}`}>
                                    Manage series
                                </button>
                            }

                            {
                                isSeries === false && isGroup === true &&
                                <button className="btn btn-icon btn-edit" to=''>
                                    Manage group
                                </button>
                            }

                        </div>  
                    </AdminOnly>
                    {
                    allEvents &&
                    <div className="after-heading">
                        <DisplayDaysAndTimes events={allEvents} />
                    </div>
                    }
                    
                    <Alert type='error' message={globalErrorMessage} />

                    {
                        savedOneTimeData && savedOneTimeData.resultMessage &&
                        <Alert type="success" message={savedOneTimeData.resultMessage} />
                    }
                    
                    
                </Fragment>
                }
                
                
                {
                    !activeEvents && 
                    <>
                        <h1>Event details</h1>
                        <Alert type='error' message={globalErrorMessage} />
                    </>
                }

                {
                    activeEvents && activeEvents.length === 0 && cancelledEvents && cancelledEvents.length > 0 &&
                    <>
                        <div className="after-heading">
                            <Alert type="warning" message={`All events have been cancelled.`} />
                        
                            <DisplayDaysAndTimes events={allEvents} />
                        </div>
            

                    </>
                }

                </>
                }
            </header>
            
            {
                isEventDetailLoading === false && activeEvents && activeEvents.length > 0 && 
                <>
                
                {
                    isWorshipEvent === false &&
                    <AttendeesForEvent attendees={attendees} manageAttendees={toggleAttendeesSlider} />
                }

                {
                    isWorshipEvent === true && 
                        <div className="row match-height">
                            <div className="col-md-3">
                                <div className="card">
                                    <SongsForEvent songs={songs} manageSongs={toggleSongSlider}/>
                                </div>
                            </div>
                            <div className="col-md-3">   
                                <div className="card">
                                    <SermonsForEvent sermons={sermons} />    
                                </div>
                            </div>
                            <div className="col-md-3">                        
                                    <NotesForEvent notes={notes} manageNotes={toggleNotesSlider} />
                            </div>
                            <div className="col-md-3">   
                                {
                                    agenda &&
                                    <AgendaForEvent agenda={agenda} scheduledEventId={scheduledEventId} />
                                }
                            </div>
                            
                        </div>
                }
                
                {
                    teams && teams.length > 0 &&
                        <div className="card">
                            <h2>Assignments</h2>
                            
                            <EventsGrid events={activeEvents} multi={true}>
                                <Assignments 
                                    manageAssignments={toggleAssignmentsSlider}
                                    memberList={members}
                                    events={activeEvents} 
                                    assignmentsByTeam={assignmentsByTeam} />
                            </EventsGrid>
                        </div>
                }
                </>
                
            }
            
            {
                isEventDetailLoading === false && isAgendaLoading === false && !activeEvents &&
                <p>No details available for the event provided</p>
            }

            
            <SecondaryContent heading={secondaryContentTitle} onClose={() => onSliderClose()}>

                {
                    secondaryContentTitle === MANAGE_SONGS_TITLE && songs && songs.length > 0 && activeEvents && activeEvents.length > 0 &&
                    <ManageSongsByEvent 
                        songs={songs} 
                        eventIds={activeEvents.map(event => event.scheduledEventId)} 
                        onSave={() => onSliderClose()} 
                        unique={flyoutState.counterKey} />
                }    

                {
                    
                    secondaryContentTitle === MANAGE_ASSIGNMENTS_TITLE && 
                    <AssignmentsProvider 
                        initialMode="edit-only"
                        initialStartDate={allEvents[0].startDate}
                        initialEndDate={allEvents[allEvents.length-1].startDate}>
                            
                         <h3><label>Team:</label> {selectedTeam.teamName}</h3>
                        <GetAssignmentData 
                            initialTeam={selectedTeam}
                        />
                        <ManageAssignments 
                            displayMode='as-grid' 
                            unique={flyoutState.counterKey}
                        />
                    </AssignmentsProvider>
                    
                }      

                {
                    secondaryContentTitle === NOTIFICATIONS_TITLE &&
                    <SubscriptionEmail 
                        subscriptionCode="EVENT_UPDATED"
                        assignedUsers={assignments} 
                        userData={members} 
                        teams={teams} 
                        eventData={activeEvents}
                        unique={flyoutState.counterKey}
                    />
                }
                 
                 {
                    secondaryContentTitle === MANAGE_NOTES_TITLE &&
                    <ManageNotes 
                        notes={notes} 
                        associatedToData={activeEvents} 
                        associatedToType='scheduled_event'
                        onSave={() => onSliderClose()} 
                        unique={flyoutState.counterKey}
                    />
                }

                {
                    secondaryContentTitle === MANAGE_ATTENDEES_TITLE &&
                    <ManageAttendees attendees={attendees} eventIds={allEvents.map(evt => evt.scheduledEventId)}/>
                    
                }

                {
                    secondaryContentTitle === MANAGE_EVENT_TITLE && 
                    <ManageEventProvider existingData={getOneTimeEventData(eventState.activeEvents[0])}>
                        <EditOneTimeEvent onSave={(result) => onOneTimeSave(result)}/>
                    </ManageEventProvider>
                }

              </SecondaryContent>

        </section>
    )
}

export default EventDetail