import React from 'react'

const MemberStatusIndicator = ({memberData}) => {
  const INACTIVE_STATUS_TXT = "inactive"  
  return (
    <>
   { memberData && memberData.status === INACTIVE_STATUS_TXT && 
      <span className="badge badge-pill badge-warning" title="This user is not available for assignments and will not receive notifications.">Inactive</span>
    
    }
  {
    !memberData && 
    <></>
  }
    </>
)
}

export default MemberStatusIndicator