import React, {useEffect}  from "react";
import { Route, Redirect } from "react-router-dom";
import sessionManager from '../../lib/session'
import Footer from '../../components/Footer'
import pageViewTracking from '../../lib/analytics'

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function UnauthenticatedRoute({ component: C, appProps, ...rest }) {
  const redirect = querystring("redirect");
  const session = sessionManager.getSession()
 

  useEffect(() => {
    pageViewTracking()
  },[rest.path])

  
  return (
    <Route
      {...rest}
      render={props =>
        session.accessToken === null
          ? 
            <>
            <main className="container-fluid">
                 <C {...props} {...appProps} />
                </main>

                <Footer />
            </>
          
          : <Redirect
              to={redirect === "" || redirect === null ? "/" : redirect}
            />}
    />
  );
}
