import React from 'react'
import { Link } from 'react-router-dom'
import MemberAssignments from './components/MemberAssignments'

const MemberSchedule = () => {
    
    return (
        <section className="page page-slim">
          <header>
            <div className="breadcrumbs">
              <Link to="/home">Home</Link>
            </div>
            <h1>Your schedule</h1>
          </header>
          <div className="card">
            <MemberAssignments limit={false} />
          </div>
        </section>
    )
}

export default MemberSchedule