import React, {Fragment} from 'react'
import sessionManager from '../../lib/session'
const PendingStatus = (props) => {
  const session = sessionManager.getSession()
  return (
    <Fragment>
      {
       session.userData && session.userData.status === 'pending' && 
       <Fragment>{ props.children }</Fragment>
      }
    </Fragment>
  )
}

export default PendingStatus