import React, {useEffect,useState,useRef} from 'react'
import {intersection} from '../lib/index'
import { useTypeAheadState } from '../contexts/TypeAhead.Context'


const TypeAheadSearch = ({htmlId,resultItemText,addItemFunction}) => {
  const [oneFieldSearch,setOneFieldSearch] = useState('')
  const [showResultList,setShowResultList] = useState(false)
  const searchField = useRef(null);

  const [typeAheadState, typeAheadDispatch] = useTypeAheadState()


  const onItemAdded = (browserEvent) => {
    browserEvent.preventDefault()
    addItemFunction()
    typeAheadDispatch({"type": "setSearchMatches", "payload": []})
    setOneFieldSearch('')
  }

  const selectResult = (browserEvent,userData) => {
    browserEvent.preventDefault()
    setOneFieldSearch('')
    setShowResultList(false)
    typeAheadDispatch({"type": "addItem", "payload": userData})

  }

  const isEmpty = (val) => {
    if(val === null) {
      return true
    } else if(typeof val === 'undefined') {
      return true
    } else if(val ==='') {
      return true
    } else {
      return false
    }
  }

  const resultsRef = useRef()

  const handleClickOutside = e => {
    if (resultsRef.current && searchField.current && !resultsRef.current.contains(e.target) && !searchField.current.contains(e.target)) {
        setShowResultList(false)
    }
  }


  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
  })

  useEffect(() => {
    const match = (needle,haystack,attribute) => {
      const upper = needle.toUpperCase()
      const match = haystack[attribute]
      if(match) {
        return match.toUpperCase().indexOf(upper) > -1
      } else {
        return false
      }
      
    }

    const getMatches = (searchWithin,searchFor) => {
      if(searchFor !== '') {
        const matches = []
        searchWithin.forEach(itemToCheck => {
          for(let i=0;i<typeAheadState.attributesToSearch.length;i++) {
            if(match(searchFor,itemToCheck,typeAheadState.attributesToSearch[i]) === true) {
              matches.push(itemToCheck)
              break
            }
          }
        })
        
        return matches
      } else {
        return []
      }
    }

    if(isEmpty(oneFieldSearch) === false ) {
      const words = oneFieldSearch.split(' ').filter(match => match !== '')
     
      const matchesPerWord = words.map((word) => {
        return getMatches(typeAheadState.allItems,word)
      })

      const allMatches = (matchesPerWord.length === 1) ? matchesPerWord 
      : matchesPerWord.map((arrayOfUsers,wordCounter) => {
        
        // all matches should only include matches that exist for every word
        // so, we only want user IDs that appear in every row of matchesPerWord
        if(wordCounter < matchesPerWord.length - 1) {
          return intersection(arrayOfUsers,matchesPerWord[wordCounter+1],typeAheadState.itemKey,typeAheadState.itemKey)
        } else {
          return []
        }
      })
      typeAheadDispatch({"type": "setSearchMatches", "payload": allMatches.flat()})


    } else {
      
      typeAheadDispatch({"type": "setSearchMatches", "payload": []})
    }
  },[oneFieldSearch])
  
  const handleSearchInput = (value) => {
    setOneFieldSearch(value)
    setShowResultList(true)

    const parentNode = document.getElementById(htmlId).parentNode
    const parentHeight = parentNode.clientHeight

    if(document.getElementById(`${htmlId}Matches`)) {
      document.getElementById(`${htmlId}Matches`).style.top = parentHeight + "px"
    }

    if(document.getElementById(`${htmlId}NoMatches`)) {
      document.getElementById(`${htmlId}NoMatches`).style.top = parentHeight + "px"
    }
    
    

  }

  const handleFocus = () => {
    typeAheadDispatch({"type":"setSearchMatches","payload": typeAheadState.allItems})
    setShowResultList(true)
  }

  return (
    <>
    {
    typeAheadState.allItems && 
    <>
      <input 
        id={htmlId}
        className="type-ahead-search"
        data-testid={`input-${htmlId}`}
        ref={searchField}
        value={ oneFieldSearch }
        onChange={(event) => {handleSearchInput(event.target.value,searchField)}}
        onFocus={() => handleFocus()}
      />

      {
        showResultList === true && typeAheadState.searchMatches &&
        <ul className="type-ahead-matches" ref={resultsRef} id={`${htmlId}Matches`}>
          {
            typeAheadState.searchMatches.map(match => 
              <li key={`usermatch${match[typeAheadState.itemKey]}`}><button className="btn btn-link btn-slim" onClick={(e) => {selectResult(e,match)}}>{ resultItemText(match) }</button></li>  
            )
          }
        </ul>
      }

      {
        typeAheadState.searchMatches && typeAheadState.searchMatches.length === 0 && isEmpty(oneFieldSearch) === false &&
        <ul className="type-ahead-matches no-matches" id={`${htmlId}NoMatches`}>
          <li>
            No matches. 
            {
              addItemFunction &&
              <>&nbsp;<button className="btn btn-link btn-slim" onClick={(e) => onItemAdded(e) }>Add a new person.</button></>
            }
          </li>
        </ul>
      }
    </>
    }
    </>
  )
}
 
export default TypeAheadSearch