import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import SetPassword from '../components/formFields/SetPassword'
import Alert from '../components/Alert'
import SubmitButton from '../components/SubmitButton'
import {post} from '../lib/call-api'

const ChangePassword = () => {
  const [ currentPassword, setCurrentPassword ] = useState('')
  const [ newPassword, setNewPassword ] = useState('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ complete, setComplete ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState()

  const passwordIsValid = () => {
    if(currentPassword === '') return false
    return true
  }

  const formIsValid = () => {
    if(passwordIsValid() === true) {
      return true
    } else {
      setErrorMessage('Correct the errors below')
      return false
    }
  }

  const onPost = (resp) => {
    setIsLoading(false)  
    setComplete(true)
  }
 
  const handleSubmit = async event => {
    event.preventDefault()
    const postBody = {
      oldPassword: currentPassword,
      newPassword: newPassword
    }
    const authPath = process.env.REACT_APP_AUTH_URI || ""

    post(`${authPath}/password/change`,onPost,setErrorMessage,formIsValid,postBody)

  }

  return (
    <form onSubmit={handleSubmit}>
      <section id="profile-page" className="page page-slim">
        <header>
          <div className="breadcrumbs">
            <Link to="/profile">Your Profile</Link>
          </div>
          <h1>Change your password</h1>  
        </header>

        {
          complete === false && 
          <>
          <div className="card">
            <Alert type='error' message={errorMessage} />

            <SetPassword setValue={setCurrentPassword} context='CHANGE_PASSWORD_CURRENT'/>

            <SetPassword setValue={setNewPassword} context='CHANGE_PASSWORD_NEW'/>
            
          </div>

          <div className="buttonContainer">
            <SubmitButton actionTextOverride='Change Password' isLoading={isLoading} />  
          </div>
        </>
        }
                
        {
          complete === true && 
          <div className="card">
            <p>Success</p>

            <p><Link to="/profile">Back to your profile</Link></p>
          </div>
        }
        
      </section>
    </form>
  )
}

export default ChangePassword