import React from 'react'
import UserAvatar from './UserAvatar'
import ToggleButton from './ToggleButton'

const UserSelector = ({teamMember,handleClick,isSelected,isEnabled,extraContent, isSmall,ifDisabledMessage}) => {

  return (
    <ToggleButton 
      uniqueID={teamMember.userId} 
      isSelected={isSelected} 
      handleClick={() => handleClick(teamMember)} 
      isEnabled={isEnabled} 
      isSmall={isSmall} 
      ifDisabledMessage={ifDisabledMessage}
      extraClass='user-name-with-avatar'>
    
      
      <UserAvatar userData={teamMember} />
      
      <div className="avatar-user-name">
        {teamMember.firstName} {teamMember.lastName} 
      </div>

      {
        extraContent && extraContent !== '' && 
        <div className='pct-assigned'> {extraContent} </div>
      }
    </ToggleButton>
  )
  
}

export default UserSelector