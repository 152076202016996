import React, {useEffect, useState} from 'react'

import DisplayAssignments from './DisplayAssignments'
import AssignMembers from './AssignMembers'
import {useAssignmentsState } from '../context'
import {scrollToTop,intersection} from '../../lib/index'
import '../styles.scss'
import Alert from '../../components/Alert'
import {post} from '../../lib/call-api'
import sessionManager from '../../lib/session'
import SubmitButton from '../../components/SubmitButton'
/**
 * 
 * @param {string} displayMode "as-grid" or "as-table"
 * @param {string} initialMode "edit" or "edit-only"
 * @param {integer} unique  used to determine if we should reset the ui state 
 *                          to the value of initial mode. needed when using this component is presented within secondary content
 *                          without it, if the user saves an assignment, then goes to edit another assignment, the UI state
 *                          will still be in a "saved" state
 * @returns 
 */
const ManageAssignments = ({displayMode = null, unique = null}) => {
  
  const [state,dispatch] = useAssignmentsState()
  const initialMode = state.uiConfig.mode
  const [ isLoading, setIsLoading ] = useState(true)
  const [globalErrorMessage, setGlobalErrorMessage] = useState()  

  useEffect(() => {
    if(unique !== null) setMode('edit-only')  
  },[unique])


  const setMode = (mode) => {
    if(mode ==='saved') scrollToTop()
    dispatch({"type": "setMode", "payload": mode})
  }

  const saveAssignments = (e) => {
    e.preventDefault()
    setIsLoading(true)

    const onPost = () => {
      setMode('saved')
      setIsLoading(false)
    }

    const onPostError = (message) => {
        setIsLoading(false)
        setGlobalErrorMessage(message)
    }

    const validate = () => {
      return true
    }

    const sessionData = sessionManager.getSession()

    /*
      state.assignmentsInRange holds ALL assignments across all teams
      the api only expects the assignments for a specific team, so only submit
      the assignments that correspond to the team that is currently in context
    */
    let currentTeamOnlyAssignments

    if(state.team.assignBy === 'member') {
      currentTeamOnlyAssignments = intersection(state.assignmentsInRange, state.team.roles, "roleId", "roleId") 
    } else {
      currentTeamOnlyAssignments = intersection(state.assignmentsInRange, state.team.subTeams, "assigneeId", "teamId") 
    }

    
    post(
      `/api/assignments/team`,
      onPost,
      onPostError, 
      validate,
      {
        "orgId": sessionData.orgData.orgId,
        "teamId": state.team.teamId,
        "assignments": currentTeamOnlyAssignments,
        "startDate": state.events[0].startDate,
        "endDate": state.events[state.events.length-1].startDate
      } 
    )
  }

  return (
    state.events && state.eventsByWeek && state.team && state.allAssignments && state.members && state.uiConfig.mode &&
    <>
    {
      (state.uiConfig.mode === 'edit' || state.uiConfig.mode === 'edit-only') && 
      <form onSubmit={(e) => saveAssignments(e)}>
        <AssignMembers 
          errorMessageFromSave={globalErrorMessage} 
          displayMode={displayMode}
          editMode={initialMode}
        />
        <div className="button-container">
          <SubmitButton actionTextOverride="Save Assignments" />
        </div>
      </form>
    }
    {
      state.uiConfig.mode === "saved" && 
        <>
          <Alert type="success" message="Your updates have been saved!" />
          
          <section>
            <DisplayAssignments 
              isEditMode={false}
              showGrouped={true}
            />
          </section>
        </>
    }
    </>
  )
}

export default ManageAssignments