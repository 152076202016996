import sessionManager from './session'

const API_PATH = process.env.REACT_APP_API_URI || ""

const authHeader = () => {
  const accessToken = sessionManager.getToken()
  return (accessToken) ? { 'authorization': `BEARER ${accessToken}` } : {}
}

const handleErrors = async (responseBody) => { 
  if(responseBody.status === 401) {
    throw new Error(401)
  } else if(responseBody.status === 403) {
    sessionManager.clearSession()
  } else {
    // "Bulk responses" are when we are updating more than one item in a 
    // single API call. An example is assigning songs to an event on song list.
    // For Bulk responses, there is additional data passed along that describes the
    // error for each item, so just passing a single message isn't good enough 
    const isBulkResponse = typeof(responseBody.unsuccessful) !== "undefined"
    if(isBulkResponse === true) {
      throw responseBody
    } else{
      throw responseBody.message
    }
  } 
}

const put = (path,body) => {
  return putOrPost('put',path,body)
}

const post = (path,body) => {
  return putOrPost('post',path,body)
}

const putOrPost = (verb,path,body) => {
  return new Promise((resolve,reject) => {
    fetch(`${API_PATH}${path}`, {
      "method": verb,
      "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json",
        ...authHeader()
      },
      "body": JSON.stringify(body)
    }).then(async(response) => {
      const responseBody = await response.json()
      if(response.ok === true) {
        resolve(responseBody)
      } else {
        reject(await handleErrors(responseBody))
      }
    }).catch(e => {
      reject(e)
    })
  })
}

const httpDelete = (path,body) => {
  return new Promise((resolve,reject) => {
    fetch(`${API_PATH}${path}`, {
      method: 'delete',
      "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json",
        ...authHeader()
      },
      "body": (body) ? JSON.stringify(body) : null
    }).then(async(response) => {
      const responseBody = await response.json()
      if(response.ok === true) {
        resolve(responseBody)
      } else {
        reject(await handleErrors(responseBody))
      }
    }).catch(e => {
      reject(e)
    })
  })
}


const get = (path) => {
  return new Promise((resolve,reject) => {
    fetch(`${API_PATH}${path}`, {
      method: 'get',
      "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json",
        ...authHeader()
      }
    }).then(async(response) => {
      const responseBody = await response.json()
      if(response.ok === true) {
        resolve(responseBody)
      } else {
        reject(await handleErrors(responseBody))
      }
    }).catch(e => {
      reject(e)
    })
  })
}

export {post,put,httpDelete,get}