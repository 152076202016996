import React, {useEffect,useState} from 'react'
import SaveMember from './components/SaveMember'
import MemberTable from './components/MemberTable'
import SecondaryContent from '../components/SecondaryContent'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import Alert from '../components/Alert' 
import AdminOnly from '../routes/components/AdminOnly'
import {get} from '../lib/call-api'
import useIsMountedRef from '../hooks/useIsMountedRef'

const Members = () => {
  const [memberList, setMemberList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  
  const [refresh, setRefresh] = useState(false)
  const [showHiddenContent, setShowHiddenContent] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [savedMember, setSavedMember] = useState({})

  const [, modalDispatch] = useSecondaryContentState()

  const isMounted = useIsMountedRef()

  const toggleSlider = () => {
    modalDispatch({ type: 'toggleIsOpen', payload: null })
  }

  const updateOnAdd = (data, message) => {
    setRefresh(true)
    setShowHiddenContent(false)
    modalDispatch({"type": "toggleIsOpen"})
    setSuccessMessage(message)
    setSavedMember(data)
  }
    
  useEffect(() => {
    const onGet = (resp) => {
      if(isMounted.current === true) {
        setMemberList(resp.memberList)
        setIsLoading(false)
      }
      
    }

    get(`/api/org/members`,onGet,setErrorMessage)

    return () => isMounted.current = false
  }, [refresh,isMounted])
  
  return (
    <section className="page">
      <header>
        <h1>Members</h1>

        { errorMessage !== '' && (
            <Alert type="error" message={errorMessage} />
        )}
        <AdminOnly>
          <div className="feature-manager">
              <button className="btn btn-icon btn-add" onClick={toggleSlider}>
                Add a member
              </button>
          </div>  
        </AdminOnly>
      </header>

      <AdminOnly>
      <SecondaryContent onClose={setShowHiddenContent} show={showHiddenContent}>
        <SaveMember onSuccess={updateOnAdd}/>
      </SecondaryContent>
      { successMessage !== '' && (
            <Alert type="success" message={successMessage} />
        )}
        </AdminOnly>
        
      <div>
      {
        isLoading === false && memberList && memberList.length > 0 && 
        <MemberTable members={memberList} savedMember={savedMember}  />
      }
      </div>
    </section>
  )
}

export default Members