import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import NoAccess from './NoAccess'
import NavigationBar from '../../components/NavigationBar'
import Footer from '../../components/Footer'
import sessionManager from '../../lib/session'
import pageViewTracking from '../../lib/analytics'
import { setPreviousPath } from '../../lib/index'

export default function AuthenticatedRoute({ component: C, accessLevel, layout = "full", ...rest }) {
  const [isLoading,setIsLoading] = useState(true)
  const [isAuthenticated,setIsAuthenticated] = useState(null)
  const [isAuthorized,setIsAuthorized] = useState(null)

  useEffect(() => {
    //console.log(`${new Date()}: path changed to ${rest.path}`)
    setIsLoading(true)
    const sessionData = sessionManager.getSession()
    const accessToken = sessionData.accessToken
    const tokenIsValid = (accessToken !== null && typeof(accessToken) !== "undefined")

    const checkAuthorization = (userData) => {
      if(userData === null || typeof(userData) === "undefined") {
        setIsAuthorized(false)
      } else if(accessLevel === 'active' && userData.status !== 'active') {
        setIsAuthorized(false)
      } else {
        setIsAuthorized(true)
      }
    }
    
    pageViewTracking()

    setPreviousPath()

    sessionManager.refreshSession(true).then(() => {
        checkAuthorization(sessionData.userData)
        setIsAuthenticated(tokenIsValid)
        setIsLoading(false)
      }).catch(e => {
        sessionManager.clearSession()
      })
    
  },[rest.path,accessLevel])

  const getClassName = (layoutType) => {
    return (layoutType === 'popup') ?
    "popup-container"
    : 
    "container-fluid"

    
  }
  
  window.document.addEventListener("visibilitychange", () => {
    sessionManager.checkSession()
  });
  
  return (
     isLoading === false &&
      <Route
        {...rest}
        render={props => {
          if(isAuthenticated === true && isAuthorized === true) {
            return (
                <>
                {
                  layout === 'full' && 
                  <NavigationBar />
                }
                  
                  <main className={getClassName(layout)}>
                    <C {...props}  />
                  </main>
                {
                  layout === 'full' && 
                  <Footer />
                }
                 
                </>
            )
          } else if(isAuthenticated === true && isAuthorized === false) {
           
            return (
              <NoAccess />
            )
          } else {
            return (<Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />)
          }
        }
      }  
    />
  )
}
