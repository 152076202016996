import React from 'react'

// unstructuredReference is used when the user has entered a passage, but it hasn't been validated via
// an api yet. currently only used on Sermon Series management.

const BiblePassageLink = ({book, chapter, verse, version='ESV', showNoResultText=true, unStructuredReference = null}) => {
  const baseUrl = `https://www.biblegateway.com/passage/?search=`
  if(unStructuredReference.biblePassage) {
    return (
      <a key={`verse${unStructuredReference.biblePassageId}`} href={`${baseUrl}${unStructuredReference.biblePassage}`} target="_new">{ unStructuredReference.biblePassage }</a>
    )
  } else if(book && chapter && book !== '' && chapter !== '') {
    return (
      <a key={`verse${book}+${chapter}+${verse}`} href={`${baseUrl}${book}+${chapter}:+${verse}&version=${version}`} target="_new">{ book } {chapter}: {verse}</a>
    )
  } else if(showNoResultText === true) {
    return <span className="no-results">No bible passage yet</span>
  } else {
    return ''
  }
  
}
export default BiblePassageLink