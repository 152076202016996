import dateManager from '@ministry-squads-common/date-manager'
import {post} from '../lib/call-api'
import {outputSeperated} from '../lib/index'

export const getDisplayDate = (eventList) => {
  if(eventList && eventList.length > 0) {  
      let dateString = ''
      if(eventList.length === 1)  {
        dateString = dateManager.formatDate(eventList[0].startDateTime,'TEXT_SHORT')
      } else {
        dateString = dateManager.dateRange(eventList[0].startDateTime,eventList[eventList.length-1].startDateTime,'TEXT_SHORT')
      }
  
      return dateString
  } else {
      // this doesn't seem to be hit, but if we remove the else statement
      // we get errors in the UI 
      return ''
  }
  
}

export const getEventDisplayTimes = (eventList) => {
  if(eventList && eventList.length > 0) {  
    return outputSeperated(eventList.map(evt => dateManager.formatDate(evt.startDateTime,'DISPLAY_TIME_ONLY')))
     
  } else {
      // this doesn't seem to be hit, but if we remove the else statement
      // we get errors in the UI 
      return ''
  }
  
}

export const getDateOnly = (dateTime) => {
  return dateManager.formatDate(dateManager.setToMidnight(dateTime),'URL_PARAMETER')
}

export const getEventDisplayName = (eventData) => (eventData.eventGroupName !== null && typeof(eventData.eventGroupName) !== "undefined") ? eventData.eventGroupName : eventData.eventName

/**
* goToEventDetail
* will link to event detail.
* if the event that is passed in is not yet "scheduled", then we will fire off a request
* to create it as a scheduled event, then use the created scheduled event ID in the 
* event detail URL
* @param {*} eventsData 
*/
export const goToEventDetail = (eventsData) => {
     if(eventsData[0].isScheduled === false) {
      window.location.href = `/event/add-to-calendar/${eventsData[0].scheduledEventId}`
   } else {
       window.location.href = `/event/detail/${eventsData[0].scheduledEventId}`
   }
}


export const getEventDataForGroup = (event,allEvents) => {
  return allEvents.filter(eventToFind => event.eventIds.includes(eventToFind.scheduledEventId))
}