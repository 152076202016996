import React from 'react'

import AdminOnly from '../../routes/components/AdminOnly'

import dateManager from '@ministry-squads-common/date-manager'


const NotesForEvent = ({notes,manageNotes}) => {
    return (
        <div className="card">
            <h2>Notes</h2>
            {
                notes && notes.length === 0 &&
                <p className="no-results">No notes for this event.</p>
            }
            {
                notes && notes.length > 0 &&
                <div id="eventNotes">{
                notes.map(note => 
                    <div className="note" key={`note${note.noteId}`}>
                        <p>{note.noteText}</p>
                        <p className="added-by">
                            <>
                            {
                                note.modifiedOn && 
                                <>Modified by {note.modifiedBy} on { dateManager.formatDate(note.modifiedOn,'TEXT_TIMESTAMP') }</>
                            }
                            {
                                !note.modifiedOn && 
                                <>Added by {note.createdBy} on { dateManager.formatDate(note.createdOn,'TEXT_TIMESTAMP') }</>
                            }
                            
                            </>
                        </p>
                    </div>
                )}
                </div>
            }
            <AdminOnly>
                <button className="btn btn-link btn-slim" onClick={() => {manageNotes()}}>
                    Manage notes
                </button>
            </AdminOnly>
        </div>
    )
}

export default NotesForEvent