import React, { useEffect, useState } from "react"
import {moveItem, removeItem} from '../lib/index'
/**
 * 
 * @param {currentIndex} integer represents the sections location in the list of all sections
 * @param {on...Click} function what to do when the corresponding link is clicked
 
 * @returns HTML
 */
const SectionActions = ({currentIndex,onEditClick,onRemoveClick=null,setFunction,keyName,valueToFind,list,compact = false}) => {

  const [additionalCssClass,setAdditionalCssClass] = useState('')
  const [editText,setEditText] = useState('Edit')
  const [deleteText,setDeleteText] = useState('Remove')
  const [moveUpText,setMoveUpText] = useState('Move up')
  const [moveDownText,setMoveDownText] = useState('Move down')
  useEffect(() => {
    if(compact === true) {
      setAdditionalCssClass('btn-icon-sol0')
      setEditText('')
      setDeleteText('')
      setMoveUpText('')
      setMoveDownText('')
    }
   
  }, [compact,setAdditionalCssClass,setEditText,setDeleteText,setMoveUpText,setMoveDownText])

  const remove = (list,keyName,valueToFind,setFunction) => {
    if(onRemoveClick !== null) {
      onRemoveClick(list,keyName,valueToFind)
    }
    removeItem(list,keyName,valueToFind,setFunction)
  }
  return (
      <div className="section-actions">
          {
            onEditClick &&
            <button className={`btn btn-icon btn-edit ${additionalCssClass}`} onClick={(event) => {onEditClick(event)}} aria-label='edit'>{editText}</button>
          }
          <button className={`btn btn-icon btn-delete ${additionalCssClass}`} onClick={(event) => {event.preventDefault();remove(list,keyName,valueToFind,setFunction)}} aria-label='delete'>{deleteText}</button>
          {
              currentIndex > 0 && 
              <button className={`btn btn-icon btn-up ${additionalCssClass}`} onClick={(event) => {event.preventDefault();moveItem(list,keyName,valueToFind,-1,setFunction)}} aria-label='moveUp'>{moveUpText}</button>
          }
          {
              currentIndex <  list.length -1 &&
              <button className={`btn btn-icon btn-down ${additionalCssClass}`} onClick={(event) => {event.preventDefault();moveItem(list,keyName,valueToFind,1,setFunction)}} aria-label='moveDown'>{moveDownText}</button>
          }
      </div>
  )
}

export default SectionActions