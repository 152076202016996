import React from "react"
import './css/Alert.scss'
import classnames from 'classnames'

const Alert = (props) => {
  const {type,message,context} = props
  const additionalClass = (context && context === 'inline') ? 'inline' : 'alert-header'

  let cssClass = `alert ${additionalClass}`
  if(type === 'error') {
    cssClass += ' alert-danger'
  } else if(type === 'success') {
    cssClass += ' alert-success'
  } else if(type === 'warning') {
    cssClass += ' alert-warning'
  }
  
  return (
    <>
     { 
      ((typeof(message) !== 'undefined' && message !== '') || props.children) &&
        <div className={cssClass} role="alert">       
          <i className={classnames(
              'fas',
              { 'fa-exclamation-triangle': type === 'error' },
              { 'fa-check-square': type === 'success' },
              { 'fa-exclamation-triangle': type === 'warning' },
          )}></i>
          <div>
          {
            Array.isArray(message) === true && 
            message.map((aMessage,ctr) => 
              <p key={`key-message-${ctr}`}>{aMessage}</p>
              )
          }
          {
            Array.isArray(message) === false && 
              <p>{message}</p>
          }

          {
            props.children && 
            <div>{props.children}</div>
          }
          </div>
        </div>
      }
    </> 
  )
    
};

export default Alert