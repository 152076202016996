import React, {useEffect,useState} from 'react'
import sessionManager from '../../lib/session'
import classnames from 'classnames'
import {get} from '../../lib/call-api'
import useIsMountedRef from '../../hooks/useIsMountedRef'


const ManageMembership = ({memberData,handleChange,reset}) => {
  const [allTeams, setAllTeams] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const session = sessionManager.getSession()
 
  const [rolesToAdd, setRolesToAdd] = useState([])
  const [rolesToRemove, setRolesToRemove] = useState([])
  const [previouslyAssignedRoles,setPreviouslyAssignedRoles] = useState([])

  const [teamAdminData,setTeamAdminData] = useState()
  const isMounted = useIsMountedRef()

  useEffect(() => {
    setRolesToAdd([])
    setRolesToRemove([])
    setPreviouslyAssignedRoles([])
  }, [reset])

    
  useEffect(() => {
    const onGet = (resp) => {
      if(isMounted.current === true) {

        setAllTeams(resp.teamList)
        
        setTeamAdminData(() => 
          resp.teamList.map(team => {
            const userAlreadyAssigned = (!memberData || !memberData.teams || memberData.teams.length === 0) ? [] : memberData.teams.filter(memberTeam => memberTeam.teamId === team.teamId)
            return {
              "teamId": team.teamId,
              "isTeamAdminOrig": (userAlreadyAssigned.length > 0) ? userAlreadyAssigned[0].isTeamAdmin : false,
              "isTeamAdminNew": (userAlreadyAssigned.length > 0) ? userAlreadyAssigned[0].isTeamAdmin : false,
            }
          })
        )
        
        if(memberData && memberData.userId) {    
          setPreviouslyAssignedRoles(() => 
            memberData.teams.map(team => team.roles).flat().map(roleId => roleId)
          )
        }
        setIsLoading(false)
      }  
    }
    get(`/api/org/teams/${session.orgData.orgId}`,onGet,null)

    return () => isMounted.current = false
    
  }, [memberData,session.orgData.orgId,isMounted])

  useEffect(() => {
    handleChange(
      {
        assignToIds: rolesToAdd,
        removeFromIds: rolesToRemove,
        teamAdminData: teamAdminData
      }
    )
  },[rolesToAdd,rolesToRemove,teamAdminData,handleChange])

  const toggleRole = async (roleId) => {

    // user de-selected an option
    if(isSelectedRole(roleId) === true) {

      // if we previously needed to add it to the database, we don't any longer
      if(isRoleToAdd(roleId) === true) {
        setRolesToAdd(
          previous => previous.filter(previousRole => previousRole !== roleId)
        )
      }

      // if it was already in the database, add it to the list of ids to remove
      if(isExistingAssignment(roleId) === true) {
        setRolesToRemove(
          previous => [
            ...previous,
            roleId
          ])
      }
      
    } else {
      // user selected an option

      // remove it from the roles to remove
      setRolesToRemove(
        previous => previous.filter(previousRole => previousRole !== roleId)
      )

      // as long as its not in the databse already, add it to the list to be added to the database
      if(isExistingAssignment(roleId) === false) {
        setRolesToAdd(
          previous => [
            ...previous,
            roleId
          ])
      } 

    }
  }

  const isExistingAssignment = (roleId) => {
    return previouslyAssignedRoles.findIndex(role => role === roleId) > -1
  }

  const isRoleToAdd =  (roleId) => {
    return rolesToAdd.findIndex(role => role === roleId) > -1
  }

  const isRoleToRemove =  (roleId) => {
    return rolesToRemove.findIndex(role => role === roleId) > -1
  }

  const isSelectedRole = (roleId) => {
    return (isRoleToAdd(roleId) === true || (isExistingAssignment(roleId) === true && isRoleToRemove(roleId) === false))
  }

  const isTeamAdmin = (teamId) => {
    return teamAdminData && teamAdminData.filter(team => team.teamId === teamId && team.isTeamAdminNew === true).length > 0
  }

  const toggleTeamAdmin = (teamId) => {
    const newTeamAdmin = teamAdminData.map(team => {
      if(team.teamId === teamId) {
        return {
          "teamId": teamId,
          "isTeamAdminOrig": team.isTeamAdminOrig,
          "isTeamAdminNew": teamAdminData.filter(changeTeam => changeTeam.teamId === teamId)[0].isTeamAdminNew === false
        }
      } else {
        return team
      }
    })  
    setTeamAdminData(() => newTeamAdmin )
  }
  
  return (
    <section>
      {
        isLoading === true && 
        <p>Loading...</p>
      }
      {
        isLoading === false && allTeams.length === 0 &&
        <p>No teams!</p>
      }
      {
        isLoading === false && allTeams.length > 0 && 
        <>
        
        {
        allTeams.map(team => 
          <section key={'team' + team.teamId}>
            <h4>{team.teamName}</h4>
            <div>
              <h5>Team Admin?</h5>
              <p>Team admins can add and edit team members to a team</p>
              <div className="form-inline form-group">
                <input 
                  type="radio" 
                  name={`teamAdmin${team.teamId}`}
                  id={`teamAdmin${team.teamId}-1`}
                  value='true' 
                  onChange={() => toggleTeamAdmin(team.teamId)} 
                  checked={isTeamAdmin(team.teamId) === true} />

                <label htmlFor={`teamAdmin${team.teamId}-1`} className="label-right">
                 Yes
                </label>
              
                <input 
                  type="radio" 
                  name={`teamAdmin${team.teamId}`}
                  id={`teamAdmin${team.teamId}-0`}
                  value='false' 
                  onChange={() => toggleTeamAdmin(team.teamId)} 
                  checked={isTeamAdmin(team.teamId) === false} />

                <label htmlFor={`teamAdmin${team.teamId}-0`} className="label-right">
                 No
                </label>
              </div>
            </div>

            <h5>Select role(s)</h5>
            {
              team.roles.map(role => 
                <RoleSelector role={role} isSelected={isSelectedRole(role.roleId)} handleClick={toggleRole} key={'role' + role.roleId} />
              )
            }
          
          </section>
        )}
        </>
      }
    </section>
  )
}

const RoleSelector = ({role, isSelected, handleClick}) => {
  
  return (
    <div 
      className={
      classnames(
          'toggle-selector',
          { 
            'selected': isSelected === true 
          }
      )}
      onClick={() => {handleClick(role.roleId)}}>{role.roleName}</div>)
}

export default ManageMembership