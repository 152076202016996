import React, { useState,Fragment } from 'react'
import Alert from '../Alert'
import DateField from '../formFields/DateField'

const DateRange = ({defaultStartDate,defaultEndDate,setStartDate,setEndDate,allowPast = false}) => {
    const [errorMessage] = useState('')

    return (
        <Fragment>
            <Alert type='error' message={errorMessage} />
            <div className="date-inputs">
                
                <DateField 
                    label='Start Date' 
                    currentValue={defaultStartDate} 
                    setValue={setStartDate}
                    minDate={(allowPast === false) ? new Date() : null}
                    maxDate={defaultEndDate}
                />
                
                <DateField 
                    label='End Date' 
                    currentValue={defaultEndDate} 
                    setValue={setEndDate}
                    minDate={defaultStartDate || null}

                />
            </div>      
        </Fragment>
    )
}

export default DateRange