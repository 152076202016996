export const outputSeperated = (data) => {
  let seperator = " "
  if(data.length === 2) {
      seperator = ' & '
  } else if(data.length > 2) {
      seperator = ', '
  }
  return `${data.join(seperator)}`
}

export const getEventDetailsForWeek = (eventIds,allEventDetails) => {
  
  const matches = []
  
  eventIds.forEach(scheduledEventId => {
      const match = allEventDetails.filter(event => event.scheduledEventId === scheduledEventId)
      if(match.length === 1) matches.push(match[0])
  })
  return(matches)
}

export const consecutiveMatches = (days,attribute) => {
  const unique = []
  let countOfConsecutive = 1
  let add
  for(let dayCtr=0;dayCtr<days.length;dayCtr++) {
    for(let eventCtr=0;eventCtr<days[dayCtr].length;eventCtr++) {

        const thisEventAssignment = JSON.stringify(days[dayCtr][eventCtr][attribute])
        
        let nextEventAssignment 

        if(eventCtr < days[dayCtr].length-1) {
          // if there are more events on this day,
          // set the next event to the next event in the list for this day
          nextEventAssignment = JSON.stringify(days[dayCtr][eventCtr+1][attribute])

        } else if(eventCtr === days[dayCtr].length-1 && dayCtr < days.length - 1) {
          
          // if its the last event of the day and there are more days available, 
          // set the next event to the first event of the next day
          nextEventAssignment = JSON.stringify(days[dayCtr+1][0][attribute])
         
        } 

        // Determine if the next event assignment is identical
        // to the current assignment
        if(nextEventAssignment !== null) {
          if(thisEventAssignment === nextEventAssignment) {
            // next assignment is the same, so don't add yet
            countOfConsecutive = countOfConsecutive + 1
            add = false
          } else { 
            // next assignment is not the same, so add the current event
            add = true
          }
        } else {
          // if we don't have any more assignments, add this event to the array of unique assignments
          add = true
        }

        if(add === true) {
          let columnsSoFar = 0
          
          for(var z=0;z<unique.length;z++) {
            columnsSoFar = columnsSoFar + unique[z].spans
          }

          unique.push({
              "spans": countOfConsecutive,
              "columnStart": columnsSoFar+1,
              "data": days[dayCtr][eventCtr][attribute]
          })
          countOfConsecutive = 1
        }
    }}
  return unique
}

export const intersection = (arr1, arr2, property1, property2) => {
    
    const check = (it1, it2, prop1, prop2) => {
        const item1Value = it1[prop1]
        const item2Value = it2[prop2]
  
        if(item1Value && item2Value) {
            return item1Value === item2Value
        } else if(!item1Value && item2Value) {
            return it1 === item2Value
        } else if(item1Value && !item2Value) {
            return item1Value === it2
        } else {
            return it1 === it2
        }
    }

    return arr1.filter(item1 => {
        return arr2.some(item2 => check(item1, item2, property1, property2));
    });
  }

  export const updateArrayOfObjects = (primaryKey,existingData,newItem) => {
    if(!existingData || existingData.length === 0) {
      return [newItem]
    } else {
      const matches = existingData.filter(existingItem => existingItem[primaryKey] === newItem[primaryKey])
      if(matches.length === 1) {
          return existingData.map(existingItem => {
          if(existingItem[primaryKey] === newItem[primaryKey]) {
            return newItem
          } else { 
            return existingItem
          }
        })
      } else {
          existingData.push(newItem)
          return existingData
      }
    }
  }

/**
 * this function will add an item if its not already in existingData
 * and remove it if it is alreadyin existingData
 * @param {*} primaryKey 
 * @param {*} existingData 
 * @param {*} newItem 
 * @returns an array
 */
  export const toggleArrayOfObjects = (primaryKey,existingData,newItem) => {
    if(!existingData || existingData.length === 0) {
      return [newItem]
    } else {
      const matches = existingData.filter(existingItem => existingItem[primaryKey] === newItem[primaryKey])
      if(matches.length === 1) {
          return existingData.filter(existingItem => existingItem[primaryKey] !== newItem[primaryKey])
      } else {
          //existingData.push(newItem)
          return [
            ...existingData,
            newItem
          ]
      }
    }
  }

  export const uniqueOnly = (list,attrib) => {
    const uniqueKeys = [...new Set(list.map(uniqueItem => uniqueItem[attrib]))]

    return uniqueKeys.map(key => {
      return list.filter(item => item[attrib] === key)[0]
    })
  }

  export const notIn = (list, filterOut, key) => {
    const returnAry = []
    list.forEach(item => {
      if(filterOut.filter(filteredItem => filteredItem[key] === item[key]).length === 0) {
        returnAry.push(item)
      }
    })
    return returnAry
  }

  export const generateTempId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
  }

  export const moveItemInArray = (arr, fromIndex, toIndex) => {
    const returnAry = arr
    var element = returnAry[fromIndex]
    returnAry.splice(fromIndex, 1)
    returnAry.splice(toIndex, 0, element)
    return returnAry
  }

  export const scrollToTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  }
  
/**
   * 
   * @param {array} list list of items
   * @param {string} key the field that we are using to find the item in the list
   * @param {string} valueToRemoveMove the value that we are looking for in the list, based on the key
   * @param {function} set the function we want to call to set the new list without the item that corresponds to valueToRemove
   */
 export const removeItem = (list,key,valueToRemove,set) => {
  set(() => [
      ...list.filter(item => item[key] !== valueToRemove)
  ])
}

/**
 * 
 * @param {array} list list of items
 * @param {string} key the field that we are using to find the item in the list
 * @param {string} valueToMove the value that we are looking for in the list, based on the key
 * @param {integer} directionToMove positive or negative number. postive moves down in array, negatie moves up
 * @param {function} set the function we want to call to set the new, re-ordered list
 */
export const moveItem = (list,key,valueToMove,directionToMove,set) => {
  const currentIndex = list.findIndex(item => item[key] === valueToMove)
  const newItems = moveItemInArray(list,currentIndex,currentIndex+directionToMove)
  set(() => [
      ...newItems
  ])
}

export const sortEventsByDate = (eventList) => {
  return eventList.sort((a,b)=>new Date(a.eventDate)-new Date(b.eventDate))
}

export const isHex = (potentialHex) => {
  if(typeof potentialHex === "string") {
    const safeHex = /[A-F0-9a-f]/
    return safeHex.test(potentialHex)

  } else {
    return false
  }
}

/**
 * 
 * @param {string} passedPath represents a path that we want to add to the previousPath array. 
 *                             when a value is passed in, the user took some kind of action on
 *                            the page that changed the URL, but didn't change the react route,
 *                            like they clicked the "next" link on the calendar
 * 
 */
export const setPreviousPath = (passedPath = null) => {
  const current = JSON.parse(window.sessionStorage.getItem('previousPath'))
  const pathToUse  = (passedPath === null) ? window.location.pathname + window.location.search : passedPath
  const newPreviousPath = [pathToUse]
  if(current) {
    newPreviousPath.push(current[0])
  }

  window.sessionStorage.setItem('previousPath',JSON.stringify(newPreviousPath))
}

export const getDisplayTime = (militaryHour,minutes) => {
  const displayHour = ((militaryHour + 11) % 12) + 1
  const amPm = (militaryHour < 12) ? ' am' : ' pm'
  return `${displayHour}:${minutes}${amPm}`
}