import React, {useState, useEffect} from 'react'

import './SongArrangement.scss'
import SaveSongArrangement from './SaveSongArrangement'
import ViewSongArrangement from './ViewSongArrangement'
import useIsMountedRef from '../../hooks/useIsMountedRef.js'
import {get} from '../../lib/call-api'
import {scrollToTop} from '../../lib/index'

const SongArrangementManager = ({songId,arrangementData, onSave, initialMode = null}) => {
  const DEFAULT_MODE = 'read' 
  const [mode,setMode] = useState((initialMode === null) ? DEFAULT_MODE : initialMode)
  const isMounted = useIsMountedRef()
  const [availableSections,setAvailableSections] = useState()
  const [successMessage, setSuccessMessage] = useState()

  const [dataToDisplay,setDataToDisplay] = useState(arrangementData)

  // get the datasource we will use to populate the list of song sections the user can select from. 
  // for example: Verse 1, Bridge, Chorus, etc.
  useEffect(() => {
    if((mode === 'edit' || mode === 'add') && !availableSections) {
      const onGet = (resp) => {
        if(isMounted.current === true) {
          setAvailableSections(resp)
        }  
      }
  
      get(
        `/api/song/section/list`,
        onGet,
        () => true
      )
  
      return  (() => { isMounted.current = false })
    }
  },[mode,isMounted,availableSections])

  const onSuccessfulSave = (message,newData) => {
    setMode(DEFAULT_MODE)
    onSave()
    setDataToDisplay(newData)
    setSuccessMessage(message)
    scrollToTop()
  }

  const cancelEditAddMode = () => {
    // reset the UI back to its original state
    setMode(DEFAULT_MODE)
  }
  return (
    <>
      {
        (mode === 'edit' || mode === 'add') && availableSections &&
        <SaveSongArrangement 
          songId={songId} 
          mode={mode} 
          arrangementData={dataToDisplay} 
          availableSections={availableSections} 
          onSaveSuccess={onSuccessfulSave} 
          onCancel={cancelEditAddMode}/>
      }
      {
        (mode === DEFAULT_MODE)  &&
        <ViewSongArrangement arrangementData={dataToDisplay} onEdit={() => setMode('edit')} successMessage={successMessage} />

      }
      </>
  )
}
export default SongArrangementManager