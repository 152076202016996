import React from 'react'
import dateManager from '@ministry-squads-common/date-manager'
import './EventDate.scss'

const EventDate = ({displayDate}) => {
    
      return (
        <div className="event-date">
          <header>
          {dateManager.singleDate(displayDate,'MONTH_ABBR')}
          </header>
          
          {dateManager.singleDate(displayDate,'DAY_OF_MONTH')}
          
        </div>
      )
    

}

export default EventDate