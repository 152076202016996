import React, {useEffect} from 'react'

import {useAssignmentsState } from '../context'
import dateManager from '@ministry-squads-common/date-manager'
import {get} from '../../lib/call-api'
import '../styles.scss'
import SessionManager from '../../lib/session'
import useIsMountedRef from '../../hooks/useIsMountedRef'

const GetAssignmentData = ({initialTeam = null}) => {
  const sessionData = SessionManager.getSession()
  const teamIdToUse = (initialTeam !== null && initialTeam.teamId) ? initialTeam.teamId : sessionData.userData.teams[0].teamId
  const [state, updateState] = useAssignmentsState()
  const isMounted = useIsMountedRef()

  useEffect(() => {
    if(state.dateRange.startDate && state.dateRange.endDate) {

      const onScheduleGet = (resp) => {
        if(isMounted.current === true) {
          updateState({"type": "initializeEvents", payload: resp})
        }
      }
    
      const apiUrl = `/api/schedule/team/${teamIdToUse}?startDate=${dateManager.singleDate(state.dateRange.startDate,'URL_PARAMETER')}&endDate=${dateManager.singleDate(state.dateRange.endDate,'URL_PARAMETER')}`
      get(apiUrl,onScheduleGet,null)

      return () => isMounted.current = false
    }
    
  }, [teamIdToUse,updateState,state.dateRange.startDate,state.dateRange.endDate,isMounted])


  useEffect(() => {
    const onTeamGet = (resp) => {
      if(isMounted.current === true) {
        updateState({"type": "setMembers", "payload": resp.members})
        updateState({type: "setTeam", payload: resp})
      }
    }
    get(`/api/team/${teamIdToUse}`,onTeamGet,null,null,'view the teams')
    return () => isMounted.current = false
  }, [teamIdToUse,updateState,isMounted])

  return (<></>)
}

export default GetAssignmentData