const sessionManager = require('../lib/session')

const {API_PATH,authHeader} = require('./common')
const { handleErrors } = require('../lib/call-api')

export const getList = () => {
  const session = sessionManager.default.getSession()
  return new Promise((resolve,reject) => {
    fetch(`${API_PATH}/api/team/list/${session.orgData.orgId}`, {
      "method": 'get',
      "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json",
        ...authHeader()
      }
    }).then(response => 
      handleErrors(response)
    ).then(response => {
      resolve(response.teams)
    }).catch(error => {
      reject(error)
    })
  
  })
}