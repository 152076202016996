import React, { useState, useRef, useEffect  } from 'react'
import Alert from '../../components/Alert'
import SubmitButton from '../../components/SubmitButton'

const ForgotPasswordGetCode = (props) => {
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [globalErrorMessage, setGlobalErrorMessage] = useState('')
    const [fieldsWithErrors, setFieldsWithErrors] = useState([])
    const emailField = useRef(null);

    useEffect(() => {
        emailField.current.focus();
      }, []);

    const addFieldError = (fieldId, fieldMessage) => {
        let currentErrors = fieldsWithErrors
        
        if(currentErrors.length === 0 || currentErrors.filter(thisError => thisError.fieldId === fieldId && thisError.fieldErrorMessage === fieldMessage).length === 0) {
            setFieldsWithErrors([{
                fieldId: fieldId,
                fieldErrorMessage: fieldMessage
            }])
        }
    }

    const validateForm = () => {
        if(email === '') {
            addFieldError('email', 'Please provide an email address.')
            return false
        } else if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) === false) {
            addFieldError('email', 'The email address you entered is not formatted correctly.')
            return false
        }
        return true
    }

    const handleChange = event => {
        setEmail(event.target.value)
    }

    const handleErrors = (response) => {
        if (response.success === false) {
            throw new Error(response.message);
        }
        return response;
    }

    const handleSubmit = async event => {
        event.preventDefault()
        setIsLoading(true)
        if(validateForm() === true) {
            const authPath = process.env.REACT_APP_AUTH_URI || ""

            fetch(`${authPath}/password/forgot/verify`, {
                method: 'post',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: email
                })
            })
            .then(response => response.json())
            .then(handleErrors)
            .then(resp => {
                props.setEmailInParent(email)
                props.nextStep()
            })
            .catch(error => {
                setGlobalErrorMessage(error.message)
                setIsLoading(false)
            });
        } else {
            setIsLoading(false)
        }
    }

    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <h1>Forgot Your Password?</h1>
                
                <form onSubmit={handleSubmit} className="after-heading">
                    <Alert type="error" message={globalErrorMessage} />
                    <p>Please enter your email address, so we can send you a verification code.</p>
                    
                    <div className="form-group">
                        <label htmlFor="email">Your email address</label>
                        <input 
                            type="text" 
                            className={fieldsWithErrors.filter(field => field.fieldId === "email").length > 0 ? 'form-control field-with-error' : 'form-control'} 
                            id="email" 
                            ref={emailField}
                            placeholder="" 
                            onChange={handleChange} />
                        {
                            fieldsWithErrors.filter(field => field.fieldId === "email")
                                .map((thisError,ctr) => 
                                    <div className="field-error" key={`error-${ctr}`}>
                                        <p>{thisError.fieldErrorMessage}</p>
                                    </div>
                                )
                        }
                    </div>

                    <div className="buttonContainer">
                        <SubmitButton actionTextOverride='Get Code' isLoading={isLoading} />  
                    </div>

                </form>
            </div>
        </div>
    )
}
export default ForgotPasswordGetCode


