import React,{useState,useEffect} from 'react'
import { get,post } from '../lib/call-api'
import useIsMountedRef from '../hooks/useIsMountedRef'
import SessionManager from '../lib/session'
import {intersection,toggleArrayOfObjects,uniqueOnly, notIn,scrollToTop} from '../lib/index.js'
import SubmitButton from '../components/SubmitButton'
import Alert from '../components/Alert'
import SecondaryContent from '../components/SecondaryContent'
import './subscriptions.scss'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import TextArea from '../components/formFields/TextArea'
import FormField from '../components/formFields/FormField'
import { getDisplayDate, getEventDisplayName } from '../events/common'

const SubscriptionEmail = ({subscriptionCode,assignedUsers,userData,teams,eventData, unique}) => {

  const assignmentsForTeam = (allAssignments,allUsers,teamRoles) => {
    const assignmentsOnTeam = intersection(allAssignments,teamRoles,'roleId','roleId')
    return intersection(allUsers,assignmentsOnTeam,'userId','assigneeId')
  }

  const getTeamsWithAssignments = (allTeams,assignedUsers,userData) => {
    const lclTeamsWithAssignments = []
    allTeams.forEach(team => {
      if(assignmentsForTeam(assignedUsers,userData,team.roles).length > 0) {
        lclTeamsWithAssignments.push(team)
      }
    })
    return lclTeamsWithAssignments
  }

  const FROM_EMAIL = 'brian@ministrysquads.com'
  const [subscribers,setSubscribers] = useState([])
  const isMounted = useIsMountedRef()
  const sessionData = SessionManager.getSession()

  const scheduledEventIds = eventData.map(event => event.scheduledEventId)
  const [toRecipients,setToRecipients] = useState([])
  const [ccRecipients,setCcRecipients] = useState([sessionData.userData])

  const [subject,setSubject] = useState(`${getEventDisplayName(eventData[0])} - ${getDisplayDate(eventData)}`)
  const [emailMessage,setEmailMessage] = useState('')
  const teamsWithAssignments = getTeamsWithAssignments(teams,assignedUsers,userData)
  const [showToRecipients,] = useState(true)

  const [selectedTeams,setSelectedTeams] = useState(getTeamsWithAssignments(teams,assignedUsers,userData))

  const [errorMessage,setErrorMessage] = useState()
  const [successMessage,setSuccessMessage] = useState()

  const [, popupDispatch] = useSecondaryContentState()

  const [previewHtml,setPreviewHtml] = useState()

  const reset = () => {
    setErrorMessage()
    setSuccessMessage()
  }

  useEffect(() => {
    reset()
  },[unique])

  const manageToRecipients = (allUsers,assignments) => {
    const newRecipients = intersection(allUsers,assignments,"userId","assigneeId")
    setToRecipients(() => newRecipients)
    
    const withoutAssignees = notIn(subscribers,newRecipients,'userId')
    setCcRecipients(() => 
    [
      sessionData.userData,
      ...withoutAssignees
    ])
  }
  
  useEffect(() => {
    if(isMounted.current === true) {
      const onGet = (resp) => {
        const allSubscribers = resp.subscribers  
        
        const senderIsSubscriber = allSubscribers.filter(subscr => subscr.userId !== sessionData.userData.userId).length > 0
        
        setSubscribers(resp.subscribers)

        const initialCcRecipients = (senderIsSubscriber === false) ? 
          [
            userData,
            ...allSubscribers
          ]
        :
        allSubscribers

        const withoutAssignees = notIn(initialCcRecipients,assignedUsers,'userId')

        setCcRecipients(() => [
          ...withoutAssignees,
          sessionData.userData
        ])
        
        return () => isMounted.current = false
      }
      get(`/api/subscription/org/${sessionData.orgData.orgId}/${subscriptionCode}`,onGet,null)
    }
  },[subscriptionCode])

  const isTeamSelected = (teamToCheck,selectedTeams,allUsers) => {
    return selectedTeams.filter(sel => sel.teamId === teamToCheck.teamId).length > 0
  }

  const toggleTeam = (team,allTeams,allAssignments,allUsers) => {
    const newSelectedTeamList = toggleArrayOfObjects('teamId',allTeams,team)
  
    
    setSelectedTeams(() => newSelectedTeamList)
  }
  
  // every time the selected teams change, up date the "to" recipients list\
  useEffect(() => {
    const rolesIdsToInclude = selectedTeams.map(teamOnList => {
      return teamOnList.roles.map(role => {
        return {
          "roleId": role.roleId
        }
    })
    }).flat()

    const assignmentsToInclude = intersection(assignedUsers,rolesIdsToInclude,'roleId','roleId')

    manageToRecipients(userData,assignmentsToInclude)

  },[selectedTeams])
  

  const handleSubmit = (e) => {
    e.preventDefault()
    scrollToTop()
    const onSuccess = (resp) => {
      setSuccessMessage('The email has been sent')
    }
    postData('/api/subscription/send',onSuccess)

  }

  const postData = (apiPath,onSuccess) => {
    const validateForm = () => {
      if((!toRecipients || toRecipients.length === 0) && (!subscribers || subscribers.length === 0)) {
        setErrorMessage('There are no recipients for the email.')
        return false
      } else {
        return true
      }
    }

    const data = {
      "scheduledEventIds": scheduledEventIds,
      "teamIdsToInclude": selectedTeams.map(team => team.teamId),
      "emailMessage": emailMessage,
      "subject": subject
    }

    const onError = (err) => {
      if(isMounted.current === true) {
        setErrorMessage(err)
        isMounted.current = false
      }
    }    
    post(apiPath,onSuccess,onError,validateForm,data)
  }
 
  const getPreview = (e) => {
    if(e) e.preventDefault()
    if(previewHtml) {
      setPreviewHtml(null)
    } else {
      popupDispatch({ type: 'togglePopup', payload: null })

      const onSuccess = (resp) => {
        setPreviewHtml(resp.htmlContent)
      }
      postData('/api/subscription/email-detail',onSuccess)
    }
  
  }


  return (
    <>
      {
        successMessage && 
        <>
          <Alert type="success" message={successMessage} />

          <p>The email will be from {FROM_EMAIL}, but any replies to the email will go to you.</p>

          <p>You will be cc'd on the email.</p>

          <p>If members report that they did not receive the email, ask that they check their spam folder.</p>
        </>
      }

      {
        !successMessage && 
        <form onSubmit={(e) => handleSubmit(e)}>
          <p>The email sent will include a link to the details of this event.</p>

          {
            errorMessage && 
            <Alert type="error" message={errorMessage} />
          }

          <section>
            <div className="callout">
              <h3>Include assigned members from:</h3>
             
            {
              teamsWithAssignments && selectedTeams && 
              teamsWithAssignments.map(team => 
                <div key={`team${team.teamId}`}>
                  <input type="checkbox" name="subscribers" checked={isTeamSelected(team,selectedTeams)} onChange={() => toggleTeam(team,selectedTeams,assignedUsers,userData)} id={`team${team.teamId}`} />  <label htmlFor={`team${team.teamId}`}>{team.teamName}</label>
                  ({ assignmentsForTeam(assignedUsers,userData,team.roles).length} assignees)
                </div>
              )
            }
            </div>
          </section>
          <section>
            <div className="emailSample">
              <h3>Email details:</h3>
              <div id="emailSampleHeader">
                <div>
                  <h4>To:</h4>
                  <div>
                    {
                      assignedUsers.length === 0 && 
                      <p className="no-results">No one is assigned to this event.</p>
                    }
                    {

                      assignedUsers.length > 0 && 
                      <>
                        {
                          toRecipients.length === 0 && 
                          <Alert type="warning" message={`There are no 'to' recipients for this email`} context="inline" />  
                        }
                      
                          {
                            showToRecipients === true && 
                            <ul>
                              { 
                                uniqueOnly(toRecipients,"userId").map(assignedUser => 
                                  <li className="recipient" key={`torecip${assignedUser.userId}`}>{assignedUser.firstName} {assignedUser.lastName}</li>
                                )
                              }
                            </ul>
                          }
                        
                      </>
                    }
                  </div>
                </div>
                <div>
                  <h4>Cc:</h4>
                  <div>
                  {
                    ccRecipients.length === 0 && 
                    <p className="no-results">No members are subscribed.</p>
                  }

                  { 
                  ccRecipients.length > 0 && 
                  <ul>
                    {ccRecipients.map(subscriber => 
                        <li className="recipient" key={`subscr${subscriber.userId}`}>{subscriber.firstName} {subscriber.lastName}</li>
                      )
                    }
                  </ul>
                  }
                  </div>
                </div>
                <div>
                  <h4>From:</h4>
                  <div>
                    <p>{FROM_EMAIL}</p>
                    <p>The email will come from {FROM_EMAIL}, but when recipients reply it will go to you.</p>
                  </div>
                </div>
                <div>
                  <h4>Subject:</h4>
                  <p><FormField
                    id="emailSubject"
                    value={subject}
                    handleChange={(val) => setSubject(val)}
                    /></p>
                </div>
                <div>
                  <h4>Message:</h4>
                  <div>
                  <TextArea handleChange={(text) => setEmailMessage(text)} value={emailMessage} errorMessge={null} errorType={null} maxLength={2000}/>

                  </div>
                </div>
              </div>
            </div>

            <section>
            

            {
              previewHtml && 
              <>
                <SecondaryContent 
                    heading='Email preview'
                    onClose={() => getPreview()} 
                    type='popup'>
                    <div dangerouslySetInnerHTML={{__html: previewHtml}} />
                  </SecondaryContent>
                  </>  
            
            }
            </section>

          <div className="button-container">
            <div className="row">
              <div className="col-md-4 offset-md-2 col-xs-12">
                <button className="btn btn-link" onClick={(e) => getPreview(e)}>Preview email</button>
              </div>
              <div className="col-md-4 col-xs-12">
                <SubmitButton actionTextOverride="Send email" />
              </div>
            </div>
          </div>
        
          </section>
        </form>
      }
    </>
  )
}


export default SubscriptionEmail