import React, {useEffect,useState} from 'react'
import classnames from 'classnames'

const ToggleList = ({originalItems,handleChange,reset,availableItems,idAttribute,labelAttribute}) => {
  const [itemsAdded, setItemsAdded] = useState([])
  const [itemsRemoved, setItemsRemoved] = useState([])

  useEffect(() => {
    setItemsAdded([])
    setItemsRemoved([])
  }, [reset])

  useEffect(() => {
    
    handleChange({
        "assignToIds": itemsAdded,
        "removeFromIds": itemsRemoved     
    })
  },[itemsAdded,itemsRemoved,handleChange])

  const toggleItem = async (itemId,idAttrib) => {
    
    // user de-selected an option
    if(isSelectedItem(itemId,idAttrib) === true) {
      // if we previously needed to add it to the database, we don't any longer
      if(isItemToAdd(itemId) === true) {
        setItemsAdded(
          previous => previous.filter(previousItem => previousItem !== itemId)
        )
      }
      // if it was already in the database, add it to the list of ids to remove
      if(isExistingItem(itemId,idAttrib) === true) {
        setItemsRemoved(
          previous => [
            ...previous,
            itemId
          ])
      }
      
    } else {
      // user selected an option

      // remove it from the roles to remove
      setItemsRemoved(
        previous => previous.filter(previousRole => previousRole !== itemId)
      )

      // as long as its not in the databse already, add it to the list to be added to the database
      if(isExistingItem(itemId,idAttrib) === false) {
        setItemsAdded(
          previous => [
            ...previous,
            itemId
          ])
      } 

    }
  }

  const isExistingItem = (itemId,idAttrib) => {
    if(!originalItems || originalItems.length === 0) {
      return false
    } else {
      return originalItems.findIndex(existingItem => existingItem[idAttrib] === itemId) > -1
    }
    
  }

  const isItemToAdd =  (itemId) => {
    return itemsAdded.findIndex(item => item === itemId) > -1
  }

  const isItemToRemove =  (itemId) => {
    return itemsRemoved.findIndex(item => item === itemId) > -1
  }

  const isSelectedItem = (itemId,idAttrib) => {
    
    return (isItemToAdd(itemId) === true || (isExistingItem(itemId,idAttrib) === true && isItemToRemove(itemId) === false))
  }

  return (
    <section>
      {
        availableItems.map(item => 
          <ItemSelector itemId={item[idAttribute]} labelText={item[labelAttribute]} isSelected={isSelectedItem(item[idAttribute],idAttribute)} handleClick={(itemId) => toggleItem(itemId,idAttribute)} key={'toggleItem' + item[idAttribute]} />
        )
      }
    </section>
  )
}

const ItemSelector = ({itemId, labelText, isSelected, handleClick}) => {
  
  return (
    <div 
      className={
      classnames(
          'toggle-selector',
          { 
            'selected': isSelected === true 
          }
      )}
      onClick={() => {handleClick(itemId)}}>{labelText}</div>)
}

export default ToggleList