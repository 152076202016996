import React from 'react'

const ArrangementsList = ({arrangements,onSelect}) => {
  const lclOnSelect = (e,arr) => {
    e.preventDefault()
    onSelect(arr)
  }
  return (
    
    <section>  
        {
          arrangements.length === 0 && 
          <p className="no-results">No arrangements associated to this song</p>
        }
        {   arrangements.length > 0 && 
        <ul>
          {
            arrangements.map(arrangement => 
              <li key={`arrangement${arrangement.arrangementId}`}><button className="btn btn-link btn-slim" onClick={(e) => lclOnSelect(e,arrangement)}>{arrangement.arrangementName}</button> </li>    
              
            )
          }
        </ul>
            
        }
      </section>
  )
}



export default ArrangementsList