import React from 'react'

const AboutUs = () => {
  return (
    <section className="page page-slim">

      <h1>About Us</h1>

      <h2>Thank you for being part of the Ministry Squads beta!</h2>

      <article>
      <p>Our goal is to:</p>
      <ul className="list-styled">
        <li>Streamline the worship serivce planning process for staff and administrators</li>
        <li>Centralize access to resources that will help ministry team members serve confidently</li>
      </ul>

      <p>Because we are still in the beta phase, there are still some kinks to work out in our product. These kinks can range from bugs where things just flat-out don't work, to confusing user experiences that could be smoother.</p>
      </article>

    </section>
  )
}


export default AboutUs