import React, {useEffect,useState} from 'react'
import TypeAhead from '../../components/TypeAhead'
import {getList} from '../../services/team.service'
import { TypeAheadProvider, useTypeAheadState } from '../../contexts/TypeAhead.Context'




const AddTeam = ({selectedList,onListChange}) => { 
  const [allTeams,setAllTeams] = useState(null)
  const initialList = selectedList
  
  useEffect(() => {
    if(allTeams === null) {
      getList().then(list => setAllTeams(list))
    }
  },[allTeams])
  
  return (
    <>
    {
      allTeams && 
      <TypeAheadProvider initialList={initialList} allItems={allTeams} attributesToSearch={["teamName"]} itemKey="teamId" >
        <RenderWithinProvider onListChange={onListChange} />
      </TypeAheadProvider>
    }
      
    </>
  )
}


const RenderWithinProvider = ({onListChange}) => {
  const [typeAheadState, typeAheadDispatch] = useTypeAheadState()

  useEffect(() => {
    onListChange(typeAheadState.selectedItems)
  },[typeAheadState.selectedItems])

  return (
    <>
    {
      typeAheadState.allItems && typeAheadState.attributesToSearch && 
      <TypeAhead 
        htmlId="manageTeams"
        itemKey="teamId"
        selectedItemText = {(item) => item.teamName}
      />
    }
    </>
  )
  
}

export default AddTeam