import React, { useRef, useEffect, useState  } from 'react'

const SetName = ({id,value, placeholder,type,handleChange,autoFocus,validateFunction = () => {},errorMessage, showErrorMessage = true}) => {
    const field = useRef(null);
    useEffect(() => {
        if(autoFocus === true) field.current.focus();
    }, [autoFocus]);

   const [localValue,setLocalValue] = useState()
    const changeValue = (newValue) => {
        setLocalValue(newValue)
        handleChange(newValue)
    }

    useEffect(() => {
        setLocalValue((value === null || typeof(value) === "undefined") ? '' : value)
    },[value,setLocalValue])

    if(localValue === null || typeof(localValue) === "undefined") {
        return (null)
    } else {
        return (
            <span>
                <>
                <input 
                    type={type}
                    className={typeof(errorMessage) !== 'undefined' && errorMessage !== null && errorMessage !== '' ? 'form-control field-with-error' : 'form-control'} 
                    id={id}
                    data-testid={`input-${id}`}
                    ref={field}
                    value={ localValue }
                    placeholder={placeholder} 
                    onChange={(event) => {changeValue(event.target.value)}}
                    onBlur ={() => {validateFunction()}}
                    />
                {
                    typeof(errorMessage) !== 'undefined' && errorMessage !== null && errorMessage !== '' && showErrorMessage === true &&    
                        <div className="field-error">
                            <p>{errorMessage}</p>
                        </div>
                }
    </>    
    
       
     </span>
        )
    }

    
}
export default SetName


