import React,{Fragment} from 'react'
import sessionManager from '../../lib/session'

/**
 * This component is a high-order-component that will only show child components 
 * if the user is an admin
 * 
 * if props.teamId is passed in, it will assume that the current user needs to be a team admin
 * otherwise, it will assume the user needs to be an org admin
 * 
 * org admins will have access to the child component, regardless of their position on a team.
 * they are considered "super users" for the org, and should be able to do all the functions
 * that any team admin can do, plus functions that only org admins can do
 * 
 * @param {*} props 
 * @returns the child HTML fragments, if the criteria is met 
 */

const AdminOnly = (props) => {
  const session = sessionManager.getSession()
  const hasAccess = () => {
    const userIsOrgAdmin = isOrgAdmin(session.userData)
    if(props.teamId) {
      // if we have a team ID passed in, figure out if the user is a team admin
      const userIsTeamAdmin = isTeamAdmin(session.userData, props.teamId)
      return (userIsTeamAdmin === true) ? userIsTeamAdmin : userIsOrgAdmin
      
    } else {
      // or, by default, determine if the user is an org admin
      return userIsOrgAdmin
    }
    
  }

  const isOrgAdmin = (userData) => {
    return userData && userData.isOrgAdmin === true
  }

  const isTeamAdmin = (userData, teamId) => {
    const team = userData.teams.filter(teamToFind => teamToFind.teamId === teamId && teamToFind.isTeamAdmin === true)
    return team.length === 1
  }


  return (
    <Fragment>
      {
       hasAccess() === true && 
       <Fragment>{ props.children }</Fragment>
      }
    </Fragment>
  )
}

export default AdminOnly