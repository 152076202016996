import React from 'react'

const TermsOfUse = () => {
  return (
    <section className="page page-slim">

      <h1>Terms of Use</h1>

      <p>Thank you for being part of the Ministry Squads beta!</p>

      <p>Because we are still in the beta phase, there are still some kinks to work out in our product. These kinks can range from bugs where things just flat-out don't work, to confusing user experiences that could be smoother.</p>

      <p>By using the beta version of Ministry Squads, you agree to give feedback on the product, and how it meets your needs, and any ideas you have for improvemnts.</p>

    </section>
  )
}


export default TermsOfUse