import React,{useEffect, useState} from "react"
import DropDownList from '../../components/formFields/DropDownField'
import { get } from '../../lib/call-api'

const EventTypes = ({value, handleChange, errorMessage, id}) => {
  const [eventTypes,setEventTypes] = useState()
  useEffect(() =>{
    const onTeamGet = (resp) => {
      setEventTypes(resp)
    }
    get(`/api/event/type/list`,onTeamGet,null)
  
  },[])

  if(eventTypes) {
    return (
      <DropDownList 
        id={`eventType${id}`}
        idField="eventTypeId"
        labelField="eventTypeName" 
        selectedValue={value}
        values={eventTypes}
        handleChange={(val) => handleChange(val)}
        errorMessage={errorMessage}
      />
    )
  } else {
    return(<></>)
  }
  
}

export default EventTypes