import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import {getList as getTeamList } from '../services/team.service'
import sessionManager from '../lib/session'
import useIsMountedRef from '../hooks/useIsMountedRef.js'
import AdminOnly from '../routes/components/AdminOnly'
import Alert from '../components/Alert' 
import SaveTeam from './components/SaveTeam'
import SecondaryContent from '../components/SecondaryContent'

const TeamList = () => {
  const session = sessionManager.getSession()

  const [teamList, setTeamList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, ] = useState('')
  
  const [refresh, setRefresh] = useState(null)
  const [showHiddenContent, setShowHiddenContent] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [savedTeam, setSavedTeam] = useState({})

  const [, modalDispatch] = useSecondaryContentState()
  const isMounted = useIsMountedRef()

  const toggleSlider = () => {
    modalDispatch({ type: 'toggleIsOpen', payload: null })
  }

  const updateOnAdd = (data, message) => {
    setRefresh(true)
    setShowHiddenContent(false)
    modalDispatch({"type": "toggleIsOpen"})
    setSuccessMessage(message)
    setSavedTeam(data)
  }

  
    
  useEffect(() => { 
    
    if((refresh === null || refresh === true) && isMounted.current === true) {
      getTeamList().then(list => {
        setIsLoading(false)
        setTeamList(list)
        setRefresh(false)
      })
    }

    return  (() => { isMounted.current = false })
  }, [refresh,isMounted,session.orgData.orgId])
  
  return(
    <section className="page">
      <header>
        <h1>Teams</h1>

        { errorMessage !== '' && (
            <Alert type="error" message={errorMessage} />
        )}
        <AdminOnly>
          <div className="feature-manager">
              <button className="btn btn-icon btn-add" onClick={toggleSlider}>
                Add a team
              </button>
          </div>  
        </AdminOnly>
      </header>

      <AdminOnly>
      <SecondaryContent onClose={setShowHiddenContent} show={showHiddenContent}>
        <SaveTeam onSuccess={updateOnAdd}/>
      </SecondaryContent>
      { successMessage !== '' && (
            <Alert type="success" message={successMessage} />
        )}
        </AdminOnly>
        
      <div>
      {
        isLoading === false && teamList && teamList.length > 0 && 
        
        <table>
          <thead>
            <tr>
              <th>Team Name</th>
            </tr>
          </thead>
          <tbody>
            {
              teamList.map(team => 
                <tr key={`team${team.teamId}`} className={(savedTeam.teamId === team.teamId) ? 'alert-success' : ''}>
                  <td>
                    <Link to={`/team/detail/${team.teamId}`}>{team.teamName}</Link>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      }
      </div>
    </section>
  )
}

export default TeamList