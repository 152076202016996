import React, { useState, useEffect } from 'react'
import DateRange from '../../components/formFields/DateRange'
import SubmitButton from '../../components/SubmitButton'
import Alert from '../../components/Alert'
import {post} from '../../lib/call-api'
import dateManager from '@ministry-squads-common/date-manager'

const AddBlockedDate = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [lclStartDate,setLclStartDate] = useState()
  const [lclEndDate,setLclEndDate] = useState()

  const [dateErrors, setDateErrors] = useState()

  const reset = () => {
    setErrorMessage('')
    setLclStartDate()
    setLclEndDate()
    setDateErrors()
  }

  useEffect(() => {
    reset()
  }, [props.unique])

  const validateForm = () => {
    if(!lclStartDate || lclStartDate === '') {
      setErrorMessage('Please provide a start date.')
      return false
    } else {
      return true
    }
  }

  const onPost = (resp) => {
    props.handleUpdate(resp)
    setIsLoading(false)
    reset()
  }

  const handlePostError = (resp) => {
    const lclError = []
    
    if(resp.unsuccessful) {
      setErrorMessage(resp.message)
      resp.unsuccessful.forEach(dateWithError => {
        lclError.push({
          "date": dateWithError.date,
          "errorMessage": dateWithError.error.userMessage
        })
      })
      setDateErrors(() => lclError)
    } else {
      setErrorMessage(resp)
    }
    
  } 

  const handleSubmit = async event => {
    event.preventDefault()
    const postBody = {
      dateToBlock: dateManager.setToMidnight(lclStartDate),
      action: 'add',
      startDate: dateManager.setToMidnight(lclStartDate),
      endDate: dateManager.setToMidnight(lclEndDate)
    }
    post("/api/availability",onPost,handlePostError,validateForm,postBody)
  }
  
  return (
    <>
      <p className="intro">Provide the date(s) you'd like to block below. To block a single date, simply provide the date as the start and end date.</p>
      {
        errorMessage &&
        <Alert type='error' message={errorMessage}>
          {
            dateErrors && dateErrors.length > 0 && 
            <ul>
            {
              dateErrors.map(dateError => 
                <li key={`dateErrro${dateError.date}`}>
                  {dateManager.formatDate(dateError.date,'TEXT_FULL')}:&nbsp;
                  {dateError.errorMessage}
                </li>
                )
            }
          </ul>
          }
        </Alert>
      }
      
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          
          <DateRange
            defaultStartDate={lclStartDate}
            setStartDate={setLclStartDate}
            defaultEndDate={lclEndDate}
            setEndDate={setLclEndDate}
            allowPast={true}
          />
        </div>
        <div className="button-container">
          <SubmitButton actionTextOverride='Block date' isLoading={isLoading} />  
        </div>  
      </form>
    </>
  )
}

export default AddBlockedDate