import React, {useState,useCallback,useEffect} from 'react'
import Alert from '../../components/Alert'
import AddSection from '../../components/AddSection'
import SectionAction from '../../components/SectionActions'
import AdminOnly from '../../routes/components/AdminOnly'
import SubmitButton from '../../components/SubmitButton'
import FormField from '../../components/formFields/FormField'
import EditSectionDetails from './EditSectionDetails'
import {generateTempId, removeItem} from '../../lib/index'
import {post,put} from '../../lib/call-api'
import SessionManager from '../../lib/session'
import ViewSectionDetails from './ViewSectionDetails'
import useIsMountedRef from '../../hooks/useIsMountedRef.js'
import './SongArrangement.scss'

const SaveSongArrangement = ({songId,arrangementData, mode, availableSections, onSaveSuccess, onCancel}) => {
  const [arrangementName,setArrangementName] = useState(arrangementData.arrangementName)
  const [songArrangementStructure,setSongArrangementStructure] = useState(arrangementData.structure)

  const [idBeingEdited,setIdBeingEdited] = useState(null)

  const [errorMessage,setErrorMessage] = useState()
  const sessionData = SessionManager.getSession()

  const isMounted = useIsMountedRef()

  const addNewSection = useCallback((structure,setStructure,setIdEdited) => {
    const newId = generateTempId()
    // create an empty section
    const newEmptySection = {
      "orderId": newId,
      "orderNumber": (structure) ? structure.length : 0,
      "direction": null,
      "songSection": null
    }

    // add the empty section to the structure so the UI knows about it
    if(!structure) {
      setStructure(() => [newEmptySection])
    } else {
      setStructure((previousSections) => [
        ...previousSections,
        newEmptySection
      ])
    }

    // tell the UI that the new section is the one we want to edit
    setIdEdited(newId) 
  },[arrangementData.structure])

  // automatically add a blank section, to save the user the trouble of clicking the "Add Section" button 
  useEffect(() => {
    if(mode === 'add') addNewSection(songArrangementStructure,setSongArrangementStructure,setIdBeingEdited)
  },[ mode, addNewSection])

  const handleItemCancel = () => {

    removeItem(songArrangementStructure,'orderId',idBeingEdited,setSongArrangementStructure)
    setIdBeingEdited(null)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const validateArrangement = (dataToValidate) => {
      if(!dataToValidate.arrangementData.arrangementName || dataToValidate.arrangementData.arrangementName === '') {
        setErrorMessage('An arrangement name is required')
        return false
      } else if (!dataToValidate.arrangementData.structure || dataToValidate.arrangementData.structure.length === 0) {
        setErrorMessage('At least on section is requried.')
        return false
      } else {
        return validateStructure(dataToValidate.arrangementData.structure)
      }
    }

    const validateStructure = (structureData) => {
      const withoutASection = structureData.filter(section => (!section.songSectionId))
      if(withoutASection.length > 0) {
        setErrorMessage('Please select a section type for each part of the song')
      } else {
        return true
      }
    }

    const data = {
      "songId": songId,
      "orgId": sessionData.orgData.orgId,
      "arrangementData": {
        "arrangementId": arrangementData.arrangementId,
        "arrangementName": arrangementName,
        "structure": songArrangementStructure.map((structureSection,ctr) => {
          return {
            ...structureSection.songSection,
            "orderId": structureSection.orderId,
            "orderNumber": ctr,
            "songSectionId": structureSection.songSection.sectionId,
            "direction": structureSection.direction
          }
        })
      }
    }

    const onSuccess = (resp) => {
      onSaveSuccess('The arrangement has been saved',resp.arrangementData)
    }

    const onError= (err) => {
      if(isMounted.current === true) {
        setErrorMessage(err)
        isMounted.current = false
      }
    }

    const apiPath = '/api/song/arrangement'
    if(arrangementData.arrangementId) {
      put(apiPath,onSuccess,onError,validateArrangement,data)
    } else {
      post(apiPath,onSuccess,onError,validateArrangement,data)
    }
  }

  const getSectionClassName = (currentId,orderId) => {
    if(currentId !== null && orderId !== currentId) {
      return 'disabled'
    } else if(currentId !== null) {
      return 'being-edited'
    } else {
      return ''
    }
  }

  const updateItemInArrangement = (newSection) => {
    // update the song arrangement data to use the new songSection
    // using map below to maintain the order of the list
    setSongArrangementStructure(() => songArrangementStructure.map(arrangementSection => {
      if(arrangementSection.orderId === newSection.orderId) {
        return newSection
      } else{
        return arrangementSection
      }
    }))

    setIdBeingEdited(null)
  }

  return (
    <>
    {
    (mode === 'add' || (arrangementName && songArrangementStructure)) && availableSections && 
      <form onSubmit={(e) => handleSubmit(e)}>
          {
            errorMessage &&
            <Alert type="error" message={errorMessage} />
          }
          <div className="form-group">
            <label htmlFor="arrangement-name">Arrangement name</label>
            <FormField 
              id="arrangement-name"
              value={arrangementName}
              handleChange={setArrangementName}
            />
          </div>
        
          <h4>Song order</h4>
          <div className="song-arrangement">
          {
            songArrangementStructure &&
            songArrangementStructure.map((songArrangementSection,sectionCtr) => 
              <div className={`song-arrangement-section ${getSectionClassName(idBeingEdited,songArrangementSection.orderId)}`} key={`section${arrangementData.arrangementId}${songArrangementSection.orderNumber}`}>

                {
                  idBeingEdited && idBeingEdited === songArrangementSection.orderId && 
                  <EditSectionDetails 
                    sectionData={songArrangementSection}
                    allSections={availableSections} 
                    onSave={updateItemInArrangement} 
                    onCancel={() => handleItemCancel()} 
                  />
                }
                
                {
                  (idBeingEdited === null || idBeingEdited !== songArrangementSection.orderId) && 
                  <>
                    <div className={getSectionClassName(idBeingEdited,songArrangementSection.orderId)}>
                      <ViewSectionDetails sectionData={songArrangementSection} id={songArrangementSection.orderNumber} />
                    </div>
                    <AdminOnly>
                      {
                      idBeingEdited === null &&
                      <SectionAction 
                        currentIndex={sectionCtr} 
                        list={songArrangementStructure} 
                        keyName='orderId'
                        valueToFind={songArrangementSection.orderId}
                        setFunction={setSongArrangementStructure}
                        onEditClick={(e) => {e.preventDefault(); setIdBeingEdited(songArrangementSection.orderId)}}
                        compact={true}
                        />
                      }
                    </AdminOnly>
                  </>
                }
              </div>
            )
        }
      </div>

      {
        idBeingEdited === null &&   
        <AdminOnly>
          <AddSection clickHandler={(e) => { e.preventDefault(); addNewSection(songArrangementStructure,setSongArrangementStructure,setIdBeingEdited)}} />

          <div className="button-container">
            <SubmitButton />
            <button className="btn btn-link" onClick={(e) => { e.preventDefault(); onCancel()}} aria-label="cancel">Cancel</button>
          </div>
        </AdminOnly>
      
      }
    </form>
    }
    </>
  )
}

export default SaveSongArrangement