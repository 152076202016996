import {getEventDisplayName,getDisplayDate,getEventDataForGroup} from '../events/common'
export const getDisplayData = (event,allEvents) => {

  const matchingEvents = getEventDataForGroup(event,allEvents)
  const firstEvent = matchingEvents[0]

  return {
    "displayName": getEventDisplayName(firstEvent),
    "scheduledEventId": firstEvent.scheduledEventId,
    "dateOutput": getDisplayDate(matchingEvents),
    "ledBy": firstEvent.ledBy
  }
}

export const emptyAssignmentObject = (songId) => {
  return {
    "songId": songId,
    "ledBy": {
      "userId": null,
      "songKey": null
    }
  }
}