
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { add, update, getInitialObject } from '../../services/event.service'

import Alert from '../../components/Alert'
import SubmitButton from '../../components/SubmitButton'
import EventStartEnd from './EventStartEnd'
import EventBasics from './EventBasics'
import OneTimeEventSummary from './OneTimeEventSummary'
import EventTeamsAttendees from './EventTeamsAttendees'

import { useManageEventState } from '../../contexts/ManageEvent.Context'

import '../events.scss'

const EditOneTimeEvent = ({ onSave, onCancel = null }) => {
  
  const [eventState, eventDispatch] = useManageEventState()
  
  const ADD_TXT = 'add'
  const UPDATE_TXT = 'update'
 
  const mode = eventState.scheduledEventId ===null ? ADD_TXT : UPDATE_TXT

  const [errorMessages, setErrorMessages] = useState(getInitialObject())
  const [saveResult, setSaveResult] = useState()
  
  const handleSubmit = (browserEvent) => {
    browserEvent.preventDefault()

    // the API was originally built using the ToggleList component, which creates an object that
    // keeps track of items that the user selected (assignToIds), and de-selected (removeFromIds)
    // right now we only support the add scenario, so just hard coding below. we'll need to refctor
    // one we allow users to edit the teams assigned to an existing event.
    const selectedTeamActions = {
      "assignToIds": (eventState.selectedTeams) ? eventState.selectedTeams.map(team => team.teamId) : [],
      "removeFromIds": []
    }


    const dataToSubmit = {
      "scheduledEventId": eventState.scheduledEventId,
      "eventName": eventState.eventName,
      "eventTypeId": eventState.eventTypeId,
      "startDate": eventState.startDate,
      "startTime": eventState.startTime,
      "endDate": eventState.endDate,
      "endTime": eventState.endTime,
      "attendees": eventState.attendees,
      "teamsToAssignActions": selectedTeamActions,
      "eventSeriesId": eventState.eventSeriesId
    }

    // an event series instance may not be saved as an event to the database
    // we can tell if its in the database already based on the isTempId property.
    // in that scenario we want to call the add function
    const saveFunction = (eventState.isTempId === true || eventState.scheduledEventId === null) ? add : update
    saveFunction(dataToSubmit).then(result => {
      setSaveResult(() => result)

      eventDispatch({"type": "reset"})

      onSave({
        "resultHeading": 'All set!',
        "resultMessage": 'Your event has been saved',
        "resultType": 'success',
        "savedOutput": () => { return <OneTimeEventSummary saveResult={result} />}
      })

    }).catch(customErrorObject => {
      setErrorMessages(() => {
        return {
          ...customErrorObject,
          "generalPageError": "Please correct the error(s) below.",
        }
      })
      console.error('Error saving event', customErrorObject)
    })

  }

  return (
    <>
      
      {
        !saveResult && eventState &&
        <form onSubmit={(e) => { handleSubmit(e) }}>
          {
            errorMessages && errorMessages.generalPageError &&
            <Alert type="error" message={errorMessages.generalPageError} />
          }
        
          <div className="row">
            <div className="col">
              <div className="card">
              <section className="dbl-bottom">
                <EventBasics eventName={eventState.eventName} eventTypeId={eventState.eventTypeId} errorMessages={errorMessages} />
              </section>

              <section className="dbl-bottom">
                <h3>Date &amp; time</h3>

                <EventStartEnd errorMessages={errorMessages}/>
              </section>
              </div>
              {
                mode === ADD_TXT &&
                <EventTeamsAttendees />
              }

            </div>
          </div>
          <div className={(onCancel === null) ? "button-container" : "button-container with-cancel"} >
            <div><SubmitButton /></div>
            {
              onCancel !== null &&
              <div><button className="btn btn-link" onClick={() => onCancel()}>Cancel</button></div>
            }
          </div>
        </form>
      }
    </>
  )
}

export default EditOneTimeEvent
