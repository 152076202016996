import React  from 'react'
import DateTime from './DateTime'

import '../events.scss'
import { useManageEventState } from '../../contexts/ManageEvent.Context'


const EventStartEnd = ({ errorMessages }) => {
  const [eventState, eventDispatch] = useManageEventState()

  const setStartDate = (val) => {
    eventDispatch({"type": "startDate", "payload": val})
  }

  const setEndDate = (val) => {
    eventDispatch({"type": "endDate", "payload": val})
  }


  const setStartTime = (val) => {
    eventDispatch({"type": "startTime", "payload": val})
  }

  const setEndTime = (val) => {
    eventDispatch({"type": "endTime", "payload": val})
  }

  const handleSingleEventDateChange = (dt) => {
    setStartDate(dt)
    setEndDate(dt)
  }

  return (<>
    <label>Start</label>
      <div className="form-group form-inline">
        <DateTime
          dateValue={eventState.startDate}
          timeValue={eventState.startTime}
          defaultTime={eventState.defaultStartTime}
          dateErrorMessage={errorMessages.startDate}
          timeErrorMessage={errorMessages.startTime}
          onDateChange={handleSingleEventDateChange}
          onTimeChange={setStartTime}
        />

      </div>

      <label>End</label>
      <div className="form-group form-inline">
        <DateTime
          dateValue={(eventState.endDate === null) ? eventState.startDate : eventState.endDate }
          timeValue={eventState.endTime}
          defaultTime={(eventState.startTime) ? eventState.startTime : eventState.defaultEndTime}
          dateErrorMessage={errorMessages.endDate}
          timeErrorMessage={errorMessages.endTime}
          onDateChange={setEndDate}
          onTimeChange={setEndTime}
        />
      </div>
  </>
  )
}

export default EventStartEnd