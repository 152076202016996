import React from 'react'
import PendingStatus from './PendingStatus'

const NoAccess = () => {

  return (
    <section>
      <h1>Sorry...</h1>

      <h2>You don't have access to this page.</h2>

      <PendingStatus>
        <p>We're waiting for your organization's administrator to approve your registration.</p> 
        <p>For privacy purposes, we can't share his or her information, but we're sure you can figure out who to follow up with!</p>
      </PendingStatus>
    </section>
  )
}

export default NoAccess