import React  from 'react'
import DisplayDaysAndTimes from './DisplayDaysAndTimes'

import '../events.scss'

const OneTimeEventSummary = ({ saveResult }) => {
  return (
    <>
      <h3>{saveResult.eventName}</h3>
      <DisplayDaysAndTimes events={[saveResult]} asLink={true} />
    </>
  )
}

export default OneTimeEventSummary