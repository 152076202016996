import React from 'react'

import { Link } from 'react-router-dom'

import AdminOnly from '../../routes/components/AdminOnly'

const SongsForEvent = ({songs,manageSongs}) => {
    return (
        <>
            <h2>Songs</h2>
            {
                songs.length > 0 && 
                songs.map(song => 
                    <p className='song-on-event-detail' key={`song-${song.songId}`}>
                        <Link to={`/song/detail/${song.songId}`}>{song.songTitle}</Link>
                        {
                            song.ledBy && 
                            <>
                            <span className="song-lead">{song.ledBy.firstName} {song.ledBy.lastName}</span>

                            {
                                song.ledBy.songKey && 
                                <span className="song-key"> ({song.ledBy.songKey})</span>
                            }
                            </>
                        }
                    </p>
                )
            }
            {
                songs && songs.length === 0 && 
                <>
                <p className="no-results">No songs associated.</p>
                <AdminOnly>
                    <p>Go to the <Link to="/song/list">song library</Link> to add songs</p>
                </AdminOnly>
                </>
            }
            {
                songs && songs.length > 0 && manageSongs &&
                <AdminOnly>
                    <button className="btn btn-link btn-slim" onClick={() => {manageSongs()}}>
                    Manage songs
                </button>
                </AdminOnly>
            }
        </>
    )
}


export default SongsForEvent