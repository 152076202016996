import React, {useEffect,useState} from 'react'
import {Link} from 'react-router-dom'
import SaveSermonSeries from './components/SaveSermonSeries'
import SecondaryContent from '../components/SecondaryContent'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import Alert from '../components/Alert' 
import AdminOnly from '../routes/components/AdminOnly'
import {get} from '../lib/call-api'
import useIsMountedRef from '../hooks/useIsMountedRef'
import {sermonDates} from './common'
import dateManager from '@ministry-squads-common/date-manager'

const SermonSeriesList = () => {
  const [sermonSeriesList, setSermonSeriesList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  
  const [refresh, setRefresh] = useState(false)
  const [showHiddenContent, setShowHiddenContent] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [savedSermonSeries, setSavedSermonSeries] = useState({})

  const emptySermonSeriesData = {
    "sermonTitle": null,
    "sermons": [],
    "startDate": null

  }

  const [, modalDispatch] = useSecondaryContentState()

  const isMounted = useIsMountedRef()

  const toggleSlider = () => {
    modalDispatch({ type: 'toggleIsOpen', payload: null })
  }

  const updateOnAdd = (data, message) => {
    setRefresh(true)
    setShowHiddenContent(false)
    modalDispatch({"type": "toggleIsOpen"})
    setSuccessMessage(message)
    setSavedSermonSeries(data)
  }
    
  useEffect(() => {
    const onGet = (resp) => {
      if(isMounted.current === true) {
        setSermonSeriesList(resp)
        setIsLoading(false)
      }
      
    }

    get(`/api/sermon-series/list`,onGet,setErrorMessage)

    return () => isMounted.current = false
  }, [refresh,isMounted])
  
  const getSermonSeriesEndDate = (sermonSeriesData) => {
    if(sermonSeriesData.startDate && sermonSeriesData.sermons.length > 0) {
      const dates = sermonDates(sermonSeriesData.startDate,sermonSeriesData.sermons)
      return dateManager.formatDate(dates[dates.length-1])
    } else {
      return null
    }
    
  }

  return (
    <section className="page">
      <header>
        <h1>Sermon Series</h1>

        { errorMessage !== '' && (
            <Alert type="error" message={errorMessage} />
        )}
        <AdminOnly>
          <div className="feature-manager">
              <button className="btn btn-icon btn-add" onClick={toggleSlider}>
                Add a sermon series
              </button>
          </div>  
        
        </AdminOnly>
      </header>

      <AdminOnly>
      <SecondaryContent heading="Add a Sermon Series" onClose={toggleSlider} show={showHiddenContent}  size="larger">
        <SaveSermonSeries 
          onSuccess={updateOnAdd}
          onCancel={toggleSlider}
          sermonSeriesData={emptySermonSeriesData} 
         
        />
      </SecondaryContent>
      { successMessage !== '' && (
            <Alert type="success" message={successMessage} />
        )}
        </AdminOnly>
        
      <div>
      {
        isLoading === false && sermonSeriesList && sermonSeriesList.length === 0 && 
        <div className="card">
          <p>There aren't any Sermon Series yet.</p>
        </div>

      }
      
      {
        isLoading === false && sermonSeriesList && sermonSeriesList.length > 0 && 
        <table>
       
           <thead>
             <tr>
               <th>Series Name</th>
               <th>Number of Sermons</th>
               <th>Start Date</th>
               <th>End Date</th>
               
             </tr>
           </thead>
            
           <tbody>
             {
               sermonSeriesList.map(sermonSeries => 
                 <tr key={`sermonSeries${sermonSeries.sermonSeriesId}`} className={(savedSermonSeries && savedSermonSeries.sermonSeriesId === sermonSeries.sermonSeriesId) ? 'alert-success' : ''}>
                   <td data-testid={`sermonSeriesTitle${sermonSeries.sermonSeriesId}`}>
                    <Link to={`/sermon-series/detail/${sermonSeries.sermonSeriesId}`}>{ sermonSeries.sermonSeriesTitle}</Link>
 
                   </td>
                   <td>{
                    sermonSeries.sermons.length
                   }
                   </td>
                   <td data-testid={`sermonSeriesStartDate${sermonSeries.sermonSeriesId}`}>
                    {
                      sermonSeries.startDate && 
                      dateManager.formatDate(sermonSeries.startDate)
                    }
                   </td>
                   <td data-testid={`sermonSeriesEndDate${sermonSeries.sermonSeriesId}`}>{ getSermonSeriesEndDate(sermonSeries) }</td>
 
                   
                   
                 </tr>
               )
             }
           </tbody>
         </table>
      }
      </div>
    </section>
  )
}

export default SermonSeriesList