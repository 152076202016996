import React, { useState } from "react"
import Alert from '../../components/Alert'
import FormField from '../../components/formFields/FormField'
import {post,put,httpDelete} from '../../lib/call-api'
import {entityTypeText} from '../common'

const ManageRolesByTeam = ({roles,setRoles,teamId,nameAttribute,idAttribute,parentEntityType}) => {
  const [roleToSave,setRoleToSave] = useState()
  const [roleJustSaved,setRoleJustSaved] = useState()
  const [statusMessage,setStatusMessage] = useState()
  const [statusType,setStatusType] = useState()
  const [action,setAction] = useState()
  const DELETE_ACTION_TXT = "DELETE"
  const ADD_ACTION_TXT = "ADD"
  const EDIT_ACTION_TXT = "EDIT"

  const entityType = entityTypeText(parentEntityType)

  const editRole = (event,roleData) => {
    event.preventDefault()
    setAction(EDIT_ACTION_TXT)
    setRoleToSave(roleData)
    setStatusMessage()
    setStatusType()
  }

  const saveRole = (event,addOrEditAction) => {
    event.preventDefault()
    setStatusMessage()
    setStatusType()

    setAction(addOrEditAction)

    const requestBody = {
      "entityName": roleToSave[nameAttribute],
      "entityId": roleToSave[idAttribute],
      "parentEntityId": teamId
    }

    const validate = () => {
      if (!roleToSave || (roleToSave && roleToSave[nameAttribute] === '')) {
        onError(`Please provide a name for the ${entityType}`)
        return false
      } else {
        return true
      }
    }

    const onSuccess = (resp) => {
      setRoleJustSaved(() => resp)
      setRoleToSave()
      setStatusMessage(`${resp[nameAttribute]} has been saved.`)
      setStatusType('success')
      setRoles(() => replaceSavedRole(roles,resp))
      setAction()
    }

    const replaceSavedRole = (currentList,savedRole) => {
      const isNew = currentList.filter(role => role[idAttribute] === savedRole[idAttribute]).length === 0
      if(isNew === true) {
        currentList.push(savedRole)
        return currentList.sort((a,b) => a[nameAttribute].toLowerCase().localeCompare(b[nameAttribute].toLowerCase()))
      } else {
        return currentList.map(role => {
          if(role[idAttribute] === savedRole[idAttribute]) {
            return savedRole
          } else {
            return role
          }
        })
      }
    }
    const endpoint = (parentEntityType === 'team_of_teams') ? '/api/team' : '/api/role'

    if(addOrEditAction === ADD_ACTION_TXT) {
      post(endpoint,onSuccess,onError,validate,requestBody)
    } else {
      put(endpoint,onSuccess,onError,validate,requestBody)
    }
  }

  const deleteRole = (event,roleData) => {   
    event.preventDefault()
    setStatusMessage('')
    setStatusType('')
    setRoleToSave(roleData)

    const onSuccess = (resp) => {
      setRoleToSave()
      setStatusMessage(`${entityType} deleted`)
      setStatusType('success')
      setRoles(() => resp.roles)
      setAction('')
    }

    setAction(DELETE_ACTION_TXT)

    httpDelete(
      `/api/entity/${roleData[idAttribute]}`,
      onSuccess,
      onError,
      null,
      null
      )
  }
  
  const onError = (error) => {
    setStatusMessage(error)
    setStatusType('error')
  }

  const updateRoleToSave = (roleName) => {
    setRoleToSave((previous) => {
      const newItem = (previous) ? previous : []
      newItem[nameAttribute] = roleName
      return newItem
    })
  }

  const cancel = (event) => {
    event.preventDefault()
    setAction('')
    setRoleToSave()
  }

  return (

    <section>
      <header>
        <p>Add, edit or remove {entityType} associated to this team below.</p>

        {
          entityType === 'role' &&
          <div className="please-note">
            <p>Please note:</p>
            <ul>
              <li>Once you add a role, you will need to go to the members page to assign members to that role.</li>
              <li>You will not be able to delete a role that has members assigned to it.</li>
            </ul>
          </div>
        }
       
    </header>
      {
        roles && roles.length > 0 &&
        
        <>
        
        {
          statusMessage !== '' && statusType === 'success' && 
          <Alert type={statusType} message={statusMessage} context="inline" />
        }

          <table className="role-actions">
            <thead>
              <tr>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
          {
            roles.map(thisRole => 
              <tr key={`role-${thisRole[idAttribute]}`} className={(roleJustSaved && roleJustSaved[idAttribute] === thisRole[idAttribute] && statusType === "success") ? 'alert-success': '' }>
                <td>
                  {
                    (!action || action !== EDIT_ACTION_TXT || (roleToSave && roleToSave[idAttribute] !== thisRole[idAttribute])) &&
                    <span>{thisRole[nameAttribute]}</span>
                  }

                  {
                    action && action === EDIT_ACTION_TXT && roleToSave && roleToSave[idAttribute] === thisRole[idAttribute] &&
                    <FormField 
                      id={`editRole${thisRole[idAttribute]}`}
                      value={roleToSave[nameAttribute]} 
                      handleChange={updateRoleToSave} />
                  }

                  {
                    action && action !== ADD_ACTION_TXT &&
                    (
                      (roleToSave && roleToSave[idAttribute] === thisRole[idAttribute] && statusType === 'error' && statusMessage !== '')
                        ||
                      (!roleToSave && roleJustSaved && roleJustSaved[idAttribute] === thisRole[idAttribute] && statusMessage !== '' && statusType !== '')
                    ) &&
                    
                      <Alert type={statusType} message={statusMessage} context="inline"/>
                  }
                </td>
                <td className="action">
                  {
                    action && action === EDIT_ACTION_TXT && roleToSave && roleToSave[idAttribute] === thisRole[idAttribute] &&
                    <>
                      <button onClick={(event) => {saveRole(event,EDIT_ACTION_TXT)}} className="btn btn-secondary" data-testid="button-saveEdit">Save </button>
                      <button className="btn btn-link" onClick={(event) => {cancel(event)}} data-testid="button-cancelEdit">Cancel</button>
                    </>
                  }
                  {
                    (!action || action !== EDIT_ACTION_TXT || (roleToSave && roleToSave[idAttribute] !== thisRole[idAttribute])) &&
                    <>
                      <button className="btn btn-link" onClick={(event) => {editRole(event,thisRole)}} data-testid={`button-editRole${thisRole[idAttribute]}`}>Edit</button>
                      <button className="btn btn-link" onClick={(event) => {deleteRole(event,thisRole)}} data-testid={`button-deleteRole${thisRole[idAttribute]}`}>Delete</button>
                    </>
                  }
                  
                </td>
              </tr>
            )
          }
          </tbody>
          </table>
          </>
      }

      {
        action !== EDIT_ACTION_TXT && 
        <table className="role-actions add-action">
            <tbody>
              <tr>
                <td className="role">
                  <FormField 
                    id="addRole"
                    placeholder={`Add a ${entityType}`}
                    value={() => {if(roleToSave) {return roleToSave[nameAttribute];} else {return ''}}} handleChange={updateRoleToSave} />

                  {
                    statusMessage !== '' && statusType === 'error' && action === ADD_ACTION_TXT &&
                    <Alert type={statusType} message={statusMessage} context="inline" />
                  }
                </td>
                <td className="action">
                  <button onClick={(event) => {saveRole(event,ADD_ACTION_TXT)}} className="btn btn-secondary" data-testid="button-addRole">Add </button>
                </td>
              </tr>
              </tbody>
          </table>
      }
          
    </section>
  )
  
}

export default ManageRolesByTeam