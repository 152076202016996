import React  from 'react'
import { Link } from 'react-router-dom'
import dateManager from '@ministry-squads-common/date-manager'
import DisplayDaysAndTimes from './DisplayDaysAndTimes'

import '../events.scss'

const EventSeriesSummary = ({ saveResult }) => {
  return (
    <>
      <h3>{saveResult.eventSeriesName}</h3>
      <DisplayDaysAndTimes events={saveResult.events} asLink={true} />
    </>
  )
}

export default EventSeriesSummary