import React, { createContext, useContext,useState,useEffect } from 'react'
import {detail} from '../services/event.service'
import {get} from '../lib/call-api'

const INITIALIZE_EVENT_DATA = "INITIALIZE"
const SET_AGENDA_DATA = "SET_AGENDA"
const EventContext = createContext()

const defaultObject = {
  "eventName": null,
  "eventTypeId": null,
  "startDate": null,
  "endDate": null,
  "rangeStartDate": null,
  "rangeEndDate": null,
  "startTime": null,
  "endTime": null,
  "repeatEvery": "none",
  "selectedTeams": [],
  "attendees": [],
  "agenda": null
}

const reducer = (state, action) => {
  if(defaultObject.hasOwnProperty(action.type) === true) {
    const newState = state
    newState[action.type] = action.payload
    return newState
  } else if(action.type === INITIALIZE_EVENT_DATA) {
    // since this is called async with the function that sets the agenda
    // we need to make sure we preserve the existing agenda, otherwise it is blanked out
    const existingAgenda = state.agenda
    return {
      ...action.payload,
      "agenda": existingAgenda
    }
  } else if(action.type === SET_AGENDA_DATA) {
    return {
      ...state,
      "agenda": action.payload
    }
  } else {
    throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const getAgendaDetails = async (eventId,dispatch) => {
  const onAgendaGet = (resp) => {
    dispatch({
      "type": SET_AGENDA_DATA,
      "payload": resp
    })
  }

  get(`/api/agenda/${eventId}`,onAgendaGet,() => {})
}

export const getEventDetails = async (eventId,dispatch) => {
  const data = await detail(eventId)
  dispatch({
    "type": INITIALIZE_EVENT_DATA,
    "payload": data
  })
}

const EventProvider = ({children}) => {

  let [ state, dispatch ] = React.useReducer(reducer, defaultObject)
  
  let initialState = [ state, dispatch ]

  let eventId = children.props.scheduledEventId

  useEffect(() => {
    if(eventId) {
      getEventDetails(eventId,dispatch)
      getAgendaDetails(eventId,dispatch)
    }
  }, [ eventId,dispatch ])

  return (
    <EventContext.Provider value={initialState}>
      { children }
    </EventContext.Provider>
  )
}



const useEventState = () => {
    const context = useContext(EventContext)
    if (context === undefined) {
      throw new Error('useEventState must be used within a EventProvider')
    }
    return context
}

export { useEventState, EventProvider  }


