import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import sessionManager from '../lib/session'
import dateManager from '@ministry-squads-common/date-manager'
import SecondaryContent from '../components/SecondaryContent'
import {getDisplayData} from './common.js'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import Alert from '../components/Alert'
import AssignSongToEvent from './components/AssignSongToEvent'
import SaveSong from './components/SaveSong'
import SaveUrls from './components/SaveUrls'
import SongArrangementManager from '../songArrangements/components/SongArrangementManager'
import ArrangementsList from '../songArrangements/components/ArrangementsList'
import {get} from '../lib/call-api'
import UserAvatar from '../components/UserAvatar'
import './songs.scss'
import useIsMountedRef from '../hooks/useIsMountedRef.js'
import AdminOnly from '../routes/components/AdminOnly'

const SongDetail = (props) => {
  const session = sessionManager.getSession()

  const [songData, setSongData] = useState()
  const [mediaUrls, setMediaUrls] = useState()
  const [musicUrls, setMusicUrls] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [, flyoutDispatch] = useSecondaryContentState()
  const [saved, setSaved] = useState(false)
  const { songId } = props.match.params
  const [historyToDisplay,setHistoryToDisplay] = useState()
  const [modalTitle,setModalTitle] = useState(null)
  const [selectedArrangement,setSelectedArrangement] = useState(null)

  const [refresh,setRefresh] = useState(null)

  const isMounted = useIsMountedRef()

  const EDIT_SONG_MODAL_TITLE = 'Edit song details'
  const ASSIGN_SONG_MODAL_TITLE = 'Add song to an event'
  const EDIT_ARRANGEMENT_MODAL_TITLE = 'View arrangement details'
  const ADD_ARRANGEMENT_MODAL_TITLE = 'Add a new arrangement'
  const EDIT_MEDIA_LINKS_MODAL_TITLE = 'Edit media links'
  const EDIT_MUSIC_LINKS_MODAL_TITLE = 'Edit lyrics & music links'
  const INITIAL_HISTORY_DISPLAY_COUNT = 15
  const SONG_EXAMPLE_DB_TEXT = "SONG_MEDIA"
  const SONG_MUSIC_DB_TEXT = "SONG_MUSIC"

  const openArrangement = (arrangement) => {
    setModalTitle(EDIT_ARRANGEMENT_MODAL_TITLE)
    setSelectedArrangement(arrangement)
  }

  useEffect(() => {
    if(modalTitle !== null) flyoutDispatch({ type: 'toggleIsOpen', payload: null })
  },[modalTitle,flyoutDispatch])

  useEffect(() => {
    const onGet = (resp) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        setSongData(resp.songData)
        setMediaUrls(() => resp.songData.urls.filter(url => url.urlType === SONG_EXAMPLE_DB_TEXT))
        setMusicUrls(() => resp.songData.urls.filter(url => url.urlType === SONG_MUSIC_DB_TEXT))
        setHistoryToDisplay(resp.songData.eventsByWeek.slice(0,INITIAL_HISTORY_DISPLAY_COUNT))
        setRefresh(false) // reset back to false so if the user adds or edits another arrangement, we'll refresh again
      }  
    }

    const handleError = (err) => {
      setIsLoading(false)
      setErrorMessage(err)
    }

    // refresh is null by default, so if it has that value,
    // the component just loaded. if refresh is true, then the user
    // just updated something in a flyout that causes us to need to refresh the page
    if(refresh === null || refresh === true) {
      get(
        `/api/song/detail/${songId}/${session.orgData.orgId}`,
        onGet,
        handleError
      )
    }
    

    return  (() => { isMounted.current = false })
  }, [songId,saved,session.orgData.orgId,isMounted,refresh])


  const updateOnDetailSave = (data, message) => {
    setSuccessMessage(message)
    setSongData(data)
    setModalTitle(null)
    setRefresh(true)
    flyoutDispatch({ type: 'toggleIsOpen', payload: null })
  }

  const updateOnEventSave = (data, message) => {
    setSuccessMessage(message)
    setSaved(data)
    setModalTitle(null)
    setRefresh(true)
    flyoutDispatch({ type: 'toggleIsOpen', payload: null })
  }

  const flyoutCancel = () => {
    flyoutDispatch({ type: 'toggleIsOpen', payload: null })
    setModalTitle(null)
  }

  const updateOnArrangementSave = () => {
    setRefresh(true)
  }

  return (
    <section className="page">
      {isLoading === false && !songData && (
        <>
          <header>
            <div className="breadcrumbs">
              <Link to="/song/list">Songs</Link>
            </div>
            <h1>Song details</h1>
          </header>
          {errorMessage === '' && (
            <p className="no-results">
              Sorry, no song found. <Link to="/song/list">View all songs</Link>
            </p>
          )}
          {errorMessage !== '' && <Alert type="error" message={errorMessage} />}
        </>
      )}

      {isLoading === false && songData && (
        <>
          <header>
            <div className="breadcrumbs">
              <Link to="/song/list">Songs</Link>
            </div>
            <h1>{songData.songTitle}</h1>
            
            <div className="row after-heading">
              <div className="col-md-3">
                <div className="form-group inline">
                  <label>CCLI Number:</label>
                  <div>
                    <ShowData data={songData.ccliNumber} />
                  </div>
                </div>  
              </div>
              <div className="col-md-3">
                <div className="form-group inline">
                  <label>Recording Artist (key):</label>
                  <div><ShowData data={songData.recordingArtist}/> (<ShowData data={songData.recordedKey} />)</div>
                </div>
              </div>
            </div>                
            
            <AdminOnly>
              <div className="feature-manager">
                <button
                  className="btn btn-icon btn-edit"
                  onClick={() => setModalTitle(EDIT_SONG_MODAL_TITLE)}>
                  Edit basics
                </button>
              </div>
            </AdminOnly>
          </header>
          
            <div>
              {successMessage !== '' && (
                <Alert type="success" message={successMessage} />
              )}

              <div className="card-layout min-left">
                <div className="card-column">

                <div className="card">
                  <header>
                    <h2>Lyrics &amp; music</h2>
                    


                    </header>
                  
                    <section>
                    <ul>
                      {
                        songData.ccliNumber && songData.ccliNumber !== '' && 
                          <li>
                            <a href={`https://songselect.ccli.com/Songs/${songData.ccliNumber}`} target="_new">
                              View on CCLI
                              <i className="fas fa-external-link-alt"></i>
                            </a>
                          </li>
                      }


                      {
                        musicUrls && musicUrls.length > 0 &&
                          musicUrls.map((musicUrl,musicUrlCtr) => 
                            <li key={`music-url-ctr${musicUrlCtr}`}>
                              <a href={musicUrl.urlPath} target="_new">
                              {musicUrl.urlTitle}
                              <i className="fas fa-external-link-alt"></i>
                            </a>

                            </li>
                          )
                      }
                      </ul>

                      {
                        (!songData.ccliNumber || songData.ccliNumber === '') && musicUrls && musicUrls.length === 0 &&
                        <p className="no-results">No lyrics &amp; music links associated to this song</p>
                      }

                    <AdminOnly>
                      <div className="feature-manager">
                      <button 
                        className="btn btn-icon btn-settings btn-slim" 
                        onClick={() => setModalTitle(EDIT_MUSIC_LINKS_MODAL_TITLE)}>
                          Manage lyrics &amp; music links
                      </button>
                      </div>
                    </AdminOnly>
                    </section>
                    
                </div>

               

                <div className="card">
                  <header>
                    <h2>Arrangements</h2>
                    
                  </header>
                  <ArrangementsList arrangements={songData.arrangements} onSelect={openArrangement} />
                  <AdminOnly>
                  <div className="feature-manager">
                    <button 
                      className="btn btn-icon btn-add btn-slim" 
                      onClick={() => setModalTitle(ADD_ARRANGEMENT_MODAL_TITLE)}>
                        Add an arrangement
                    </button>
                    </div>
                    </AdminOnly>
                </div>

                  <div className="card">
                    <header>
                      <h2>Listen</h2>
                      
                    </header>
                    
                    {
                      mediaUrls &&
                      <MediaLinks list={mediaUrls} />
                    }

                    <AdminOnly>
                    <div className="feature-manager">
                       <button 
                         className="btn btn-icon btn-settings btn-slim" 
                         onClick={() => setModalTitle(EDIT_MEDIA_LINKS_MODAL_TITLE)}>
                           Manage media links
                       </button>
                      
                    </div>
                   </AdminOnly>
                      
                    
                  </div>

                </div>
                <div className="card-col">
                  <div className="card-column">
                    <div className="card">
                      <h2>Song History at {session.orgData.orgName}</h2>
                        <section>
                          <LedBySummary songData={songData} />
                        </section>

                        <section>
                          <header>
                            <h3>Full history</h3>
                            <AdminOnly>
                              <div className="feature-manager">
                                <button 
                                  className="btn btn-icon btn-add btn-slim" 
                                  onClick={() => setModalTitle(ASSIGN_SONG_MODAL_TITLE)}>
                                  Add song to an event
                                </button>
                              </div>
                          </AdminOnly>
                          </header>
                          

                        {(!historyToDisplay || historyToDisplay.length === 0) && 
                          <p className="no-results">No song history for this song</p>
                        }
                        {
                        historyToDisplay && historyToDisplay.length > 0 && (
                          <>
                          <table>
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Led By</th>
                                <th>Key</th>
                              </tr>
                              </thead>
                              <tbody>
                                {
                                  historyToDisplay.map(week => 
                                    <Fragment key={`event-week-${week.startDate}`}>
                                    {
                                      week.eventsByGroup.map(event => 
                                        <EventRow key={`event-${event.eventIds[0]}`} event={event} allEvents={songData.eventsPlayed}/>  
                                      )
                                    }
                                    </Fragment>
                                  )   
                                }
                            </tbody>
                          </table>
                          {
                            historyToDisplay.length < songData.eventsByWeek.length && songData.eventsByWeek.length > INITIAL_HISTORY_DISPLAY_COUNT &&
                            <p><button className="btn btn-link btn-slim" onClick={() => setHistoryToDisplay(songData.eventsByWeek)}>Show all {songData.eventsByWeek.length} events</button></p>
                          }
                          {
                            historyToDisplay.length === songData.eventsByWeek.length && songData.eventsByWeek.length > INITIAL_HISTORY_DISPLAY_COUNT &&
                            <p><button className="btn btn-link btn-slim" onClick={() => setHistoryToDisplay(songData.eventsByWeek.slice(0,INITIAL_HISTORY_DISPLAY_COUNT))}>Show most recent {INITIAL_HISTORY_DISPLAY_COUNT} events</button></p>
                          }
                          </>
                        )}
                        </section>
                    </div>
                  </div>
                </div>
              </div>
                        
              
              <SecondaryContent 
                heading={modalTitle}
                onClose={() => { setModalTitle(null)}} 
                show={modalTitle !== null}>
                
                {
                  modalTitle === EDIT_SONG_MODAL_TITLE &&
                  <SaveSong onSuccess={updateOnDetailSave} songData={songData} exampleUrl={mediaUrls} />
                }
                {
                  modalTitle === ASSIGN_SONG_MODAL_TITLE && 
                  <AssignSongToEvent songList={[songData]} onSave={() => updateOnEventSave(true, "Song added to event")}/>
                }
                {
                  modalTitle === EDIT_ARRANGEMENT_MODAL_TITLE && 
                  <SongArrangementManager songId={songData.songId} arrangementData={selectedArrangement} onSave={updateOnArrangementSave}/>
                }
                {
                  modalTitle === ADD_ARRANGEMENT_MODAL_TITLE && 
                  <SongArrangementManager songId={songData.songId} arrangementData={{}} initialMode='add' onSave={updateOnArrangementSave}/>
                }

                {
                  modalTitle === EDIT_MEDIA_LINKS_MODAL_TITLE && 
                  <SaveUrls 
                    onSuccess={() => setRefresh(true)} 
                    existingUrls={mediaUrls} 
                    onCancel={flyoutCancel} 
                    urlType={SONG_EXAMPLE_DB_TEXT} 
                    relatedToType='song' 
                    relatedToId={songData.songId} 
                    introCopy={[`Provide links to videos and other media related to this song.`]}
                    noItemsText={`There are no media links associated to this song`}
                  
                  />
                }

                {
                  modalTitle === EDIT_MUSIC_LINKS_MODAL_TITLE && 
                  <SaveUrls 
                    onSuccess={() => setRefresh(true)} 
                    existingUrls={musicUrls} 
                    onCancel={flyoutCancel} 
                    urlType={SONG_MUSIC_DB_TEXT} 
                    relatedToType='song' 
                    relatedToId={songData.songId} 
                    introCopy={[`Provide links to sheet music and other resources about the song.`, `Please note that a link to CCLI will automatically be added, if a CCLI number is provided for the song.`]}
                    noItemsText={`There are no links associated to this song`}
                    />
                }


              </SecondaryContent>
            </div>
         
         
        </>
      )}
    </section>
  )
}

const MediaLinks = ({list}) => {
  const getEmbedUrl = (watchUrl) => {
    // can't use the link you find on you tube via google to embed a youtube video
    // need to use the embed directory, and remove the v query param
    return watchUrl.urlPath.replace('/watch', '/embed').replace('?v=', '/')
  }

  const isVideoSite = (urlPath) => {
    if(!urlPath) {
      return false
    } else {
      return urlPath.indexOf('youtube.com') > 0 || urlPath.indexOf('vimeo.com') > 0
    }
    

  }
  const videoLinks = list.filter(url => isVideoSite(url.urlPath) === true)
  const nonVideoLinks = list.filter(url => isVideoSite(url.urlPath) === false)

  return (
    <>
    {
      videoLinks && videoLinks.length > 0 && (
        <>
          <div className="video-container">
            <iframe
              src={getEmbedUrl(videoLinks[0])}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Recording example"
            ></iframe>
          </div>

        </>
      )
    }

    <ul>
    {
      videoLinks && videoLinks.length > 1 && (
        videoLinks.map((notEmbedded,notEmbeddedCtr) => 
          notEmbeddedCtr > 0 &&
            <li key={`nonvideo-ctr-${notEmbeddedCtr}`}>
              <a href={notEmbedded.urlPath} target="_new">
                {notEmbedded.urlTitle}
                <i className="fas fa-external-link-alt"></i>
              </a>
            </li>
         
        )
      )
    }
    {
      nonVideoLinks && nonVideoLinks.length > 0 && (
        nonVideoLinks.map((nonVideo,nonVideoCtr) => 
          
            <li key={`nonvideo-ctr-${nonVideoCtr}`}>
              <a href={nonVideo.urlPath} target="_new">
                {nonVideo.urlTitle}
                <i className="fas fa-external-link-alt"></i>
              </a>
            </li>
         
        )
      )
    }
    </ul>


    {
      list && list.length === 0 && <p className="no-results">No media links associated to this song</p>
    }
   </>
  )
  
}

const ShowData = (props) => {
  if(props.data && props.data !== '') {
    if(props.children) {
      return(<>{props.children}</>)
    } else {
      return(<>{props.data}</>)  
    }    
  } else {
    return(<span className="no-results">N/A</span>)
  }
  
}

const EventRow = ({event,allEvents}) => {
  
  const displayData = getDisplayData(event,allEvents)

  
  return (
    <tr>
      <td className="event-with-date">
          <p><Link to={`/event/detail/${displayData.scheduledEventId}`}>{displayData.dateOutput}</Link></p>
          <small>{displayData.displayName}</small>
      </td>
      <td>
        {
          displayData.ledBy !== null && 
          <>
            <UserAvatar userData={displayData.ledBy} />{' '}
            {displayData.ledBy.firstName}{' '}
            {displayData.ledBy.lastName}
          </>
        }
        { 
          displayData.ledBy === null && 
          <p className="no-results">
          n/a
          </p>
        }
      </td>
      <td>
      {
        displayData.ledBy !== null && displayData.ledBy.songKey !== null && 
        <>
          {displayData.ledBy.songKey}
        </>
      }
      { 
        displayData.ledBy !== null && displayData.ledBy.songKey === null && 
        <p className="no-results">
        n/a
        </p>
      }
      </td>
    </tr>
  )
}

const UsageSummary = ({songData}) => {

  if(songData && songData.eventsPlayed.length > 0) {
    const lastPlayedItem = songData.eventsPlayed
    
    
    const lastPlayedDate = new Date(lastPlayedItem[0].eventDate)
    
    const lastPlayedDays = dateManager.diff(new Date(), lastPlayedDate,'DAYS')
    
    let lastPlayedDisplay
    let lastPlayedUnit
    let overText 
    if(lastPlayedDays <= 7) {
      lastPlayedDisplay = lastPlayedDays
      lastPlayedUnit = (lastPlayedDays > 1) ? 'days' : 'day'
    } else if(lastPlayedDays <= 30) {
      lastPlayedDisplay = lastPlayedDays/7
      lastPlayedUnit = 'weeks'
    } else if(lastPlayedDays < 365) {
      lastPlayedDisplay = dateManager.diff(new Date(), lastPlayedDate,'MONTHS')
      lastPlayedUnit = 'months'
    } else {
      lastPlayedDisplay = dateManager.diff(new Date(), lastPlayedDate,"YEARS")
      lastPlayedUnit = (lastPlayedDisplay < 2) ? 'year' : 'years'
    }

    if(lastPlayedDisplay % 1 !== 0) {
      overText = " over"
      lastPlayedDisplay = lastPlayedDisplay - (lastPlayedDisplay % 1)
    }

    const threeMonthsAgo = dateManager.subtract(new Date(),3,'MONTHS')
    const sixMonthsAgo = dateManager.subtract(new Date(),6,'MONTHS')
    const twelveMonthsAgo = dateManager.subtract(new Date(),12,'MONTHS')
    
    const inLastThreeMonths = (passedDate) => {
      return dateManager.diff(passedDate,threeMonthsAgo,'DAYS') > 0
    }

    const inLastSixMonths = (passedDate) => {
      return dateManager.diff(passedDate,threeMonthsAgo,'DAYS') < 0 && dateManager.diff(passedDate,sixMonthsAgo,'DAYS') > 0
    }

    const inLastSixTwelveMonths = (passedDate) => {
      return dateManager.diff(passedDate,sixMonthsAgo,'DAYS') < 0 && dateManager.diff(passedDate,twelveMonthsAgo,'DAYS') > 0
    }

    const inTwelveMonthsPlus = (passedDate) => {
      return dateManager.diff(passedDate,twelveMonthsAgo,'DAYS') < 0
    }

    const ranges = [{
        "label": "Last 3 months",
        "value": lastPlayedItem.filter(item => inLastThreeMonths(item.date)).length || 0
      },{
        "label": "3-6 months ago",
        "value": lastPlayedItem.filter(item => inLastSixMonths(item.date)).length || 0
      },{
        "label": "6-12 months ago",
        "value": lastPlayedItem.filter(item => inLastSixTwelveMonths(item.date)).length || 0
      },{
        "label": "Over 12 months ago",
        "value": lastPlayedItem.filter(item => inTwelveMonthsPlus(item.date)).length || 0
      }
    ]

    const total = ranges.reduce((a, b) => a + (b["value"] || 0), 0);
    return (
      <div className="row">
        <div className="col-md-4">
          <h3>Last played {overText}</h3>
          <div className="big-number">
            <span className="big-number-value">{lastPlayedDisplay}</span>
            <span className="big-number-label">{lastPlayedUnit} ago</span>
          </div>
        </div>
        <div className="col-md-8" id="song-usage-table">
          <div className="row">
            <div className="col-md-6">
              <h3>A Heading!</h3>
            </div>
            <div className="col-md-6">
             
            </div>
          </div>
          {
            ranges.map(range => 
              <div className="row" key={range.label}>
                <div className="col-md-6">
                  {range.label}
                </div>
                <div className="col-md-6">
                  <Bar val={range.value} max={total} />
                </div>
              </div>
            )
          }
          <div className="row">
                <div className="col-md-6">
                  Total
                </div>
                <div className="col-md-6">
                  <Bar val={total} max={total} />
                </div>
              </div>
        </div>
      </div>
    )
  } else {
    return (<div><p>No summary to display</p></div>)
  }
}

const Bar = ({val,max}) => {
  const value = parseInt(val)
  const maxValue = parseInt(max)
  if(value === 0) {
    return (
      <div className="no-results">not played</div>
    )
  } else {
    return (
      <div className="bar" style={{"width": `${(value/maxValue) * 100}%`}}>{value}</div>
    )
  }
  
}

const LedBySummary = ({songData}) => {
  
  if(songData && songData.eventsPlayed.length > 0) {
    const uniqueLeaderIds = [...new Set(songData.eventsPlayed.map(date => date.ledBy.userId))]
    return (
      <>
        <h3>Led by</h3> 
        <div className="lead-by-summary">
          {
            uniqueLeaderIds.map(ledBy => 
              <LeaderWithKeys songData={songData} leadByUserId={ledBy} key={`song-lead-${songData.songId}-${ledBy}`}/>
            )
          }
        </div>
      </>
    )
  } else {
    return null
  }
}

const LeaderWithKeys = ({songData,leadByUserId}) => {
  const datesWithLeader = songData.eventsPlayed.filter(date => date.ledBy.userId === leadByUserId)
  const displayData = {
    "keys": [...new Set(datesWithLeader.map(date => date.ledBy.songKey))],
    "leaderData": datesWithLeader[0].ledBy
  }
  return(
    <div className="stacked-user-data">
      <div className="avatar">
        <Link to={`/member/detail/${displayData.leaderData.userId}`}>
          <UserAvatar userData={displayData.leaderData} size="big"/>
        </Link>
      </div>
      <div className="name">
        <Link to={`/member/detail/${displayData.leaderData.userId}`}>
          {displayData.leaderData.firstName} {displayData.leaderData.lastName}
        </Link>

        <div className="supplemental">
          {
            displayData.keys.length > 1 &&
            <>Keys: </>
          }
          {
            displayData.keys.length === 1 &&
            <>Key: </>
          }
          {displayData.keys.map((key,ctr) => 
            <span key={`key-${key}-${ctr}`}>
              {key}
              {
                ctr !== displayData.keys.length - 1 &&
                <>, </>
              }
            </span>
          )}
        </div>
      </div>
      
    </div>
  )
  
}




export default SongDetail