import React, { useState } from 'react'
import DropDownField from './DropDownField'
import {getDisplayTime} from '../../lib/index'

const TimeOfDay = ({htmlId,onChange,errorMessage,selectedTime,scrollToTime}) => {
  
  const removeSeconds = (timeWithSeconds) => {
    if(timeWithSeconds === null) {
      return null
   } else {
      const hms = timeWithSeconds.split(":")
      return `${hms[0]}:${hms[1]}`
    }
  }

  const [localTime,setLocalTime] = useState(removeSeconds(selectedTime))

  const addZeros = (number) => {
    if(number < 10) {
      return '0' + number 
    } else {
      return number
    }
  }
  
  const getTimes = () => {
    const times = [{
      "value": null,
      "label":""
    }]
    const incrementsPerHour = 4
    for (let i=0;i<24;i++) {
      for(let z=0;z<incrementsPerHour;z++) {
        const minutes = addZeros(z * (60/incrementsPerHour))
        times.push({
          "value": `${addZeros(i)}:${minutes}`,
          "label": getDisplayTime(i,minutes)
        })
      }
    }
    return times
  }

  //const times = getTimes()

  return (
    <span className="time-selector">
    <DropDownField 
        id={htmlId}
        idField="value"
        labelField="label"
        showDefaultOption={false}
        selectedValue={localTime}
        values={getTimes()}
        handleChange={(val) => {setLocalTime(val); onChange(val)}}
        errorMessage={errorMessage}
        scrollToValue={scrollToTime}
      />
    </span>
  )
}

export default TimeOfDay