import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import sessionManager from '../../lib/session'
import {get} from '../../lib/call-api'
import {intersection} from '../../lib/index'
import Alert from '../../components/Alert'
import { getDateOnly,getEventDisplayName } from '../../events/common.js'
import './MemberAssignments.scss'
import useIsMountedRef from '../../hooks/useIsMountedRef'
import DisplayDaysAndTimes from '../../events/components/DisplayDaysAndTimes'
import EventDate from '../../events/components/EventDate'

/**
 * 
 * @param {limit} boolean - indicates if we want to limit the events displayed to only include the first week of events
 * @returns 
 */
const MemberAssignments = ({limit = false}) => {
    const session = sessionManager.getSession()
    const [userSchedule, setUserSchedule] = useState([])
    const [globalErrorMessage,setGlobalErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const isMounted = useIsMountedRef()

    const pageHeaderText = (limit === true) ? "Your next assignment(s)" : "Your upcoming assignments"

    useEffect(() => {
        const groupByDate = (respData) => {
            if(!respData || !respData.assignments || respData.assignments.length === 0) return []
            
            let eventsData
            if(limit === true) {
                // when limit is true, we only want to show the first week's worth of assignments
                // get an array of event Ids as an object, so we can call the intersection function
                const firstWeekEventIds = respData.eventsByWeek[0].events.map(scheduledEventId => {return {"scheduledEventId": scheduledEventId}})
                eventsData = intersection(respData.events,firstWeekEventIds,"scheduledEventId","scheduledEventId")
            } else {
                // if there is no limit, just return all events for display
                eventsData = respData.events
            }

            const assignments = respData.assignments

            if(eventsData.length === 0) return []

            const uniqueDays = [...new Set(eventsData.map(evt => getDateOnly(evt.startDate)))]

            const allRoles = respData.roles
            // merge events data with the role(s) the user has for each event
            const eventsWithAssignments = eventsData.map(event => {
                const thisRole = assignments.filter(assignment => assignment.scheduledEventId === event.scheduledEventId)
                return {
                    ...event,
                    "roles": (thisRole.length > 0) ? intersection(allRoles,thisRole,'roleId','roleId') : []
                }
            })
            
            return uniqueDays.map(day => {
                const assignmentsOnDay = eventsWithAssignments.filter(evt => getDateOnly(evt.startDate) === day)
                const uniqueSeriesIds = [...new Set(assignmentsOnDay.map(evt => evt.eventSeriesId))]
                return {
                    "day": day,
                    "events": uniqueSeriesIds.map(seriesId => assignmentsOnDay.filter(assignment => assignment.eventSeriesId === seriesId))
                }
            })

        }
    
        const onGet = (resp) => {
            if(resp.events && isMounted.current === true) {
                setUserSchedule(groupByDate(resp))
                setIsLoading(false)
            }
        }
        get(`/api/schedule/member/${session.userData.userId}`,onGet,setGlobalErrorMessage)

        return () => { isMounted.current = false }

    }, [isMounted,session.userData.userId,limit])
    
    return ( 
       <>
       <h3>{ pageHeaderText }</h3>
            <Alert type='error' message={globalErrorMessage} />

            {
                isLoading === false &&
                <AssignmentSumary userSchedule={userSchedule} showLink={limit} userId={session.userData.userId} />
            }
         </>   
    )
}

const AssignmentSumary = ({userSchedule,showLink,userId}) => { 
    if(userSchedule.length > 0) {
        return (
            <>
            <div className="upcoming-member-assignments">
                {
                    userSchedule.length > 0 &&
                    userSchedule.map(
                        day => 
                        <div className="member-assignments-day" key={`day-${day.day}`}>
                           <div className="event-date-container">
                            <EventDate displayDate={day.day} />
                            </div>
                            <div className="event">
                             {
                            day.events.map(evt => 
                            
                                <div key={`assignment-summary-${evt[0].scheduledEventId }`}>
                                    <Link to={`/schedule/event/detail/${evt[0].scheduledEventId}`}>{getEventDisplayName(evt[0])}</Link>
                                    <RolesForEvents events={evt} />
                                </div>
                            )
                            
                            }
                            </div>
                        </div>
                    )
                }
            </div>
            {
                showLink === true && 
                <Link to={`/schedule/member/${userId}`}>All upcoming assignments</Link>
            }
            </>   
        )
    }
    
    if(userSchedule.length === 0) {
        return (
            <div className='no-results'>
                <p>You are not assigned to any upcoming events.</p>
            </div>
        )
    }
}

const RolesForEvents = ({events}) => {
    const allRoles = events.map(evt => evt.roles).map(role => role).flat()
    const uniqueRoleIds = [...new Set(allRoles.map(role => role.roleId))]
    
    const getRoleById = (roleData,roleId) => {
        return roleData.filter(role => role.roleId === roleId)[0]
    }

     const outputEvents = (allEvents,roleId) => {
        
        let eventsWithRole = []
        allEvents.forEach(event => {
            if(event.roles.filter(role => role.roleId === roleId).length > 0) {
                eventsWithRole.push(event)
            }
        })

        return eventsWithRole

     }

    return (
        <>
        {
            events && events.length > 0 &&    
            <ul className="member-event-assignments">
            {
                uniqueRoleIds.map(
                    roleId => 
                        <li 
                            data-testid={`assignment-role-${roleId}-${events[0].scheduledEventId}`} 
                            key={`assign-summary-role-${roleId}`}>
                                {getRoleById(allRoles,roleId).roleName}&nbsp;-&nbsp; 
                                <DisplayDaysAndTimes events={outputEvents(events,roleId)} levelOfDetail="timeonly" />
                        </li>
                    )
            }
            </ul>
        }
        </>
    )
}

export default MemberAssignments