import React from 'react'
import { Link } from 'react-router-dom'
import dateManager from '@ministry-squads-common/date-manager'

const DisplayDaysAndTimes = ({events,levelOfDetail = "full",asLink=false}) => {

    const uniqueDates = [...new Set(events.map(event => dateManager.formatDate(event.startDateTime,"URL_PARAMETER")))]
  
    const result = uniqueDates.map(uniqueDate => {
        const eventsOnDate = events.filter(event => dateManager.formatDate(event.startDateTime,"URL_PARAMETER") === uniqueDate)
        
        return {
          "scheduledEventId": eventsOnDate[0].scheduledEventId,  
          "date": new Date(eventsOnDate[0].startDateTime),
          "times": eventsOnDate.map(event => {
            return {
                "isActive": event.isActive,
                "startTime": dateManager.formatDate(event.startDateTime,'DISPLAY_TIME_ONLY'),
                "endTime": (event.endDateTime) ? dateManager.formatDate(event.endDateTime,'DISPLAY_TIME_ONLY') : null
              }
          })
        }
    })

    const getSeparator = (list) => {
      let seperator = " "
      if(list.length === 2) {
          seperator = ' & '
      } else if(list.length > 2) {
          seperator = ', '
      }
      return seperator
    }

    const getDayClassName = (eventsOnDay) => {
      return (eventsOnDay.filter(evt => evt.isActive === false).length === eventsOnDay.length) ? 'cancelled' : 'active'
    }

    const getEventClassName = (eventData) => (eventData.isActive === false) ? 'cancelled' : 'active'
    

    

    return (
      <ul className="days-and-times">
       { 
        result.map((dayOfWeek,dayCtr) => 
        <li key={`day-of-week-${dayCtr}`} className={getDayClassName(dayOfWeek.times)}>
           <Wrapper showLink={asLink} scheduledEventId={dayOfWeek.scheduledEventId}>
            {
              levelOfDetail === "full" && 
              <> 
              {dateManager.formatDate(dayOfWeek.date,'TEXT_FULL')} at&nbsp;
              </>
            }
            {
              dayOfWeek.times.map((item,timeCtr) => 
                <span key={`time-${dayCtr}-${timeCtr}`} className={getEventClassName(item)}>
                  {item.startTime}
                  {
                    item.endTime &&
                    <> to {item.endTime}</>
                  }
                  {
                    (timeCtr !== dayOfWeek.times.length - 1) && 
                    <>{getSeparator(dayOfWeek.times)}</>
                  }
                </span>
              )
            }

          </Wrapper>
          </li>
        )}
        </ul>
    )      
}

const Wrapper = (props) => {

  if(props.showLink === true) {
    return (<Link to={`/calendar/event/detail/${props.scheduledEventId}`}>
      {props.children}
    </Link>)
  } else {
    return (props.children)
  }

}

export default DisplayDaysAndTimes