import React, { useEffect, useState } from "react"
import {put,httpDelete} from '../../lib/call-api'
import Alert from '../../components/Alert'
import ManageSongAssignment from './ManageSongAssignment'

const ManageSongsByEvent = ({songs,eventIds,onSave,unique}) => {
  const [,setSongIdDeleted] = useState(false)
  const [songsToDisplay,setSongsToDisplay] = useState(songs)
  const [successMessage, setSuccessMessage] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [songEditing,setSongEditing] = useState()
  const [updatedSongAssignment,setUpdatedSongAssignment] = useState([])

  const reset = () => {
    setSuccessMessage()
    setErrorMessage()
    setSongEditing()
    setUpdatedSongAssignment([])
  }

  useEffect(() => {
      reset()
  }, [unique])

  const deleteSong = (event,songToDelete) => {   
    event.preventDefault()
    
    const onSuccess = (resp) => {
      setSongIdDeleted(songToDelete.songId)
      setSongsToDisplay((current) => current.filter(song => song.songId !== songToDelete.songId))
      setSuccessMessage(`${songToDelete.songTitle} has been removed.`)
      setErrorMessage()
      onSave()
    }

    const body = {
      "scheduledEventIds": eventIds
    }

    httpDelete(
      `/api/song/${songToDelete.songId}`,
      onSuccess,
      onError,
      null,
      null,
      body
      )
  }
  
  const onError = (error) => {
    setErrorMessage("Couldn't remove the song.")
    setSuccessMessage()

  }

  const cancel = (event) => {
    event.preventDefault()
    setSongEditing()
    setSuccessMessage()
    setErrorMessage()
    setUpdatedSongAssignment()
  }

  const editSong = (event,songData) => {
    event.preventDefault()
    setSongEditing(songData)
    setUpdatedSongAssignment(songData)
  }

  const saveSong = (event,songAssignment) => {
    event.preventDefault()
   
    const onPut = () => {
      const songTitle = songEditing.songTitle
      setSuccessMessage(`Successfully saved song assignment for ${songTitle}`)
      console.log(songsToDisplay)
      setSongEditing()
      setErrorMessage()
      onSave()
    }

    const onPutError = (error) => {
      setErrorMessage(error)
      setSuccessMessage()
    }

    const validateForm = () => {
      return true
    }
    
    const putBody = {
      "scheduledEventIds": eventIds,
      "songAssignment": songAssignment      
    }
    put("/api/song/assign-member-date",onPut,onPutError,validateForm,putBody)

  }

  return (
    <section>
      {
        successMessage && 
        <Alert type="success" message={successMessage} />
      }

      {
        errorMessage && 
        <Alert type="error" message={errorMessage} />
      }

      {
        songsToDisplay && songsToDisplay.length > 0 &&
        
          <table className="role-actions">
            <thead>
              <tr>
                <th>Song</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
          {
            songsToDisplay.map(thisSong => 
              <tr key={`song-${thisSong.songId}`}>
              {
                (!songEditing || (songEditing && (songEditing.songId !== thisSong.songId))) && 
                <>
                <td>
                  {thisSong.songTitle} 
                  {
                      thisSong.ledBy && 
                      <>
                      <span className="song-lead"> - {thisSong.ledBy.firstName} {thisSong.ledBy.lastName}</span>
                      {
                        thisSong.ledBy.songKey && 
                        <span className="song-key"> ({thisSong.ledBy.songKey})</span>
                      }
                      </>
                  }
                </td>
                <td className="action">
                  <button className="btn btn-link" onClick={(event) => {editSong(event,thisSong)}}>Edit</button>
                  <button className="btn btn-link" onClick={(event) => {deleteSong(event,thisSong)}}>Delete</button>
                </td>
                  </>
              }

              {
                songEditing && songEditing.songId === thisSong.songId && 
                <>
                <td>
                  <div>{thisSong.songTitle}</div>
                  <ManageSongAssignment songData={{"songId": songEditing.songId}} assignmentData={songEditing} onDataChange={(newAssignment) => setUpdatedSongAssignment(newAssignment)} />
                </td>
                <td className="action">
                  <button className="btn btn-primary" onClick={(event) => {saveSong(event,updatedSongAssignment)}}>Save</button>
                  <button className="btn btn-link" onClick={(event) => {cancel(event)}}>Cancel</button>
                </td>
              </>
              }
              </tr>
              
            )
          }
          </tbody>
          </table>
      }
          
    </section>
  )
  
}

export default ManageSongsByEvent