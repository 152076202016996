import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import SaveMember from './components/SaveMember'
import Alert from '../components/Alert'
import {get,post} from '../lib/call-api'
import AdminOnly from '../routes/components/AdminOnly'
import UserAvatar from '../components/UserAvatar'
import SubmitButton from '../components/SubmitButton'
import SecondaryContent from '../components/SecondaryContent'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import dateManager from '@ministry-squads-common/date-manager'
import sessionManager from '../lib/session'
import useIsMountedRef from '../hooks/useIsMountedRef'
import MemberStatusIndicator from './components/MemberStatusIndicator'

const MemberDetail = (props) => {
  const session = sessionManager.getSession()

  const [memberData, setMemberData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const {userId} = props.match.params
  const [successMessage, setSuccessMessage] = useState('')
  const [modalState, modalDispatch] = useSecondaryContentState()

  const [secondaryContentType,setSecondaryContentType] = useState()
  const [secondaryContentAction,setSecondaryContentAction] = useState()
  const [secondaryContentHeader,setSecondaryContentHeader] = useState()
  const isMounted = useIsMountedRef()

  const toggleSlider = (action) => {
    setSecondaryContentAction(action)
    if(action === 'editMember') {
      setSecondaryContentType('flyout')
      setSecondaryContentHeader(`Edit this member's data`)
      modalDispatch({ type: 'toggleIsOpen', payload: null })
    } else if(action === 'approveMember') {
      setSecondaryContentHeader(`Add ${memberData.firstName} to Cornerstone Church?`)
      setSecondaryContentType('popup')
      modalDispatch({ type: 'toggleIsOpen', payload: null })
    }
  } 
    
  useEffect(() => {
    const onGet = (resp) => {
      if(isMounted.current === true) {
        setMemberData(resp.memberDetail)
        setIsLoading(false)
      }
    }

    const onGetError = (resp) => {
      setIsLoading(false)
      setErrorMessage(resp)
    }
    get(`/api/member/detail/${userId}`,onGet,onGetError)

    return () => isMounted.current = false
  }, [userId, successMessage, isMounted])


  const approveUser = (event) => {
    event.preventDefault()
    const postBody = {
      "userId": memberData.userId,
      "status": "approve",
      "orgId": session.orgData.orgId
    }

    const onStatusUpdateSuccess = (resp) => {
      setSuccessMessage(`User has been approved.`)
      setErrorMessage('')
      closeSecondaryContent()
      setIsLoading(false)
    }

    const onStatusUpdateError = (resp) => {
      setSecondaryContentHeader()
      setSecondaryContentType()
      closeSecondaryContent()
      setIsLoading(false)
      setErrorMessage(`Could not update the user's status.`)
    }

    post('/api/member/status', onStatusUpdateSuccess,onStatusUpdateError,() => true, postBody)

  }
  const updateOnAdd = (data, message) => {
    setMemberData(data)
    setSuccessMessage(message)
    closeSecondaryContent()
  }

  const closeSecondaryContent = () => {
    modalDispatch({ type: 'close', payload: null })
    setSecondaryContentHeader()
    setSecondaryContentType()

  }
  return (
    <section className="page">
        {
          isLoading === false && 
          <>
          <header>
          <div className="breadcrumbs">
              <Link to="/member/list">Members</Link>
            </div>
            {
            memberData && 
            <>
              <h1>
                <UserAvatar userData={memberData} size="big" />
                {memberData.firstName} {memberData.lastName} 
                <MemberStatusIndicator memberData={memberData} />
              </h1>
              <AdminOnly>
                <div className="feature-manager">
                  <button className="btn btn-icon btn-edit" onClick={() => toggleSlider('editMember')}>Edit this member</button>
                </div>  
              </AdminOnly>
              <Alert type="success" message={successMessage} />
                
              {
                memberData.status === "pending" && 
                <Alert type="warning">
                  <button className="btn btn-link btn-slim" onClick={() => {toggleSlider('approveMember')}}>This member is waiting for your approval</button>
                </Alert>
              }

              <Alert type="error" message={errorMessage} />
              </>
            }
            {
              !memberData && 
              <>
                <h1>Member Details</h1>
                <Alert type="error" message={errorMessage} />
              </>
            }
          </header>
          {
            memberData && 
          <div className="card-layout min-left">
            <div className="card-column">
              <div className="card">
                <div className="card-header">
                  <h2>Member Details</h2>
                </div>
                <div className="card-content">
                <label>Email</label>
                {
                  memberData.email && 
                  <p>{memberData.email}</p>        
                }
                {
                  !memberData.email && 
                  <p className="no-results">This person doesn't have an email address in the system</p>        
                }

                {
                  memberData.isMinor === true &&
                  <>
                  <label>Guardian information</label>
                  <p>This person is a minor.</p>
                  <ul>
                  {
                    memberData.guardians.map(guardianData => 
                      <li key={`guardian${guardianData.userId}`}><Link to={`/member/detail/${guardianData.userId}`}>{guardianData.firstName} {guardianData.lastName}</Link></li>
                    )
                  }
                  </ul>
                  </>
                }
                <AdminOnly>
                    <MemberStatus memberData={memberData} toggleSlider={toggleSlider}/>
                  </AdminOnly>
                </div>
              </div>
            </div>
            <div className="card-column">
              <div className="card">
                <div className="card-header">
                  <h2>Teams</h2>
                </div>
                <div className="card-content">
                  <MemberMembership teams={memberData.teams} roles={memberData.roles} />
                </div>
              </div>
            </div>
            <div className="card-column">
              <div className="card">
                <div className="card-header">
                  <h2>Additional Attributes</h2>
                </div>
                <div className="card-content">
                  {
                    memberData.attributes && memberData.attributes.length === 0 &&
                    <p className="no-results">No additional attributes</p>
                  }
                  {
                    memberData.attributes && memberData.attributes.length > 0 &&
                    <ul>
                      {
                        memberData.attributes.map(attribute => 
                          <li key={`userattribute-${attribute.attributeId}`}> {attribute.attributeName} </li>)
                      }
                    
                    </ul>
                  }
                </div>
              </div>
            </div>
          </div>
          }
          </>
        }

        <SecondaryContent 
          heading={secondaryContentHeader}
          onClose={closeSecondaryContent} 
          show={modalState.isOpen}
          type={secondaryContentType}>
          
          {
            secondaryContentAction === 'editMember' &&
            <SaveMember onSuccess={updateOnAdd} memberData={memberData}/>
          }
          {
            secondaryContentAction === 'approveMember' &&
            <div>
              <p>{memberData.firstName} has registered and requested that you add them to your organization.</p>

              <p>Until you approve them, they will have limited access to ministrysquads.com.</p>

              <div className="buttonContainer">
                <form onSubmit={(event) => approveUser(event)} >
                  <SubmitButton actionTextOverride="Approve" />
                </form>
              </div>
            </div>
          }
        </SecondaryContent>
      
    </section>
  )
}

const MemberStatus = ({memberData,toggleSlider}) => {
  const INVITED_BUT_NOT_FINISHED_TXT = "Invited"
  const [resendResult,setResendResult] = useState({
    "status":null,
    "message":null
  })
  const resendInvitation = (e,userId) => {
    e.preventDefault()

    setResendResult(() => {
      return {
        "status": null,
        "message": null
      }
    })

    const onResendSuccess = (resp) => {
      setResendResult(() => {
        return {
          "status": "success",
          "message": "Invitation email resent"
        }
      })
    } 

    const onResendError = (resp) => {
      setResendResult(() => {
        return {
          "status": "error",
          "message": "Could not resend invitation email"
        }
      })
    }

    const resendBody = {
      "userId": userId
    }
    post('/api/member/reinvite',onResendSuccess,onResendError,() => true,resendBody)
  }

    return (
      <>
       
        <AdminOnly>

        {
          memberData.loginStatus === null && 
          <>
            <label>Registration status</label>          
            <p>Not registered</p>
          </>
        }

        {
          memberData.loginStatus !== null && 
          <>
            {
              memberData.loginStatus === INVITED_BUT_NOT_FINISHED_TXT &&
              <>
                <label>Registration status</label>
                {
                  resendResult.status && resendResult.message &&
                  <Alert context="inline" type={resendResult.status} message={resendResult.message} />
                }
                <p>Invitation sent <button className="btn btn-link btn-slim right" onClick={(e) => resendInvitation(e,memberData.userId)}>Resend invitation</button></p>
              </>
            }

            {
              memberData.loginStatus !== INVITED_BUT_NOT_FINISHED_TXT &&
              <>
                <label>Last login</label>
                { 
                  memberData.lastLogin &&
                  <p>{dateManager.singleDate(memberData.lastLogin,'TEXT_TIMESTAMP')}</p>
                }
                { 
                  memberData.hasUserId === true && !memberData.lastLogin &&
                  <p>This user has never logged in</p>
                }
              </>
            }
          </>
}
        

        </AdminOnly>
       
        
        
      </>
    )
}

const MemberMembership = ({teams,roles}) => {
  const getRole = (roleId) => {
    return roles.filter(role => role.roleId === roleId)[0]
  }

  return (
    <div className="util-layout three-equal">
        {
          (!teams || teams.length === 0) &&
          <p className="no-results">Not assigned to any teams</p>
        }
        {
          teams && teams.length > 0 && 
          teams.map(team => 
            <div key={'team' + team.teamId}>
              <h4>{team.teamName}</h4>
              <div>
              {
                team.roles.length === 0 &&
                <p className="no-results">Not assigned to any roles</p>
              }
              {
                team.roles &&
                team.roles.map(roleId => 
                    <p key={'role' + roleId}>{getRole(roleId).roleName}</p>
                )
              }
              {
                team.isTeamAdmin === true &&
                <p>Team admin</p>
              }
              </div>
            </div>
          )
        }
      </div>
    )
}

export default MemberDetail