import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import Alert from '../components/Alert'
import EditOneTimeEvent from './components/EditOneTimeEvent'
import EditEventSeries from './components/EditEventSeries'

import './events.scss'
import { ManageEventProvider } from '../contexts/ManageEvent.Context'

const CreateEvent = () => {
  

  const [eventType,setEventType] = useState()

  const ONE_TIME = 'oneTime'
  const RECURRING = 'recurring'
  const GROUP = 'group'

  const [savedData,setSavedData] = useState(null)

  const onSave = (data) => {
    setSavedData(data)
  }

  return (

    <ManageEventProvider>
    <section className="page">
      {
        savedData !== null &&
    
    
          <div className="card">
            <header>
              <div className="breadcrumbs"><Link to="/calendar">Calendar</Link></div>
              <h1>{savedData.resultHeading}</h1>
              <Alert type={savedData.resultType} message={savedData.resultMessage} />
            </header>
            <section>
            {
             <>{savedData.savedOutput()}</>
            }
            </section>
          </div>
      }

        {
          savedData === null &&
          <>
          <header>
            <div className="breadcrumbs"><Link to="/calendar">Calendar</Link></div>
            <h1>Add an event</h1>
            <p className="intro">Add events to your organization's calendar.</p>

            <h2>What kind of event do you want to add?</h2>

            <div className="form form-group radio-with-label">
              <input type="radio" name="eventType" id="eventTypeOneTime" value="true" onChange={() => setEventType(ONE_TIME)} checked={eventType===ONE_TIME}/> 
              <label className="label-right"  htmlFor='eventTypeOneTime'>One-time
              <p>Occurs on a specifc day at a specific time</p></label>
              
              
            </div>

            <div className="form form-group radio-with-label">
              <input type="radio" name="eventType" id="eventTypeRecurring" value="true" onChange={() => setEventType(RECURRING)} checked={eventType===RECURRING}/> 
              <label className="label-right" htmlFor='eventTypeRecurring'>Recurring
              <p>Repeats weekly or daily at a specific time</p>
              </label>
              
             
            </div>
            {
            /*
              <div className="form form-group radio-with-label">
                <input type="radio" name="eventType" id="eventTypeGroup" value="true" onChange={() => setEventType(GROUP)} checked={eventType===GROUP}/> 
                <label className="label-right"  htmlFor='eventTypeGroup'>Group
                <p>One-time or recurring events that are planned together</p></label>
                
              </div>
            */
            }
          </header>
         
          </>
        }
       
          {
            eventType === ONE_TIME &&
              <EditOneTimeEvent onSave={(data) => onSave(data)} />  
          }
            
          {
            eventType === RECURRING &&
              <EditEventSeries onSave={(data) => onSave(data)} />  
          }

        </section>
     </ManageEventProvider>
  )
}

export default CreateEvent