import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import dateManager from '@ministry-squads-common/date-manager'
import sessionManager from '../lib/session'
import Alert from '../components/Alert'
import {get} from '../lib/call-api'
import './profile.scss'
import useIsMountedRef from '../hooks/useIsMountedRef'

const Profile = () => {
  const state = sessionManager.getSession()
  const [profileSummary, setProfileSummary] = useState({})
  const [globalErrorMessage, setGlobalErrorMessage] = useState('')
  const [lastPasswordChangedDate, setLastPasswordChangedDate] = useState(null)
  const isMounted = useIsMountedRef()
    
  useEffect(() => {
    const onGet = (resp) => {
      if(isMounted.current === true) {
        setProfileSummary(resp.profileSummary)
        if(resp.profileSummary.datePasswordLastChanged !== null) {
          setLastPasswordChangedDate(dateManager.singleDate(resp.profileSummary.datePasswordLastChanged,"TEXT_TIMESTAMP"))
        }
      }
    }

    get(`/api/profile/summary`,onGet,setGlobalErrorMessage)

    return () => isMounted.current = false
  }, [isMounted])

  return (
    <section id="profile" className="page page-slim">

      
          <header>
            <h1>Hi, {state.userData.firstName}</h1>
            <Alert type='error' message={globalErrorMessage} />
          </header>
          
          <div className="card">
            <h2>Your Availability</h2>
            
            <div className="profile-feature">
            <Link to="/profile/availability">
                {
                  !profileSummary.availability && 
                  <p>Error getting your availability.</p>
                }
                {
                  profileSummary.availability && profileSummary.availability.numberDatesBlocked > 0 && 
                  <div>
                    <p data-testid="numberDatesBlocked">You have blocked {profileSummary.availability.numberDatesBlocked} upcoming date(s).</p>
                    <p>Your next blocked date is {dateManager.singleDate(profileSummary.availability.nextDateBlocked)}.</p>
                </div>
                }
                {
                  profileSummary.availability && profileSummary.availability.numberDatesBlocked === 0 && 
                  <p>You are currently available for all dates in the future.</p>
                }
                </Link>
              </div>
           
          </div>

          <div className="card">
            <h2>Your Password</h2>
            
            <div className="profile-feature">
              <Link to="/profile/change-password">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                {
                  lastPasswordChangedDate &&
                  <p><small>You last changed your password: {lastPasswordChangedDate}</small></p>
                }
                
              </Link>
              </div>
           
          </div>

    </section>

  );
  
}

export default Profile;