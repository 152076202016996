import React from "react"
import { Link } from 'react-router-dom'
import UserAvatar from '../../components/UserAvatar'
import dateManager from '@ministry-squads-common/date-manager'
import MemberStatusIndicator from './MemberStatusIndicator'
import AdminOnly from '../../routes/components/AdminOnly'

const MemberTable = ({members,savedMember,context}) => {
  const contextToUse = (!context) ? 'list' : context
  return (
    <table>
       {
          contextToUse === 'list' && 
          <thead>
            <tr>
              <th>Member Name</th>
              <th>Email</th>
              <th>Teams</th>
              <AdminOnly>
                <th>Last login</th>
              </AdminOnly>
            </tr>
          </thead>
           }
          <tbody>
            {
              members.map(member => 
                <tr key={`member${member.userId}`} className={(savedMember && savedMember.userId === member.userId) ? 'alert-success' : ''}>
                  <td data-testid={`memberName${member.userId}`}>
                    <div className='user-name-with-avatar'>
                      <UserAvatar userData={member} />
                      <div className="avatar-user-name">
                        <Link to={`/member/detail/${member.userId}`}>{member.firstName} {member.lastName}</Link> 
                        <MemberStatusIndicator memberData={member} />
                      </div>

                    </div>
                    

                  </td>
                  <td data-testid={`memberEmail${member.userId}`}>{member.email}</td>
                  {
                    contextToUse === 'list' && 
                    <>
                      <td data-testid={`memberTeams${member.userId}`}>
                      {
                        member.teams.length > 0 && 
                        member.teams.map(team => 
                          <p key={`memberteam${team.teamId}`}>{team.teamName}  </p>
                        )
                      }
                      {
                        (!member.teams || member.teams.length === 0) &&
                        <p className="no-results">No team assignment</p>
                      }
                      </td>
                      <AdminOnly>
                        <td data-testid={`memberLastLogin${member.userId}`}>
                          <LastLogin memberData={member} />
                        </td>
                      </AdminOnly>
                    </>
                  }
                  
                  
                </tr>
              )
            }
          </tbody>
        </table>
  )
}

const LastLogin = ({memberData}) => {
  
  const displayText = (memberData) => {
    if(memberData.hasUserId === false) {
      return "Does not have access"
    } else if(memberData.lastLogin === null || typeof memberData.lastLogin === 'undefined') {
      return "Never logged in"
    } else {
      return dateManager.formatDate(memberData.lastLogin,"TEXT_TIMESTAMP")
    }
  } 
  
  return (
    <>{displayText(memberData)}</>
  )
}

export default MemberTable