import React from 'react'

import UserAvatar from '../../components/UserAvatar'

import AdminOnly from '../../routes/components/AdminOnly'

const Assignments = ({memberList,manageAssignments,events,assignmentsByTeam}) => {

    const countOfEvents = events.length

    const eventIsAssignable = (evtData) => {
        return evtData.data !== null
    }

    return (
        <>
            {
            assignmentsByTeam.map((teamAssigned,teamCtr) => 
                <div 
                    className="calendar-row"    
                    key={`team-${teamCtr}`}
                    style={{display: 'grid', gridTemplateColumns: `repeat(${countOfEvents}, 1fr)`, gridColumn: `1 / span ${countOfEvents}`}}>
                        {
                            /* the team has the same assignments for all events */
                            eventIsAssignable(teamAssigned.eventsAssigned[0]) === true && teamAssigned.eventsAssigned.length === 1 && teamAssigned.eventsAssigned[0].spans === countOfEvents &&
                            <div 
                                key={`team-${teamCtr}`}
                                style={{gridColumn: `1 / span ${teamAssigned.eventsAssigned[0].spans}`,backgroundColor:`#F8F5F1`}}>
                                <OutputTeamAssignmentForEvent eventCtr={0} teamAssigned={teamAssigned} event={teamAssigned.eventsAssigned[0]} memberList ={memberList} manageAssignments={manageAssignments} />
                            </div>
                        }

                        {
                            /* the team has at least two events with different assignments */
                            teamAssigned.eventsAssigned.length > 1 &&    
                            teamAssigned.eventsAssigned.map((event,eventCtr) => 
                                eventIsAssignable(event) === true &&
                                <div 
                                    key={`event-${eventCtr}`}
                                    style={{gridColumn: `${event.columnStart} / span ${event.spans}`,backgroundColor:`#F8F5F1`}}>
                                    <OutputTeamAssignmentForEvent eventCtr={eventCtr} teamAssigned={teamAssigned} event={event} memberList={memberList} manageAssignments={manageAssignments} />
                                </div>
                            )
                        }  
                  
                    
                </div>
            )
        }
        {
            assignmentsByTeam.length === 0 &&
            <div 
                className="calendar-row"    
                key={`noresults`}
                style={{display: 'grid', gridTemplateColumns: `repeat(${countOfEvents}, 1fr)`, gridColumn: `1 / span ${countOfEvents}`}}>
                
                <div style={{gridColumn: `1 / span ${countOfEvents}`,backgroundColor:`#F8F5F1`}}>
                    <p className="no-results">No assignments</p>
                </div>
            </div>
        }
        </>
    )
}

const OutputTeamAssignmentForEvent = ({eventCtr,teamAssigned,event,memberList,manageAssignments}) => {


    const eventIsAssignable = (evtData) => {
        return evtData !== null
    }

    return (
        <>
        {
                eventIsAssignable(event.data) === true &&
                <>
                    <div className="team-assigned">
                        {teamAssigned.teamName}
                        <AdminOnly>
                            - <button className="btn btn-link btn-slim" onClick={(e) => { e.preventDefault();manageAssignments(teamAssigned)}}>Edit assignments</button>
                        </AdminOnly>
                    </div>
                {
                    event.data.length > 0 &&
                    event.data.map((assignment,ctr) => 
                        <div key={`event-assignment-${eventCtr}-${ctr}`}>
                            { 
                                assignment !== null && teamAssigned.assignBy === 'member' &&
                                <OutputTeamAssignmentByRole assignment={assignment} teamAssigned={teamAssigned} event={event} memberList={memberList}  />
                            }
                            { 
                                assignment !== null && teamAssigned.assignBy === 'team' &&
                                <OutputTeamAssignmentByTeam teamAssigned={teamAssigned} assignment={assignment} />
                            }
                        </div>
                    )
                }
                {
                    event.data.length === 0 &&
                    <p className="no-results">No assignments</p>
                }
            </>
        }
        {
           /* eventIsAssignable(event.data) === false &&
            <div className="not-assignable">{teamAssigned.teamName} is not assignable</div>
        */
        }
        </>
    )
}

const OutputTeamAssignmentByRole = ({teamAssigned,memberList,assignment}) => {
    const getMemberData = userId => {
        return memberList.filter(member => member.userId === userId)[0]
    }
    const getRoleData = roleId => {
        return teamAssigned.roles.filter(role => role.roleId === roleId)[0]
    }
    return (
        <div className="userBadge">
            <UserAvatar userData={getMemberData(assignment.assigneeId)} />
            <div className="avatar-user-name">
                {getMemberData(assignment.assigneeId).firstName} {getMemberData(assignment.assigneeId).lastName} - {getRoleData(assignment.roleId).roleName}
                
            </div>
        </div>    
    )
}

const OutputTeamAssignmentByTeam = ({teamAssigned,assignment}) => {
   
    const getTeamName = (subTeamId) => {
        return teamAssigned.subTeams.filter(subTeam => subTeam.teamId === subTeamId)[0].teamName
    }
    return (
        <div>
           { getTeamName(assignment.assigneeId) }
        </div>    
    )
}

export default Assignments