import React from 'react'
import MemberTable from '../../members/components/MemberTable'

const TeamOfTeamsDetail = ({teamData,subTeams,members}) => {
  
  return (
    <section className="page">
        {
          teamData && 
          <>
          
            <div className="card-layout min-left">
              <div className="card-column">
                <div className="card">
                  <div className="card-header">
                    <h2>Teams</h2>
                  </div>
                  <div className="card-content">
                    
                    {
                      subTeams && subTeams.length > 0 && 
                    <ul>
                  {
                      
                      subTeams.map(team => 
                        <li key={`role-${team.teamId}`}>
                          <a href={`/team/detail/${team.teamId}`}>{team.teamName}</a>
                        </li>
                      )
                    }
                    </ul>
                    }
                    {
                      subTeams && subTeams.length === 0 && 
                      <p className="no-results">No roles assigned to this team.</p>
                    } 
                    
                  </div>
                </div>
              </div>
              <div className="card-column">
                <div className="card">
                  <div className="card-header">
                    <h2>Members</h2>
                  </div>
                  <div className="card-content">
                    
                    {
                      members && members.length > 0 && 
                      <MemberTable members={members} context='team'/>
                    }
                  
                    {
                      members && members.length === 0 && 
                      <p className="no-results">No members assigned to this team.</p>
                    } 
                  </div>
                </div>
              </div>
            </div>
            </>
          }
      </section>
  )
}

export default TeamOfTeamsDetail