import React, { Fragment,useEffect, useState } from "react"
import {post,put,httpDelete} from '../../lib/call-api'
import Alert from '../../components/Alert'
import session from '../../lib/session'
import TextArea from '../../components/formFields/TextArea'

const ManageNotes = ({ notes,associatedToData,onSave,associatedToType,unique}) => {
  const [notesToDisplay,setNotesToDisplay] = useState(notes)
  const [saveSuccessMessage, setSaveSuccessMessage] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState()

  const [noteEditing,setNoteEditing] = useState()
  const [adding,setAdding] = useState(false)
  const [noteIdJustSaved,setNoteIdJustSaved] = useState()

  const reset = () => {
    setSaveSuccessMessage()
    setDeleteSuccessMessage()
    setErrorMessage()
    setNoteEditing()
    setNoteIdJustSaved()
    setAdding(false)
  }

  useEffect(() => {
    reset()
  }, [unique])

  const deleteNote = (event,noteToDelete) => {   
    event.preventDefault()
    
    const onSuccess = () => {
      setNotesToDisplay((current) => current.filter(note => note.noteId !== noteToDelete.noteId))
      setDeleteSuccessMessage(`The note has been removed.`)
      setErrorMessage()
    }

    const onError = (error) => {
      setErrorMessage(error)
      setSaveSuccessMessage()
    }

    httpDelete(
      `/api/note/${noteToDelete.noteId}`,
      onSuccess,
      onError,
      null,
      null,
      {}
    )
  }

  const cancel = (event) => {
    event.preventDefault()
    reset()
  }

  const onNoteSuccess = (noteData) => {
    reset()
    setNoteIdJustSaved(noteData.noteId)
    setSaveSuccessMessage('Note saved')
    const exists = notesToDisplay.filter(note => note.noteId === noteData.noteId).length === 1
    if(exists === true) {
      const newNotesToDisplay = notesToDisplay.map(note => {
        if(note.noteId === noteData.noteId) {
          return noteData
        } else {
          return note
        }
      })
      setNotesToDisplay(() => newNotesToDisplay)
    } else {
      setNotesToDisplay((current) => [
        ...current,
        noteData
      ])
    }
  }

  return (
    <section>
      {
        deleteSuccessMessage && 
        <Alert type="success" message={deleteSuccessMessage} />
      }
      {
        errorMessage && 
        <Alert type="error" message={errorMessage} />
      }
      {
        notesToDisplay && notesToDisplay.length === 0 && adding === false &&
        <p className="no-results">No notes associated to this event</p>
      }
      {
        notesToDisplay && notesToDisplay.length > 0 &&
          <div className="table manage-notes">
            <header>
              <div>Note</div>
              <div>Actions</div>
            </header>
            <div className="tbody">             
            {
            notesToDisplay.map(thisNote => 
              <div className="table-row" key={`note${thisNote.noteId}`}>
              {
                (!noteEditing || (noteEditing && (noteEditing.noteId !== thisNote.noteId))) && 
                <Fragment key={`note-${thisNote.noteId}`}>
                  <div className="content">
                    {
                      noteIdJustSaved === thisNote.noteId && 
                      <Alert type="success" context="inline" message={saveSuccessMessage} />
                    }
                    {thisNote.noteText} 
                  </div>
                  <div className="action">
                    <button className="btn btn-link" onClick={(e) => {e.preventDefault();setNoteEditing(thisNote)}}>Edit</button>
                    <button className="btn btn-link" onClick={(e) => {deleteNote(e,thisNote)}}>Delete</button>
                  </div>
                </Fragment>
              }

              {
                noteEditing && noteEditing.noteId === thisNote.noteId && 
                <ManageNote 
                  passedNoteId={thisNote.noteId} 
                  passedNoteText={thisNote.noteText} 
                  associatedToData={associatedToData}
                  associatedToType={associatedToType}
                  onSave={onNoteSuccess} 
                  onCancel={(e) => { cancel(e) }} />
              }
             </div>
            )
          }
          </div>
        </div>
      }
      {
        adding === false && !noteEditing &&
        <div className="text-center">
          <button className="btn btn-icon btn-add btn-empty" onClick={(e) => {e.preventDefault();setAdding(true)}}>Add a note</button>
        </div>
      }
      {
        adding === true && 
        <div className="table manage-notes">
          <div className="tbody">
            <div className="table-row">
              <ManageNote 
                associatedToData={associatedToData} 
                associatedToType={associatedToType}
                onCancel={() => setAdding(false) } 
                onSave={onNoteSuccess} />
            </div>
          </div>
        </div>
      }
    </section>
  )
}

const ManageNote = ({passedNoteId,passedNoteText,associatedToData, associatedToType, onSave, onCancel}) => {
  const [note,setNote] = useState(passedNoteText)

  const action = (passedNoteText !== null && typeof passedNoteText !== "undefined") ? "edit" : "add"
  const sessionManager = session.getSession()

  const [errorMessage,setErrorMessage] = useState()
  const [errorType,setErrorType] = useState()
  const [noteLength,setNoteLength] = useState(0)
  const handleSubmit = (e) => {

    e.preventDefault()

    const onSuccess = (resp) => {
      if(resp.unsuccessful.length === 0) {
        setErrorMessage()
        setErrorType()
      } else {
        setErrorMessage(`We couldn't save your notes to all the events.`)
        setErrorType('warning')
      }
      console.log(resp)
      onSave(resp.successful[0])
    }
  
    const onError = () => {
      setErrorMessage('Sorry, we could not save your note to the event(s)')
      setErrorType('error')
    }
  
    const validateForm = () => {
      return true
    }
    
    const baseBody = {
      "noteText": note,
      "associatedToData": associatedToData,
      "orgId": sessionManager.orgData.orgId    
    }

    if(action === "add") {
      post("/api/note/scheduledEvent",onSuccess,onError,validateForm,baseBody)
    } else {
      const body = {
        ...baseBody,
        "noteId": passedNoteId
      }
      put("/api/note",onSuccess,onError,validateForm,body)
    }
  }
  
  const MAX_LENGTH = 255
  return (
     <>
      <div className="content">
        <TextArea maxLength={MAX_LENGTH} handleChange={(text) => setNote(text)} value={note} errorMessge={errorMessage} errorType={errorType} />
  
      </div>
      <div className="action">
        <button className="btn btn-primary" onClick={(event) => {handleSubmit(event)}}>Save</button>
        <button className="btn btn-link" onClick={(event) => {onCancel(event)}}>Cancel</button>
      </div>
      </>
  )
}

export default ManageNotes