import React, { useEffect,useState,useRef } from 'react'
import { createPortal } from 'react-dom'
import { useSecondaryContentState  } from '../contexts/SecondaryContent.Context'
import './SecondaryContent.scss'
import useIsMountedRef from '../hooks/useIsMountedRef.js'


const SecondaryContent = (props) => {
  const [ state, dispatch ] = useSecondaryContentState()
  const containerId = (props.type === "popup") ? 'popup-content-container' : 'secondary-content-container'

  const isMounted = useIsMountedRef()
  const close = () => {
    if(props.onClose) props.onClose()

    if(props.type === "popup") {
      dispatch({type: 'closePopup', payload: null})
    } else {
      dispatch({type: 'close', payload: null})
    }
  }

  const modalRef = useRef(null)

  useEffect(() => {
    const containerId = (props.type && props.type === "popup") ? 'popup-content-container' : 'secondary-content-container'
    const isOpenState = (props.type && props.type === "popup") ? state.popupIsOpen : state.isOpen
    const element = document.getElementById(containerId);
    const className = 'active'  

    if(isOpenState === true && (props.type && props.type === "popup")) {
      // doesn't work in IE9. I don't think I care.
      element.classList.add(className);
      
      const modalHeight = modalRef.current.clientHeight
      const modalWidth = modalRef.current.clientWidth

      const viewportHeight = window.innerHeight
      const viewportWidth = window.innerWidth
      
      let modalTop = 25
      if(modalHeight < viewportHeight) modalTop = (viewportHeight - modalHeight)/2;

      let modalLeft = (viewportWidth - modalWidth)/2;
      modalRef.current.style.left = `${modalLeft}px`
      modalRef.current.style.top = `${modalTop}px`
    }

    return () => { isMounted.current = false }
  },[props.type, state.popupIsOpen, isMounted, state.isPositioned, dispatch])

  useEffect(() => {
    
    if(state.popupIsOpen === false) {
      const element = document.getElementById('popup-content-container');
      element.classList.remove('active');
    }
  },[state.popupIsOpen])

  useEffect(() => {
    if(state.isOpen === false) {
      const element = document.getElementById('secondary-content-container');
      element.classList.remove('active');
    }
  },[state.isOpen])

  const getTypeCssClass = () => {
    return (props.type && props.type === "popup") ? "secondary-content-popup" : "secondary-content-flyout"
  }

  const getSizeCssClass = () => {
    return (props.size && props.size === "larger") ? "larger" : ""
  }

  return createPortal(
    ((props.type === "popup" && state.popupIsOpen) || ((!props.type || props.type === "flyout") && state.isOpen))  && 
      <div className={`${getTypeCssClass()} ${getSizeCssClass()}`} ref={modalRef}> 
        <header>
        {
            (!props.hideClose || props.hideClose === false) && 
            <div className="feature-manager">
              <button className="btn btn-icon btn-close" onClick={() => close() }>
                Close
              </button>
            </div>
          }
          {
            props.heading &&
            <h2>{props.heading}</h2>
          }
          
          </header>
        { props.children }

        
      </div>  
    , document.getElementById(containerId))
}



export default SecondaryContent