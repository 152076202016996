import React, {useState} from 'react'
import FormField from '../../components/formFields/FormField'
import DropDownField from '../../components/formFields/DropDownField'

const EditSectionDetails = ({sectionData,onSave,onCancel,allSections}) => {

  const [newSectionData,setNewSectionData] = useState(sectionData)
  const changeArrangementSongSection = (newVal) => {
      // make a copy of the data from the full list of available sections
      const newSongSectionData = allSections.filter(songSection => songSection.sectionId === newVal)

      setNewSectionData((current) => {
        return {
          ...current,
          "songSection": newSongSectionData[0]
        }
      })
  }

  const changeArrangementDirection = (newDirection) => {
    setNewSectionData((current) => {
      return {
        ...current,
        "direction": newDirection
      }
    })
  }

  const localSave = () => {
    onSave(newSectionData)
  }

 return <>
 <div>
   <div className="form-group">
     <DropDownField 
       id={`songSection${newSectionData.orderNumber}`}
       values={allSections} 
       idField="sectionId" 
       labelField="sectionName" 
       selectedValue={newSectionData.songSection}
       handleChange={(newValue) => {changeArrangementSongSection(newValue)}}
     />
   </div>
   <div className="form-group">
     <FormField 
       id={`direction${newSectionData.orderNumber}`}
       value={newSectionData.direction}
       placeholder="Directions, e.g. quiet, then build"
       handleChange={(newValue) => { changeArrangementDirection(newValue)}}
     />
   </div>
 </div>
 <div>
     <button className="btn btn-link" onClick={(e) => {e.preventDefault(); localSave()}}>Save</button>
     <button className="btn btn-link" onClick={(e) => {e.preventDefault(); onCancel()}}>Cancel</button>
 </div>
</>
}


export default EditSectionDetails