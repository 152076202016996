import React,{useState} from 'react'
import {useAssignmentsState } from '../context'
import SessionManager from '../../lib/session'
import DropDownField from '../../components/formFields/DropDownField'
import {get} from '../../lib/call-api'
import dateManager from '@ministry-squads-common/date-manager'

const TeamFilter = ({showDefault = false}) => {
  const [state,updateState] = useAssignmentsState()

  const sessionData = SessionManager.getSession()
  const teams = sessionData.userData.teams

  const getInitialTeam = (selectDefaultValue,userTeams, stateTeams) => {
    if(selectDefaultValue === true) {
      return null
    } else {
      return (stateTeams) ? stateTeams[0] : userTeams[0]
    } 
  }

  const [,setSelectedTeam] = useState(getInitialTeam(showDefault, teams, state.teams))

  const changeTeam = (selectedTeamId) => {

    const newSelectedTeam = teams.filter(team => team.teamId === selectedTeamId)
    
    if(newSelectedTeam.length === 1) {
      
      const getSchedule = (teamIdToUse) => {
        const onScheduleGet = (resp) => {
          updateState({"type": "initializeEvents", payload: resp})
        }
    
        const apiUrl = `/api/schedule/team/${teamIdToUse}?startDate=${dateManager.singleDate(state.dateRange.startDate,'URL_PARAMETER')}&endDate=${dateManager.singleDate(state.dateRange.endDate,'URL_PARAMETER')}`
        get(apiUrl,onScheduleGet,null)
      }

      const onTeamGet = (resp) => {
          setSelectedTeam(resp)
          getSchedule(resp.teamId)
          updateState({"type": "setMembers", "payload": resp.members})
          updateState({"type": "setTeam", "payload": resp})
      }
      get(`/api/team/${selectedTeamId}`,onTeamGet,null,null,'view the teams')

      
    } else {
      throw new Error('Team not found')
    }
  }


  return (
    <div className="form-group form-inline">
      <label className="inline" htmlFor="change-team">Team</label>
      {
        teams && teams.length === 1 &&
        <>{teams[0].teamName}</>
      }
      {
        teams && teams.length > 1 && state.team &&
        <DropDownField 
          id="change-team"
          values={sessionData.userData.teams}
          idField="teamId"
          labelField="teamName"
          selectedValue={state.team.teamId}
          handleChange={changeTeam}
          showDefaultOption={showDefault}
          ></DropDownField>
      }
    </div>
  )
}

export default TeamFilter