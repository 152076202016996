import React from 'react'

const AddSection = ({clickHandler,actionText = "Add a section"}) => {
    return (
        <div className="agenda-section text-center">
            <div><button onClick={clickHandler} className="btn btn-icon btn-add btn-empty">{ actionText }</button></div>
        </div>
    )
}

export default AddSection