import React from 'react'

import { Link } from 'react-router-dom'

const DynamicBreadcrumb = () => {

    const paths = JSON.parse(window.sessionStorage.getItem('previousPath'))
    const currentPath = paths[0]
    const previousPath = paths[1]

    const breadcrumb = (currentPath.indexOf('/schedule/') > -1) ? 

    {
        "path": (currentPath === previousPath|| previousPath.indexOf('/schedule') === -1) ? '/schedule' : previousPath,
        "label": "Schedule"
    } 
    :
    {
        "path": (currentPath === previousPath || previousPath.indexOf('/calendar') === -1) ? '/calendar' : previousPath,
        "label": "Calendar"
    } 
    return (<div className="breadcrumbs"><Link to={`${breadcrumb.path}`}>{breadcrumb.label}</Link></div>)
}


export default DynamicBreadcrumb