import React from 'react'
import {  Link } from 'react-router-dom';
import {onDay} from '../../lib/calendar'
import {goToEventDetail} from '../common'
import dateManager from '@ministry-squads-common/date-manager'
import '../Calendar.scss'

const ITEM_TYPE_WORSHIP_SERVICE = 'Worship Service'
const ITEM_TYPE_BLOCKED_DATE = 'Blocked date'

  const CalendarEvents = ({dateRangeData, eventData}) => {

    /**
 * @param {array of objects} allItems 
 * @returns array of objects that represents each date
 */
  const buildOutput = (allItems) => {
    
    const isOnPreviousDay = (dt,itemId,items) => {
      const previousDay = dateManager.add(dt,-1,"DAYS")
      return isOnDay(previousDay,itemId,items)
    }

    const isOnNextDay = (dt,itemId,items) => {
      const nextDay = dateManager.add(dt,1,"DAYS")

      return isOnDay(nextDay,itemId,items)
    }

    const isOnDay = (dt,itemId,items) => {
      return items.filter(itm => itm.itemId === itemId && onDay(dt,itm.itemDate) === true).length > 0
    }

    const initialSort = (fullArray) => {
     // the order of the items matter, so we can make sure that bars that span multiple days are continuous
      return  fullArray.filter(itemOnDay => itemOnDay.hasPrevious === true && itemOnDay.hasNext === true).concat(fullArray.filter(itemOnDay => itemOnDay.hasPrevious === true && itemOnDay.hasNext === false)).concat(fullArray.filter(itemOnDay => itemOnDay.hasPrevious === false && itemOnDay.hasNext === true)).concat(fullArray.filter(itemOnDay => itemOnDay.hasPrevious === false && itemOnDay.hasNext === false))
    }

    const getPredecessorPosition = (itemId,allPreviousDays) => {
      const allPreviousForItem = allPreviousDays.map(previousDay => {
        return initialSort(previousDay.items).filter(dayItem => dayItem.itemId === itemId && dayItem.hasPrevious === false)
      }).flat()
      return (allPreviousForItem && allPreviousForItem.length > 0 && allPreviousForItem[allPreviousForItem.length-1] && allPreviousForItem[allPreviousForItem.length-1].sortIdx) ? allPreviousForItem[allPreviousForItem.length-1].sortIdx : 0
    }

    const sortByIndex = (list) => list.sort((a, b) => (a.sortIdx > b.sortIdx) ? 1 : -1)

    const setListItemIndex = (fullList,listToProcess) => {
      const sortedByIdx = sortByIndex(fullList)
      let itemIdx = (fullList.length > 0) ? sortedByIdx[sortedByIdx.length - 1].sortIdx + 1 : 0

      listToProcess.forEach(startingItem => {
        fullList.push({
          ...startingItem,
          "sortIdx": itemIdx
        })
        itemIdx = itemIdx + 1
      })

      return fullList
    }
    const setSortOrder = (itemsOnDay,allPreviousDays) => {

      const continuingOnDay = itemsOnDay.filter(itemOnDay => itemOnDay.hasPrevious === true)
      const startingOnDay = itemsOnDay.filter(itemOnDay => itemOnDay.hasPrevious === false && itemOnDay.hasNext === true)
      const onlyOnDay = itemsOnDay.filter(itemOnDay => itemOnDay.hasPrevious === false && itemOnDay.hasNext === false)

      // first roll through the items that are continuing from a previous day
      // so we have one continuous bar on the calendar
      const sortedDay = []
      continuingOnDay.forEach(continuingItem => {
        // should always just use the position of the item on the previous day
        const predecessorPosition = getPredecessorPosition(continuingItem.itemId,allPreviousDays)
        sortedDay.push({
          ...continuingItem,
          "sortIdx": predecessorPosition
        })

      })

      sortedDay.concat(setListItemIndex(sortedDay,startingOnDay))
      
      sortedDay.concat(setListItemIndex(sortedDay,onlyOnDay))

      // Now add blanks
      const finalArray = sortByIndex(sortedDay)

      let expectedIdx = 0
      finalArray.forEach(itemOnDay => {
        if(itemOnDay.sortIdx > expectedIdx) {
          const blanksNeeded = itemOnDay.sortIdx - expectedIdx
          for(let i=0;i<blanksNeeded;i++) {
            finalArray.push({
              "itemId": null,
              "cssClass": "blank-spacer",
              "sortIdx": i
            })
            
          }
          expectedIdx = itemOnDay.sortIdx + blanksNeeded
        } else {
          expectedIdx = itemOnDay.sortIdx + 1
        }
      })

      return sortByIndex(finalArray)
    }


    const uniqueDates = [...new Set(allItems.map(itm => dateManager.setToMidnight(itm.itemDate).toISOString()))]

    // the raw data for each date. items are not ordered in the correct order yet
    const initialOutputObj = uniqueDates.map(dt => {

      return {
        "date": dt,
        "items": allItems.filter(itm => onDay(dt,itm.itemDate) === true).map(item => {

          if(item.itemId === null) {
            return item 
          } else {
            const hasPrevious = isOnPreviousDay(dt,item.itemId,allItems)
            const hasNext =  isOnNextDay(dt,item.itemId,allItems)
            //    if the item started on a previous day
            //    find what position it was in on the first day
            return {
              ...item,
              "hasPrevious": hasPrevious,
              "hasNext": hasNext
            }
          }

          
        })
      }

    })

    // now order the items so the bars align properly when an event spans across days
    // for each item on each day

    const finalOutputObj = []
    
    initialOutputObj.forEach(day => {
      finalOutputObj.push({
        "date": day.date,
        "items": setSortOrder(day.items,finalOutputObj)
      })

    })
    return finalOutputObj
  }

    const allItems = buildOutput(eventData.flat().sort((a,b) => a.itemDate > b.itemDate ? 1 : -1))


    const getItemsOnDay = (day,allDatesWithItems) => {
      return allDatesWithItems.filter(dt => onDay(dateManager.setToMidnight(day.dateObj),dateManager.setToMidnight(dt.date)))
    }

    
    const getClass = (day) => {
      return (day.isCurrentMonth === false) ? 'disabled' : ''
    }
    
    const getDaysOfWeek = (firstDate) => {
      const weekDays = [
        'Sun',
        'Mon',
        'Tues',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
      ]
      const firstDayOfRange = new Date(firstDate).getDay()

      let daysOutput = 0
      const days = []
      let ctr = firstDayOfRange
      while(daysOutput < 7) {
        days.push(weekDays[ctr])
        
        if(ctr + 1 > 6) {
          ctr = 0
        } else {
          ctr = ctr + 1
        }
        daysOutput = daysOutput + 1
      }

      return days
    }
   return (
      <>
      
        <ul className="calendar">
          {
            getDaysOfWeek(dateRangeData[0].dateObj).map(dayOfWeek => 
              <li className="day-of-week" key={dayOfWeek}>
              { dayOfWeek }
            </li>
            )
          }
           
            {
                dateRangeData.map(day => 
                  <li className={getClass(day)} key={`${day.dateObj.toString()}`}>
                      <DayOfMonth day={day} />

                        {
                          getItemsOnDay(day,allItems).map(group =>
                            group.items.map(item => 
                              <ShowItem key={`${item.itemId}-${item.itemType}`} item={item} day={day} allItems={allItems}/>
                            )
                          )
                        }
                  </li>
                )
            }
            
        </ul>
      </>
    )
  
  
  }

  const DayOfMonth = ({day}) => {
    const display = day.dateObj.getDate()
    
    return (
      <div>
        <div className="full-date-data">
        { dateManager.formatDate(day.dateObj,'DAY_OF_WEEK')}, { dateManager.formatDate(day.dateObj,'MONTH_ABBR')} {display}
        </div>
        <div className="abbr-date-data">
          {
            display === 1 &&
            <>{dateManager.formatDate(day.dateObj,'MONTH_ABBR')} </>
          }

          {
            display
          }
        </div>
      </div>
    )
  }

  const ShowItem = ({item,day}) => {

    const getClassName = (itm) => {

      if(itm.itemId !== null) {
        const previousDayBlocked = itm.hasPrevious 
        const nextDayBlocked = itm.hasNext 
  
        const previousDayClassName = `incoming-${previousDayBlocked.toString()}`
        const nextDayClassName = `outgoing-${nextDayBlocked.toString()}`
  
        return `${previousDayClassName} ${nextDayClassName}`
      } else {
        return ''
      } 
    }

    const getLabelToShow = (itm) => {
      const isOnlyToday = itm.hasPrevious === false && itm.hasNext === false
      const isFirstInstance = itm.hasPrevious === false && itm.hasNext === true
      const isFirstDayOfWeek = day.dateObj.getDay() === 0

      if(item.itemId === null || (isOnlyToday === false && isFirstInstance === false && !isFirstDayOfWeek)) {
        return null
      } else {
        return itm.itemLabel
      }
    }

    const labelToShow = getLabelToShow(item)

    const getUrl = (itm) => {
      if(item.itemType === ITEM_TYPE_BLOCKED_DATE) {
        return `/member/detail/${itm.itemId}`
      } else if(item.itemType === ITEM_TYPE_WORSHIP_SERVICE) {
        return `/calendar/event/detail/${itm.itemId}`
      }
    }
    
    return (
        
          <Link to={getUrl(item)} className={`calendar-event ${item.cssClass} ${getClassName(item)}`} style={{backgroundColor:'#' + item.backgroundColor}}>
          {
            labelToShow === null &&
            <>&nbsp;</>
          }
          {
            labelToShow !== null &&
            <>{labelToShow}</>
          }
          </Link>

    )
    
  }


export default CalendarEvents