import React  from 'react'
import EventTypes from './EventTypes'
import FormField from '../../components/formFields/FormField'
import '../events.scss'
import { useManageEventState } from '../../contexts/ManageEvent.Context'


const EventBasics = ({ eventName, eventTypeId, errorMessages }) => {
  const [, eventDispatch] = useManageEventState()

  const onNameChange = (val) => {
    eventDispatch({"type": "eventName", "payload": val})
  }

  const onTypeChange = (val) => {
    eventDispatch({"type": "eventTypeId", "payload": val})
  }

  return (<>
    <h3>Event basics</h3>
    <div className="form-group">
      <label htmlFor="eventName">Name of event</label>
      <FormField
        id='eventName'
        value={eventName}
        type='text'
        handleChange={(val) => { onNameChange(val) }}
        errorMessage={errorMessages.eventName}
      />
    </div>
    <div className="form-group">
      <label htmlFor="repeatEvery">What kind of event is it?</label>
      {
        <EventTypes
          id="eventTypes"
          value={eventTypeId}
          handleChange={(val) => onTypeChange(val)}
          errorMessage={errorMessages.eventType}
        />
      }
    </div>
  </>
  )
}

export default EventBasics