import React from 'react'
import DropDownField from '../../components/formFields/DropDownField'

const SongKeys = ({selectedSongKey,setFunction,songId}) => {
  const keys = [
    {
      "id":'Ab',
      "label": 'Ab'
    },
    {
      "id":'Abm',
      "label": 'Abm'
    },
    {
      "id":'A',
      "label": 'A'
    },
    {
      "id":'Am',
      "label": 'Am'
    },
    {
      "id":'Bb',
      "label": 'Bb'
    },
    {
      "id":'Bbm',
      "label": 'Bbm'
    },
    {
      "id":'B',
      "label": 'B'
    },
    {
      "id":'Bm',
      "label": 'Bm'
    },
    {
      "id":'C',
      "label": 'C'
    },
    {
      "id":'Cm',
      "label": 'Cm'
    },
    {
      "id":'C#',
      "label": 'C#'
    },
    {
      "id":'C#m',
      "label": 'C#m'
    },
    {
      "id":'Db',
      "label": 'Db'
    },
    {
      "id":'Dbm',
      "label": 'Dbm'
    },
    {
      "id":'D',
      "label": 'D'
    },
    {
      "id":'Dm',
      "label": 'Dm'
    },
    {
      "id":'Eb',
      "label": 'Eb'
    },
    {
      "id":'Ebm',
      "label": 'Ebm'
    },
    {
      "id":'E',
      "label": 'E'
    },
    {
      "id":'Em',
      "label": 'Em'
    },
    {
      "id":'F',
      "label": 'F'
    },
    {
      "id":'Fm',
      "label": 'Fm'
    },
    {
      "id":'F#',
      "label": 'F#'
    },
    {
      "id":'F#m',
      "label": 'F#m'
    },
    {
      "id":'G',
      "label": 'G'
    },
    {
      "id":'G',
      "label": 'Gm'
    },
    {
      "id":'G#',
      "label": 'G#'
    },
    {
      "id":'G#',
      "label": 'G#m'
    }
  ]

  const getSelectedKey = () => {
    const match = keys.filter(songKey => songKey.id === selectedSongKey)
    return (match.length > 0) ? match[0] : null
  }

return (
  <DropDownField
    type="text"
    id={`recorded-key${songId}`}
    selectedValue={getSelectedKey()}
    handleChange={setFunction}
    values={keys}
    idField="id"
    labelField="label"
  />
  )
}

export default SongKeys