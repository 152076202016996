import React  from 'react'
import DateField from '../../components/formFields/DateField'
import TimeOfDay from '../../components/formFields/TimeOfDay'
import dateManager from '@ministry-squads-common/date-manager'
import '../events.scss'

const DateTime = ({ dateValue, timeValue, defaultTime, dateErrorMessage, timeErrorMessage, onDateChange, onTimeChange, minDate }) => {
  return (
    <>
      <DateField
        label=''
        currentValue={dateManager.asDate(dateValue)}
        setValue={(val) => onDateChange(val)}
        errorMessage={dateErrorMessage}
        minDate={minDate}
      />
      <div className="date-time-at">at</div>
      <TimeOfDay
        htmlId="startTime"
        selectedTime={timeValue}
        onChange={(val) => onTimeChange(val)}
        errorMessage={timeErrorMessage}
        scrollToTime={defaultTime}
      />
    </>
  )
}

export default DateTime