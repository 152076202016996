import React, { useState } from 'react'
import { post } from '../../lib/call-api'
import '../Register.css'
import Alert from '../../components/Alert'
import SetName from '../../components/formFields/SetName'
import SetEmail from '../../components/formFields/SetEmail'
import SetPassword from '../../components/formFields/SetPassword'
import UrlField from '../../components/formFields/UrlField'
import SubmitButton from '../../components/SubmitButton'


const Register = ({nextStep, setDataInParent}) => {
  
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [churchUrl, setChurchUrl] = useState('')

  const [errorText,setError] = useState('')

  const validateForm = () => {
    if (email === '' || firstName === '' || lastName === '' || password === '') {
        setError('All fields below are required')
      return false
    } else if(churchUrl === '') {
        setError('Please provide a website for your church.')
        return false
      } else {
        setError('')
        return true
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)
    const authPath = process.env.REACT_APP_AUTH_URI || ""

    const onPost = (response) => {
      setIsLoading(false)
      setDataInParent(response)
      nextStep()
    }

    const onPostError = (error) => {
      setIsLoading(false)
      setError(error.message)
    }

    const postBody = {
      "email": email,
      "password": password,
      "firstName": firstName,
      "lastName": lastName,
      "churchUrl": churchUrl
    }

    post(`${authPath}/register`,onPost,onPostError, validateForm, postBody)
  }
  
  return (
    <section className="page page-slim">
      <h1>Register for access</h1>
      <p className="intro">Once you register, your organization's admin will assign you to a team.</p>
      
      <form onSubmit={handleSubmit}>
        
        <Alert type='error' message={errorText} />
        
          
          <SetName values={[firstName,lastName]} setValue={[setFirstName,setLastName]} />

          <SetEmail setValue={setEmail} />
          
          <SetPassword setValue={setPassword} context='REGISTER' />
          
          <UrlField 
            setValue={setChurchUrl} 
            passedValue={churchUrl} 
            labelText="Your church's website" />

          <div className="buttonContainer">
            <SubmitButton actionTextOverride='Register' isLoading={isLoading} />  
          </div>
      </form>    
    </section>
  )
}
export default Register