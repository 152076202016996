import React from 'react'

import { Link } from 'react-router-dom'

import AdminOnly from '../../routes/components/AdminOnly'

const AttendeesForEvent = ({attendees,manageAttendees}) => {
    return (
        <div className="card">
            <h2>Attendees</h2>
            {
                attendees && attendees.length === 0 &&
                <p className="no-results">No attendees</p>
            }
            {
                attendees && attendees.length > 0 &&
                <ul>
                    {
                        attendees.map(attendee => 
                            <li key={`userAttendee${attendee.userId}`}>
                                <div className="avatar-user-name">
                                    <Link to={`/member/detail/${attendee.userId}`}>{attendee.firstName} {attendee.lastName}</Link> 
                                </div>
                            </li>    
                            
                        )
                    }
                </ul>
                
            }
            {
                manageAttendees &&  
                <AdminOnly>
                    <button className="btn btn-link btn-slim" onClick={() => {manageAttendees()}}>
                        Manage attendees
                    </button>
                </AdminOnly>
            }
            
        </div>
    )
}


export default AttendeesForEvent