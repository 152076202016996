import React,{useState,useEffect} from 'react'
import TypeAhead from '../../components/TypeAhead'
import AddPerson from './AddPerson'
import { getList } from '../../services/member.service.js'
import { useSecondaryContentState } from '../../contexts/SecondaryContent.Context'

import SecondaryContent from '../../components/SecondaryContent'
import { TypeAheadProvider, useTypeAheadState } from '../../contexts/TypeAhead.Context'

const PersonSearch = ({selectedList,onListChange}) => { 
  const [allPeople,setAllPeople] = useState(null)
  const initialList = selectedList
  
  useEffect(() => {
    if(allPeople === null) {
      getList().then(list => setAllPeople(list))
    }
  },[allPeople])

  return (
    <>
    {
      allPeople && 
      <TypeAheadProvider initialList={initialList} allItems={allPeople} attributesToSearch={["firstName","lastName","email"]} itemKey="userId" >
        <RenderWithinProvider onListChange={onListChange} />
      </TypeAheadProvider>
    }
      
    </>
  )
}

const RenderWithinProvider = ({onListChange}) => {

  const [typeAheadState, typeAheadDispatch] = useTypeAheadState()

  const oneFieldSearch = "TEMPORARY TO AVOID ERROR"
  const getExistingUsers = () => {
    getList().then(list => {
      typeAheadDispatch({"type":"setAllItems","payload": list})
    }).catch(e => {
    })
  }
  
  useEffect(() => {
    // when the list in context changes, fiter the onListChange event that was passed in
    // this allows components outside the PersonSearch/look-ahead component to have the most up-to-date list
    // that the user has selected in the UI
    onListChange(typeAheadState.selectedItems)
  },[typeAheadState.selectedItems])
  
  
  /* ** FOR ADDING NEW PERSON POPUP ** */
  const [showAddFields,setShowAddFields] = useState(false)

  const [, popupDispatch] = useSecondaryContentState()

  const togglePopup = () => {
    popupDispatch({ type: 'togglePopup', payload: null })
    setShowAddFields(showAddFields === false)
  }

  const showAddNewAttendeeForm = () => {
    togglePopup()
    setShowAddFields(true)
  }

  const afterNewPersonAdded =  (addedPerson) => {
    // once we get here, a new person should have been saved to the databsae
    // call getExistingUsers to get the new, updated list from the DB
    getExistingUsers()
    
    setShowAddFields(false)

    // add the new person to the list of selected people
    typeAheadDispatch({"type":"addItem","payload":addedPerson})

    popupDispatch({ type: 'closePopup', payload: null })
  }

  const cancel = () => {
    setShowAddFields(false)
    popupDispatch({ type: 'closePopup', payload: null })
  }
  return (
    <>
    {
      typeAheadState.allItems && typeAheadState.attributesToSearch && 
      <TypeAhead 
        htmlId="manageAttendees"
        itemKey="userId"
        selectedItemText = {(item) => `${ item.firstName } ${item.lastName}`}
        resultItemText = {(item) => `${ item.firstName } ${item.lastName} - ${item.email}`}
        addItemFunction={showAddNewAttendeeForm}
      />
    }

    {
      showAddFields === true &&
      <SecondaryContent 
          heading='Add an attendee'
          onClose={togglePopup} 
          type='popup'>
          <AddPerson 
            searchText={oneFieldSearch} 
            onSave={(newPerson) => afterNewPersonAdded(newPerson)} 
            onCancel={cancel} 
            saveButtonText='Save attendee'
          />
        </SecondaryContent>
      
    }
    </>
  )
}



export default PersonSearch