import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Alert from '../components/Alert'
import SubmitButton from '../components/SubmitButton'
import sessionManager from '../lib/session'
import ForgotPasswordVerifyAndSet from './components/ForgotPasswordVerifyAndSet'

const Login = (props) => {
  const LOGIN_NEEDED = "LOGIN_NEEDED"
  const RESET_REQUIRED = "RESET_REQUIRED"
  const RESET_COMPLETE = "RESET_COMPLETE"

  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setError] = useState('')
  const [loginState, setLoginState] = useState(LOGIN_NEEDED)
  const [dataForResetNeeded,setDataForResetNeeded] = useState()
  const timeout = (window.location.search.indexOf('timeout=true') > -1) 

  
  const validateForm = () => {
    // todo also check to see if its a valid email address
    return email.length > 0 && password.length > 0
  }

  const handleChange = event => {
    switch (event.target.id) {
        case "email" :
          setEmail(event.target.value)
          break
        case "password" :
          setPassword(event.target.value)
          break

        // no default
    }
  }

 const handleErrors = async(response) => {
  const responseBody = await response.json()
  if(response.status !== 200) {
    throw new Error(responseBody.message)
  } else {
    return responseBody
  }
}

  const handleSubmit = async event => {
    event.preventDefault()
    if(validateForm() === true) {
      setIsLoading(true)
      const authPath = process.env.REACT_APP_AUTH_URI || ""

      fetch(`${authPath}/auth/login`, {
          method: 'post',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: email,
            password: password
          })
      })
      .then(handleErrors)
      .then(resp => {
        let redirectTo
        if(typeof resp.passwordResetRequired !== "undefined" && resp.passwordResetRequired === true) {
          setLoginState(RESET_REQUIRED)
          setDataForResetNeeded(resp)
        } else {
          sessionManager.setSession(resp)
          redirectTo = new URL(window.location).searchParams.get("redirect")
          window.location = (redirectTo) ? redirectTo : "/"
        }
       
      })
      .catch(error => {
        setError(error.message)
        setIsLoading(false)
      });

    } else {
      setError('An email and password are required to login.')
    }
  }

    return (
      <>
        {
          loginState && loginState === RESET_REQUIRED && dataForResetNeeded &&
          <ForgotPasswordVerifyAndSet 
            context="FORCE_RESET" 
            values={dataForResetNeeded} 
            nextStep={() => {setLoginState(RESET_COMPLETE)}}/>
        }

        {
          loginState && loginState === RESET_COMPLETE &&
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <h1>You've successfully reset your password.</h1>
              
              <p><button className='btn btn-link' onClick={() => setLoginState(LOGIN_NEEDED)}>Login now</button></p>
            </div>
          </div>
        }

        {
          loginState && loginState === LOGIN_NEEDED &&
          <section className="page page-slim">
            <h1>Login</h1>
            <form onSubmit={handleSubmit} className="after-heading">
              
              {
                loginError === '' && timeout === true && 
                <Alert type='warning' message={'Your session has timed out. Please login again.'} />  
              }

              <Alert type='error' message={loginError} />
              
              <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" 
                    onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" className="form-control" id="password" placeholder="Password"
                    onChange={handleChange} />
                </div>
                <div className="buttonContainer">
                  <SubmitButton actionTextOverride='Login' isLoading={isLoading} />  
                </div>
            </form>
            
            <p><Link to="/forgot-password">Forgot your password?</Link></p>
            <p><Link to="/register">Need to register?</Link></p>
            </section>

        }

        
     </>

    );
  
}

export default Login