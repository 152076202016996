import React from 'react'
import MemberAssignments from '../assignments/components/MemberAssignments'
import DatesBlocked from '../profile/components/DatesBlocked'
import CurrentWeekEvents from '../events/components/CurrentWeekEvents'
import sessionManager from '../lib/session'
import AvailabilityList from '../members/components/AvailabilityList'
import AdminOnly from '../routes/components/AdminOnly'
const Home = () => {
  const session = sessionManager.getSession()
 

  return (
    <section id="home" className="with-sidebar">
      {
      session.currentWeek && session.orgData &&
      
      <div className="row">
      <div className="col-md-3">
        <div className="card">
          <h2>Hi, {session.userData.firstName}</h2>
          <DatesBlocked readOnly={true} />
          {<MemberAssignments limit={true} />}
          </div>
        </div>
        <div className="col-md-9 column-with-sidebar">
          <AdminOnly>
          <div className="card">
           
              <AvailabilityList />
            
          </div>
          </AdminOnly>
          <div className="card">
          <CurrentWeekEvents />
          </div>
        </div>
        
      </div>
      }
    </section>
  )
}

export default Home;