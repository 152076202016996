import React from 'react'

const ViewSectionDetails = ({id,sectionData}) => {
  return (
    <div className="arrangement-section" key={`songSection${id}`}>
      <span data-testid={`songSection${id}`}>{ sectionData.songSection.sectionName }</span>
      {
        sectionData.direction &&
        <div className="arrangement-section-direction" data-testid={`direction${id}`}>{sectionData.direction}</div>
      }
    </div>
  )
}


export default ViewSectionDetails