import React, { useState,useEffect } from "react"
import {updateArrayOfObjects,generateTempId,moveItemInArray} from '../../lib/index'
import Alert from '../../components/Alert'
import DropDownField from "../../components/formFields/DropDownField"
import FormField from "../../components/formFields/FormField"
import SelectMembers from '../../members/components/SelectMembers'
import SubmitButton from '../../components/SubmitButton'


const SaveAgendaSection = ({ onSuccess, associatedSongs, assignedMembers, sectionData, availableSermons }) => {
  const [errorMessage, ] = useState("")
  const [isLoading, ] = useState(false)
  const [newSectionData,setNewSectionData] = useState()
  
  /* 
    without this useEffect, for some reason it was holding on
    other section data when adding and editing sections
  */
  useEffect(() => {
    if(sectionData) setNewSectionData(() => sectionData)
  },[sectionData])
  
  const [fieldErrors,] = useState([{
    "title": null
  }])
  
  const validationFunctions = {
    "title": () => { return true }
  }

  const setSectionTitle = (newTitle) => {
    setNewSectionData((previous) => {
      return {
        ...previous,
        "sectionTitle": newTitle
      }
    })
  }
  const setSectionLedBy = (newLeadById) => {
    const newLead = (newLeadById === -1) ? null : assignedMembers.filter(member => member.userId === newLeadById)[0]
    setNewSectionData((previous) => {
      return {
        ...previous,
        "sectionLead": newLead
      }
    })
  }

  const setSectionItemTitle = (itemId,sectionTitle) => {
    const itemData = {
      "itemId": itemId,
      "itemType": "text",
      "itemTitle": sectionTitle
    }

    const newItems = updateArrayOfObjects("itemId",newSectionData.items,itemData)

    setNewSectionData((previous) => {
      return {
        ...previous,
        "items": newItems
      }
    })

  }

  const setSongItem = (itemId,songId) => {

    // grab the data about the song from all songs associated to the event
    // (the drop down is only going to give us the ID)
    const newSongSelected = (songId) ? associatedSongs.filter(song => song.songId === songId)[0] :
    {
      "songId": null,
      "songTitle": ""
    }

    // now we need to tie the agenda section item ID to the song
    const newItemData = {
      ...newSongSelected,
      "itemType": "song",
      "itemId": itemId
    }

    // get the new items for this agenda section based on the user's change
    const newItems = updateArrayOfObjects("itemId",newSectionData.items,newItemData)

    // change the section data
    setNewSectionData((previous) => {
      return {
        ...previous,
        "items": newItems
      }
    })
  }

  const setSermonItem = (itemId,sermonId) => {
    
    const match = (sermonId) ? availableSermons.filter(sermon => sermon.sermonId === sermonId)[0] :
    {
      "sermonId": null,
      "sermonTitle": ""
    }
    
    const newSermonSelected = (availableSermons.length === 1) ? 
      availableSermons[0]
      :
      match

    // now we need to tie the agenda section item ID to the song
    const newItemData = {
      ...newSermonSelected,
      "itemType": "sermon",
      "itemId": itemId,
    }

    // get the new items for this agenda section based on the user's change
    const newItems = updateArrayOfObjects("itemId",newSectionData.items,newItemData)

    // change the section data
    setNewSectionData((previous) => {
      return {
        ...previous,
        "items": newItems
      }
    })
  }

  const removeItem = (event,itemId) => {
    event.preventDefault()
     // change the section data
     setNewSectionData((previous) => {
      return {
        ...previous,
        "items": newSectionData.items.filter(item => item.itemId !== itemId)
      }
    })
  }


  const validateForm = () => {
    return true
  }

  const handleSubmit = async event => {
    event.preventDefault()
    
    if(validateForm() === true) {
      onSuccess(newSectionData)
    }
  }

  const moveItem = (event,itemId,direction) => {
    event.preventDefault()
    const currentIndex = newSectionData.items.findIndex(item => item.itemId === itemId)
    const newItems = moveItemInArray(newSectionData.items,currentIndex,currentIndex+direction)
    setNewSectionData((previous) => {
      return {
        ...previous,
        "items": newItems
      }
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      {associatedSongs && assignedMembers && newSectionData && 
      <>
      <Alert type='error' message={errorMessage} />
      
      <div className="form-group">
        <label htmlFor="section-title">Section title</label>
        <FormField
          type="text"
          id="section-title"
          errorMessage={fieldErrors.title}
          value={newSectionData.sectionTitle}
          handleChange={setSectionTitle}
          validateFunction={validationFunctions.title}
        />
      </div>
      {
        assignedMembers && 
        <div className="form-group">
          <label htmlFor="song-title">Section led by</label>
          {
            assignedMembers && assignedMembers.length > 0 &&
            <SelectMembers id={`sectionLedBy${newSectionData.sectionId}`} passedInList={assignedMembers} selectedMember={(newSectionData.sectionLead) ? newSectionData.sectionLead : "-1"} setFunction={setSectionLedBy} defaultValueText="Please select..."/>
          }
          {
            assignedMembers && assignedMembers.length === 0 &&
            <p className="no-results">No members assigned to the event. </p>
          }
        </div>
      }
      <div id="sectionItems">
        <label>Items</label>
        {
            newSectionData && newSectionData.items.length === 0 &&
            <p className="no-results">Add to the agenda using the links below.</p>
          }
          {
            newSectionData && newSectionData.items.length > 0 &&
            <>
            
          {
            newSectionData.items.map(item => 
            <div className="with-actions" key={`sectionItem${item.itemId}`}>
              {
              item && item.itemType && item.itemType.toUpperCase() === "SONG" &&
                <div>
                  <SongDropDown songs={associatedSongs} selectedSong={item} itemId={item.itemId} onChange={setSongItem}/>
                </div>
              }
              {
                item && item.itemType && item.itemType.toUpperCase() === "TEXT" &&
                  <div>
                    <FormField 
                      id={`title${item.itemId}`}
                      value={item.itemTitle}
                      type="text"
                      handleChange={(value) => setSectionItemTitle(item.itemId,value)}
                    />
                  </div>
              }

              {
                availableSermons && availableSermons.length > 0 && item && item.itemType && item.itemType.toUpperCase() === "SERMON" &&
                <div>
                  {
                    availableSermons.length === 1 &&
                    <>{item.sermonTitle}</>
                  }
                  {
                    availableSermons.length > 1 && 
                    <SermonDropDown 
                    itemId={item.itemId}
                    selectedSermon={item}
                    onChange={setSermonItem}
                    sermonList={availableSermons}
                  />
                  }
                 
                </div>
              }
              <div>
                <button className='btn btn-icon btn-delete' onClick={(event) => removeItem(event,item.itemId)}></button>
                {
                  sectionData.items.length > 1 &&
                  <>
                    <button className='btn btn-icon btn-up' onClick={(event) => moveItem(event,item.itemId,-1)}></button>
                    <button className='btn btn-icon btn-down' onClick={(event) => moveItem(event,item.itemId,1)}></button>
                  </>
                }
              </div>
            </div>
            )
          }
          </>
          }
        </div>

        <button className='btn btn-icon btn-add' onClick={(event) => {event.preventDefault();setSectionItemTitle(generateTempId(),'')}}>Add an item</button>

        {
          associatedSongs && associatedSongs.length > 0 &&
          <button className='btn btn-icon btn-add' onClick={(event) => {event.preventDefault();setSongItem(generateTempId())}}>Add a song</button>
        }

        {
          availableSermons && availableSermons.length > 0 &&
          <button className='btn btn-icon btn-add' onClick={(event) => {event.preventDefault();setSermonItem(generateTempId())}}>Add a sermon</button>
        }

        
        
        


        <div className="buttonContainer">
          <SubmitButton isLoading={isLoading} />  
        </div>
      </>
      }
    </form>

  )
}

const SongDropDown = ({songs,selectedSong,itemId,onChange}) => {
  const songsForDropDown = songs.map(song => {
    return {
      "songId": song.songId,
      "displayTitle": `${song.songTitle} (${song.ledBy.songKey}) - ${song.ledBy.firstName} ${song.ledBy.lastName}`
    }
  })

  return (
    <DropDownField
      type="text"
      id={`existingSong${itemId}`}
      defaultValueText={"Select a song"}
      selectedValue={selectedSong}
      handleChange={(value) => { onChange(itemId,value) }}
      idField="songId"
      labelField="displayTitle"
      values={songsForDropDown}
    />
  )
}



const SermonDropDown = ({sermonList, selectedSermon,itemId,onChange}) => {
  return (
    <>
      {
        sermonList && sermonList.length > 0 &&
        <DropDownField
          type="text"
          id={`sermon-for-event${itemId}`}
          defaultValueText={"Select a sermon"}
          selectedValue={selectedSermon}
          handleChange={(value) => { onChange(itemId,value) }}
          idField="sermonId"
          labelField="sermonTitle"
          values={sermonList}
        />
      }
    </>
    
  )
}

export default SaveAgendaSection