import React from 'react'
import './css/Footer.scss'

const Footer = () => {
    return (
        <footer>
            <img src="/img/logo-grey.svg" className="logo" alt="Ministry Squads Footer Logo" />
            <div>All rights reserved &bull; { new Date().getFullYear() }</div>
        </footer>
    )
}

export default Footer