import React, { createContext, useContext } from 'react'
import dateManager from '@ministry-squads-common/date-manager'
const ManageEventContext = createContext()

const defaultObject = {
  "scheduledEventId": null,
  "eventName": null,
  "eventTypeId": null,
  "startDate": new Date(),
  "startTime": null,
  "endDate":  new Date(),
  "endTime": null,
  "rangeStartDate": null,
  "rangeEndDate": null,
  "repeatEvery": "none",
  "endDateManuallyChanged": false,
  "selectedTeams": [],
  "attendees": [],
  "eventSeriesId": null,
  "seriesData": {
    "rangeStartDate": null,
    "rangeEndDate": null,
    "repeatEvery": "none",
    "endDateManuallyChanged": false,
  },
  "oneTimeData": {
    "startDate": new Date(),
    "endDate":  new Date(),
  },
  "defaultStartTime": "09:00",
  "defaultEndTime": "09:00"
}

const reducer = (state, action) => {
  if(action.type === "startDate") {
    // if the user manually changed the end date, don't change it when they change the start date
    const newEndDate = (state.endDateManuallyChanged === true) ? state.endDate : action.payload
    return {
      ...state,
      "startDate": action.payload,
      "endDate": newEndDate
    }
  } else if(action.type === "endDate") {
    console.log("should keep end date")
    return {
      ...state,
      "endDate": action.payload,
      "endDateManuallyChanged": true
    }
  } else if (action.type === "reset") {
    return defaultObject
  } else if(defaultObject.hasOwnProperty(action.type) === true) {
    const newItem = []
    newItem[action.type] = action.payload
    
    return {
      ...state,
      ...newItem
    }

  } else {
    throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const ManageEventProvider = (props) => {

  const passedData = (props.existingData) ? props.existingData : null
  const initialData = (passedData) ? passedData : defaultObject

  let [ state, dispatch ] = React.useReducer(
    reducer, 
    initialData
  )
  let initialState = [ state, dispatch ]
  return (
    <ManageEventContext.Provider value={initialState}>
      { props.children }
    </ManageEventContext.Provider>
  )
}

const useManageEventState = () => {
    const context = useContext(ManageEventContext)
    if (context === undefined) {
      throw new Error('useEventState must be used within a ManageEventProvider')
    }
    return context
}

export { useManageEventState, ManageEventProvider  }