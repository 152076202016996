import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Alert from '../../components/Alert'
import dateManager from '@ministry-squads-common/date-manager'
import {get,httpDelete} from '../../lib/call-api'
import useIsMountedRef from '../../hooks/useIsMountedRef'
import session from '../../lib/session'

const DatesBlocked = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [dateToUnblock, setDateToUnblock] = useState()
    const [datesBlocked, setDatesBlocked] = useState({})
    const [errorMessage, setErrorMessage] = useState()
    const [successMessage, setSuccessMessage] = useState()
    const [refresh,setRefresh] = useState(true)

    const isMounted = useIsMountedRef()
    const sessionData = session.getSession()
    useEffect(() => {
        const onGet = (resp) => {
            if(isMounted.current === true) {
                setDatesBlocked(resp)
                setIsLoading(false)
            }
        }

        if(refresh === true || typeof(props.justBlocked) !== 'undefined') {
            get(`/api/availability/user/${sessionData.userData.userId}`,onGet,setErrorMessage)
            if(typeof(props.justBlocked) !== 'undefined') {
                setErrorMessage('')
                setSuccessMessage('')
            }
            setRefresh(false)
        }
        return () => isMounted.current = false

      },[props.justBlocked,refresh,isMounted]
    )

    const removeBlockedDate = async event => {
        setIsLoading(true)
        setSuccessMessage()
        setErrorMessage()
        const localDateToUnblock = event.target.value
        setDateToUnblock(localDateToUnblock)
        
        props.onDelete()

        const onPost = (resp) => {
            setRefresh(true)
            setSuccessMessage(`You are now available on ${dateManager.singleDate(localDateToUnblock)}`)
        }

        const deleteBody = {
            "dateToUnblock": localDateToUnblock,
            "action": "remove"
        }
        httpDelete("/api/availability",onPost,setErrorMessage,setIsLoading,' unblock a date',deleteBody)
    } 

    const wasDateJustBlocked = (dtToCheck,datesJustBlocked) => {
        if(datesJustBlocked) {
            const match = datesJustBlocked.filter(dt => dateManager.isSame(dtToCheck,dt.date, 'DAY'))
            return match.length > 0
        }
    }

    return (
        <section id="dates-blocked">
            <HeadingTag readOnly={props.readOnly}>Dates you have blocked</HeadingTag>
           
            {
                datesBlocked.length === 0 &&
                <>
                    <p className="no-results">You are availble for all dates in the future.</p>
                    {
                        props.readOnly === true &&
                        <Link to="/profile/availability">Manage your availability</Link>
                    }
                </>
            }
            {
                datesBlocked.length > 0 &&
                <>
                {
                    props.readOnly === true &&
                    <>
                        <ul>
                            {
                                datesBlocked.map((dateBlocked, datesBlockedCtr) =>
                                    <li key={`blocked-date${datesBlockedCtr}`}><OutputBlockedDate dt={dateBlocked} /></li>
                                )
                            }
                        </ul>
                        <Link to="/profile/availability">Manage your availability</Link>
                    </>
                }
                {       
                    props.readOnly === false && 
                    <>
                    {
                        errorMessage && 
                        <Alert type='error' message={errorMessage} />
                    }
                    {
                        successMessage &&
                        <Alert type='success' message={successMessage} />
                    }

                    { props.children }
                   
                    <table>
                        <tbody>
                        {
                        datesBlocked.map((dateBlocked, datesBlockedCtr) =>
                            <tr 
                                className={(wasDateJustBlocked(dateBlocked.dateBlocked,props.justBlocked) && datesBlocked.length > 1) ? 'alert-success' : ''}
                                key={`date-availability-${datesBlockedCtr}`}>
                                <td>
                                    <OutputBlockedDate dt={dateBlocked} />
                                </td>
                            
                                <td>
                                    {
                                        ((isLoading === false ) || (isLoading === true && dateToUnblock !== dateBlocked.dateBlocked)) && 
                                        <button className="btn btn-link" onClick={removeBlockedDate} value={dateBlocked.dateBlocked}>Unblock</button>
                                    }
                                    {
                                        isLoading === true && dateToUnblock === dateBlocked.dateBlocked && 
                                        <p className="loading-status"><i className="fas fa-spinner fa-pulse"></i> Loading...</p>
                                    }
                                </td>
                            </tr>
                        )
                        }
                        </tbody>
                    </table>
                    </>
                    }
                </>
            }
           
        </section>
    )
}
const OutputBlockedDate = ({dt}) => {
    return dateManager.formatDate(dt.dateBlocked,'TEXT_FULL')
}

const HeadingTag = (props) => {
    return (
        <>
        { 
            props.readOnly === true && 
            <h3 data-testid="datesBlockedH3">{props.children}</h3>
        }

        {
            props.readOnly === false &&
            <h2 data-testid="datesBlockedH2">{props.children}</h2>
        }
        </>
        

    )
}

export default DatesBlocked