import React from 'react'
import Alert from '../../components/Alert'
import ViewSectionDetails from './ViewSectionDetails'
import './SongArrangement.scss'

const ViewSongArrangement = ({arrangementData, onEdit, successMessage = null}) => {
  return (
    <> 
      <header>
        {
          onEdit && 
          <div className="feature-manager">
            <button className="btn btn-link btn-slim" onClick={(e) => {e.preventDefault(); onEdit()}}>Edit this arrangement</button>
          </div>
        }
        
        <h3>{arrangementData.arrangementName}</h3>

        {
          successMessage &&
          <Alert type="success" message={successMessage} />
        }
        
      </header>
      <h4>Song order</h4>
      <div className="song-arrangement">
      {
        arrangementData.structure &&
        arrangementData.structure.map((section) => 
          <div key={`section${section.orderId}`} className="song-arrangement-section">
            <ViewSectionDetails 
              id={section.orderNumber}
              sectionData={section} />
          </div>
        )
      }
      </div>
    </>
  )
}

export default ViewSongArrangement