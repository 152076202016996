import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import SaveTeam from './components/SaveTeam'
import ManageRolesByTeam from './components/ManageRolesByTeam'
import Alert from '../components/Alert'
import {get} from '../lib/call-api'
import AdminOnly from '../routes/components/AdminOnly'
import SecondaryContent from '../components/SecondaryContent'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import useIsMountedRef from '../hooks/useIsMountedRef.js'
import SingleTeamDetail from './components/SingleTeamDetail.js'
import TeamOfTeamsDetail from './components/TeamOfTeamsDetail'
import SessionManager from '../lib/session'
import {SINGLE_TEAM_TYPE,TEAM_OF_TEAMS_TYPE,entityTypeText} from './common'

const TeamDetail = (props) => {
  const [teamData, setTeamData] = useState([])
  const [roles, setRoles] = useState([])
  const [subTeams, setSubTeams] = useState([])

  const [members, setMembers] = useState([])
  const [teamType,setTeamType] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const {teamId} = props.match.params
  const [successMessage, setSuccessMessage] = useState('')
  const [modalState, modalDispatch] = useSecondaryContentState()

  const [secondaryContentType,setSecondaryContentType] = useState()
  const [secondaryContentAction,setSecondaryContentAction] = useState()
  const [secondaryContentHeader,setSecondaryContentHeader] = useState()
  const isMounted = useIsMountedRef()

  const toggleSlider = (action) => {
    setSecondaryContentAction(action)
    setSecondaryContentType('flyout')

    if(action === 'editTeam') {
      setSecondaryContentHeader(`Edit team`)
    } else if(action === 'manageRoles') {
      setSecondaryContentHeader(`Manage ${entityTypeText(teamType)}`)
    }
  }

  useEffect(() => {
    if(secondaryContentType && secondaryContentAction) modalDispatch({ type: 'toggleIsOpen', payload: null })
  },[secondaryContentType,secondaryContentAction,modalDispatch])

    
  
  useEffect(() => {

    const onGet = (resp) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        if(resp) {
          const dbSubTeams = resp.subTeams
          setTeamData(resp)
          setRoles(resp.roles)
          setSubTeams(dbSubTeams)
          setMembers(resp.members)

          if(dbSubTeams && dbSubTeams.length !== 0) {
            setTeamType(TEAM_OF_TEAMS_TYPE)
          } else {
            setTeamType(SINGLE_TEAM_TYPE)
          }

        } else {
          setErrorMessage(`Couldn't get team detail`)
        }
      }
    }

    const onGetError = (error) => {
      setIsLoading(false)
      //if(isMounted.current === true) {
        setErrorMessage(error)
      //}
    }

    get(`/api/team/${teamId}`,onGet,onGetError)

    return  (() => { isMounted.current = false })

  }, [teamId, successMessage, isMounted])


  const updateOnAdd = (data, message) => {
    setTeamData(data)
    setRoles(data.roles)
    setMembers(data.members)
    setSuccessMessage(message)
    closeSecondaryContent()
  }

  const closeSecondaryContent = () => {
    modalDispatch({ type: 'close', payload: null })
    setSecondaryContentHeader()
    setSecondaryContentType()

  }
  return (
    <section className="page">
        {
          isLoading === false && 
          <>
          {
            errorMessage && 
            <> 
              <header>
                <Breadcrumbs  teamData={teamData}/>
                <h1>Team Detail</h1>   
                <Alert type="error" message={errorMessage} />
              </header>
            </>
          }
          {
            !errorMessage && teamData && teamData.teamName && 
            <>           
            <header>
              <Breadcrumbs teamData={teamData} />
          
              <h1>
                {teamData.teamName}
              </h1>
                
              <AdminOnly>
                <div className="feature-manager">
                  <button className="btn btn-icon btn-edit" onClick={() => toggleSlider('editTeam')}>Edit this team</button>

                  <button className="btn btn-icon btn-edit" onClick={() => toggleSlider('manageRoles')}>Manage {entityTypeText(teamType)}s</button>
                 
                </div>  
              </AdminOnly>

              <Alert type="success" message={successMessage} />
                
            </header>

            {
              teamType && teamType === SINGLE_TEAM_TYPE &&
              <SingleTeamDetail teamData={teamData} roles={roles} members={members} />
            }
            {
              teamType && teamType === TEAM_OF_TEAMS_TYPE && teamData && subTeams && members && 
              <TeamOfTeamsDetail teamData={teamData} subTeams={subTeams} members={members} />
            }
            </>
          }
          </>
        }

        <SecondaryContent 
          heading={secondaryContentHeader}
          onClose={closeSecondaryContent} 
          show={modalState.isOpen}
          type={secondaryContentType}>
          
          {
            secondaryContentAction === 'editTeam' &&
            <SaveTeam onSuccess={updateOnAdd} data={teamData}/>
            
          }

          {
            secondaryContentAction === 'manageRoles' &&
            <>
            {
              teamType && teamType === SINGLE_TEAM_TYPE &&
              <ManageRolesByTeam
                roles={roles} 
                nameAttribute='roleName'
                idAttribute='roleId'
                setRoles={setRoles} 
                teamId={teamData.teamId}
                parentEntityType={teamType}
              />
            }
            {
              teamType && teamType === TEAM_OF_TEAMS_TYPE && teamData && subTeams && members && 
              <ManageRolesByTeam
                roles={subTeams} 
                nameAttribute='teamName'
                idAttribute='teamId'
                setRoles={setSubTeams} 
                teamId={teamData.teamId}
                parentEntityType={teamType}
            />
            }
            </>

           
            
            
          }
         
        </SecondaryContent>
      
    </section>
  )
}

const Breadcrumbs = ({teamData}) => {
  const sessionData = SessionManager.getSession()

  return  (
    <div className="breadcrumbs">
      <Link to="/team/list">Teams</Link>
      {
        teamData && teamData.parentEntity.parentEntityId !== sessionData.orgData.orgId &&
        <Link to={`/team/detail/${teamData.parentEntity.parentEntityId}`}>{teamData.parentEntity.parentEntityName}</Link>
      }
    </div>
    )
}

export default TeamDetail