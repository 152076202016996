import dateManager from '@ministry-squads-common/date-manager'
import React, { useState,useEffect } from 'react'
import {Link} from 'react-router-dom'
import {detail} from '../services/eventSeries.service'

import Alert from '../components/Alert'
import EditOneTimeEvent from './components/EditOneTimeEvent'
import EditEventSeries from './components/EditEventSeries'

import './events.scss'
import { ManageEventProvider } from '../contexts/ManageEvent.Context'

const EditEvent = (props) => {
  const {eventSeriesId,eventInstanceId} = props.match.params
  const [savedData,setSavedData] = useState(null)

  const onSave = (data) => {
    setSavedData(data)
  }

  return (
    <section className="page">
      {
        savedData !== null &&
    
    
          <div className="card">
            <header>
              <div className="breadcrumbs"><Link to="/calendar">Calendar</Link></div>
              <h1>{savedData.resultHeading}</h1>
              <Alert type={savedData.resultType} message={savedData.resultMessage} />
            </header>
            <section>
            {
             <>{savedData.savedOutput()}</>
            }
            </section>
          </div>
      }

      {
        savedData === null &&
        <OutputComponent eventSeriesId={eventSeriesId} eventInstanceId={eventInstanceId} onSave={onSave} />  
      }
    </section>
  )
}


const OutputComponent = ({eventSeriesId,eventInstanceId,onSave}) => {
  const THIS_INSTANCE = 'this_instance'
  const ALL_INSTANCES = 'all_instances'
  const FUTURE_INSTANCES = 'future_instances'

  const [scopeOfChange,setScopeOfChange] = useState(THIS_INSTANCE)

  const [stateInitialData,setStateInitialData] = useState(null)

  useEffect(() => {
    if(stateInitialData === null && typeof(eventSeriesId) !== 'undefined' && eventSeriesId !== null) {
      detail(eventSeriesId).then(apiResponse => {
        setStateInitialData(determineInitialData(apiResponse,scopeOfChange))

      })
    }
  },[stateInitialData, eventSeriesId])

  const determineInitialData = (data,scopeOfChange) => {
    if(scopeOfChange === THIS_INSTANCE) {
      return translateInstanceToContext(data)
    } else {
      return translateSeriesToContext(data)
    }
  }
  
  const translateSeriesToContext = (seriesData) => {
    return {
      ...seriesData,
      "eventName": seriesData.eventSeriesName,
      "eventTypeId": seriesData.eventTypeId,
      "repeatEvery": seriesData.eventSeriesData.recurrencePattern,
      "rangeStartDate": dateManager.asDate(seriesData.eventSeriesData.rangeStartDate), 
      "rangeEndDate": dateManager.asDate(seriesData.eventSeriesData.rangeEndDate)
  }
  }

  const translateInstanceToContext = (seriesData) => {
    const instanceData = seriesData.events.filter(evt => evt.scheduledEventId === eventInstanceId)
    return translateSeriesToContext(instanceData[0])
  }

  return (

    <>
    <header>
      <div className="breadcrumbs"><Link to="/calendar">Calendar</Link></div>
      <h1>Edit an event</h1>
      <p></p>
      <h2>How do you want to apply your changes?</h2>
      <div className="form form-group radio-with-label">
        <input type="radio" name="eventType" id="eventTypeThisInstance" value="true" onChange={() => setScopeOfChange(THIS_INSTANCE)} checked={scopeOfChange===THIS_INSTANCE}/> 
        <label className="label-right"  htmlFor='eventTypeThisInstance'>This event only</label>
      </div>

      <div className="form form-group radio-with-label">
        <input type="radio" name="eventType" id="eventTypeAllInstances" value="true" onChange={() => setScopeOfChange(ALL_INSTANCES)} checked={scopeOfChange===ALL_INSTANCES}/> 
        <label className="label-right" htmlFor='eventTypeAllInstances'>All events in the series</label>
      </div>
      {
        /*
          <div className="form form-group radio-with-label">
            <input type="radio" name="eventType" id="eventTypeFutureInstances" value="true" onChange={() => setScopeOfChange(FUTURE_INSTANCES)} checked={scopeOfChange===FUTURE_INSTANCES}/> 
            <label className="label-right" htmlFor='eventTypeFutureInstances'>This event and all future events in the series</label>
          </div>
        */
      }
            
    </header>

    {
      stateInitialData &&
      <ManageEventProvider existingData={stateInitialData}>
          {
        scopeOfChange === THIS_INSTANCE &&
          <EditOneTimeEvent onSave={(data) => onSave(data)} />  
      }
        
      {
        scopeOfChange !== null && scopeOfChange !== THIS_INSTANCE &&
        
          <EditEventSeries onSave={(data) => onSave(data)} />  

      }
      </ManageEventProvider>
    }

  </>
  )
  

}
export default EditEvent