import React, { Fragment,useState } from 'react'
import {Link} from 'react-router-dom'
import classnames from 'classnames'
import RoleIcon from '../../components/RoleIcon'
import {useAssignmentsState } from '../context'
import dateManager from '@ministry-squads-common/date-manager'
import EventHeader from '../../events/components/EventHeader'
import UserSelector from '../../components/UserSelector'
import {consecutiveMatches,intersection} from '../../lib/index'
import ToggleButton from '../../components/ToggleButton'
import '../styles.scss' 

const DisplayAssignments = ({onMemberClick,onEventClick,displayMode}) => {
    const [state] = useAssignmentsState()
    const showGrouped = state.uiConfig.groupEvents === true
    const getEventDetails = (eventDetails,eventsByGroup) => {
        return eventDetails.filter(event => {
            return eventsByGroup.eventIds.some(eventId => eventId === event.scheduledEventId && eventsByGroup.eventGroupId === event.eventGroupId)
        })
    }

    const className = (displayMode) ? displayMode : state.uiConfig.defaultDisplayMode
    
    const getKey = (ctr,eventSeries) => {
        return `weekrow-${ctr}-${eventSeries.eventIds[0]}`
    } 

    return ( 
        <section>
        {
            state.eventsByWeek && state.eventsByWeek.length === 0 &&
            <div className="card">
                <p className="no-results">There aren't any events in the current date range that the selected team can be assigned to.</p>
            </div>
        }

        {
            state.events && state.eventsByWeek && state.team && state.assignmentsInRange && state.members && state.eventsByWeek.length > 0 &&
            <div className={`assignments ${className}`}>
                {
                    state.eventsByWeek.length > 0 && 
                    state.eventsByWeek.map((thisWeek,weekCtr) => 
                            <Fragment key={`weekrow-${weekCtr}`}>
                            {
                                
                                thisWeek.eventsByGroup.map(eventGroup => 
                                //assignableEvents(thisWeek.eventsByType).map(eventSeries =>
                                <Output 
                                    key={getKey(weekCtr,eventGroup)}
                                    eventSeries={eventGroup}    
                                    eventData={getEventDetails(state.events,eventGroup)}
                                    onMemberClick={onMemberClick}
                                    onEventClick={onEventClick}
                                    showGrouped={showGrouped}
                                />
                            )
                        }
                        </Fragment>
                        )
                    }
            </div>
        }
        </section>
    )
}

const getEventsOnDay = (day,allEvents) => {
    return allEvents.filter(evt => new Date(evt.startDate).getDay() === day)
}

const findSameAssignments = (events,allTeamAssignments) => {
    const uniqueDaysOfWeek = [...new Set(events.map(event => new Date(event.startDate).getDay()))]

    const assignmentsByEvent = uniqueDaysOfWeek.map(day => {
        const eventsOnDay = getEventsOnDay(day,events)
        
        return eventsOnDay.map(evt => {
            const eventAssignments = allTeamAssignments.filter(allTeamAssignment => allTeamAssignment.scheduledEventId === evt.scheduledEventId)
            
            if(eventAssignments.length === 0) {
                return {
                    "eventId": evt.scheduledEventId,
                    "assignments": []
                }
            } else {
                return {
                    "eventId": evt.scheduledEventId,
                    "assignments": eventAssignments.map(eventAssignment => {
                        return {
                            "roleId": eventAssignment.roleId,
                            "assigneeId": eventAssignment.assigneeId
                        }
                    })
                }
            }
            
        }) 

    })

    return consecutiveMatches(assignmentsByEvent,"assignments")
}

const Output = ({ eventSeries, eventData, onEventClick, onMemberClick, showGrouped }) => {
    const [state] = useAssignmentsState()
    const onlyTeamAssignments = intersection(state.assignmentsInRange,state.team.roles,'roleId','roleId')
    const eventsWithSameAssignments = findSameAssignments(eventData,onlyTeamAssignments)
    // if we are in edit mode, honor the configuration setting when showing events.
    // if we are not in edit mode, we want the data to drive if we group events
    // if all events of a type within a week have the same assignment, group them.
    const groupEvents = (state.uiConfig.mode === 'edit' || state.uiConfig.mode === 'edit-only') ? showGrouped : eventsWithSameAssignments.length === 1
    

    const getAssignableEvents = (listOfEvents) => {
        
        // events that are not yet scheduled don't bring back an assignableTeams array in the schedule list API right now.
        // they DO come back in the event detail API
        // since the schedule API is called on a page in the UI that is ALREDY filtered by team, we just automatically include the event when an assignableTeams array is undefined
        // probably should do this a better way

        const assignableEventsForTeam = listOfEvents.filter(evt => (typeof evt.assignableTeams === 'undefined') ? true : evt.assignableTeams.filter(assignableTeam => assignableTeam.teamId === state.team.teamId).length > 0)
            
        return intersection(listOfEvents,assignableEventsForTeam,"scheduledEventId", "scheduledEventId")
    }

    const assignableEvents = getAssignableEvents(eventData)
    
    return (
        <>
        {
            (groupEvents === true) && assignableEvents && assignableEvents.length > 0 && 
        
                <div 
                    className="event grouped-event"
                    key={`thisweek-${eventSeries.eventSeriesId}-${assignableEvents[0].scheduledEventId}`}>  
                    <EventHeader 
                        eventData={assignableEvents}
                        eventSeries={eventSeries}
                        onEventClick={onEventClick}
                    />
                    <EventAssignment  
                        eventData={assignableEvents}
                        onMemberClick={onMemberClick}
                    />
                </div>
        }
        
        {
            groupEvents === false && assignableEvents  && assignableEvents.length > 0 && 
            assignableEvents.map(evt => 
                <div 
                    className="event grouped-event"
                    key={`thisweek-${eventSeries.eventSeriesId}-${evt.scheduledEventId}`}>  
                    <EventHeader 
                        eventData={[evt]}
                        eventSeries={eventSeries}
                        onEventClick={onEventClick}
                    />
                    <EventAssignment  
                        eventData={[evt]}
                        onMemberClick={onMemberClick}
                    />
                </div>
            )
        }
    </>
    )
}

const EventAssignment = ({ eventData, onMemberClick }) => {
    const [state] = useAssignmentsState()

    const className = (state.uiConfig.mode === 'edit') ? "editable" : "read-only"
    const getShowPanelDefault = () => {
        return state.uiConfig.mode === 'edit-only'
    }

    const [showPanel,setShowPanel] = useState(getShowPanelDefault())
    return (
        <div className={`event-assignments ${className}`}>
        
        {
            state.uiConfig.mode === 'edit' &&
            <div className="assignments-action-row" onClick={() => {setShowPanel(showPanel === false)}}>
                <button 
                    className='btn btn-link btn-slim btn-edit-assignment'
                    onClick={(e) => {e.preventDefault();setShowPanel(showPanel === false)}}
                    >
                    {
                        showPanel === false &&
                        <>Edit assignments</>
                    }
                    {
                        showPanel === true &&
                        <>Close</>
                    }
                </button>

            </div>
        }
        {
            state.uiConfig.assignBy === 'team' &&
                <EventAssignmentByTeam eventData={eventData} onMemberClick={onMemberClick} editing={showPanel} />
        }
        {
            state.uiConfig.assignBy === 'member' &&
                <EventAssignmentByRole eventData={eventData} onMemberClick={onMemberClick} editing={showPanel} />
        }
        </div>
    )
   
    
}

const EventAssignmentByTeam = ({ eventData, onMemberClick, editing }) => {
    return (
        <>
        {
            editing === true && 
            <EditEventAssignmentByTeam eventData={eventData} onMemberClick={onMemberClick} />          
        }

        {
            editing === false && 
            <ShowEventAssignmentByTeam eventData={eventData} />          
        }
        </>
        
    )
}

const ShowEventAssignmentByTeam = ({ eventData }) => {
    const [state] = useAssignmentsState()

    const eventIds = eventData.map(event => event.scheduledEventId)
    const teamSubIds = state.team.subTeams.map(subTeam => subTeam.teamId)
    const assignmentsForDisplay = state.assignmentsInRange.filter(assignment => 
        assignment.scheduledEventId === eventIds[0] &&
        assignment.assigneeType === 'team' &&
        teamSubIds.filter(subTeamId => assignment.assigneeId === subTeamId).length > 0
    )

    const getTeamName = (subTeamId) => {
        const team = state.team.subTeams.filter(subTeam => subTeam.teamId === subTeamId)
        return team[0].teamName
    } 
    return (
        <div className="role-assignment">
            {
                assignmentsForDisplay.length === 0 &&
                <div className="no-results">No assignment</div>
            }
             {
                assignmentsForDisplay.length > 0 &&
                assignmentsForDisplay.map(assignment => 
                    <div key={`assignee${assignment.assigneeId}-${assignment.scheduledEventId}`}>
                        {getTeamName(assignment.assigneeId)}
                    </div>
                )
            }
        </div>
    )
}

const EditEventAssignmentByTeam = ({ eventData, onMemberClick }) => {
    const [state] = useAssignmentsState()
    const eventIds = eventData.map(event => event.scheduledEventId)

    const isAssigned = (teamToCheck) => {
        return state.assignmentsInRange.filter(assignment => 
            assignment.scheduledEventId === eventIds[0] &&
            assignment.assigneeType === 'team' &&
            assignment.assigneeId === teamToCheck.teamId).length > 0
    }
    
    return (
        <div className="role-assignment">
{            
        state.team.subTeams.map(subTeam => 
            <ToggleButton
                key={`toggle${eventIds[0].scheduledEventId}-${subTeam.teamId}}`}
                unique={subTeam.teamId}
                handleClick={() => onMemberClick(eventIds,null,subTeam.teamId,'team')}
                isEnabled={true}
                isSmall = {false}
                isSelected = {isAssigned(subTeam)}
                extraClass="inline">
                {subTeam.teamName}
            </ToggleButton> 
        )
        
}    
</div>)
} 

const EventAssignmentByRole = ({ eventData, onMemberClick,editing }) => {
    const [state] = useAssignmentsState()

    const eventIds = eventData.map(event => event.scheduledEventId)
    
    const assignmentsForRole = (role) => {
        const assignmentsForDisplay = state.assignmentsInRange.filter(assignment => assignment.scheduledEventId === eventIds[0])

        return assignmentsForDisplay.filter(assignment => 
        assignment.roleId === role.roleId && assignment.roleId !== null && assignment.assigneeId !== null)
    }
    return (
       
        <div className="assignments-by-role">
        {
        state.team.roles.map(role => 
            <div className={
                classnames(
                    `role-assignment role${role.roleId}`,
                    { 'no-assignments' : assignmentsForRole(role).length === 0}
                )} 
                key={`roletd${role.roleId}`}>
                <div className="event-role-assignment">
                    <div className="role-with-icon">
                        <RoleIcon roleData={role} /> 
                            {role.roleName}
                    </div>
       
        {
            editing === false &&
            <ShowAssignmentsByRole 
                eventData={eventData} 
                role={role} 
                assignmentsForRole={assignmentsForRole} 
            />
        }
        
        {
           editing === true &&
            <EditAssignmentsByRole 
                eventData={eventData} 
                role={role} 
                assignmentsForRole={assignmentsForRole} 
                onMemberClick={onMemberClick} 
            />
        }
        </div>



            </div>
            
        )}
        </div>
    )
    
}   

const ShowAssignmentsByRole = ({eventData,role, assignmentsForRole}) => {
    const [state] = useAssignmentsState()
    const eventIds = eventData.map(event => event.scheduledEventId)

    const getMember = (userId) => {
        const returnVal = state.members.filter(member => member.userId === userId)
        if(returnVal.length === 1) {
            return returnVal[0]
        } else {
            return "error getting member name"
        }
    }

    return (
       <> {
            assignmentsForRole(role).length === 0 && 
            <div className="no-results">
                No assignment
            </div>
        }
        {
            assignmentsForRole(role).length > 0 && 
            <div>
            { 
            assignmentsForRole(role).map((assignment,ctr) => 
                <span key={`ub-${ctr}-${eventIds[0]}-${role.roleId}-${assignment.assigneeId}`}>
                    {getMember(assignment.assigneeId).firstName} { getMember(assignment.assigneeId).lastName}<br/>
                </span>
            )
            }
            </div>
        }
        </>
    )
}

const EditAssignmentsByRole = ({eventData,role,assignmentsForRole, onMemberClick}) => {
    const [state] = useAssignmentsState()
    const eventIds = eventData.map(event => event.scheduledEventId)

    const eventDates = eventData.map(event => event.startDate)

    const membersInRole = (roleId) => {
        const returnAry = []
    
        state.members.forEach(member => {
           
            if(member.roles.filter(thisRole => thisRole.roleId === roleId).length > 0) returnAry.push(member)
            
        })
        return returnAry
    }

    const isAssigned = (roleToCheck,userId) => {
        return (assignmentsForRole(roleToCheck).filter(assignment => assignment.assigneeId === userId).length > 0)
    }

    const isAvailable = (userData,datesToCheck) => {

        const datesNotAvailable = (userData.datesNotAvailable) ? userData.datesNotAvailable.map(notAvailDate => dateManager.formatDate(notAvailDate,"URL_PARAMETER")) : []

        const uniqueEventDays = [...new Set(datesToCheck.map(startDate => dateManager.formatDate(startDate,"URL_PARAMETER")))]
       
        const intersection = uniqueEventDays.filter(eventDay => datesNotAvailable.includes(eventDay));
        
        if(!datesNotAvailable || datesNotAvailable.length === 0) {
            return true
        } else if(intersection.length > 0) {
            return false
        } else {
            return true
        }

    }

    return (
        <div>
            {
                membersInRole(role.roleId).map(member => 
                    <UserSelector 
                        key={`mem-role-${role.roleId}-${member.userId}`}
                        teamMember={member} 
                        handleClick={() => {onMemberClick(eventIds,role,member.userId,'member')}}
                        isSmall = {true}
                        isSelected = {isAssigned(role,member.userId)}
                        isEnabled = {isAvailable(member,eventDates) === true}
                        ifDisabledMessage = {`${member.firstName} has blocked this date.`}
                        />
                    
                )
            }   
        </div>
    )
}


export default DisplayAssignments