import React, { createContext, useContext } from 'react'
import { updateArrayOfObjects } from '../lib/index'

const OrderableSectionContext = createContext()

const ADD_MODE_TXT = "ADD"
const EDIT_MODE_TXT = "EDIT"
const READ_MODE_TXT = "READ"

const reducer = (state, action) => {
    switch (action.type) {
        case "updateSectionList" :
            return { ...state, "sections": action.payload }
        case "setAddMode" :
            return { ...state, "mode":ADD_MODE_TXT }
        case "setEditMode" :
            return { ...state, "mode":EDIT_MODE_TXT }
        case "setReadMode" :
            return { ...state, "mode":READ_MODE_TXT }
        case "updateSectionBeingEdited" :
            return { ...state, "sectionBeingEdited": action.payload }
        case "updateKeyName" :
            return { ...state, "keyName": action.payload }
        case "addSectionError":
            const newErrors = state.sectionErrors
            newErrors.push(action.payload )
            return { ...state, "sectionErrors": newErrors }
        case "clearSectionError":
            const sectionId = action.payload
            const withoutSectionError = state.sectionErrors.filter(sectionError => sectionError[state.keyName] !== sectionId)
            return { ...state, "sectionErrors": withoutSectionError }
        case "cancelUpdate": 
        
            let withoutSection
            if(state.mode === EDIT_MODE_TXT) {
                withoutSection = updateArrayOfObjects(state.keyName,state.sections, state.sectionBeingEdited) 
            } else if(state.mode === ADD_MODE_TXT) {
                withoutSection =  state.sections.filter(item => item[state.keyName] !== state.sectionBeingEdited[state.keyName])
            } else {
                throw new Error('Invalid mode when cancelling.')
            }
            return { 
                ...state, 
                "sections": withoutSection,
                "sectionBeingEdited": null,
                "mode": READ_MODE_TXT
            } 
        
        case "updateSection" :
            const withUpdatedSection = updateArrayOfObjects(state.keyName,state.sections, action.payload)
            return { 
                ...state, 
                "sections": withUpdatedSection,
                "sectionBeingEdited": null
            } 

        case "addNewSection": 
            const newSection = action.payload
            const withNewSection = updateArrayOfObjects(state.keyName,state.sections, newSection)
            return { 
                ...state, 
                "sections": withNewSection,
                "sectionBeingEdited": newSection
            }
        default :
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}


const OrderableSectionProvider = ({children}) => {
    
    let [ state, dispatch ] = React.useReducer(
        reducer, 
        {
            "mode": "READ",
            "keyName": null,
            "sections": null,
            "sectionBeingEdited": null,
            "sectionErrors": []
        });
    let orderableSections = [ state, dispatch ];

    return (
        <OrderableSectionContext.Provider value={orderableSections}>
            { children }
        </OrderableSectionContext.Provider>
    )
}

const useOrderableSectionState = () => {
    const context = useContext(OrderableSectionContext)
    if (context === undefined) {
      throw new Error('useOrderableSectionState must be used within a OrderableSectionProvider')
    }
    return context
}

export { useOrderableSectionState, OrderableSectionProvider }