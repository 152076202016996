import React, { createContext, useContext } from 'react'
import dateManager from '@ministry-squads-common/date-manager'
import {intersection} from '../lib/index'
import sessionManager from '../lib/session'
const AssignmentsContext = createContext()

const scheduleReducer = (state, action) => {
    switch (action.type) {
        case "initializeEvents":
          const events = action.payload.events
          const assignments = action.payload.assignments
          const newMinMax =  (action.payload.minMax) ? action.payload.minMax : state.minMax
          
          const dateRange = {
            "minDate": (events.length > 0) ? events[0].startDate : null,
            "maxDate": (events.length > 0) ? events[events.length-1].startDate : null
          }
          const newDateRangeHistory = setViewedRange(dateRange,state.dateRangeHistory)

          const previouslySavedAssignments = state.allAssignments
          const newAssignments = (Array.isArray(previouslySavedAssignments) === true && state.mode === 'edit') ? previouslySavedAssignments.concat(addAssignmentsOutOfRange(events,assignments,newDateRangeHistory)) : assignments
          return {
            ...state,
            "eventsByWeek": action.payload.eventsByWeek,
            "events": events,
            "allAssignments": newAssignments,
            "assignmentsInRange": setAssignmentsInRange(newAssignments,events),
            "minMax": newMinMax,
            "dateRangeHistory": newDateRangeHistory
          }
        case "setMembers" :
          return { ...state, "members": action.payload }
        case "updateAssignments" :
          return { 
            ...state, 
            "allAssignments": action.payload,
            "assignmentsInRange": setAssignmentsInRange(action.payload,state.events)
        }
        case "setSelectedTeamId": return { 
          ...state, 
          "selectedTeamId": action.payload
        }
     
        case "setTeam":
          return { 
            ...state, 
            "uiConfig": {
              ...state.uiConfig,
              "groupEvents": action.payload.groupAssignmentsByWeek,
              "assignBy": action.payload.assignBy
            },
            "team": action.payload 
          }
       
        case "setDateRange":
          return { ...state, "dateRange": action.payload }
        case "setGroupEvents":
            return { 
              ...state, 
              "uiConfig": {
                ...state.uiConfig,
                "groupEvents": action.payload,
              }
          }
        case "setMode":
          return { 
            ...state, 
            "uiConfig": {
              ...state.uiConfig,
              "mode": action.payload,
            }
          }
        default :
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

/**
 * setViewedRange
 * dateRangeHistory keeps track of the earliest and latest event dates that the user has selected.
 * initially state.dateRangeHistory.minDate and maxDate are null
 * when the user applies their first date range, the state.dateRangeHistory.minDate and maxDate will be set to the first and last event dates respectively
 * if the user then changes the end date of the range to be further out in the future, state.dateRangeHistory.maxDate will be updated
 * if the user then changes the start date of the range to be further in the past, state.dateRangeHistory.minDate will be updated
 * @param {object} newRange contains the earliest and latest event dates in state.events.
 * @param {object} previousViewedRange this represents the oldest and latest event dates that the user has selected while using this context
 * @returns object
 */
const setViewedRange = (newRange,previousViewedRange) => {

  const getEarliest = (newEarliest,previousEarliest) => {
    if(!previousEarliest) {
      return newEarliest
    } else if(newEarliest < previousEarliest) {
      return newEarliest
    } else {
      return previousEarliest
    }
  }
  const getLatest = (newLatest,previousLatest) => {
    if(!previousLatest) {
      return newLatest
    } else if(newLatest > previousLatest) {
      return newLatest
    } else {
      return previousLatest
    }
  }

  return {
    "minDate": getEarliest(newRange.minDate,previousViewedRange.minDate),    
    "maxDate": getLatest(newRange.maxDate,previousViewedRange.maxDate)
  }
  
}

/**
 * state.allAssignments represents all of the assignments that have been shown in the UI. As the user interacts
 * with the date range of the assignments UI, we add assignments that we haven't catpured yet.
 * the range of events that we've already captured assignments for in allAssignments is represented by state.dateRangeHistory
 * 
 * this function will look through the latest list of assignments and return those assignments that correspond to 
 * events that fall out of the range defined by dateRangeHistory
 * 
 * @param {*} events array of objects that represents the events that fall within the current date range
 * @param {*} assignments array of objects that represents the assignments that fall within the current date range
 * @param {object} dateRangeHistory two properties: minDate and MaxDate
 * @returns 
 */
const addAssignmentsOutOfRange = (events,assignments,dateRangeHistory) => {
  let mostAssignments = []
  let minAssignments = []

  // ADD EVENTS that precede the previous earliest event date
  if(dateManager.diff(dateRangeHistory.minDate,events[0].startDate,'SECONDS') < 0) {
    // get all the events that are later than the lastest date we've used so far
    const earlierEvents = events.filter(evt => evt.startDate < dateRangeHistory.minDate)

    // get all the assignments for those events
    minAssignments = intersection(assignments, earlierEvents, "scheduledEventId", "scheduledEventId")
  }

  // ADD EVENTS that are after the previous latest event date
  if(dateManager.diff(dateRangeHistory.maxDate,events[events.length-1].startDate,'SECONDS') > 0) {
    // get all the events that are later than the lastest date we've used so far
    const laterEvents = events.filter(evt => evt.startDate > dateRangeHistory.maxDate)

    // get all the assignments for those events
    mostAssignments = intersection(assignments, laterEvents, "scheduledEventId", "scheduledEventId")
  }

  return minAssignments.concat(mostAssignments)
}

/**
 * while allAssignments stores all the assignments that have been made, assignmentsInRange keeps track of only the 
 * filters that list down to only correspond to the events that are within the current date range
 * @param {*} assignments 
 * @param {*} eventsInRange 
 * @returns 
 */
const setAssignmentsInRange = (assignments,eventsInRange) => {
  return intersection(assignments, eventsInRange, "scheduledEventId", "scheduledEventId")
}

const AssignmentsProvider = (props) => {

  const sessionData = sessionManager.getSession()

    if(typeof props.initialMode === 'undefined' || (props.initialMode !== 'edit' && props.initialMode !== 'edit-only' && props.initialMode !== 'read-only')) {
      throw new Error(`A valid initial mode is required. Received ${props.initialMode}`)
    } else {
      const initialMode = props.initialMode
    
      const initialStartDate = (props.initialStartDate) ? props.initialStartDate : null
      const initialEndDate = (props.initialEndDate) ? props.initialEndDate : null

      const initialTeamId = (sessionData.userData.teams.length > 0) ? sessionData.userData.teams[0].teamId : null
      let [ scheduleState, scheduleDispatch ] = React.useReducer(
          scheduleReducer, 
          {
            "allAssignments": null,
            "assignmentsInRange": null,
            "members": null,
            "selectedTeamId": initialTeamId,
            "team": null,
            "events":null,
            "eventsByWeek":[],
            "dateRange": {
              "startDate" : initialStartDate,
              "endDate": initialEndDate
            },
            "minMax": {
              "firstEventDate": null,
              "lastEventDate": null,
            },
            "uiConfig": {
              "groupEvents": null,
              "defaultDisplayMode": "as-grid",
              "mode": initialMode
            },
            "dateRangeHistory": {
              "minDate": null,
              "maxDate": null
            }
            
  
          });
      let scheduleValue = [ scheduleState, scheduleDispatch ];
  
      return (
          <AssignmentsContext.Provider value={scheduleValue}>
              { props.children }
          </AssignmentsContext.Provider>
      )
      }
}

const useAssignmentsState = () => {
    const context = useContext(AssignmentsContext)
    if (context === undefined) {
      throw new Error('useAssignmentsState must be used within a AssignmentsProvider')
    }
    return context
}

export { useAssignmentsState, AssignmentsProvider }