import React, {useReducer,useEffect} from 'react'
import DropDownField from '../../components/formFields/DropDownField'
import {get} from '../../lib/call-api'

const MemberList = ({id="selecMembers", passedInList,defaultValueText,selectedMember,setFunction, attributeId=null}) => {

  const initialMembers = passedInList

  const reducer = (state, action) => {
    if (action.type === 'setMembers') {
      return action.payload;
    } else {
      throw new Error();
    }
  }
  const [members, dispatch] = useReducer(reducer, initialMembers);

  useEffect(() => {
    if(!passedInList || passedInList.length === 0) {
      const onGet = (resp) => {
        dispatch({"type": "setMembers", "payload": resp.memberList})
      }
      const path = (attributeId !== null) ? `/api/org/members/attribute/${attributeId}` : `/api/org/members`
      get(path, onGet, null, null, 'view member list')
    }
  },[passedInList])

  return (
    <>
    {members && members.length > 0 &&
    <DropDownField
      type="text"
      id={id}
      defaultValueText={defaultValueText}
      selectedValue={selectedMember}
      handleChange={setFunction}
      idField="userId"
      labelField="shortName"
      values={members}
  />}
  </>
  )
}

export default MemberList