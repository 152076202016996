import React, { useState} from 'react'

import { useAssignmentsState  } from '../context'

import DisplayAssignments from './DisplayAssignments'
import Alert from '../../components/Alert'

const AssignMembers = ({errorMessageFromSave,displayMode}) => {
  const [state, dispatch] = useAssignmentsState()
  const [membersToDisplay,] = useState(state.members)
  const toggleAssignment = (eventIds,role,assigneeId,assigneeType) => {
    
    const roleIdToCheck = (role) ? role.roleId : null
    // why would we need this??
    //const userId = (!assigneeId) ? selectedMember.userId : assigneeId
    
    const currentAssignments = state.allAssignments
    const newAssignments = currentAssignments

    eventIds.forEach(eventId => {
      const indexInArray = currentAssignments.findIndex((assignment) => 
      (!roleIdToCheck || (roleIdToCheck && assignment.roleId === roleIdToCheck))&& 
        assignment.scheduledEventId === eventId &&
        assignment.assigneeId === assigneeId &&
        assignment.assigneeType === assigneeType)
        if(indexInArray === -1) { 
          newAssignments.push({
            "assigneeId": assigneeId,
            "roleId": roleIdToCheck,
            "scheduledEventId": eventId,
            "assigneeType": assigneeType
          })
        } else {
          newAssignments.splice(indexInArray,1)
        }
    }) 
    dispatch(
      {
        "type": "updateAssignments", 
        "payload": newAssignments
      } 
    )   
  }
 
  return (
    <>

      {
        errorMessageFromSave && errorMessageFromSave !== '' && 
        <Alert type="error" message={errorMessageFromSave} />
      }
      {
      state.events && state.allAssignments && state.assignmentsInRange && state.team && membersToDisplay &&
      <div>
          <div className="row">
          </div>
          <DisplayAssignments 
            displayMode={displayMode}
            onMemberClick={toggleAssignment}
          />
      </div>
    }
    
      </>
  )
  
}



export default AssignMembers