import React, { Fragment } from 'react'
import { Link } from "react-router-dom"
import sessionManager from '../lib/session'
import AdminOnly from '../routes/components/AdminOnly'

const NavigationBar = () => {
  const session =  sessionManager.getSession() 
  
  const handleLogout = (e) => {
    sessionManager.clearSession(false)
  } 

  return (
    <nav className="navbar navbar-expand-md navbar-light">
      <div className="container">
      <input type="checkbox" id="navbar-toggle-cbox"></input>
      <Link className="navbar-brand" to="/home"><img src='/img/logo.svg' alt='Ministry Squads Logo'/></Link>
      <label htmlFor="navbar-toggle-cbox" className="navbar-toggle collapsed d-md-none" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
        <span className="sr-only">Toggle navigation</span>
        <span className="navbar-toggler-icon"></span>
      </label>

      <div className="collapse navbar-collapse" id="navbar">
        <ul className="navbar-nav mx-auto" id="primary-nav">
            <li>
              <Link className="nav-link" to="/calendar">Calendar</Link>
            </li>
            <li>
              <Link className="nav-link" to="/schedule">Schedule</Link>
            </li>
            <li>
              <Link className="nav-link" to="/song/list">Songs</Link>
            </li>
            <li>
              <Link className="nav-link" to="/team/list">Teams</Link>
            </li>
            <li>
              <Link className="nav-link" to="/member/list">Members</Link>
            </li>
           <AdminOnly>
            <li>
              <Link className="nav-link" to="/sermon-series/list">Sermons</Link>
            </li>
            </AdminOnly>
        </ul>
        <ul className="navbar-nav" id="utility-nav">
          {
            session.accessToken !== null && session.userData &&
              <li>
                
                <Link className="nav-link" to="/profile"><i className="fas fa-user-circle"></i> Hi, {session.userData.firstName}</Link>
              </li>
          }  
        <li>
        {
          session.accessToken !== null
            ? <button className="btn btn-link nav-link" onClick={handleLogout}>Logout</button>

            : <Fragment>
              <Link className="nav-link" to="/login">Login</Link>
            </Fragment>
        }
      </li>
      </ul>
      </div>
      </div>
    </nav>
  )
          
}

export default NavigationBar