import ReactGA from 'react-ga4'
import ReactGALegacy from 'react-ga'

ReactGA.initialize('G-52H667F6DS')
ReactGALegacy.initialize('UA-161434038-1')



const pageViewTracking = () => {
  const page = window.location.pathname + window.location.search
  ReactGA.send({ hitType: "pageview", page: page });

  // will be decommissioned
  ReactGALegacy.pageview(page)
}

export default pageViewTracking