import React, { useState, useEffect } from 'react'
import TimeOfDay from '../../components/formFields/TimeOfDay'
import DateField from '../../components/formFields/DateField'
import DropDownList from '../../components/formFields/DropDownField'
import '../events.scss'
import { useManageEventState } from '../../contexts/ManageEvent.Context'


const EventRepeatFields = ({ errorMessages, passedMinDate, allowRepeatEveryChange = true }) => {
  const [eventState, eventDispatch] = useManageEventState()

  const [minDate,setMinDate] = useState(passedMinDate)
  const [endDateMinDate,setEndDateMinDate] = useState(passedMinDate)

  const onRepeatEveryChange = (val) => {
    eventDispatch({ "type": "repeatEvery", "payload": val })
  }

  const onRangeStartDateChange = (val) => {
    eventDispatch({ "type": "rangeStartDate", "payload": val })
    setEndDateMinDate(val)
  }

  const onRangeEndDateChange = (val) => {
    eventDispatch({ "type": "rangeEndDate", "payload": val })
  }

  const setStartTime = (val) => {
    eventDispatch({ "type": "startTime", "payload": val })
  }

  const setEndTime = (val) => {
    eventDispatch({ "type": "endTime", "payload": val })
  }

  const repeatEveryOptions = [
    {
      "value": "",
      "label": ""
    }, {
      "value": "day",
      "label": "Every day"
    },
    {
      "value": "week",
      "label": "Every week"
    }
  ]

  /* 
  context workaround
  for some reason when I switched AddEvent to use context, this component stopped re-rendering
  when a new value was selected in the drop down here.
  so, if you switched it from 'none' to 'day', the "Until" section didn't show up
  */
  const [localValue, setLocalValue] = useState(null)

  useEffect(() => {
    if (localValue === null) { setLocalValue(eventState.repeatEvery) }
  }, [eventState.repeatEvery, localValue])

  const localChange = (val) => {
    setLocalValue(val)
    onRepeatEveryChange(val)
  }
  // end context workaround

  return (


    <div className="entry-form">

      <div className="row">
        <div className="col col-md-2">Start on</div>
        <div className="col">
          <div className="form-group form-inline">
            <DateField
              label=''
              currentValue={eventState.rangeStartDate}
              setValue={onRangeStartDateChange}
              errorMessage={errorMessages.rangeStartDate}
              minDate={minDate}
            />
            <div className="date-time-at">from</div>
            <TimeOfDay
              htmlId="startTime"
              selectedTime={eventState.startTime}
              onChange={(val) => setStartTime(val)}
              errorMessage={errorMessages.startTime}
              scrollToTime={(eventState.startTime) ? eventState.startTime : eventState.defaultStartTime}
            />
            <div className="date-time-at">to</div>
            <TimeOfDay
              htmlId="endTime"
              selectedTime={eventState.endTime}
              onChange={(val) => setEndTime(val)}
              errorMessage={errorMessages.endTime}
              scrollToTime={(eventState.endTime) ? eventState.endTime : eventState.defaultEndTime}
            />
       

          </div>
        </div>
      </div>

      {
            allowRepeatEveryChange === true && 
      <div className="row">
        <div className="col col-md-2">Repeat every</div>
        <div className="col col-md-3 form-group">
         
            <DropDownList
              id='repeatEvery'
              idField="value"
              labelField="label"
              showDefaultOption={false}
              selectedValue={eventState.repeatEvery}
              values={repeatEveryOptions}
              handleChange={localChange}

              errorMessage={errorMessages.repeatEvery}
            />
         
         
        </div>
      </div>
      }
      
      <div className="row">
        <div className="col col-md-2">End on</div>
        <div className="col">
          <DateField
            label=''
            currentValue={eventState.rangeEndDate}
            setValue={onRangeEndDateChange}
            errorMessage={errorMessages.rangeEndDate}
            minDate={endDateMinDate}
          />
        </div>
      </div>

      
    </div>



        )

}

        export default EventRepeatFields