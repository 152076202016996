import React from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import dateManager from '@ministry-squads-common/date-manager'
import AdminOnly from '../routes/components/AdminOnly'

import {AssignmentsProvider,useAssignmentsState } from './context'

import GetAssignmentData from './components/GetAssignmentData'
import DisplayAssignments from './components/DisplayAssignments'
import Filters from './components/Filters'

import SessionManager from '../lib/session'
import {goToEventDetail} from '../events/common'



const FullSchedule = () => {
    const MONTHS_AT_A_TIME = 4
    const sessionData = SessionManager.getSession()
    const {startDate,endDate} = useParams()

    const initialStartDate = (startDate) ? startDate : new Date()
    const initialEndDate = (endDate) ? endDate : dateManager.add(new Date(),MONTHS_AT_A_TIME,'MONTHS')

    return (
        <section className="page">
             <AssignmentsProvider initialMode="read-only" initialStartDate={initialStartDate} initialEndDate={initialEndDate}>
                <Header />
                {
                    sessionData && sessionData.userData.teams.length > 0 &&
                    <>
                        <GetAssignmentData 
                            initialTeam={sessionData.userData.teams[0]} 
                            startDate={initialStartDate}
                            endDate={initialEndDate}
                            />
                        <Filters />
                        <DisplayAssignments showGrouped={true} onEventClick={goToEventDetail}/>
                    </>
                }
            </AssignmentsProvider>
        </section>
    )
}


const Header = () => {
    const [state,] = useAssignmentsState()
    const history = useHistory()
    const sessionData = SessionManager.getSession()

    const teamParam = () => {
        const teamId = state.team.teamId
        if(teamId) {
            return `/${teamId}`
        } else {
            return ''
        }
    }
    return (
        <header> 
            <h1>Schedule</h1>
        
            {
                sessionData && sessionData.userData.teams.length === 0 &&
                <>
                    <p className="intro">You are not currently assigned to any teams, and so you cannot view the schedule.</p>
                    <p className="intro">Contact your squad's leader to get you added to the appropriate team.</p>
                </>
            }

            {
                sessionData && sessionData.userData.teams.length > 0 && state.team &&
                <>
                    {
                        state.eventsByWeek && state.eventsByWeek.length > 0 &&
                        <p className="intro">Review the assignments below. To view details of an event, click the name and date of the event.</p>
                    }
                
                    
                        <div className="feature-manager">
                        <AdminOnly teamId={state.team.teamId}>
                            <div><button className="btn btn-icon btn-calendar" onClick={() => history.push(`/make-assignments${teamParam()}`)}>Edit assignments</button></div>
                        </AdminOnly>

                        </div>  
                   
                </>
            }
        </header>
    )
}

export default FullSchedule