import React, { useEffect, useState } from 'react'

import {save} from '../../services/attendees.service'

import Alert from '../../components/Alert'
import PersonSearch from '../../members/components/PersonSearch'
import SubmitButton from '../../components/SubmitButton'

const ManageAttendees = ({attendees,eventIds}) => {
    const [localAttendeesList,setLocalAttendeesList] = useState()
    const [errorMessage,setErrorMessage] = useState()
    const [successMessage,setSuccessMessage] = useState()

    const [mode,setMode] = useState('editing')
    const handleSubmit = (browserEvent) => {
        browserEvent.preventDefault()
        save(eventIds,localAttendeesList).then(() => {
            setMode('saved')
            setSuccessMessage('The attendees for your event have been saved.')
        }).catch(e => {
            setErrorMessage(e)
        })
    }

    useEffect(() => {
        setMode('editing')
        setSuccessMessage()
    },[attendees])

    return (
        <>
        {
            mode === 'saved' && 
            <>
            <Alert type="success" message={successMessage} />
            <ul>
            { 
                localAttendeesList.map(attendee => 
                    <li key={`attendee${attendee.userId}`}>{ attendee.firstName } {attendee.lastName}</li>
                )
            }
            </ul>
            </>
        }

        {
            (mode !== 'saved') && 
            <form onSubmit={(e) => handleSubmit(e)}>
                <Alert type="error" message={errorMessage} />
                <PersonSearch selectedList={attendees} onListChange={(newList) => setLocalAttendeesList(() => newList)}/>
                
                <div className="button-container">
                    <SubmitButton actionTextOverride='Save attendees' />
                </div>

                
            </form>
        }
        </>
    )
}

export default ManageAttendees