import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import {get,post} from '../../lib/call-api'
import {scrollToTop,updateArrayOfObjects, generateTempId} from '../../lib/index'
import AdminOnly from '../../routes/components/AdminOnly'
import Alert from '../../components/Alert'
import SecondaryContent from '../../components/SecondaryContent'
import SelectFromRecentWeeks from './SelectFromRecentWeeks.js'
import AddSection from '../../components/AddSection'
import SectionActions from '../../components/SectionActions'
import { useSecondaryContentState } from '../../contexts/SecondaryContent.Context'
import SaveAgendaSection from './SaveAgendaSection'
import { getDisplayDate,getEventDisplayName } from '../common.js'   
import './ViewAgenda.scss'
import SubmitButton from '../../components/SubmitButton'
import useIsMountedRef from '../../hooks/useIsMountedRef.js'
import ViewPrintAgendaLink from './ViewPrintAgendaLink'
import BiblePassageLink from '../../components/BiblePassageLink'

const ViewAgenda = ({scheduledEventId, mode = 'editable'}) => {
    const [agendaId,setAgendaId] = useState()
    const [events,setEvents] = useState()
    const [agendaSections,setAgendaSections] = useState(null)
    const [, flyoutDispatch] = useSecondaryContentState()
    const [sectionBeingEdited,setSectionBeingEdited] = useState()
    const [successMessage,setSuccessMessage] = useState()
    const [errorMessage,setErrorMessage] = useState()
    const [secondaryContentAction,setSecondaryContentAction] = useState()
    const [localMode,setLocalMode] = useState(mode)
    const [associatedSongs,setAssociatedSongs] = useState()
    const [availableSermons,setAvailableSermons] = useState()
    const [assignedMembers, setAssignedMembers] = useState()
    const isMounted = useIsMountedRef()
    const [secondaryContentHeading,setSecondaryContentHeading] = useState()

   useEffect(() => {
      const onAgendaGet = (response) => {
          if(isMounted.current === true) {
            setAgendaId(response.agendaId)
            if(response.sections) {    
                setAgendaSections(response.sections)
              } else {
                  setAgendaSections([])
              }
          }
      }
  
      get(`/api/agenda/${scheduledEventId}`,onAgendaGet,setErrorMessage)

      const onEventGet = (resp) => {
        if(isMounted.current === true) {
            setAssociatedSongs(resp.songs)
            setAssignedMembers(resp.members)
            setEvents(resp.events)
            setAvailableSermons(resp.sermons)
        }
      }  
      get(`/api/calendar/detail/${scheduledEventId}`,onEventGet,setErrorMessage)
      return () => isMounted.current === false
     
  }, [scheduledEventId,isMounted])

  const enterCopyMode = (event) => {
    event.preventDefault()
    setSecondaryContentAction("copy")
    setSecondaryContentHeading("Copy a previous agenda")
    flyoutDispatch({ type: 'toggleIsOpen', payload: null })
    setSuccessMessage('')
    setErrorMessage('')
  }


  const enterEditSectionMode = (event,sectionData) => {
    event.preventDefault()
   
    if(sectionData) {
        setSectionBeingEdited(sectionData)
        setSecondaryContentHeading("Edit an agenda section")

    } else {
        setSectionBeingEdited(
            {
                "sectionId": generateTempId(),
                "sectionTitle": '',
                "ledBy": {},
                "items":[]
              } 
        )
        setSecondaryContentHeading("Add an agenda section")

    }
    setSecondaryContentAction("edit")
    flyoutDispatch({ type: 'toggleIsOpen', payload: null })
    setSuccessMessage('')
    setErrorMessage('')
  }




const saveSectionChanges = (newOrUpdatedSection) => {
    const newSections = updateArrayOfObjects ("sectionId",agendaSections,newOrUpdatedSection)
    setAgendaSections(newSections)
    flyoutDispatch({ type: 'toggleIsOpen', payload: null })
  }


  const handleSubmit = async event => {
    event.preventDefault()
    const postBody = {
      "events": events,
      "scheduledEventId": scheduledEventId,
      "agendaId": agendaId,
      "sections": agendaSections
    }

    const handlePostError = (error) => {
        setErrorMessage(error)
        scrollToTop()
    }

    const onPost = (resp) => {
        scrollToTop()
        setLocalMode('readonly')
        setSuccessMessage('Your changes have been saved!')
    }

    post("/api/agenda",onPost,handlePostError,() => {return true},postBody)
  }

  const applyCopiedAgenda = (agendaData) => {
    setAgendaSections(agendaData)
    flyoutDispatch({ type: 'toggleIsOpen', payload: null })
    setSecondaryContentAction()
    setSuccessMessage('Copied agenda. Make changes below.')
  }

  const getAgendaSectionClass = () => {
    const baseClassName = 'agenda-section'
    return (localMode === 'editable') ? baseClassName + ' with-actions' : baseClassName
}    


    return (
        <section className={`page agenda ${localMode}`}>
            <header>
                    <div className="breadcrumbs">
                        <Link to={`/event/detail/${scheduledEventId}`}>Event detail</Link>
                    </div>
            {
                localMode !== 'print' && 
                <>
                    <div>
                        <h1>Agenda</h1>
                        {
                            events &&
                            <p className="intro">{getEventDisplayName(events[0])} - {getDisplayDate(events)}</p>
                        }
                        <AdminOnly>
                            <div className="feature-manager">            
                                <div><button onClick={(event) => enterCopyMode(event) } className="btn btn-icon btn-copy">Copy a previous agenda</button></div>
                            </div>  
                        </AdminOnly>
                    </div>
                    
                    {
                        localMode !== "editable" && successMessage && successMessage !== '' &&
                        <Alert type="success" message={successMessage}>
                            <button onClick={(event) => {event.preventDefault();setLocalMode('editable')}} className="btn btn-link btn-slim" >Make changes</button> or <ViewPrintAgendaLink scheduledEventId={scheduledEventId} />
                        </Alert>
                    }
                    {
                        errorMessage && errorMessage !== '' &&
                        <Alert type="error" message={errorMessage} />
                    }
                    </>
            }
           {
            events && localMode === 'print' && 
            <>
                <h2>{getEventDisplayName(events[0])}</h2>
                <h3 className="event-dates">{getDisplayDate(events)}</h3>
            </>          
        }
    </header>

    <div>
        
        {
            agendaSections && agendaSections.length === 0 &&
            <>
                <div className="card">
                    <p className="no-results">No agenda sections yet.</p>
                </div>
                <AdminOnly>
                    <AddSection clickHandler={(event) => enterEditSectionMode(event, null)}/>
                </AdminOnly>
            </>

        }   
        <form onSubmit={handleSubmit}>
            
        
      {
          agendaSections && agendaSections.length > 0 &&
          <>   
            {
                agendaSections.map((section,sectionCtr) => 
                    
                        <section className={getAgendaSectionClass()} key={`section-${section.sectionId}`}>
                            <DisplaySectionContent section={section} />
                            {
                                localMode === 'editable' && 
                                <AdminOnly>
                                    <SectionActions 
                                        currentIndex={sectionCtr}
                                        setFunction={setAgendaSections}
                                        keyName="sectionId"
                                        valueToFind={section.sectionId}
                                        list={agendaSections}
                                        onEditClick={(event) => enterEditSectionMode(event,section)} 
                                    />
                                </AdminOnly>
                            }
                        </section>    
                )
            }

            {
                localMode === 'editable' && 
                <AdminOnly>

                   <AddSection clickHandler={(event) => enterEditSectionMode(event, null)}/>

                    <div className="button-container">
                        <SubmitButton actionTextOverride='Save Agenda'/>
                    </div>
                </AdminOnly>
             }
          </>
      }
      </form>
        <SecondaryContent heading={secondaryContentHeading}>
            {
                secondaryContentAction === "edit" &&
                <SaveAgendaSection 
                    sectionData={sectionBeingEdited}
                    associatedSongs={associatedSongs} 
                    assignedMembers={assignedMembers}
                    availableSermons={availableSermons}
                    onSuccess={saveSectionChanges}
                />
            }
            {
                secondaryContentAction === "copy" &&
                <CopyFromEvent onAgendaCopy={applyCopiedAgenda} haveSections={agendaSections.length > 0} />
            }
            
        </SecondaryContent>
      </div>
      </section>
  )
}

const DisplaySong = ({songData}) => {

    return (
      <Link data-testid={`item-song-${songData.songId}-link`} to={`/song/detail/${songData.songId}`}>{songData.songTitle}</Link>
    )
}   

const DisplaySermon = ({sermonData}) => {
        /*  */
    return (
        <>
        {sermonData.sermonSeries.sermonSeriesTitle}
        {
            sermonData.sermonTitle &&
            <>: {
                sermonData.sermonTitle
            } 
            </>
        }
        {
            sermonData.biblePassages.length > 0 &&
            <>
            &nbsp;(
                {
                    <BiblePassageLink unStructuredReference={sermonData.biblePassages[0]} />
                }
            ) 
            </>
        }
        </>
    )
    
}

const DisplaySectionContent = ({section}) => {
    return (
        <div className="section-content">
            <h3 data-testid={`section-${section.sectionId}`}>
                {section.sectionTitle} 
                {
                    section.sectionLead && section.sectionLead.firstName && section.sectionLead.lastName &&
                    <span className="led-by"> - {section.sectionLead.firstName} {section.sectionLead.lastName}</span>
                }
            </h3>
           
            {
                section.items.length > 0 && 
                <ul className="items">
                {
                    section.items.map((item,ctr) => 
                    <li key={`section-item-${ctr}`}>
                        {
                            item && item.itemType && item.itemType.toUpperCase() === "SONG" &&
                            <span data-testid={`item-song-${item.songId}`}>
                                <DisplaySong songData={item} /> <span className="led-by"> - {item.ledBy.firstName} {item.ledBy.lastName}</span> ({item.ledBy.songKey})
                            </span>
                        }
                        {
                            item && item.itemType && item.itemType.toUpperCase() === "TEXT" &&
                            <span data-testid={`item-${item.itemId}`}>
                                {item.itemTitle}
                            </span>
                        }

{
                            item && item.itemType && item.itemType.toUpperCase() === "SERMON" &&
                            <span data-testid={`item-${item.itemId}`}>
                               <DisplaySermon sermonData={item} />
                            </span>
                        }
                    </li>
                    )
                }
                </ul>
            }
        </div>
    )
}

const CopyFromEvent = ({onAgendaCopy,haveSections}) => {
    const [copyErrorMessage,setCopyErrorMessage] = useState()
    const [eventsToCopy,setEventsToCopy] = useState()
    
    
    const processCopiedAgenda = (agendaSections) => {
        return agendaSections.map(section => {

            // since the songs and sermons will likely be different from event-to-event, we should remove the songs
            // from the response before copying it to the UI. otherwise, the songs are listed in the UI, 
            // but then they can't be found when the user goes to edit the section
            const itemsToCopy = section.items.filter(item => item.itemType.toUpperCase() === "TEXT")
            
            // wipe the section item IDs. so new section items  are created by the back end, instead of the 
            // existing section items being updated.
            const newSectionItems = (itemsToCopy.length === 0) ? [] : itemsToCopy.map(nonSongItem => {
                return {
                    ...nonSongItem,
                    "itemId": generateTempId()
                }
            })

            // return a new section with the available items. we need to generate a new temp id, so the backend doesn't update the existing 
            // section, but creates a new one assocated to a new agenda ID.
            // we're also wiping out the sectionLead, since we assume assignments will be different from event-to-event.
            return {
                ...section,
                "sectionId": generateTempId(),
                "sectionLead": null,
                "items": newSectionItems
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if(!eventsToCopy || !eventsToCopy.length === 0) {
            setCopyErrorMessage('Please select an event')
        } else {
            const onAgendaGet = (response) => { 
                if(response.agendaId) {
                    onAgendaCopy(processCopiedAgenda(response.sections))
                } else {
                    setCopyErrorMessage('The event you selected does not have an agenda.')
                }
            }
            const onAgendaError = (err) => {
                console.log(err)
                setCopyErrorMessage('There was a problem copying the agenda')

            }
            get(`/api/agenda/${eventsToCopy[0].scheduledEventId}`,onAgendaGet,onAgendaError,null,'get the agenda')    
        }
    }

    return (
        <form onSubmit={(event) => handleSubmit(event)}>
            {
                copyErrorMessage && copyErrorMessage !== '' && 
                <Alert type="error" message={copyErrorMessage} />
            }
            
            {
                haveSections === true &&
                <Alert type="warning" context="inline" message="Warning, you will lose your existing sections by proceeding below." />
            }
        <section>
            <p className="intro">Select the event that has the agenda you want to copy</p>
            <SelectFromRecentWeeks handleChange={setEventsToCopy} weeksInPast={3} weeksInFuture={-1} />
        </section>
        <div className="button-container">
            <SubmitButton />
        </div>
        </form>
    )
}


export default ViewAgenda