import React from 'react'
import {outputSeperated} from '../../lib/index'

const PreviouslyPlayed = ({eventsPlayed}) => {
  if(eventsPlayed && eventsPlayed.length > 0) {
    const uniqueLedBy = [...new Set(eventsPlayed.map(evt => evt.ledBy.userId))]
    const consolidated = uniqueLedBy.map(uniqueUserId => {
      const matches = eventsPlayed.filter(evt => evt.ledBy.userId === uniqueUserId)
      return {
        "key": matches[0].ledBy.songKey,
        "singerName": matches[0].ledBy.shortName
      }
    })
  
    return (
      <div className="previous-events-played">
      {
        consolidated.length > 0 && 
        <>
          <label>Previously lead by:</label> {outputSeperated(consolidated.map(ledBy => {return `${ledBy.singerName} (${ledBy.key})`}))}
        </>
      }
      {
        consolidated.length === 0 && 
        <span className="no-results">
          This song has not yet been played at an event.  
        </span>
      }
      </div>
    )
  } else {
    return(<></>)
  }
  

}

export default PreviouslyPlayed