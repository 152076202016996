import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import DropDownField from '../components/formFields/DropDownField'
import SaveSong from './components/SaveSong'
import SecondaryContent from '../components/SecondaryContent'
import { useSecondaryContentState } from '../contexts/SecondaryContent.Context'
import {getDisplayData} from './common.js'
import Alert from '../components/Alert'
import AdminOnly from '../routes/components/AdminOnly'
import AssignSongToEvent from './components/AssignSongToEvent'
import sessionManager from '../lib/session'
import {get} from '../lib/call-api'
import {diff} from '@ministry-squads-common/date-manager'
import useIsMountedRef from '../hooks/useIsMountedRef.js'
import { getEventDisplayName,getDisplayDate } from '../events/common'
import './songs.scss'

const SongList = () => {
  const session = sessionManager.getSession()
  const [unfilteredSongList,setUnfilteredSongList] = useState([])
  const [songList, setSongList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [showHiddenContent, setShowHiddenContent] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [savedSong, setSavedSong] = useState()
  const [savedToEvents, setSavedToEvents] = useState([])
  const [flyoutState, flyoutDispatch] = useSecondaryContentState()
  const [updateSongAction,setUpdateSongAction] = useState()
  const [selectedLead,setSelectedlead] = useState(-1)
  const isMounted = useIsMountedRef()

  const [selectedSongs,setSelectedSongs] = useState([])

  const ADD_SONG_ACTION = "ADD_SONG"
  const ASSIGN_TO_EVENT_ACTION = "ASSIGN_EVENT"
  const toggleSlider = (action) => {
    setSuccessMessage('')
    flyoutDispatch({ type: 'toggleIsOpen', payload: null })
    if(action === ADD_SONG_ACTION || action === ASSIGN_TO_EVENT_ACTION) {
      setUpdateSongAction(action)
    }
  }

  const updateOnAdd = (data, message) => {
    setIsLoading(true) // needed to wait to render results until get is executed to refresh the song list
    setRefresh(true)
    setShowHiddenContent(false)
    toggleSlider()
    setSuccessMessage(message)
    setSavedSong(data)
  }

  const updateOnAddToEvent = (data, message) => {
    setIsLoading(true) // needed to wait to render results until get is executed to refresh the song list
    setRefresh(true)
    setShowHiddenContent(false)
    toggleSlider()
    setSuccessMessage(message)
    setSelectedSongs([])
    //setSavedSong(data)
    setSavedSong(data.songs)
    setSavedToEvents(data.events)
  }

  const toggleSongSelected = (song) => {
    if(songIsSelected(song.songId) === true) {
      setSelectedSongs(() => [
        ...selectedSongs.filter(songInList => songInList.songId !== song.songId)
      ])
    } else {
      setSelectedSongs((current) => [
        ...current,
        song
      ])
    }
  }

  const songIsSelected = (songId) => {
    return findSongInArray(songId).length > 0
  }

  const findSongInArray = (songId) => {
    return selectedSongs.filter(song => song.songId === songId)
  }
  
  useEffect(() => {
    const onGet = (resp) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        setSongList(resp.songList)
        setUnfilteredSongList(resp.songList)
        setRefresh(false)
      }
    }

    get(`/api/song/list/${session.orgData.orgId}`,onGet,setErrorMessage)
    
    return () => { isMounted.current = false }
  }, [refresh,isMounted,session.orgData.orgId])

  return (
    <section className="page">
      <header>
        <h1>Songs</h1>
        <AdminOnly>
          <div className="feature-manager">
              <button className="btn btn-icon btn-add" onClick={() => {toggleSlider(ADD_SONG_ACTION)}}>
                Add a song
              </button>
          </div>  
        </AdminOnly>
      </header>

      {
        errorMessage !== '' &&
        <Alert message={errorMessage} type='error' />
        
      }

      {(isLoading === false && unfilteredSongList.length === 0 && errorMessage === '') && 
        <div>There are no songs in the system</div>
      }

      <AdminOnly>
        <SecondaryContent 
          heading={(updateSongAction === ADD_SONG_ACTION) ? 'Add a song' : 'Add song(s) to an event' }
          onClose={setShowHiddenContent} 
          show={showHiddenContent}>
          {
            updateSongAction === ADD_SONG_ACTION &&
            <SaveSong onSuccess={updateOnAdd} />
          }
          {
            updateSongAction === ASSIGN_TO_EVENT_ACTION &&
            <AssignSongToEvent songList={selectedSongs} onSave={updateOnAddToEvent} unique={flyoutState.counterKey} />
          }

        </SecondaryContent>
      </AdminOnly>

      {
      isLoading === false && unfilteredSongList.length > 0 && 
        <div>
          
          {
            savedSong && successMessage !== '' && updateSongAction === ADD_SONG_ACTION && 
            <Alert type="success" message={successMessage} />
          }

          {
            savedSong && successMessage !== '' && updateSongAction === ASSIGN_TO_EVENT_ACTION && 
            <Alert type="success">
              <p>Successfully added the following songs to <Link to={`/event/detail/${savedToEvents[0].scheduledEventId}`}>
                {getEventDisplayName(savedToEvents[0])} on {getDisplayDate(savedToEvents)}
                </Link></p>
              <ul>
              {
                savedSong.map(song => 
                  <li key={`songid${song.songId}`}>{song.songTitle}</li>
                )
              }
              </ul>
              </Alert>
          }
            


          <Filters unfilteredSongList={unfilteredSongList} onFilterChange={setSongList} onLeadSelected={setSelectedlead} />
          
          {
            songList.length === 0 &&
            <p className="no-results">No songs matched your criteria</p>
          }

          {
            songList.length > 0 &&
          
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Recording Artist</th>
                <th>Total times played</th>
                <th>Last played</th>
                <AdminOnly>
                <th>Add to an event</th>
                </AdminOnly>
              </tr>
            </thead>
            <tbody>
              {songList.map(song => (
                <tr
                  id={`song${song.songId}`}
                  key={`song${song.songId}`}
                  className={(savedSong && ((Array.isArray(savedSong) === false && savedSong.songId === song.songId) || (Array.isArray(savedSong) === true && savedSong.filter(saved => saved.songId === song.songId).length > 0))) ? 'alert-success' : ''}
                >
                  
                  <td data-testid={`songTitle${song.songId}`}>
                    <Link to={`/song/detail/${song.songId}`}>{song.songTitle}</Link>
                  </td>
                
                  <td>{song.recordingArtist}</td>
                  <td data-testid={`songCountofEvents${song.songId}`}>
                  {
                    song.eventsPlayed.length 
                  }
                  </td>
                  <td>
                    <OutputLastEventPlayed songData={song} selectedLead={selectedLead} />
                  </td>
                  <AdminOnly>
                  <td className="add-to-event">
                    <input type="checkbox" id={`select-song`} checked={songIsSelected(song.songId)===true} onChange={() => toggleSongSelected(song)}/>
                    {
                      songIsSelected(song.songId)===true && 
                      <button className="btn btn-slim btn-link" onClick={(event) => {event.preventDefault();toggleSlider(ASSIGN_TO_EVENT_ACTION);}}>Pick event</button>
                    }
                  </td>
                  </AdminOnly>
                </tr>
              ))}
            </tbody>
          </table>
          }
        </div>
      }
    </section>
  )
}

const OutputLastEventPlayed = ({songData,selectedLead}) => {
  if(songData.eventsPlayed.length === 0) {
    return (<span className='no-results'>n/a</span>)
  } else {
    const displayData = (parseInt(selectedLead) !== -1) ?
      songData.eventsByWeek.map(scheduledEvent => getDisplayData(scheduledEvent.eventsByGroup[0],songData.eventsPlayed))
      :
      [getDisplayData(songData.eventsByWeek[0].eventsByGroup[0],songData.eventsPlayed)]
   
    const getClassName = (userId) => {
      if(userId === selectedLead) {
        return "highlighted"
      } else {
        return ""
      }
    }


    return (
      displayData.map((data,ctr) => 
        <div 
          data-testid={`songLastPlayed${songData.songId}${ctr}`}  
          key={`${data.dateOutput}-${data.ledBy.userId}-${songData.songId}}`} 
          className={getClassName(data.ledBy.userId)}>
            <Link to={`/event/detail/${data.scheduledEventId}`}>{data.dateOutput}</Link>
        {
          data.ledBy !== null && 
          <>: {data.ledBy.shortName} 
          {
          data.ledBy.songKey !== null && 
            <> ({data.ledBy.songKey})</>
          }
          </>
        }
      </div>

      )
      
    )
  }
}


const Filters = ({unfilteredSongList,onFilterChange,onLeadSelected}) => {
  
  
  const [uniqueLeads,setUniqueLeads] = useState([])

  const [uniqueArtists,setUniqueArtists] = useState([])

  const isMounted = useIsMountedRef()

  /**
   * this function will either grab the filters from session storage (if they exist)
   * or create a default set of filters
   * @returns an object that represents the selected filters in the UI
   */
  const getDefaultFilterObject = () => {
    const previousPath = JSON.parse(window.sessionStorage.getItem('previousPath'))
    
    const restoreFilters = (val) => {
      return (val.indexOf('/event/detail') > -1 || val.indexOf('/song/detail') > -1)
    }

    const storedInSession = JSON.parse(window.sessionStorage.getItem('songListFilters'))
    const defaultFilterValues = {
      "ledBy": -1,
      "artist": -1,
      "olderThan": 0
    }

    if (storedInSession && previousPath && previousPath.length === 2 && restoreFilters(previousPath[1]) === true) {
      return storedInSession
    } else {
      return defaultFilterValues
    }

  }
  const [filterObject,setFilterObject] = useState(getDefaultFilterObject())

  /**
   * when the filter object changes, we need to save the change to the session
   * and also apply the filters so the song list updates
   */
  useEffect(() => {
    
    // update session storage so if we leave the page and come back, we know how they were set up
    window.sessionStorage.setItem('songListFilters',JSON.stringify(filterObject))

    // tell the song list who to highlight 
    onLeadSelected(filterObject.ledBy)

    // figure out the songs that match the filters that are selected by the UI
    const filterSongsByLead = (list,leadUserId) => {
      return list.filter(song => song.eventsPlayed.filter(eventPlayed => eventPlayed.ledBy.userId === leadUserId).length > 0)
    }   
  
    const filterSongsByMonth = (list,numberOfMonths) => {
      const mostRecentEvent = (songData) => {
        return new Date(songData.eventsPlayed[0].startDateTime)
      }
      
      const isSongInRange = (song) => {
        const rightNow = new Date()
        const numberOfDays = numberOfMonths * 30
        const mostRecenltyPlayed = mostRecentEvent(song)
        const daysSinceLastPlayed = diff(rightNow,mostRecenltyPlayed,"DAYS")
        
        return daysSinceLastPlayed > numberOfDays
      }

      return list.filter(song => song.eventsPlayed.length > 0 && isSongInRange(song))
      
    }
    
    const filterSongsByArtist = (list,artist) => {
      return list.filter(song => song.recordingArtist === artist)
    }   

    // used to determine what data to use for a filter
    // if the filtered array has a length > 0, then the user
    // has applied a filter for the associated attribute. 
    // if not, we just return the unfiltered list
    const getSongIds = (filteredArray) => {
      return (filteredArray.length > 0) ? filteredArray.map(song => song.songId) : []
    }

    // find all the matches
    const filteredByLead = (parseInt(filterObject.ledBy) !== -1) ? filterSongsByLead(unfilteredSongList,filterObject.ledBy) : unfilteredSongList
    const filteredByArtist = (parseInt(filterObject.artist) !== -1) ? filterSongsByArtist(unfilteredSongList,filterObject.artist) : unfilteredSongList
    const filteredByMonth = (filterObject.olderThan !== 0) ? filterSongsByMonth(unfilteredSongList,filterObject.olderThan) : unfilteredSongList

    // get just the song ids
    const monthData = getSongIds(filteredByMonth)
    const leadData = getSongIds(filteredByLead)
    const artistData = getSongIds(filteredByArtist)

    let arrayOfComparisonArrays = [monthData, leadData, artistData]
    let intersectionArray  = arrayOfComparisonArrays.reduce((lastArray, nextArray)=>{
        return lastArray.filter(x => nextArray.includes(x))
    })

    // now determine where the songs overlap
     const overlap = unfilteredSongList.filter(e => {
      return intersectionArray.some(songId => songId === e.songId); 
     });

   // and set the song list to the resulting array, so the UI shows the songs with the filters applied
   if(isMounted.current === true) {
     onFilterChange(() => [
      ...overlap
    ])
   }
   return () => { isMounted.current = false}
    

  },[filterObject, isMounted, onFilterChange, onLeadSelected, unfilteredSongList])

  const setSelectedlead = (selectedLead) => {
    const current = JSON.parse(window.sessionStorage.getItem('songListFilters'))
    const newSessionStorageObject = {
      ...current,
      "ledBy": selectedLead,
    }
    setFilterObject(newSessionStorageObject)
  }
 
  const setSelectedArtist = (selectedArtist) => {
    const current = JSON.parse(window.sessionStorage.getItem('songListFilters'))
    const newSessionStorageObject = {
      ...current,
      "artist": selectedArtist,
    }
    setFilterObject(newSessionStorageObject)
  }

  const setOlderThan = (olderThan) => {
    const current = JSON.parse(window.sessionStorage.getItem('songListFilters'))
    const newSessionStorageObject = {
      ...current,
      "olderThan": parseInt(olderThan),
    }
    setFilterObject(newSessionStorageObject)
  }

  const getUniqueLeads = (songList) => {
    const leads = []
    songList.forEach(song => {
      return song.eventsPlayed.forEach(event => {
         if(event.ledBy && leads.findIndex(user => user.userId === event.ledBy.userId) === -1) leads.push({
           "userId": event.ledBy.userId,
            "firstName": event.ledBy.firstName,
            "lastName": event.ledBy.lastName
          })
      })

    })
    return leads.sort((a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()))
  }

  const getUniqueArtists = (songList) => {
    const list = songList.map(song => song.recordingArtist).filter((x, i, a) => a.indexOf(x) === i && x !== '')
    return list.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  }

  useEffect(() => {
    if(isMounted.current === true) {
      setUniqueLeads(() => [...getUniqueLeads(unfilteredSongList)])
      setUniqueArtists(() => [...getUniqueArtists(unfilteredSongList)])
    }
    return () => { isMounted.current = false }

  },[isMounted,unfilteredSongList])

  const lastPlayedFilterValues = [
    {
      "value": -1,
      "label": "Any time"
    },
    {
      "value": 3,
      "label": "Three or more months ago"
    },
    {
      "value": 6,
      "label": "Six or more months ago"
    }
  ]

  const ledByFilterValues = [{
    "value": -1,
    "label": "Anyone"
  }]
  uniqueLeads.forEach(lead => {
    ledByFilterValues.push(
      {
        "value": lead.userId,
        "label": `${lead.lastName}, ${lead.firstName}`
      })
  })


  const artistFilterValues = [{
    "value": -1,
    "label": "Any artist"
  }]
  uniqueArtists.forEach(artist => {
    artistFilterValues.push(
      {
        "value": artist,
        "label": artist
      })
    })


  return  (
    <div className="card">
      <div className="filters" id="songFilters">
        <div className="filter-header">
          <i className="fas fa-filter"></i> Filter
        </div>
        {
        filterObject && 
        <>
        <div>
          <label htmlFor="timeFilter">Last played:</label> 
          <DropDownField 
            id="last-played-filter"
            selectedValue={filterObject.olderThan}
            handleChange={setOlderThan}
            values={lastPlayedFilterValues}
            showDefaultOption={false}
            idField="value"
            labelField="label"
          />
        </div>

        <div>
          <label htmlFor="leadByFilter">Lead by:</label>
          {
          uniqueLeads && uniqueLeads.length > 0 &&
          <DropDownField 
            id="leadByFilter"
            selectedValue={filterObject.ledBy}
            handleChange={setSelectedlead}
            values={ledByFilterValues}
            showDefaultOption={false}
            idField="value"
            labelField="label"
          />

          }
        </div>

        <div>
          <label htmlFor="recordingArtistFilter">Recording artist:</label>
          {
          uniqueArtists && uniqueArtists.length > 0 &&
          <DropDownField 
            id="recordingArtistFilter"
            selectedValue={filterObject.artist}
            handleChange={setSelectedArtist}
            values={artistFilterValues}
            showDefaultOption={false}
            idField="value"
            labelField="label"
          />

          
          }
        </div>
        </>
      }
    </div>
  </div>
  )

}


export default SongList