import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import dateManager from '@ministry-squads-common/date-manager'
import {get} from '../lib/call-api'
import {getMonthData,ITEM_TYPE_WORSHIP_SERVICE,ITEM_TYPE_BLOCKED_DATE} from '../lib/calendar'
import './Calendar.scss'
import SessionManager from '../lib/session'
import CalendarFilters from './components/CalendarFilters'
import CalendarKey from './components/CalendarKey'
import CalendarEvents from './components/CalendarEvents'
import AdminOnly from '../routes/components/AdminOnly'

const Calendar = () => {
  const history = useHistory()

  const {month,year} = useParams()
  const getInitialDate = () => {
    if(!month && !year) {
      return new Date()
    } else {
      return new Date(year,month-1,1)
    }
  }

  const [dateToUse,setDateToUse] = useState(getInitialDate())
  const [monthData,setMonthData] = useState()
  const [singleObject,setSingleObject] = useState([])
  const [eventData,setEventData] = useState()

  const [blockedDatesData,setBlockedDatesData] = useState()

  // window.onpopstate is triggered when the user clicks the back or forward buttons
  // make sure state is updated to reflect the params in the URL when this happens
  window.onpopstate = () => {
    setDateToUse(getInitialDate())
  }
  
  
  const getEventData = (monthStart,monthEnd) => {
      const sessionData = SessionManager.getSession()
      const orgId = sessionData.orgData.orgId

      const onScheduleGet = (resp) => {
        //if(isMounted.current === true) {
          const genericEventsObject = resp.map(evt => {
            return {
              "itemId": evt.scheduledEventId,
              "itemType": ITEM_TYPE_WORSHIP_SERVICE,
              "cssClass": "worship-service",
              "itemDate": evt.startDateTime,
              "itemLabel": evt.eventName,
              "backgroundColor": evt.eventTypeColor,
              "eventTypeName": evt.eventTypeName,
              "eventTypeId": evt.eventTypeId,
              "eventGroupId": evt.eventGroupId
            }
          })
          setEventData(genericEventsObject)

         
        //}
      }
  
      const apiUrl = `/api/calendar/${orgId}?startDate=${monthStart}&endDate=${monthEnd}`
      get(apiUrl,onScheduleGet,null)
  }

  const getBlockedDatesData = (monthStart,monthEnd) => {
    const onGet = (resp) => {
      const genericBlockedDatesObject = []
      
      resp.forEach(member => {
        member.blockedDates.forEach(blockedDate => {
          genericBlockedDatesObject.push( {
            "itemId": member.userId,
            "itemType": ITEM_TYPE_BLOCKED_DATE,
            "cssClass": "member-blocked-date",
            "itemDate": blockedDate.dateBlocked,
            "itemLabel": member.firstName
          })
        })        
      })

      setBlockedDatesData(genericBlockedDatesObject)
    }
    get(`/api/availability/org?startDate=${monthStart}&endDate=${monthEnd}`,onGet,()=>true )

  }

  useEffect(() => {
    const monthDataToUse = getMonthData(dateToUse)

    setMonthData(monthDataToUse)
 
    const monthStart = dateManager.formatDate(monthDataToUse[0].dateObj,'URL_PARAMETER')
    const monthEnd = dateManager.formatDate(monthDataToUse[monthDataToUse.length-1].dateObj,'URL_PARAMETER')
    getBlockedDatesData(monthStart,monthEnd)
    getEventData(monthStart,monthEnd)
  }, [dateToUse])



  useEffect(() => {
    if(eventData && blockedDatesData) {
      setSingleObject(() => [
        ...eventData,blockedDatesData
      ])
    }
  },[eventData,blockedDatesData])

  return (
    <section className="page">
      <header> 
        <h1>Calendar</h1>
        {
           <div className="feature-manager">
           <AdminOnly>
               <div><button className="btn btn-icon btn-add" onClick={() => history.push("/event/create")}>Add event(s)</button> </div>
           </AdminOnly>
           </div>  
        }
      </header>
      {
        dateToUse && monthData && singleObject && singleObject.length > 0 &&
        <>
          <CalendarFilters dateToUse={ dateToUse }  setFunction={ (dateReceived) => setDateToUse(dateReceived) } />
          { /* <CalendarKey eventData={eventData} />  */ }
          <CalendarEvents dateRangeData={ monthData } eventData={singleObject} />
        </>        
      }
    </section>
  )
}
  
 
export default Calendar