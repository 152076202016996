import React, {useEffect,useState} from 'react'
import {get} from '../../lib/call-api'
import useIsMountedRef from '../../hooks/useIsMountedRef'
import dateManager from '@ministry-squads-common/date-manager'
import Alert from '../../components/Alert'

const AvailabilityList = () => {
  
  const NUMBER_OF_DAYS = 90
  
  const [availabilityData, setAvailabilityData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)

  const isMounted = useIsMountedRef()
  const startDate = dateManager.formatDate(new Date(),"URL_PARAMETER")
  const endDate = dateManager.formatDate(dateManager.add(new Date(),NUMBER_OF_DAYS,"DAYS"),"URL_PARAMETER")

  useEffect(() => {
    const onGet = (resp) => {
      if(isMounted.current === true) {

        setAvailabilityData(resp)
        
        setIsLoading(false)
        setErrorMessage(null)
      }  
    }
    
    
    get(`/api/availability/org?startDate=${startDate}&endDate=${endDate}`,onGet,(message) => setErrorMessage(message) )
    
    

    return () => isMounted.current = false
    
  }, [setAvailabilityData,isMounted])

  
  return (
    <section>
       <h2>Member availability <small>(for the next {NUMBER_OF_DAYS} days)</small></h2>
      {
        isLoading === true && 
        <p>Getting member availability...</p>
      }
     
      {
        isLoading === false && errorMessage !== null &&
        <Alert context="inline" type="error" message={errorMessage} />
      }

      {
        isLoading === false && availabilityData && availabilityData.length === 0 && 
        <p>All members are available for the next {NUMBER_OF_DAYS} days</p>
      }

      {
        isLoading === false && availabilityData && availabilityData.length > 0 && 
        <>
        {
        availabilityData.map(member => 
          <section key={'member' + member.userId} data-testid={`memberAvailability-${member.userId}`}>
            {member.firstName} {member.lastName} is not available&nbsp;
              <MemberAvailability 
                listStyle="inline"
                dates={member.blockedDates.map(blockedDate => blockedDate.dateBlocked)}
                key={member.userId} 
                />
          </section>
        )}
        </>
      }
    </section>
  )
}

const MemberAvailability = ({dates,key=null,listStyle = null}) => {
  /**
   * this function will take in a list of days and group consecutive days together
   * for example if it gets a list of 2/1/22, 2/2/22, 2/3/22, 4/1/22, and 4/2/22, it will return 2/1/22-2/3/22; 4/1/22-4/2/22
   * @param {array} listOfDays 
   */
   const groupDays = (listOfDays) => {
    let previousDay = listOfDays[0]
    let allGroupedDays = []
    let group = [previousDay]
    for(let i=1; i<listOfDays.length;i++) {
      if(dateManager.diff(new Date(listOfDays[i]),new Date(previousDay),"DAYS") === 1) {
        group.push(listOfDays[i])
      } else {
        allGroupedDays.push(group)
        group=[]
      }
      previousDay = listOfDays[i]
      group.push(previousDay)
    }

    allGroupedDays.push(group)

    return allGroupedDays
  }

  const className = (listStyle === "inline") ? 'punctuation-separated-list semi-colon' : ''

  return  (
  <ul className={className}>
  {
    groupDays(dates).map(group =>
     
      <li key={`blocked${key}${group[0]}`}>
         {dateManager.formatDateRange(group[0],group[group.length-1],'TEXT_SHORT')}
      </li>    
     
    )
  }
</ul>
)
}

export default AvailabilityList