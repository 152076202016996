import React from 'react'

import { Link } from 'react-router-dom'

import sessionManager from '../../lib/session'

import ViewPrintAgendaLink from './ViewPrintAgendaLink'


const AgendaForEvent = ({agenda,scheduledEventId}) => {
    const session = sessionManager.getSession()

    return (
        <div className="card">
            <h2>Agenda</h2>
            {
                !agenda.agendaId && session.userData.isOrgAdmin === false &&
                <p className="no-results">An agenda is not available for this event.</p>
            }
            {   
                !agenda.agendaId && session.userData.isOrgAdmin === true &&
                <p><Link to={`/event/agenda/${scheduledEventId}`}>Create an agenda</Link></p>
            }
            {   
                agenda.agendaId && session.userData.isOrgAdmin === true &&
                <p><Link to={`/event/agenda/${scheduledEventId}`}>Edit the agenda</Link></p>
            }
            {   
                agenda.agendaId &&
                <p>
                    <ViewPrintAgendaLink scheduledEventId={scheduledEventId} />
                </p>
            }   
        </div>
    )
}

export default AgendaForEvent