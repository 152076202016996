import React from 'react'
import ViewAgenda from './components/ViewAgenda'

const AgendaDetail = (props) => {
  const {scheduledEventId} = props.match.params
  return (
    scheduledEventId && 
      <ViewAgenda scheduledEventId={scheduledEventId} />
  )
  
}

export default AgendaDetail