import React, {useEffect,useState} from "react"
import Alert from '../../components/Alert'
import FormField from '../../components/formFields/FormField'
import DateField from '../../components/formFields/DateField'
import sessionManager from '../../lib/session'
import BiblePassageLink from '../../components/BiblePassageLink'
import dateManager from "@ministry-squads-common/date-manager"
import AdminOnly from '../../routes/components/AdminOnly'
import SubmitButton from '../../components/SubmitButton'
import DisplayEvent from "./DisplayEvent"
import EventSelector from "../../events/components/EventSelector"
import {sermonDates} from '../common'
import {listByAttribute} from '../../services/event.service'
import {save} from '../../services/sermonSeries.service'
import OrderableSection from '../../components/OrderableSection'
import { useOrderableSectionState, OrderableSectionProvider  } from '../../contexts/OrderableSectionContext'


const SaveSermonSeries = ({sermonSeriesData,onCancel, onSuccess}) => {
   return (
    <OrderableSectionProvider> 
      <DisplayPage sermonSeriesData={sermonSeriesData} onCancel={onCancel} onSuccess={onSuccess} />
   </OrderableSectionProvider >
  )
}

const DisplayPage = ({ sermonSeriesData,onCancel,onSuccess }) => {
  const [seriesTitle,setSeriesTitle] = useState()
  const [startDate,setStartDate] = useState()
  const [eventDates,setEventDates] = useState([]) 
  const [errorMessage,setErrorMessage] = useState()
  const [ state,dispatch ] = useOrderableSectionState()
  const [eventsInRange,setEventsInRange] = useState()

  useEffect(() => {
    if(sermonSeriesData) {
      setSeriesTitle(sermonSeriesData.sermonSeriesTitle)
      if(sermonSeriesData.startDate && sermonSeriesData.startDate !== '') setStartDate(new Date(sermonSeriesData.startDate))
    } 
  },[sermonSeriesData])

  // need to assign the scheduledEventIds every time a sermon is edited
  // this should be triggered when a user edits a sermon
  // or re-orders the sermon list
  useEffect(() => {
    if(eventsInRange && eventsInRange.length > 0) {

      const newSections = state.sections.map((section,ctr) => {
        const eventToAssociate = eventsInRange.events[ctr]
        const scheduledEventIds = (!eventToAssociate) ? [] : (eventToAssociate.parentEventId) ? eventToAssociate.childEvents.map(evt => evt.scheduledEventId) : [eventToAssociate.scheduledEventId]
        
        return {
          ...section,
          "scheduledEventIds": scheduledEventIds
        }
      })

      // since this useEffect edits state.sections, we need to only update the section list
      // when the current section list is DIFFERENT than the newSections list we generated
      // otherwise, we'll just get stuck in an infinite loop
      const flatNew = JSON.stringify(newSections)
      const flatExisting = JSON.stringify(state.sections)

      if(flatNew !== flatExisting) {
        dispatch({'type': 'updateSectionList', 'payload': newSections})
      }
      
    }

  },[state.sections, eventsInRange, dispatch])


  // each time the start date of the series is modified, or a section is added
  // the date range of the sermon series changes.
  // we need to refresh the list of events within the range of the sermon series
  // so we can associate the sermons to the appropriate event
  useEffect(() => {
    const lclEventDates = sermonDates(startDate,state.sections)
    setEventDates(() => [...lclEventDates])
    if(startDate && state.sections.length > 0) {
      const rangeStart = dateManager.formatDate(lclEventDates[0],'URL_PARAMETER')
      const endDate = dateManager.add(lclEventDates[0],lclEventDates.length*7,'DAYS')
      const rangeEnd = dateManager.formatDate(endDate,'URL_PARAMETER')
      listByAttribute('sermon',rangeStart,rangeEnd).then(events => {
        setEventsInRange(events)
      }).catch(e => {
        console.error('Error getting events in range', e)
      })
    }
  },[startDate,state.sections])


  const handleSubmit = (e) => {
    e.preventDefault()

    const sessionData = sessionManager.getSession()

    const dataToSave = {
      "orgId":  sessionData.orgData.orgId,
      "sermonSeriesData": {
        "sermonSeriesId": sermonSeriesData.sermonSeriesId,
        "sermonSeriesTitle": seriesTitle,
        "startDate": (startDate && startDate !== '') ? dateManager.formatDate(startDate,'URL_PARAMETER') : null,
        "timezone": dateManager.currentTimeZone(),
        "sermons": state.sections
      }
    }

    save(dataToSave).then(resp => {
      const unsuccessfulSermons = resp.unsuccessful

      const getErrorMessageText = (error) => {
       if(error.userMessage) {
          return error.userMessage
        } else {
          return error
        }
      }

      if(unsuccessfulSermons && unsuccessfulSermons.length > 0) {
        // at least one sermon failed
        
        const newUnsuccessfulSermons = unsuccessfulSermons.map(sermonWithError => {
          return {
            "sermonId": sermonWithError.sermonId,
            "errorMessage": getErrorMessageText(sermonWithError.error)
          }
        })

        newUnsuccessfulSermons.forEach(sermonWithError => {
          dispatch({'type': 'addSectionError', 'payload': sermonWithError})
        })
      } else {
        // all sermons were saved successfully
        onSuccess(resp, "The sermon series has been saved.")
      }
    }).catch(e => {
      setErrorMessage(e)
    })
  }

   return (
    
      <form onSubmit={(e) => handleSubmit(e)}>
        {
          sermonSeriesData && 
        <>
      {
        errorMessage &&
        <Alert type="error" message={errorMessage} />
      }

      <section>
        <div className="form-group">
          <label>Series title</label>
          <FormField 
            id="seriesTitle" 
            handleChange={setSeriesTitle}
            value={seriesTitle}
          />
        </div>

        <div className="form-group">
          
            <DateField 
              label='Start date' 
              id="startdate" 
              setValue={setStartDate}
              currentValue={startDate || ''}
            />
          
        </div>
      </section>

      <section>
        <h3>Sermons in series</h3>

        <div className="group-container with-title" id="editSermonsInSeries">
          <header className="grouped-row">
            <div>Service(s)</div>
            <div>Sermon details</div>
            <div>Actions</div>
          </header>
          
          { 
              (state.sections && state.sections.length === 0) && state.mode === "READ" && 
              <div className="grouped-row span-all"><span className="no-results">No sermons yet.</span></div>
          }


          <OrderableSection 
            keyName='sermonId'
            sectionList={sermonSeriesData.sermons}
            emptySectionData={{
              "sermonId": null,
              "sermonTitle": null,
              "biblePassages": [{
                "biblePassageId": null,
                "biblePassage": null
              }]
            }}
            renderSection={(sermon,sermonCtr) => <DisplaySermonData sermon={sermon} sermonCtr={sermonCtr} eventDates={eventDates} eventList={eventsInRange } />}
            editSection={() => <EditSermonData eventList={eventsInRange } />}
            addSectionText="Add a sermon" 
          />  
        
        </div>
        <AdminOnly>
          {
            state.mode === "READ" && 
            <div className="button-container">
              <SubmitButton />
              <button className="btn btn-link" onClick={(e) => { e.preventDefault(); onCancel()}} aria-label="cancel">Cancel</button>
            </div>
          }
            
          </AdminOnly>
        </section>
        </> 
        }
      </form>
  )

}

const DisplaySermonData = ({sermon,sermonCtr,eventDates,eventList}) => {  

  const [ state,dispatch ] = useOrderableSectionState()
  const [ dateToPickFrom,setDatesToPickFrom] = useState()
  
  /* 
    transalate the inputs into this component into what the EventSelector component needs    
    we need to determine the set of events that can be selected, based on this sermon's position in the list.
      the EventSelector component expects this format:
      {
        "events": [],
        "eventsByWeek": []
      }
      events is a flat list of all event data available
      eventsByWeek is a list of weeks, and then a list the events that occur within that week
   */
  useEffect(() => {
    if(eventList && eventList.events) {
      setDatesToPickFrom({
        "events": eventList.events,
        "eventsByWeek": [eventList.eventsByWeek[sermonCtr]]
      })

      /*
      // if there is only one event by group in the week, the user shouldn't need to pick it
      if(eventList.eventsByWeek[sermonCtr].eventsByGroup.length === 1) {
        setSermonEvents(eventList.eventsByWeek[sermonCtr].eventsByGroup)
      }
      */
      
    }
  },[eventList,sermonCtr])


  const setSermonEvents = (scheduledEventIdsForSermon) => {
    const thisSermon = state.sections[sermonCtr]
    const newSermonData = {
      ...thisSermon,
      "events": scheduledEventIdsForSermon.map(evt => {return ({
        "scheduledEventId": evt.scheduledEventId
      })})
    }

    const newSermonList = state.sections.map((sermon,ctr) => {
      if(ctr === sermonCtr) {
        return newSermonData 
      } else {
        return sermon
      }
    })

    dispatch({"type": "updateSectionList", "payload": newSermonList})
  }

  

  return (  
    <>
      <div>
        {
         dateToPickFrom && state.sectionBeingEdited === null &&
         <>
         <EventSelector allEvents={dateToPickFrom} groupKey={`sermon${sermonCtr}`} handleChange={(listOfEventIds) => setSermonEvents(listOfEventIds)} unique={1} showHeader={false}/>
         </>
          
        }
        {
          (!eventDates || eventDates.length === 0) &&
          <span className="no-results">Select a start date above  </span>
        }
      </div>
      <div>
        <p>{sermon.sermonTitle || <span className="no-results">No title yet</span>}</p>
        {
          sermon.biblePassages.map((biblePassage,biblePassageCtr) => 
            <BiblePassageLink key={`sermonBiblePassage${sermon.sermonId}-${biblePassageCtr}`} unStructuredReference={biblePassage}/>
          )
        }
      </div>
    </>
  )
}

const EditSermonData = ({eventList,ctr}) => {
  
  const [ state,dispatch ] = useOrderableSectionState()
  const sermon = state.sectionBeingEdited

  const [newSermonData,setNewSermonData] = useState(sermon) 
  const onSectionCancel = () => {    
    dispatch({'type': 'cancelUpdate', 'payload': null})
  }

  const changeTitle = (newTitle) => {
    setNewSermonData((previous) =>  {
      return {
        ...previous,
        "sermonTitle": newTitle
      }
    })
  }

  const changeBiblePassage = (newBiblePassage) => {

    setNewSermonData((previousSermonData) =>  {
      const previousPassage = previousSermonData.biblePassages[0]
      const newBiblePassageArray = [{
        ...previousPassage,
        "biblePassage": newBiblePassage
      }]
      return {
        ...previousSermonData,
        "biblePassages": newBiblePassageArray
      }
    })
  }

  const onSectionSave = (data) => {
    dispatch({"type": "setReadMode", "payload": null})
    dispatch({"type": "updateSection", "payload": data})
    dispatch({"type": "clearSectionError", "payload": data.sermonId})
  }

  const getBiblePassageText = (sermonData) => {
    return (sermonData.biblePassages.length > 0 && sermonData.biblePassages[0].biblePassage) ? 
     `${sermonData.biblePassages[0].biblePassage}` : null
  }

  return (
    <>
    {
      newSermonData &&
    <>
      <div>
       &nbsp;
      </div>
    
      <div>
        <div className="form-group">
          <label>Sermon title</label>
          <FormField 
            id={`sermonTitle${newSermonData.sermonId}`}
            value={newSermonData.sermonTitle}
            placeholder="Sermon title"
            handleChange={(newTitle) => { changeTitle(newTitle)}}
          />
        </div>
        <div className="form-group">
          <label>Scripture reference</label>
          <FormField 
            id={`sermonBiblePassage${newSermonData.sermonId}`}
            value={getBiblePassageText(newSermonData)}
            placeholder="Acts 1: 1-10,12; James 2: 1-12"
            handleChange={(newPassage) => { changeBiblePassage(newPassage)}}
          />
        </div>
        
       
      </div>
      <div className="save-section-controls">
        <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); onSectionSave(newSermonData)}}>Add</button>
        <button className="btn btn-link" onClick={(e) => {e.preventDefault(); onSectionCancel()}}>Cancel</button>
      </div>
      </>
      }
    </>
  )
}


export default SaveSermonSeries