import React, { useEffect,useState,useRef  } from 'react'
import '../css/DropDownField.scss'

const DropDownField = ({id,selectedValue = null,handleChange,errorMessage,values,idField,labelField, showDefaultOption = true,showErrorMessage=true,width="auto",scrollToValue=null}) => {
    const BASE_CSS_CLASS = 'drop-down-container'
    const [items,setItems] = useState()
    const [showList,setShowList] = useState(false)
    const [selectedItem,setSelectedItem] = useState()
    const [itemToSetWidth,setItemToSetWidth] = useState()
    const [cssClass,setCssClass] = useState(BASE_CSS_CLASS)    
    useEffect(() => {
            const getSelectedId = (selectedItem,allValues,idAttrib) => {

                if(selectedItem) {
                    const isObject =  typeof selectedItem[idAttrib] !== "undefined"

                    const match = (isObject === true) ? allValues.filter(item => item[idAttrib] === selectedItem[idAttrib]) : allValues.filter(item => item[idAttrib] === selectedItem)

                    if(match.length > 0) {
                        return match[0]
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            }
            const selectedId = getSelectedId(selectedValue,values,idField)

             if(selectedId) {
                
                setSelectedItem(selectedId)
                
                setItemToSetWidth(findWidest(values,labelField))
                setItems(values)

                setCssClass(`${BASE_CSS_CLASS}`)

            } else if(showDefaultOption === true && values && values.length > 0 && values[0][idField] !== -1) {
                const defaultObj = {}
                defaultObj[idField] = -1
                defaultObj[labelField] = 'Please select'

                const newItems = values
                newItems.unshift(defaultObj)
                setItems(newItems)

                setItemToSetWidth(findWidest(newItems,labelField))

                setSelectedItem(defaultObj)

                
                setCssClass(`${BASE_CSS_CLASS}`)
            } else {
                // no item was passed in, and we don't want to add a "please select" option,
                // so just pre-select the first item in the list of values
                setItemToSetWidth(findWidest(values,labelField))
                setItems(values)
                setSelectedItem(values[0])

                // set the "default-blank" css class, so horizontal and vertical space is allocated for the drop down.
                // otherwise the BASE_CSS_CLASS div will collapse and it will be difficult to select in the UI.
                // this is causing the drop down on the schedule to be too tall...
                setCssClass(`${BASE_CSS_CLASS} `)

                // default-blank
            }
            
        }
    ,[selectedValue])

   
    const onItemClick = (browserEvent,itm) => {
        browserEvent.preventDefault()
        setShowList(false)
        setSelectedItem(itm)
        handleChange(itm[idField])
        
        // since there is a value in the field, we no longer need the default-blank classs, if it was there
        setCssClass(BASE_CSS_CLASS)
    }

    const findWidest = (list,field) => {
        // force each field to be as wide as the widest value in the list.
        // this value is rendered in the DOM, but hidden via CSS.
        let currentMaxItem = ""
        list.forEach(item => {
            if(item[field].length > currentMaxItem.length) {
                currentMaxItem = item[field]
            }
        })
        return currentMaxItem
    }

    const resultsRef = useRef()

    const handleSelectedItemClick = () => {
        setShowList(showList === false)
    }

    useEffect(() => {
        // this must be in a useEffect, because the resultsRef isn't available until
        // showList is set to true
        if(showList === true) {
            // set the scroll top, so when the user re-opens the menu, the selected item
            // is at the top
            if(resultsRef.current) { 
                const selectedItems = resultsRef.current.getElementsByClassName('selected')
                const scrollToItem = document.getElementById(`${id}item${scrollToValue}`)
                if(selectedItems.length === 1 && selectedItems.length > 0) {
                    resultsRef.current.scrollTop = selectedItems[0].offsetTop
                } else if(scrollToValue && scrollToItem) {
                    resultsRef.current.scrollTop = scrollToItem.offsetTop
                }
            }
        }
    },[showList,scrollToValue,id])

  const handleClickOutside = e => {
    if (resultsRef.current && !resultsRef.current.contains(e.target)) {
        setShowList(false)
    }
  }

  const getItemCssClass = (itm,selectedVal) => {
    return (itm[idField] === selectedVal) ? 'dropdownitem selected' : 'dropdownitem'
  }

  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
  })

    return (
        <span>
            {
            items && selectedItem && itemToSetWidth &&
            <>
                <div 
                    id={id}
                    className={cssClass}>
                    <div 
                        onClick={() => handleSelectedItemClick()}
                        className={typeof(errorMessage) !== 'undefined' && errorMessage !== null && errorMessage !== '' ? `selected-item field-with-error` : `selected-item`}
                        data-testid={`${id}-selected-item`}
                        >
                        <div>
                            {selectedItem[labelField]}
                            <div className="width-setter">{itemToSetWidth}</div>
                        </div>
                    </div>
                    {
                        showList === true && 
                    
                        <div className="all-items" id={`${id}AllItems`} ref={resultsRef}>
                        {
                            items.map(item => 
                                item[idField] !== null &&
                                <div key={`${id}item${item[idField]}`} id={`${id}item${item[idField]}`} data-testid={`${id}item${item[idField]}`} className={getItemCssClass(item,selectedValue)} onClick={(e) => {onItemClick(e,item)}}>
                                        {item[labelField]}
                                </div>    
                            )
                        }
                        </div>
                    }
                </div>


                {
                    typeof(errorMessage) !== 'undefined' && errorMessage !== null && errorMessage !== '' && showErrorMessage === true &&
                        <div className="field-error">
                            <p>{errorMessage}</p>
                        </div>
                }
            </>
            }
        </span>
    )
}
export default DropDownField


