import React, {useEffect, useState } from "react"
import sessionManager from '../../lib/session'
import ManageMemberships from './ManageMembership'
import Alert from '../../components/Alert'
import SetEmail from '../../components/formFields/SetEmail'
import SetName from '../../components/formFields/SetName'
import SubmitButton from '../../components/SubmitButton'
import ToggleList from '../../components/formFields/ToggleList'
import PersonSearch from './PersonSearch'

import {get,post,put} from '../../lib/call-api'

const SaveMember = ({onSuccess,memberData = null}) => {
  const session = sessionManager.getSession()

  const originalEmailAddress = () =>  (memberData) ? memberData.email : null
  const originalFirstName = () => (memberData) ? memberData.firstName : null
  const originalLastName = () => (memberData) ? memberData.lastName : null
  const originalFullName =  `${originalFirstName()} ${originalLastName()}`
  const initialIsMinor = (memberData) ? (memberData.isMinor === true) : false 
  const initialGuardians = (memberData) ? (memberData.guardians) : []

  const [errorMessage, setErrorMessage] = useState('')
  const [emailAddress, setEmailAddress] = useState(originalEmailAddress())
  const [firstName, setFirstName] = useState(originalFirstName)
  const [lastName, setLastName] = useState(originalLastName)
  const [isMinor, setIsMinor] = useState(initialIsMinor)
  const [guardians, setGuardians] = useState(initialGuardians)

  const [memberAttributeActions,setMemberAttributeActions] = useState()

  const [assignmentData, setAssignmentData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [sendReset, setSendReset] = useState(false)
 
  const [registerUser,setRegisterUser] = useState(false)
  
  const [userStatus,setUserStatus] = useState((memberData) ? memberData.status : 'active')

  const validateForm = () => {
    // we're going to let the API tell us if the data is valid
    // so just return true here and the API will figure out the rest
    return true
  }

  const reset = () => {
    setFirstName(originalFirstName())
    setLastName(originalLastName())
    setEmailAddress(originalEmailAddress())
    setRegisterUser(false)
    setIsMinor(false)
    setErrorMessage()
    setAssignmentData({})
    setIsLoading(false) 
    setSendReset(true)
    setUserStatus()
  }

  const onSave = (resp) => {
    if (typeof onSuccess !== "undefined") {
      onSuccess(
        resp.memberDetail,
        `${resp.memberDetail.firstName} ${resp.memberDetail.lastName} has been saved.`
      )
    }
    reset()
    setIsLoading(false)

    return resp
  }
  
  const handleSubmit = async event => {
    event.preventDefault()
    const postBody = {
      "orgId": session.orgData.orgId,
      "registerUser": registerUser,
      "memberData": {
        "userId": (memberData) ? memberData.userId : null,
        "email": emailAddress,
        "originalEmail": originalEmailAddress,
        "firstName": firstName,
        "lastName": lastName,
        "fullName": `${firstName} ${lastName}`,
        "originalFullName": originalFullName,
        "status": userStatus,
        "rolesToRemove": assignmentData.removeFromIds,
        "rolesToAdd": assignmentData.assignToIds,
        "teamAdminData": assignmentData.teamAdminData,
        "attributeActions": memberAttributeActions,
        "isMinor": isMinor === true,
        "guardianIds": guardians.map(guardian => guardian.userId)
      }
      
    }

    // if we have memberData already, we are updating an existing user, so we should use an http put
    if(memberData) {
      put("/api/member/save",onSave,setErrorMessage,validateForm,postBody)
    } else {
      post("/api/member/save",onSave,setErrorMessage,validateForm,postBody)
    }
    
  }

  return (
    <form onSubmit={handleSubmit}>
      {
        !memberData && 
        <h2>Add a Member</h2>
      }
      <Alert type='error' message={errorMessage} />

      <section>
        <SetName values={[firstName,lastName]} setValue={[setFirstName,setLastName]} />
        <SetEmail passedValue={emailAddress} setValue={setEmailAddress} emailIsRequired={registerUser === true} />
      </section>

      {
        
        (memberData && memberData.userStatus !== "pending") && 
        <section>
          <h3>User status</h3>
            <div className="form-inline form-group">
              <input type="radio" name="status" value="1" id="statusActive" onChange={() => setUserStatus('active') } defaultChecked={userStatus === 'active'}/>
              <label htmlFor="statusActive" className="label-right">Active</label>
            
              <input type="radio" name="status" value="0" id="statusInactive" onChange={() => setUserStatus('inactive') } defaultChecked={userStatus === 'inactive'} />
              <label htmlFor="statusInactive" className="label-right">Inactive</label>
            </div>
          {
            userStatus === 'active' && 
            <p><small>
              The user will be available for assignments.</small></p>
          }
          {
            userStatus === 'inactive' && 
            <p><small>The user will not be available for assignments.</small></p>
          }
        </section>
      }


      {
        // if we do not have member data, we are adding a new user
        // OR
        // if we have member data and the user does not have a login ID
        // let the admin sign them up for an ID
        ((!memberData) || (memberData && memberData.hasUserId === false)) && 
        <section>
          <h3>Create a login for the user?</h3>
          <div className="form-inline form-group">
            <input type="radio" name="registerUser" value="1" id="registerUserYes" onChange={() => setRegisterUser(true) } defaultChecked={registerUser === true}/>
            <label htmlFor="registerUserYes" className="label-right">Yes</label>
          
            <input type="radio" name="registerUser" value="0" id="registerUserNo" onChange={() => setRegisterUser(false) } defaultChecked={registerUser === false} />
            <label htmlFor="registerUserNo" className="label-right">No</label>
          </div>
          {
            registerUser === true && 
            <p><small>The user will receive an email inviting join Ministry Squads.</small></p>
          }
          {
            registerUser === false && 
            <p><small>The user will not have access to Ministry Squads.</small></p>
          }
        </section>
      }

      <section>
        <h3>Is this person a minor?</h3>
        <p>Minors are under the age of 13 and require a guardian to be entered.</p>
        <div className="form-inline form-group">
            <input type="radio" name="isMinor" value="1" id="isMinorYes" onChange={() => setIsMinor(true) } defaultChecked={isMinor === true}/>
            <label htmlFor="isMinorYes" className="label-right">Yes</label>
          
            <input type="radio" name="isMinor" value="0" id="isMinorNo" onChange={() => setIsMinor(false) } defaultChecked={isMinor === false} />
            <label htmlFor="isMinorNo" className="label-right">No</label>
          </div>

        {
          isMinor === true &&
          <>
            <h5>Please add a guardian</h5>
            <PersonSearch initialList={guardians} onListChange={(newList) => {
              setGuardians(() => newList)
            }}/>
          </>
        }
        
      </section>
      
      <section>
        <MemberAttributes 
          handleChange={setMemberAttributeActions} 
          memberAttributes={(memberData && memberData.attributes) ? memberData.attributes : null} 
        />
      </section>
      <section> 
        <h3>Teams &amp; roles</h3>
        <ManageMemberships memberData={memberData} handleChange={setAssignmentData} reset={sendReset}/>
      </section>
      
      <section className="button-container">
        <SubmitButton isLoading={isLoading} />  
        
      </section>
    </form>
  )
}

const MemberAttributes= ({memberAttributes, handleChange}) => {
  const [availableAttributes,setAvailableAttributes] = useState()
  const [availableAttributesError, setAvailableAttributesError] = useState()
  useEffect(() => {
    const onGet = (resp) => {
      setAvailableAttributes(resp)
    }
    const onGetError = () => {
      setAvailableAttributesError(`Couldn't retreive list of attributes.`)
    }

    get('/api/attribute/list',onGet,onGetError)
  },[setAvailableAttributes])

  if(!availableAttributes && availableAttributesError) {
    return (
      <>
      <h3>Additional attributes</h3>
      <p>{availableAttributesError}</p>
      </>
    )
  } else if(availableAttributes && availableAttributes.length > 0) {
    return (<>
      <h3>Additional attributes</h3>
      
      <ToggleList 
        originalItems={memberAttributes}
        handleChange={handleChange}
        reset={false}
        availableItems={availableAttributes}
        idAttribute="attributeId"
        labelAttribute="attributeName"
      />
      </>
    )
  } else {
    return(null)
  }
}


export default SaveMember