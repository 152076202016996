import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import BiblePassageLink from '../../components/BiblePassageLink'

const SermonsForEvent = ({sermons}) => {
    return (
        <>
            <h2>Sermon 
            {
                sermons.length > 1 &&
                <>s</>
            }
            </h2>
            <div id="sermons">
            {
                sermons.map((sermon,) => 
                    <div className="note">
                        <h3><Link to={`/sermon-series/detail/${sermon.sermonSeries.sermonSeriesId}`}>{sermon.sermonSeries.sermonSeriesTitle}</Link></h3>
                        <p key={`sermon${sermon.sermonId}`}>{sermon.sermonTitle}</p>
                        {
                            sermon.sermonDescription &&
                            <p key={`sermon${sermon.sermonId}description`}>{sermon.sermonDescription}</p>
                        }
                        {
                            sermon.biblePassages.map(passage => 
                                <p key={passage.biblePassageId}>
                                    <BiblePassageLink unStructuredReference={passage} />
                                </p>    
                                
                            )
                        }


                    </div>
                )
            }
            </div>
            {
                sermons.length === 0 &&
                <p className="no-results">No sermons associated to this event</p>
            }

            

        </>
    )
}


export default SermonsForEvent