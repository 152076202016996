
const {API_PATH,authHeader} = require('./common')
const { handleErrors } = require('../lib/call-api')

export const save = (listOfEventIds,listOfAttendees) => {
 
    return new Promise((resolve,reject) => {
      try {
      const body = {
        "eventIds": listOfEventIds,
        "attendeeIds": listOfAttendees.map(attendee => attendee.userId)
      }
  
      validate(listOfEventIds,listOfAttendees).then(() => {
        fetch(`${API_PATH}/api/attendee/save`, {
          "method": "post",
          "headers": {
            "Accept": "application/json",
            "Content-Type": "application/json",
            ...authHeader()
          },
          "body": JSON.stringify(body)
        }).then(response => 
          handleErrors(response)
        ).then(response => {
          resolve(response.memberDetail)
        }).catch(error => {
          reject(error)
        })
      }).catch((e) => {
        reject(e)
      })
    } catch(e) {
      console.error(e)
      throw new Error(`Ran into a technical issue. Sorry.`)
    }
    })
}

const validate = (data) => {
  return new Promise((resolve,reject) => {
    if(data.firstName === '' || data.lastName === '' || data.emailAddress === '') {
      reject('Please provide a name and email address.')
    } else {
      resolve()
    }
  })
}
