import React, { createContext, useContext } from 'react'
const TypeAheadContext = createContext()

const reducer = (state, action) => {
  if(action.type === "addItem") {
    return {
      ...state,
      "selectedItems": [
        ...state.selectedItems,
        action.payload
      ]
      
    }
  } else if(action.type === "removeItem") {
    return {
      ...state,
      "selectedItems": state.selectedItems.filter(previousItem => previousItem[state.itemKey] !== action.payload[state.itemKey]) 
    }
  } else if(action.type === "setSelectedItems") {
    console.log(action.payload)
    return {
      ...state,
      "selectedItems": action.payload
    }

  } else if(action.type === "setAllItems") {
    return {
      ...state,
      "allItems": action.payload
    }
  } else if(action.type === "setAttributesToSearch") {
    return {
      ...state,
      "attributesToSearch": action.payload
    }
  } else if(action.type === "setItemKey") {
    return {
      ...state,
      "itemKey": action.payload
    } 
  } else if(action.type === "setSearchMatches") {
    return {
      ...state,
      "searchMatches": action.payload
    }  
  } else {
    throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const TypeAheadProvider = (props) => {

    let [ state, dispatch ] = React.useReducer(
        reducer, 
        {
          "itemKey": props.itemKey,
          "allItems": props.allItems,
          "selectedItems": (props.initialList) ? props.initialList : [],
          "attributesToSearch": props.attributesToSearch,
          "searchMatches": [],
        });
    let initialState = [ state, dispatch ];
        
    return (
        <TypeAheadContext.Provider value={initialState}>
           {props.children}
        </TypeAheadContext.Provider>
    )
}

const useTypeAheadState = () => {
    const context = useContext(TypeAheadContext)
    if (context === undefined) {
      throw new Error('useTypeAheadState must be used within a TypeAheadProvider')
    }
    return context
}

export { useTypeAheadState, TypeAheadProvider }