import React from 'react'
import { useHistory } from 'react-router-dom';
import { setPreviousPath } from '../../lib/index'

import dateManager from '@ministry-squads-common/date-manager'
import {getCurrentMonth,getPreviousMonth,getNextMonth} from '../../lib/calendar'
import '../Calendar.scss'

  const CalendarFilters = ({dateToUse,setFunction}) => {
   
    const history = useHistory()

    const currentMonth = getCurrentMonth(dateToUse)

    const previous = () => {
      const prevMonth = getPreviousMonth(dateToUse)
      setFunction(new Date(prevMonth.year,prevMonth.month,1))      
      const newPath = `/calendar/${prevMonth.month+1}/${prevMonth.year}`
      setPreviousPath(newPath)
      history.push(newPath)
 
    }
  
    const next = () => {
      const nextMonth = getNextMonth(dateToUse)
      setFunction(new Date(nextMonth.year,nextMonth.month,1))
      const newPath = `/calendar/${nextMonth.month+1}/${nextMonth.year}`
      setPreviousPath(newPath)
      history.push(newPath)
    }
  
    const showPrevious = () => {
      return true   
    }
  
    const showNext = () => {
      return true
    }
    
    return (
      <div className="card">
        <div className="filters inline" id="scheduleFilters">
      
       
          <div id="date-range">
            <div>
            {
              showPrevious() === true  &&
              <button className="btn btn-link btn-slim btn-previous btn-icon" onClick={() => {previous()}}>Previous</button>
            }
            </div>
            <div id="summary"><h3>{ dateManager.formatDate(dateToUse,'MONTH_ABBR')} {   currentMonth.year }</h3> </div>
            <div id="next">
            {
              showNext() === true &&
              <button className="btn btn-link btn-slim btn-next btn-icon-after" onClick={() => {next()}}>Next</button>
            }
            </div>
          </div>
      </div>  
      </div>
    )
  }

export default CalendarFilters