import dateManager from '@ministry-squads-common/date-manager'
import {get,post,put} from '../lib/http.js'

const save = (eventSeriesData) => {
  return new Promise((resolve,reject) => {
    validate(eventSeriesData).then(result => {
      if(result === true) {
        return
      } else {
        reject(result)
      }
    }).then(() => {
      const {eventSeriesId,eventSeriesName, startTime,endTime,rangeStartDate,repeatEvery,dayOfWeek,teamsToAssignActions,rangeEndDate,eventTypeId,attendees} = eventSeriesData

        const timezone = dateManager.currentTimeZone()
        const body = {
          "eventSeriesData": {
            "eventSeriesId": eventSeriesId,
            "eventSeriesName": eventSeriesName,
            "startTime": startTime,
            "endTime": endTime,
            "timeZone": timezone,
            "rangeStartDate": dateManager.formatDate(rangeStartDate,'URL_PARAMETER'),
            "rangeEndDate": rangeEndDate,
            "recurrencePattern": repeatEvery,
            "dayOfWeek": dayOfWeek,
            "eventTypeId": eventTypeId,
            "attendeeIds": (attendees && attendees.length > 0) ? attendees.map(attendee => attendee.userId) : null,
            "assignableTeamsActions": teamsToAssignActions
          }
        }

        const saveFunction = (eventSeriesId === null) ? post : put

        return saveFunction('/api/event-series',body).then((resp) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })


    }).catch(customErrorObj => {
      reject(customErrorObj)
    })

  })
}

  
  const validate = (eventSeriesData) => {
    const {eventSeriesName, startTime,endTime,rangeStartDate, rangeEndDate,repeatEvery,eventTypeId} = eventSeriesData
    return new Promise((resolve,reject) => {
      const newErrorObj = getInitialObject()
      let haveError = false

      if(!eventSeriesName || eventSeriesName === '') {
        haveError = true
        newErrorObj.eventName = "Please provide a name for your event"
      } 

      if(!eventTypeId || parseInt(eventTypeId) === -1) {
        haveError = true
        newErrorObj.eventType = "Please select a type of event"
      } 

      if(!rangeStartDate || rangeStartDate === '') {
        haveError = true
        newErrorObj.startDate = "Please provide a start date"
      } 
      
      if(!rangeEndDate || rangeEndDate === '') {
        haveError = true
        newErrorObj.endDate = "Please provide an end date"
      } else if(rangeStartDate && rangeStartDate > rangeEndDate) {
        haveError = true
        
        newErrorObj.endDate = `${rangeEndDate} Must be after the start date`
      }
        
      if(!startTime || startTime === '')  {
        haveError = true
        newErrorObj.startTime = 'Please provide a start time'
      }

      if(!endTime || endTime === '')  {
        haveError = true
        newErrorObj.endTime = 'Please provide an end time'
      } else if(startTime && startTime >= endTime) {
        haveError = true
        newErrorObj.endTime = 'Must be after the start time.'
      }

      if(repeatEvery !== "none") {
        if (!rangeEndDate || rangeEndDate === '') {
          haveError = true
          newErrorObj.rangeEndDate = 'Please provide date your recurring meeting should stop'
        } else if(rangeEndDate && dateManager.diff(rangeEndDate,rangeStartDate,'SECONDS') < 0) {
          haveError = true
          newErrorObj.rangeEndDate = 'Must be after the end date.'
        }
      }

      if(haveError === true) {
        reject(newErrorObj)
      } else {
        resolve(true)
      }
    })
  }

  const getInitialObject = () => {
    return {
      "eventSeriesName": null,
      "rangeStartDate": null,
      "rangeEndDate": null,
      "repeatEvery": null,
      "repeatDayOfWeek": null,
      "repeatDayOfMonth": null,
      "repeatDayOfWeekInstance": null,
      "timeOfDay": null,
      "monthRepeatGeneralError": null,
      "generalPageError": null
    }
  }

  const detail = (eventSeriesId) => {


    return new Promise((resolve,reject) => {
      get(`/api/event-series/${eventSeriesId}`).then(resp => {
        resolve(resp)
      }).catch(e => {
        reject(e)
      })
    })
    

  }

export {save,detail}