import dateManager from '@ministry-squads-common/date-manager'
const authPath = process.env.REACT_APP_AUTH_URI || ""

const sessionManager = () => {
  let SESSION_TIMER
  let TOKEN_TIMER

  const initialSession = {
    userData: null,
    orgData: null,
    currentWeek: null,
    accessToken: null,
    tokenExpiresOn: null,
    sessionExpiresOn: null,
    availableAttributes: null
  }

  let sessionData = initialSession

  const WARN_WITH_MINUTES_REMAINING = 2
  const startSessionTimer = () => {
    //console.log(`${new Date()}: session timer started`)
    clearInterval(SESSION_TIMER)
    let sessionCountDown = dateManager.diff(sessionData.sessionExpiresOn,new Date(),'SECONDS')
    SESSION_TIMER = setInterval(() => {
    
      const minutesLeft = sessionCountDown / 60
      if(sessionCountDown > 0) {
        sessionCountDown = sessionCountDown - 1
        
        const uiCounter = document.getElementById("time-out-counter")
          if(uiCounter) {
            const minutes = Math.floor(sessionCountDown / 60)
            const seconds = sessionCountDown % 60
            const secondsDisplay = (seconds < 10) ? `0${seconds}` : seconds
            uiCounter.innerHTML = `${minutes}:${secondsDisplay}`
          }

        if(minutesLeft === WARN_WITH_MINUTES_REMAINING) {
          //console.log(`${new Date()}: warning shown`)
          showWarning()
        }

        if(sessionCountDown % 60 === 0) {
          //console.log(`${new Date()}: timer at ${sessionCountDown/60} minutes`)
        }

      } else { 
        clearSession(true)
      }      
    },1000)
    //console.log(`starting session at ${new Date()}. should expire in ${sessionCountDown/60} minutes`)
    return () => {
      clearInterval(SESSION_TIMER)
    }
  }
  
  const checkSession = () => {
    const sessionData = getSession()
    //console.log(`${new Date()}: visibility changed to ${document.visibilityState}, session expires at ${sessionData.sessionExpiresOn}`)  
  
    if(dateManager.diff(sessionData.sessionExpiresOn,new Date(),'SECONDS') <= 0) {
      //console.log(`${new Date()}: logging out. session expired while tab was not active.`)  
      clearSession(true)
    }
  }

  const startTokenTimer = () => {
    // this function will make a call to the refresh endpoint when there are REFRESH_ON_SECONDS
    // left until the access token will expire.
    // it allows us to keep the access token alive for API calls on the page

    clearInterval(TOKEN_TIMER)

    const REFRESH_ON_SECONDS = 5

    let tokenCountDown = dateManager.diff(sessionData.tokenExpiresOn,new Date(),'SECONDS')
    TOKEN_TIMER = setInterval(() => {
      if(tokenCountDown > REFRESH_ON_SECONDS + 1) {
        tokenCountDown = tokenCountDown - 1
      } else if(!isNaN(tokenCountDown)) { 
        // refresh the session but don't restart the timer, because the user didn't take any action
        refreshSession(false)
      }      
    },1000)
    return () => {
      clearInterval(TOKEN_TIMER)
    }
  }

  const getToken = () => {
    return sessionData.accessToken
  }

  const setSession = (payload,restartSessionTimer) => {
    sessionData.userData = payload.userData
    sessionData.orgData = payload.orgData
    sessionData.currentWeek = payload.currentWeek
    sessionData.accessToken = payload.accessToken
    sessionData.tokenExpiresOn = payload.tokenExpiresOn
    sessionData.sessionExpiresOn = payload.sessionExpiresOn
    sessionData.availableAttributes = payload.availableAttributes
    
    if(restartSessionTimer === true) startSessionTimer()
    startTokenTimer()
  }

  const clearSession = async(isTimeout) => {
    //console.log(`${new Date()}: session cleared`)
    // if the user timed out, the refresh token will already be invalid
    // if the user clicked "logout" we need to explicitly invalidate the refresh
    // token by calling the logout endpoint
    if(isTimeout === false) await fetch(`${authPath}/auth/logout`, { "method": 'delete' })

    const timeoutParam = (isTimeout === true) ? "&timeout=true" : ""
    clearInterval(SESSION_TIMER)
    clearInterval(TOKEN_TIMER)
    sessionData = initialSession
    window.location = `/login?redirect=${window.location.pathname}${window.location.search}${timeoutParam}`

  }

  const refreshSession = async (restartSessionTimer) => {
    const handleErrors = async (response) => {
      const asJson = await response.json()

      if(response.status !== 200) {
        throw new Error(asJson.message)
      } else {
        return asJson
      }
    }

    return fetch(`${authPath}/auth/refresh-token`, {
      "method": "post",
      "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      "body": JSON.stringify({
        "refresh": restartSessionTimer === true
      })
    }).then(response => 
      handleErrors(response)
    ).then(response => {
      setSession(response,restartSessionTimer)
      return sessionData
    }).catch(error => {
      console.error('error refreshing session', error)
      clearSession()
    })
  }

  const getSession = () => {
    return sessionData
  }

  const showWarning = async () => {
    
    const timeoutWarningContainer = document.getElementById("timeout-warning-container")
    timeoutWarningContainer.classList.add('active')

    const timeoutWarning = document.getElementById("timeout-warning")
    const modalWidth = timeoutWarning.clientWidth

    const viewportWidth = window.innerWidth

    let modalTop = 75

    let modalLeft = (viewportWidth - modalWidth)/2;

    timeoutWarning.style.left = `${modalLeft}px`
    timeoutWarning.style.top = `${modalTop}px`

  }

  return {
      getToken,
      setSession,
      clearSession,
      refreshSession,
      getSession,
      checkSession
  }
};

export default sessionManager()