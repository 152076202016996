import React, { createContext, useContext,useEffect } from 'react'
import {scrollToTop} from '../lib/index'
const SecondaryContentContext = createContext()

const reducer = (state, action) => {
  scrollToTop()
  if(action.type === "toggleIsOpen") {
    return {
      ...state,
      "isOpen": state.isOpen === false,
      "counterKey": state.counterKey+1
    }
  } else if(action.type === "close") {
    return {
      ...state,
      "isOpen": false 
    }
  } else if(action.type === "togglePopup") {
    return {
      ...state,
      "popupIsOpen": state.popupIsOpen === false
    }
  } else if(action.type === "closePopup") {
    return {
      ...state,
      "popupIsOpen":false
    }
  } else {
    throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const SecondaryContentProvider = ({children}) => {
    let [ state, dispatch ] = React.useReducer(
        reducer, 
        {
            "isOpen": false,
            "counterKey": 1,
            "popupIsOpen": false
        });
    let modalValue = [ state, dispatch ];

    useEffect(() => {
      const containerId = 'secondary-content-container'
      
      const element = document.getElementById(containerId);
      const className = 'active'
  
      // doesn't work in IE9. I don't think I care.
      if(state.isOpen === true) {
        element.classList.add(className)
      } else {
        element.classList.remove(className)
      }  
    },[state.isOpen])


    useEffect(() => {
      const containerId = 'popup-content-container'

      const element = document.getElementById(containerId);
      const className = 'active'
      // doesn't work in IE9. I don't think I care.
      if(state.popupIsOpen === false) {
        element.classList.remove(className)
      }


  
    },[state.popupIsOpen])

    return (
        <SecondaryContentContext.Provider value={modalValue}>
            { children }
        </SecondaryContentContext.Provider>
    )
}

const useSecondaryContentState = () => {
    const context = useContext(SecondaryContentContext)
    if (context === undefined) {
      throw new Error('useSecondaryContentState must be used within a SecondaryContentProvider')
    }
    return context
}

export { useSecondaryContentState , SecondaryContentProvider}