import React from 'react'

const Privacy = () => {
  return (
    <section className="page page-slim">
      
<h1>Our Privacy Policy</h1>

<div>Last updated October 22, 2021</div>

<p>Thank you for being part of our Ministry Squads community!</p>

<p>This privacy notice describes how we use the data that is collected through app.ministrysquads.com</p>

<ul>
  <li><a href="#whatdata">What data do we collect and how is it used?</a></li>
  <li><a href="#sharing">Do we share your information with anyone?</a></li>
  <li><a href="#howlong">How long do we keep your information</a></li>
</ul>

<section> 
  <h3 id="whatdata">What data do we collect and how is it used?</h3>
  <p>We collect data from two potential sources: directly from users, and systematically.</p>

  <h4>Data we collect directly from users</h4>
  <p>
    We collect the following personal data about each user: first name, last name, email address. This data could be collected directly from you (if you registered yourself), or by the administrators of your organization's account.
  </p>
  <p>
    Once a person is registered in the system, an administrator can then assign you to squads, songs, roles, and events for the purposes of planning events and services.
  </p>

  <p>We will use your email address to communicate with you. Some of these communications are triggered by the actions your administrator can take above. We also use your email address to help you manage your MinistrySquads account.</p>

  <h4>Data we collect systematically</h4>
  <p>There is also data we automatically collect when you visit, use, or navigate the Website. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Website and other technical information.</p>

  <p>This information is primarily needed to maintain the security and operation of our Website; for our internal analytics and reporting purposes; and to troubleshoot and diagnose any issues with the web site.</p>


</section>

<section>
  <h2 id="sharing">Do we share your information with anyone?</h2>
  <p>No. We just use your data on our web site and to communicate with you.</p>
</section>

<section>
  <h2 id="howlong">How long do we keep your information?</h2>
  <p>Right now we keep your information indefinitely. As our product matures, we'll create a data purging process that will remove data from our records after a certain amount of inactivity.</p>
</section>

<section>
  <h2 id="keepsafe">How do we keep your information safe?</h2>
  <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Website is at your own risk. You should only access the Website within a secure environment.</p>
</section>


7. WHAT ARE YOUR PRIVACY RIGHTS?

In Short:  You may review, change, or terminate your account at any time.

If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.

If you are a resident in Switzerland, the contact details for the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.

Account Information

If you would at any time like to review or change the information in your account or terminate your account, you can:
Contact us using the contact information provided.
Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.

Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Website . To opt-out of interest-based advertising by advertisers on our Website visit http://www.aboutads.info/choices/.

Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
Contact us using the contact information provided.

8. CONTROLS FOR DO-NOT-TRACK FEATURES

Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. 

9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?

In Short:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.

California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.

If you are under 18 years of age, reside in California, and have a registered account with the Website , you have the right to request removal of unwanted data that you publicly post on the Website . To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Website , but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).

CCPA Privacy Notice

The California Code of Regulations defines a "resident" as:

(1) every individual who is in the State of California for other than a temporary or transitory purpose and
(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose

All other individuals are defined as "non-residents."

If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.

What categories of personal information do we collect?

We have collected the following categories of personal information in the past twelve (12) months:

Category	Examples	Collected
A. Identifiers
Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address and account name

YES

B. Personal information categories listed in the California Customer Records statute
Name, contact information, education, employment, employment history and financial information

YES

C. Protected classification characteristics under California or federal law
Gender and date of birth

NO

D. Commercial information
Transaction information, purchase history, financial details and payment information

NO

E. Biometric information
Fingerprints and voiceprints

NO

F. Internet or other similar network activity
Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements

NO

G. Geolocation data
Device location

NO

H. Audio, electronic, visual, thermal, olfactory, or similar information
Images and audio, video or call recordings created in connection with our business activities

NO

I. Professional or employment-related information
Business contact details in order to provide you our services at a business level, job title as well as work history and professional qualifications if you apply for a job with us

NO

J. Education Information
Student records and directory information

NO

K. Inferences drawn from other personal information
Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics

NO


We may also collect other personal information outside of these categories instances where you interact with us in-person, online, or by phone or mail in the context of:
Receiving help through our customer support channels;
Participation in customer surveys or contests; and
Facilitation in the delivery of our Services and to respond to your inquiries.
How do we use and share your personal information?

More information about our data collection and sharing practices can be found in this privacy notice .

You may contact us or by referring to the contact details at the bottom of this document.

If you are using an authorized agent to exercise your right to opt-out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.

Will your information be shared with anyone else?

We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.

We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal data.

__________  has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding 12 months. __________ will not sell personal information in the future belonging to website visitors, users and other consumers.

Your rights with respect to your personal data

Right to request deletion of the data - Request to delete

You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation or any processing that may be required to protect against illegal activities.

Right to be informed - Request to know

Depending on the circumstances, you have a right to know:
whether we collect and use your personal information;
the categories of personal information that we collect;
the purposes for which the collected personal information is used;
whether we sell your personal information to third parties;
the categories of personal information that we sold or disclosed for a business purpose;
the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and
the business or commercial purpose for collecting or selling personal information.
In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.

Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights

We will not discriminate against you if you exercise your privacy rights.

Verification process

Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.

We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. If, however, we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity, and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.

Other privacy rights
you may object to the processing of your personal data
you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the data
you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
you may request to opt-out from future selling of your personal information to third parties. Upon receiving a request to opt-out, we will act upon the request as soon as feasibly possible, but no later than 15 days from the date of the request submission.
To exercise these rights, you can contact us  or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.  

10. DO WE MAKE UPDATES TO THIS NOTICE?     

In Short:  Yes, we will update this notice as necessary to stay compliant with relevant laws.

We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.

11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?     

If you have questions or comments about this notice, you may email us at __________  or by post to:

__________
__________
__________

12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?     

Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please visit: __________ .
</section>
  )
}


export default Privacy