import React from "react"
import { getDisplayDate,getEventDisplayName,getEventDisplayTimes } from '../../events/common.js'

const DisplayEvent = ({eventList}) => {
  if(eventList.length > 0) {
    const displayDate = getDisplayDate(eventList)
    const eventName = getEventDisplayName(eventList[0])
    const eventTimes = getEventDisplayTimes(eventList)
  
    return (
      <>
        <div>{ eventName }</div>
        <div>{ displayDate }</div>
        <div className="small">{ eventTimes } </div>
      </>

    )
  } else {
    return(<></>)
  }  
}

export default DisplayEvent