const sessionManager = require('../lib/session')

const {API_PATH,authHeader} = require('./common')
const { handleErrors } = require('../lib/call-api')

export const save = (newMemberData,originalMemberData=null,registerUser = false,userStatus = null,assignmentData = { "assignToIds" : [], "removeFromIds": [], "teamAdminData": []},memberAttributeActions = { "assignToIds" : [], "removeFromIds": []}) => {
 
    return new Promise((resolve,reject) => {
      try {
      const session = sessionManager.default.getSession()
      const body = {
        "orgId": session.orgData.orgId,
        "registerUser": registerUser,
        "memberData": {
          ...newMemberData,
          "fullName": `${newMemberData.firstName} ${newMemberData.lastName}`,
          "originalFullName": (originalMemberData !== null) ? originalMemberData.fullName : null,
          "originalEmail": (originalMemberData !== null) ? originalMemberData.emailAddress : null,
          "status": userStatus,
          "rolesToRemove": assignmentData.removeFromIds,
          "rolesToAdd":  assignmentData.assignToIds,
          "teamAdminData":  assignmentData.teamAdminData,
          "attributeActions": memberAttributeActions
        } 
      }
  
      const verb = (newMemberData.userId) ? 'put' : 'post'
    
      validate(newMemberData).then(() => {
        fetch(`${API_PATH}/api/member/save`, {
          "method": verb,
          "headers": {
            "Accept": "application/json",
            "Content-Type": "application/json",
            ...authHeader()
          },
          "body": JSON.stringify(body)
        }).then(response => 
          handleErrors(response)
        ).then(response => {
          resolve(response.memberDetail)
        }).catch(error => {
          reject(error)
        })
      }).catch((e) => {
        reject(e)
      })
    } catch(e) {
      console.error(e)
      throw new Error(`Ran into a technical issue. Sorry.`)
    }
    })
}

const validate = (data) => {
  return new Promise((resolve,reject) => {
    if(data.firstName === '' || data.lastName === '' || data.emailAddress === '') {
      reject('Please provide a name and email address.')
    } else {
      resolve()
    }
  })
}

export const getList = () => {
  return new Promise((resolve,reject) => {
    fetch(`${API_PATH}/api/org/members`, {
      "method": 'get',
      "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json",
        ...authHeader()
      }
    }).then(response => 
      handleErrors(response)
    ).then(response => {
      resolve(response.memberList)
    }).catch(error => {
      reject(error)
    })
  
  })
}