import React from 'react'
import { useParams } from 'react-router'
import {useAssignmentsState } from '../context'
import dateManager from '@ministry-squads-common/date-manager'
import { useHistory } from 'react-router-dom';
import TeamFilter from './TeamFilter'
import { setPreviousPath } from '../../lib/index'

import '../styles.scss'

const ScheduleFilters = () => {
  const [state,updateState] = useAssignmentsState()
  const formatUrlDate = (dt) => dateManager.formatDate(dt,'URL_PARAMETER')

  const history = useHistory()

  const MONTHS_AT_A_TIME = 4

  const {startDate,endDate} = useParams() // hooks can't be used in window.onpopstate

  // window.onpopstate is triggered when the user clicks the back or forward buttons
  // make sure state is updated to reflect the params in the URL when this happens
  window.onpopstate = () => {
    const paramAsDate = (paramDate) => paramDate
  
    const getStart = (fromParam) => (fromParam) ? paramAsDate(fromParam) :new Date()
  
    const getEnd = (fromParam) => (fromParam) ? paramAsDate(fromParam) : dateManager.add(new Date(),MONTHS_AT_A_TIME,'MONTHS')
  
    updateState({ "type": "setDateRange", "payload": {
      "startDate": getStart(startDate),
      "endDate": getEnd(endDate)
      }
    })
  }

  const previous = () => {
    const previousStart = dateManager.add(state.dateRange.startDate, -1 * MONTHS_AT_A_TIME,'MONTHS')
    const previousEnd = dateManager.add(state.dateRange.endDate, -1 * MONTHS_AT_A_TIME,'MONTHS')

      const newPath =`/schedule/${formatUrlDate(previousStart)}/${formatUrlDate(previousEnd)}` 
      history.push(newPath)

      setPreviousPath(newPath)

      updateState({ "type": "setDateRange", "payload": {
        "startDate": previousStart,
        "endDate": previousEnd
        }
      })
  }

  const next = () => {
    const nextStart = dateManager.add(state.dateRange.startDate, MONTHS_AT_A_TIME,'MONTHS')
    const nextEnd = dateManager.add(state.dateRange.endDate, MONTHS_AT_A_TIME,'MONTHS')

    const newPath =`/schedule/${formatUrlDate(nextStart)}/${formatUrlDate(nextEnd)}`
      history.push(newPath)

      setPreviousPath(newPath)

    updateState({ "type": "setDateRange", "payload": {
      "startDate": nextStart,
      "endDate": nextEnd
      }
    })
  }

  const haveEvents = () => state.events && state.events.length > 0
  const haveMinMax = () => state.minMax && state.minMax.firstEventDate !== null && state.minMax.lastEventDate !== null
  
  const showPrevious = () => {
    if(state.events.length === 0) {
      return false
    } else {
      return dateManager.diff(state.minMax.firstEventDate,state.dateRange.startDate,'DAYS') < 0
    }
  }

  const showNext = () => {
    if(state.events.length === 0) {
      return false
    } else {
      return dateManager.diff(state.minMax.lastEventDate,state.dateRange.endDate,'DAYS') > 0
    }
  }
  
  const getBeginDisplayDate = (rangeStartDate) => {
    if(dateManager.diff(state.minMax.firstEventDate,rangeStartDate,'DAYS') < 0) {
      return dateManager.singleDate(rangeStartDate)  
    } else {
      return dateManager.singleDate(state.minMax.firstEventDate)
    }
    
  }


  const getEndDisplayDate = (dt) => {
    return dateManager.singleDate(dt)
  }


  return (
    (haveEvents() === true || haveMinMax() === true) &&
    <div className="card">
      <div className="filters inline" id="scheduleFilters">
        <TeamFilter />
    
      {
        haveMinMax() === true &&
        <div id="date-range">
          <div>
          {
            showPrevious() === true  &&
            <button className="btn btn-link btn-slim btn-previous btn-icon" onClick={() => {previous()}}>Previous</button>
          }
          </div>
          <div id="summary">Showing assignments from {getBeginDisplayDate(state.dateRange.startDate)} to {getEndDisplayDate(state.dateRange.endDate)}</div>
          <div id="next">
          {
            showNext() === true &&
            <button className="btn btn-link btn-slim btn-next btn-icon-after" onClick={() => {next()}}>Next</button>
          }
          </div>
        </div>
      }  
    </div>  
    </div>
  )
}

export default ScheduleFilters