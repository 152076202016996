import React, { Fragment, useState, useEffect } from 'react'
import sessionManager from '../lib/session'
import './css/UserAvatar.scss'
const UserAvatar = ( props ) => {
    const state = sessionManager.getSession()
    const userData = (props.userData) ? props.userData : state.userData
    const [initials, setInitials] = useState()

    const sizeClass = (props.size==='big') ? ' big' : ''

    useEffect(() => {
        setInitials(userData.firstName[0] + userData.lastName[0])
    },[userData])

    return(
        <Fragment>
           {
               (userData && userData.avatar) && 
               <div 
                className={`user-avatar with-image ${sizeClass}` }
                style={{backgroundImage:'url(' + userData.avatar + ')'}}>&nbsp;</div>
           }
            {
               (!userData.avatar) && 
               <div 
                className={`user-avatar with-initials ${sizeClass}`}>
                {initials}
                </div>
           }
          
        </Fragment>
    )
}

export default UserAvatar