import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import {getEventDetailsForWeek} from '../../lib/index'
import {getEventDisplayName,getDisplayDate} from '../common'

// groupKey allows you to control the sets of radio buttons in this component
// by default all instances of this component will be treated as one set of radio buttons
// if you pass in a groupKey and there are multiple instances of this component, each instance using
// that group key will be treated as one set of radio buttons
const EventSelector = ({allEvents,handleChange,unique,groupKey="eventSelector",showHeader = true}) => {
  const [selectedEvent,setSelectedEvent] = useState([])

  useEffect(() => {
    // if there is only one to pick from, pre-select it
    if(allEvents.eventsByWeek.length === 1 && allEvents.eventsByWeek[0].eventsByGroup.length === 1) {
      selectEvent(allEvents.eventsByWeek[0].eventsByGroup[0])
    } else {
      setSelectedEvent(() => [])
    }
    
  },[unique])

  
  const getEventDetail = (scheduledEventId) => {
    const thisEvent = allEvents.events.filter(evt => evt.scheduledEventId === scheduledEventId)
    if(thisEvent.length === 1) {
      return thisEvent[0]
    } else {
      throw new Error("can't get event details")
    }
  }

  const getGroupEvents = (eventGroup) => {
    return getEventDetailsForWeek(eventGroup.eventIds,allEvents.events) 
  }

  const selectEvent = (eventGroup) => {
    const eventsToSelect = getGroupEvents(eventGroup)
    setSelectedEvent(() => eventsToSelect)
    handleChange(eventsToSelect)
  }

  const isSelected = (currentlySelected,eventGroupToCheck,groupsForWeek) => {
   // if(groupsForWeek.length === 1) {
   //   return true
   // } else {
      const eventIds = eventGroupToCheck.eventIds.map(scheduledEventId => scheduledEventId)
      return currentlySelected.filter(value => eventIds.includes(value.scheduledEventId)).length === eventGroupToCheck.eventIds.length
   // }
  }
  
  return (
    <>
      {
        allEvents && allEvents.eventsByWeek.length === 0 &&
        <div>
          No recent events. You'll need to <Link to='/event/create'>add an event</Link> before proceeding.
        </div>
      }
      { 
        allEvents && allEvents.eventsByWeek.length > 0 && 
        <div className="group-with-title">
          {
            showHeader === true &&
            <header className="grouped-row">
              <div>Select an event</div>
            </header>
          }

          {
   
            allEvents.eventsByWeek.map((thisWeek,weekCtr) => 
              thisWeek.eventsByGroup.map(eventGroup => 
                <div className="group grouped-row" key={`event-group-container-${eventGroup.eventIds[0]}`}>
                    <div className="form-group">
                      <input id={`event-group-${eventGroup.eventIds[0]}`} name={`event-group-${groupKey}`} type='radio' className="form-control" onChange={() => {selectEvent(eventGroup)}} checked={isSelected(selectedEvent,eventGroup,thisWeek.eventsByGroup)} /> 
                      <label className="label-right" htmlFor={`event-group-${eventGroup.eventIds[0]}`}>
                        {getDisplayDate(getGroupEvents(eventGroup))}
                        <br/>{ getEventDisplayName(getEventDetail(eventGroup.eventIds[0])) }
                      </label>
                    </div>
                 </div>
                )
            )
          }

        </div>
      }
    </>
  )
}

export default EventSelector