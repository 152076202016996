import React  from 'react'
import dateManager from '@ministry-squads-common/date-manager'
import { getDisplayTime } from '../../lib/index'
import '../events.scss'

const EventRepeatSummary = ({eventData}) => {
  const {repeatEvery,startDate,startTime,endTime,endDate,rangeEndDate} = eventData
  const formatTimeForSummary = (militaryTime) => {

    const hrsMinuts = militaryTime.split(":")
    return getDisplayTime(parseInt(hrsMinuts[0]), hrsMinuts[1])
  }

  return (
    <section>
      {
        repeatEvery === 'none' && startDate && startTime && endTime && endDate &&
        <p>{dateManager.formatDate(startDate, "DAY_OF_WEEK")}, {dateManager.formatDate(startDate, "TEXT_SHORT")} at {formatTimeForSummary(startTime)} to  {formatTimeForSummary(endTime)}</p>
      }
      {
        repeatEvery === 'day' && startDate && startTime && endTime && endDate && rangeEndDate &&
        <p>Repeat every day at {formatTimeForSummary(startTime)} to {formatTimeForSummary(endTime)} from {dateManager.formatDate(startDate, "TEXT_FULL")} to {dateManager.formatDate(rangeEndDate, "TEXT_FULL")} </p>
      }
      {
        repeatEvery === 'week' && startDate && startTime && endTime && endDate && rangeEndDate &&
        <p>Repeat every {dateManager.formatDate(startDate, "DAY_OF_WEEK")} at {formatTimeForSummary(startTime)} to {formatTimeForSummary(endTime)} from {dateManager.formatDate(startDate, "TEXT_SHORT")} to {dateManager.formatDate(rangeEndDate, "TEXT_SHORT")}</p>
      }
    </section>
  )
}

export default EventRepeatSummary